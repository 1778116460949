<template>
  <div>
    <Loading v-model:active="isLoading" />
    <div class=" mt-2 mb-2 flex justify-center items-center">
        <AlertSuccess
            v-if="successMessage"
            :message="successMessage"
            @hide="successMessage = null"
        />
        <AlertError
            v-if="errorMessage"
            :message="[errorMessage]"
            @hide="errorMessage = null"
        />
    </div>
    <div class="sm:flex sm:justify-between sm:items-center pb-4">
        <div class="sm:flex sm:justify-center sm:items-end  mb-5">
            <div class="sm:mx-4  my-3">
                <label for="period_select" class="block text-sm font-bold text-red-900">Period</label>
                <select v-model="period_select" id="period_select" name="period_select" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded-md">
                  <option selected value>All</option>
                  <option value="today">Today</option>
                  <!-- <option value="yesterday">Yesterday</option>
                  <option value="this_week">This Week</option>
                  <option value="this_month">This Month</option> -->
                </select>
            </div>
            <div class="sm:mx-4  my-3">
                <button @click="reloadRequisitions()" type="button" class="inline-flex items-center px-7 py-1.5 mb-0.5 border border-transparent text-xs font-medium rounded shadow-md text-white bg-red-900 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    load
                </button>
            </div>
        </div>
        <!-- <div class="pr-3 italic capitalize text-red-900">
            {{ dateRange? dateRange.split("_").join(" ") : '' }}
        </div> -->
        <div class="relative flex">
            <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
            </div>
            <input type="text" v-model="filter" id="table-search" class="block flex-1 p-2 pl-10 sm:w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items">
        </div>
    </div>
    <div class="flex flex-col">
        <div class="-my-2  sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
                    <table class="w-full divide-y divide-gray-200">
                        <thead class="bg-teal-700">
                            <tr>
                                <!-- <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">#</th> -->
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"> Id Number</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Reason</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Description</th>
                                <!-- <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">FY</th> -->
                                <th scope="col" class="relative px-6 py-3">
                                <span class="sr-only">statuses</span>
                                </th>
                                <th scope="col" class="relative px-6 py-3">
                                <span class="sr-only">delete</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="(requisition) in filteredRequisitionsTable" :key="requisition.id" :class="requisition.is_urgent == 1? 'border-2 border-red-600' : ''">
                                <!-- <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {{ index+1 }}
                                </td> -->
                                <td class="px-6 py-4 break-words text-sm font-medium text-gray-900">
                                  <a :id="requisition.requisition_name" :name="requisition.requisition_name" class="text-blue-600 visited:text-purple-600 
                                        hover:underline cursor-pointer" @click.prevent="selectedRequisitionTotal = requisition.local_total_amount; showRequisition(requisition.id)">
                                          {{ requisition.requisition_name }}
                                  </a>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {{ requisition.purpose_of_requisition }}
                                </td>
                                <td class="px-6 py-4 break-words text-sm text-gray-500">
                                {{ requisition.summary }}
                                </td>
                                <!-- <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {{ requisition.financial_year }}
                                </td> -->
                                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <a @click.prevent="showTransitionStatuses(requisition.id)" class="bg-indigo-600 text-white text-xs  py-1 px-2 rounded-md cursor-pointer hover:bg-indigo-900 shadow-md">statuses</a>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <button  @click.prevent="selectedRequisitionTotal = requisition.local_total_amount; showRequisition(requisition.id)"  class="text-white bg-green-600 py-1 px-2 rounded-md text-xs cursor-pointer hover:bg-green-900 shadow-md">view</button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                              <td colspan="9">
                                  <nav class="bg-white px-4 py-3 flex items-center justify-between  sm:px-6" aria-label="Pagination">
                                      <div class="hidden sm:block">
                                      <p class="text-sm text-gray-700">
                                          Showing
                                          {{ ' ' }}
                                          <span class="font-medium">{{ fromPage }}</span>
                                          {{ ' ' }}
                                          to
                                          {{ ' ' }}
                                          <span class="font-medium">{{ toPage }}</span>
                                          {{ ' ' }}
                                          of
                                          {{ ' ' }}
                                          <span class="font-medium">{{ totalPageResults }}</span>
                                          {{ ' ' }}
                                          results
                                      </p>
                                      </div>
                                      <div class="flex-1 flex justify-between sm:justify-end">
                                      <a :class="prevPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="getRequisitions( pageUrl+prevPageUrl)"  class="relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Previous </a>
                                      <a :class="nextPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="getRequisitions( pageUrl+nextPageUrl)" class="ml-3 cursor-pointer relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Next </a>
                                      </div>
                                  </nav>
                              </td>
                          </tr>
                      </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <RequisitionerViewRequisitionModalComponent :requisition="requisitionProp" :requisitionAttachments="requisitionAttachmentsProp" :requisitionTransitions="requisitionTransitionsProp" :show="showRequisitionModal" @dismiss="resetValues(true);showRequisitionModal = false" >
    
      <template v-slot:footer>
        <div class="mt-4 pl-2 text-center">
            <p class="text-sm font-bold text-red-900">Budget Line Usage</p>
        </div>
        <div class="mt-5 sm:mx-6">
            <div class="flex flex-col">
                  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="shadow overflow-hidden ">
                            <table class="min-w-full divide-y divide-gray-200 border border-slate-300 ">
                                <thead class="bg-teal-700">
                                    <tr>
                                        <th scope="col" class="px-4 py-2 text-center text-xs font-medium text-white capitalize tracking-normal">Amount Recognised (a)</th>
                                        <th scope="col" class="px-4 py-2 text-center text-xs font-medium text-white capitalize tracking-normal">Amount Committed (b)</th>
                                        <th scope="col" class="px-4 py-2 text-center text-xs font-medium text-white capitalize tracking-normal">Total (a + b) </th>
                                        <th  scope="col" class="px-4 py-2 text-center text-xs font-medium text-white capitalize tracking-normal">Budget Line Amount</th>
                                        <th scope="col" class="px-4 py-2 text-center text-xs font-medium text-white capitalize tracking-normal">Amount Left</th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200 text-center">
                                    <tr >
                                        <td class="px-3 py-2 text-xs text-gray-500 border border-slate-300">
                                           {{ Number(recognized_amount).toLocaleString() }}
                                        </td>
                                        <td class="px-3 py-2 text-xs text-gray-500 border border-slate-300">
                                          {{ Number(committed_amount).toLocaleString() }}
                                        </td>
                                        <td class="px-3 py-2 text-xs text-gray-500 border border-slate-300">
                                          {{ (Number(recognized_amount) + Number(committed_amount)).toLocaleString() }}
                                        </td>
                                        <td class="px-3 py-2 text-xs text-gray-500 border border-slate-300">
                                          {{ Number(budget_line_amount).toLocaleString() }}
                                        </td>
                                        <td class="px-3 py-2 text-xs text-gray-500 border border-slate-300">
                                          {{ (Number(budget_line_amount) - (Number(recognized_amount) + Number(committed_amount))).toLocaleString() }}
                                        </td>                            
                                    </tr>
                                   
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="my-2 pl-2 text-center">
            <p class="text-sm font-bold text-red-900">Restriction Information</p>
        </div>
         <div class="mt-5 sm:mx-6">
            <div class="flex flex-col">
                  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="shadow overflow-hidden ">
                            <table class="min-w-full divide-y divide-gray-200 border border-slate-300 ">
                                <thead class="bg-teal-700">
                                    <tr>
                                        <th scope="col" class="px-2 py-2 text-center text-xs font-normal text-white capitalize tracking-normal">Restricted Amount</th>
                                        <th scope="col" class="px-2 py-3 text-center text-xs font-normal text-white capitalize tracking-normal">Current Month</th>
                                        <th scope="col" class="px-2 py-3 text-center text-xs font-normal text-white capitalize tracking-normal">Status</th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200 text-center">
                                    <tr>
                                        <td class="px-3 py-2 text-xs text-gray-500 border border-slate-300">
                                           {{ Number(restricted_amount).toLocaleString() }}
                                        </td>
                                        <td class="px-3 py-2 text-xs text-gray-500 border border-slate-300">
                                          {{ restricted_month != '--'? toMonthName(restricted_month) :  restricted_month }}
                                        </td>
                                        <td class="px-3 py-2 text-xs text-gray-500 border border-slate-300">
                                          {{ restricted_status }}
                                        </td>            
                                    </tr>
                                   
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-5 sm:mt-4">
          <AlertError
                v-if="errorMessage"
                :message="[errorMessage]"
                @hide="errorMessage = null"
            />
       </div>

        <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <div class="w-full p-3 rounded border border-gray-200 dark:border-gray-700 mb-2 sm:mb-0">
                <label for="actionToBeTaken" class="block text-sm font-bold text-red-900 sm:mt-px sm:pt-2">Action to be Taken </label>
                <div class="mt-1">
                    <select v-model="actionToBeTaken" @change="resetValues()" id="actionToBeTaken" name="actionToBeTaken" class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                        <option value="0">Make a choice</option>
                        <option value="1">Approve</option>
                        <option value="2">Send Back</option>
                        <option value="3">Reject</option>
                       
                    </select>
                </div>
              </div>
              <div v-if="actionToBeTaken == 1" class="w-full p-3 rounded border border-gray-200 dark:border-gray-700">
                <label for="expenditureType" class="block text-sm font-bold text-red-900 sm:mt-px sm:pt-2">Select Expenditure Type</label>
                <div class="mt-1">
                    <select v-model="expenditureType" id="expenditureType" name="expenditureType" class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                        <option value="0">Make a choice</option>
                        <option value="capex">Capex</option>
                        <option value="opex">Opex</option>
                        <option value="services">Services</option>
                    </select>
                </div>
              </div>
        </div>
        <div class="sm:grid sm:grid-cols-2 gap-2">
          <div v-if="actionToBeTaken == 1" class=" sm:border-gray-200 pt-1">
                <label for="country" class="block text-sm font-bold text-red-900 sm:mt-px pt-2"> Select Your Budget Line</label>
                <Combobox as="div" class="pt-2" v-model="selectedBudgetLine"  @update:modelValue="getBudgetlineInfo()">
                    <!-- <ComboboxLabel class="block text-sm font-medium text-gray-700">Assigned to</ComboboxLabel> -->
                    <div class="relative mt-1">
                    <ComboboxInput placeholder="Click the arrows or start typing name here" class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm" @change="query1 = $event.target.value;" :display-value="(person) => person.name" />
                    <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </ComboboxButton>

                    <ComboboxOptions v-if="filteredBudgetLines.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <ComboboxOption v-for="budgetLine in filteredBudgetLines" :key="budgetLine.id" :value="budgetLine" as="template" v-slot="{ active, selected }" >
                        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                            <span :class="['block truncate', selected && 'font-semibold']">
                            {{ budgetLine.name }}
                            </span>

                            <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                        </li>
                        </ComboboxOption>
                    </ComboboxOptions>
                    </div>
                </Combobox>
          </div>
          <div v-if="actionToBeTaken == 1" class=" sm:border-gray-200 pt-1">
                <label for="country" class="block text-sm font-bold text-red-900 sm:mt-px pt-2"> Select who to send to</label>
                <Combobox as="div" class="pt-2" v-model="selectedPerson">
                    <!-- <ComboboxLabel class="block text-sm font-medium text-gray-700">Assigned to</ComboboxLabel> -->
                    <div class="relative mt-1">
                    <ComboboxInput placeholder="Click the arrows or start typing name here" class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm" @change="query = $event.target.value" :display-value="(person) => person.name" />
                    <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </ComboboxButton>

                    <ComboboxOptions v-if="filteredPeople.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <ComboboxOption v-for="person in filteredPeople" :key="person.id" :value="person" as="template" v-slot="{ active, selected }">
                        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                            <span :class="['block truncate', selected && 'font-semibold']">
                            {{ person.name }}
                            </span>

                            <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                        </li>
                        </ComboboxOption>
                    </ComboboxOptions>
                    </div>
                </Combobox>
          </div>
          <div v-if="((Number(restricted_amount) > 0) && (Number(selectedRequisitionTotal) > Number(restricted_amount)) && (restricted_status == 'ON'))" class="mt-1 sm:col-span-2 text-center ">
            <p for="about" class="block text-sm font-bold text-red-600 ">Requisition Total exceeds restriction amount for this budget line</p>
          </div>
          <div v-if="(Number(budget_line_amount) > 0) && (Number(selectedRequisitionTotal) > (Number(budget_line_amount) - (Number(recognized_amount) + Number(committed_amount))))" class="mt-1 sm:col-span-2 text-center ">
            <p for="about" class="block text-sm font-bold text-red-600 ">Requisition Total exceeds amount left on budget line</p>
          </div>
          <div v-if="actionToBeTaken == 1 || actionToBeTaken == 2  || actionToBeTaken == 3" class="mt-1 sm:col-span-2">
            <label for="about" class="block text-sm font-bold text-red-900 sm:mt-px lg:pt-2 pb-2">Budget Holder Comments </label>
            <div class="mt-1 sm:mt-0 sm:col-span-1" >
                <textarea  maxlength="300" v-model="comment"  id="about" name="summary" rows="5" class=" shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md" />
            </div>
          </div>
        </div>
        <div class="mt-5 text-center">
              <button :disabled="((Number(restricted_amount) > 0) && (Number(selectedRequisitionTotal) > Number(restricted_amount)) && (restricted_status == 'ON')) || (Number(selectedRequisitionTotal) > (Number(budget_line_amount) - (Number(recognized_amount) + Number(committed_amount))))" v-if="(actionToBeTaken == 1) " type="button"  :class="actionToBeTaken == 1?'bg-green-600 focus:ring-green-500  hover:bg-green-700': ''" class=" w-1/3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm" @click="requisitionAction(requisitionProp.requisition_id)">Approve</button>
              <button v-if="(actionToBeTaken == 3)" type="button"  :class="actionToBeTaken == 3?'bg-red-600 focus:ring-red-500  hover:bg-red-700': ''" class=" w-1/3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm" @click="requisitionAction(requisitionProp.requisition_id)">Reject</button>
              <button v-if="(actionToBeTaken == 2)" type="button"  :class="actionToBeTaken == 2?'bg-amber-600 focus:ring-amber-500  hover:bg-amber-700': ''" class=" w-1/3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm" @click="requisitionAction(requisitionProp.requisition_id)">Send Back</button>
        </div>
      </template>
    </RequisitionerViewRequisitionModalComponent>
    <RequisitionerMultiPurposeModalComponent :show="showStatusesModal" @dismiss="showStatusesModal = false">
        <template v-slot:header>
                    Requisition Action Statuses
        </template>
        <template v-slot:body>
          <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-teal-700">
                                <tr>
                                    <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">#</th>
                                    <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Role</th>
                                    <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">User</th>
                                    <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Seen</th>
                                    <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Duration</th>
                                    <th scope="col" class="pl-1 pr-3 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Status</th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="(transition,index) in requisitionTransitions" :key="index" >
                                    <td class="pl-3 pr-2 py-4 text-sm text-gray-500">
                                        {{ index + 1 }}
                                    </td>
                                    <td class="px-4 py-4 text-sm text-gray-500">
                                      {{ transition.role_name }} 
                                    </td>
                                    <td class="px-4 py-4 text-sm text-gray-500">
                                      {{ transition.name }}
                                    </td>
                                    <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {{ transition.seen_on? 'Yes, on ' + transition.seen_on : 'No' }}
                                    </td>
                                    <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {{ transition.duration }} days
                                    </td>
                                    <td class="pl-1 pr-3 py-4 text-sm text-gray-500">
                                      {{ transition.status }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
          </div>
        </template>
    </RequisitionerMultiPurposeModalComponent>
  </div>
</template>

<script>
import { ref, computed, inject } from 'vue'
import RequisitionerViewRequisitionModalComponent from "../requisitionerComponents/RequisitionerViewRequisitionModalComponent.vue";
import RequisitionerMultiPurposeModalComponent from "../requisitionerComponents/RequisitionerMultiPurposeModalComponent.vue";
import { Combobox, ComboboxButton, ComboboxInput,
  ComboboxOption, ComboboxOptions,
} from '@headlessui/vue'
import {  CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import Api from "@/components/api/Api";
import { useRoute } from "vue-router";

export default {

  name: "BudgetholderPendingComponent",
  components: {
          RequisitionerViewRequisitionModalComponent,
          RequisitionerMultiPurposeModalComponent,
          Combobox,     ComboboxButton,
          ComboboxInput,    ComboboxOption,     ComboboxOptions,
          SelectorIcon, CheckIcon,
          AlertError, AlertSuccess
  },

  setup(){
      
     const showRequisitionModal = ref(false);
     const showStatusesModal    = ref(false);
    //  const router = useRouter();
     const people = ref([
      { id: 0, user_id: 0, name: '' }
      ])
      const query = ref('')
      const selectedPerson = ref(people.value[0])
      const filteredPeople = computed(() =>
      query.value === ''
          ? people.value
          : people.value.filter((person) => {
              return person.name.toLowerCase().includes(query.value.toLowerCase())
          })
      )

      const requisitions = ref([
        { id: '', 
        requisition_name: '', 
        purpose_of_requisition: '', 
        summary: '' },
       
      ])
      const route = useRoute();
      let isLoading = ref(false);
      let errorMessage = ref("");
      let successMessage = ref("");
      let fromPage = ref(0)
      let toPage   = ref(0)
      let totalPageResults   = ref(0);
      let prevPageUrl = ref('#')
      let nextPageUrl = ref('#')
      let status_title = inject('status_title');
      let loggedInUser =  inject("loggedInUser");
      let status = ref('0');
      let dateRange = ref(route.params.date);
      let period_select = ref('');

      if(dateRange.value){

         period_select.value = dateRange.value

      }
      let expenditureType = ref('0');
      const staticOldApprovalRoleId = 6;
      const staticNewApprovalRoleId = 9;

      let activePage = inject('activePage');
      activePage.value = 'Pending Requisitions'

       let requisitionProp = ref({});
       let requisitionAttachmentsProp = ref([]);
       let requisitionTransitionsProp = ref([]);
       let requisitionTransitions   = ref([]);
      //  let selectedRequisition  = ref('0');

      function setRequisitionStatusFromUrl(){

        if(route.params.status == 'pending'){

          //status_title.value = 'Pending'

          status.value = 0;

        }else if(route.params.status == 'approved'){

          status_title.value = 'Approved'

          status.value = 1;

        }else if(route.params.status == 'sent back'){

          status_title.value = 'Sent Back'

          status.value = 2;
        
        }else if(route.params.status == 'rejected'){

          status_title.value = 'Rejected'

          status.value = 3;

        }

      }
      setRequisitionStatusFromUrl();
      // let pageUrl = `/requisition/get-requisitioner-requisitions/${loggedInUser.value.id}/${status.value}/${dateRange.value}`;
      let pageUrl = ref(`/requisition/get-approver-requisitions/${loggedInUser.value.id}/${staticOldApprovalRoleId}/${status.value}/${dateRange.value}`);
      let reload_page_Url = ref('');
      
      async function getRequisitions(url = pageUrl.value) {

        reload_page_Url.value = url;

        isLoading.value = true;

        try{

          

          const response = await  Api().get(url);

          if (response.data.status === "success") {

               
                successMessage.value = response.data.message
                requisitions.value = response.data.requisitions.data;
                nextPageUrl.value = response.data.requisitions.next_page_url
                prevPageUrl.value = response.data.requisitions.prev_page_url
                fromPage.value = response.data.requisitions.from
                toPage.value   = response.data.requisitions.to
                totalPageResults.value   = response.data.requisitions.total
                
          }

        }catch (err) {

            if (err.response) {

              errorMessage.value = err.response.data.message;

            } else if (err.request) {

                errorMessage.value = err.request.data.message

            } else {

                errorMessage.value = err.message

            }
        }

        isLoading.value = false;

      }

      const filter = ref("");
      const filteredRequisitionsTable = computed(() => {
        return requisitions.value.filter(
          (val) =>{
            const name = val.requisition_name.toString().toLowerCase();
            const summary = val.summary.toString().toLowerCase();
            const reason = val.purpose_of_requisition.toString().toLowerCase();
            
            return name.includes(filter.value.toLowerCase()) || summary.includes(filter.value.toLowerCase()) 
                    || reason.includes(filter.value.toLowerCase());
          }
        );
      });
      async function showRequisition(requisition_id){

        isLoading.value = true;

        try{

          const response = await  Api().get(`/requisition/get-requisition/${requisition_id}/${loggedInUser.value.id}/${staticOldApprovalRoleId}`);

          if (response.data.status === "success") {

                requisitionProp.value = response.data.requisition;
                requisitionAttachmentsProp.value = response.data.attachments;
                requisitionTransitionsProp.value = response.data.transitions;
               
          }

        }catch (err) {

            if (err.response) {

              errorMessage.value = err.response.data.message;

               

            } else if (err.request) {

                errorMessage.value = err.request.data.message


            } else {

                errorMessage.value = err.message


            }
        }

        isLoading.value = false;
        showRequisitionModal.value = true;

      }

      async function showTransitionStatuses(requisition_id){


         isLoading.value = true;

        try{

          const response = await  Api().get(`/requisition/get-transition-statuses/${requisition_id}`);

          if (response.data.status === "success") {

               
                requisitionTransitions.value = response.data.transitions;
               
          }

        }catch (err) {

            if (err.response) {

              errorMessage.value = err.response.data.message;

            } else if (err.request) {

                errorMessage.value = err.request.data.message

            } else {

                errorMessage.value = err.message

            }
        }

        isLoading.value = false;
        showStatusesModal.value = true;

      }

      async function loadApproverList(approval_level_role_id){

        try {

                const res = await Api().get(`/requisition/get-approvers/${approval_level_role_id}`);

                if (res.data.status === "success") {

                    people.value = res.data.approvers;

                }

            } catch (err) {

                if (err.response) {

                   //

                } else if (err.request) {

                   //

                } else {
                    
                     //

                }
            }


      }

      async function requisitionAction(requisition_id){


        let actionObj = {
          'new_approver_user_id'    : selectedPerson.value.user_id,
          'current_approver_user_id'   : loggedInUser.value.id,
          'old_approval_level_role_id'    : staticOldApprovalRoleId,
          'erp_systems_role_id'    : selectedPerson.value.id,
          'comment' : comment.value,
          'transition_status' : actionToBeTaken.value,
          'new_approval_level_role_id'    : staticNewApprovalRoleId,
          'expenditureType': expenditureType.value,
          'budgetLineId': selectedBudgetLine.value.id

        }

         isLoading.value = true;
         successMessage.value = '';
         errorMessage.value = '';

             try {
            
              const res = await Api().patch(`/requisition/approve/${requisition_id}`,actionObj);

              if (res.data.status === "success") {

                successMessage.value = res.data.message;
                showRequisitionModal.value = false;
                resetValues(true);
                await getRequisitions(reload_page_Url.value);
               

              }else{

                errorMessage.value = res.data.message;

              }


            } catch (err) {

                if (err.response) {

                  errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                    errorMessage.value = err.request.data.message ;

                } else {

                  errorMessage.value = err.message;

                }
            }

            isLoading.value = false;
      
      }

      let comment = ref('');
      let actionToBeTaken = ref('0');
      

      const budgetLines = ref([{'id': 0, 'name': ''}])
      const query1 = ref('')
      const selectedBudgetLine = ref(budgetLines.value[0])
      const filteredBudgetLines = computed(() =>
        query1.value === ''
            ? budgetLines.value
            : budgetLines.value.filter((budgetLine) => {
                return budgetLine.name.toLowerCase().includes(query1.value.toLowerCase())
            })
      )

      function resetValues(resetall = false){


       comment.value = ''
       selectedBudgetLine.value = {'id': 0, 'name': ''}
       expenditureType.value = '0'
       selectedPerson.value = { id: 0, user_id: 0, name: '' }

       if(resetall){
          actionToBeTaken.value = '0'
       }


      }

      async function loadMybudgetLines(){


         try {

                const response = await Api().get(`/requisition/load-my-budget-lines/${loggedInUser.value.id}`);

                if(response.data.status === "success"){

                        budgetLines.value         = response.data.budgetLines;
                      //  console.log(budgetLines.value);
                        
                } 

            } catch (err) {

                if (err.response) {

                    errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                errorMessage.value =  err.request.data.message;

                } else {

                    errorMessage.value =  err.message;
                    
                }
            }





      }

      let recognized_amount   = ref(0);
      let committed_amount    = ref(0);
      let budget_line_amount  = ref(0);

      let restricted_amount   = ref(0);
      let restricted_month    = ref('--');
      let restricted_status   = ref('--');

      const selectedRequisitionTotal = ref(0);

      async function getBudgetlineInfo(){

       // console.log(selectedBudgetLine.value.id);
       isLoading.value = true;
        recognized_amount.value  = 0; 
        committed_amount.value   = 0;   
        budget_line_amount.value = 0; 

        restricted_amount.value  = 0;
        restricted_month.value   = '--';
        restricted_status.value        = '--';


         try {

                const res = await Api().get(`/requisition/get-budget-line-usage-summary/${selectedBudgetLine.value.id}`);

                if (res.data.status === "success") {

                  let requisition_summary = res.data.requisition_summary
                  let budget_line_summary = res.data.budget_line_summary
                  let restriction_summary = res.data.restriction_summary

                  for(let i = 0; i < requisition_summary.length; i++ ){

                    if(requisition_summary[i].status == "Committed"){

                      committed_amount.value = requisition_summary[i].total;

                    }else if(requisition_summary[i].status == "Recognized"){

                      recognized_amount.value = requisition_summary[i].total;

                    }

                  }

                  if(budget_line_summary){

                    budget_line_amount.value = budget_line_summary.total_amount;

                  }

                   if(restriction_summary){

                    restricted_amount.value = restriction_summary.restricted_amount;
                    restricted_month.value = restriction_summary.month;
                    if(restriction_summary.is_active == 1){

                      restricted_status.value = 'ON'

                    }else if(restriction_summary.is_active == 0){

                      restricted_status.value = 'OFF'

                    }
                    
                   }

                  //  console.log('restric ' + restricted_amount.value)
                  //  console.log('selecr ' + selectedRequisitionTotal.value)
                  //  console.log('status ' + restricted_status.value)
                  //  if((Number(restricted_amount.value) > 0)){
                  //    console.log('true 1 --->')
                  //  }
                  //  if (Number(selectedRequisitionTotal.value) > Number(restricted_amount.value)){

                  //      console.log('true 2--->')

                  //  }

                  //  if((restricted_status.value == 'ON')){

                  //      console.log('true 3 --->')

                  //  }

                }

            } catch (err) {

                if (err.response) {

                   //

                } else if (err.request) {

                   //

                } else {
                    
                     //

                }
            }

          isLoading.value = false;

      }

      function toMonthName(monthNumber) {
        const date = new Date();
        date.setMonth(monthNumber - 1);

        return date.toLocaleString('en-US', {
          month: 'long',
        });
      }

      async function reloadRequisitions() {

        pageUrl.value = `/requisition/get-approver-requisitions/${loggedInUser.value.id}/${staticOldApprovalRoleId}/${status.value}/${period_select.value}`
        await getRequisitions();
        // console.log(period_select.value)
        // console.log(pageUrl.value)


      }

      loadMybudgetLines();
      loadApproverList(staticNewApprovalRoleId)
      getRequisitions();

    return{
      requisitions,
      showStatusesModal,
      showRequisitionModal,
      query,selectedPerson,filteredPeople,
       errorMessage, successMessage, isLoading, 
      fromPage, toPage, totalPageResults, prevPageUrl,nextPageUrl,pageUrl,
      filteredRequisitionsTable,filter,
      showRequisition,showTransitionStatuses,
      requisitionProp, requisitionAttachmentsProp,requisitionTransitionsProp, requisitionTransitions, dateRange,
      getRequisitions,reload_page_Url,
      comment,actionToBeTaken,
      requisitionAction,
      query1,selectedBudgetLine,filteredBudgetLines,expenditureType,resetValues,
      getBudgetlineInfo,  recognized_amount, committed_amount, budget_line_amount,
      restricted_amount , restricted_month , restricted_status, toMonthName, selectedRequisitionTotal,
      period_select, reloadRequisitions


    }
  }


}
</script>

<style>

</style>