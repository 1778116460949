import { ref } from "vue";
import Api from "@/components/api/Api";

// State
const requisitionRoles = ref(JSON.parse(localStorage.getItem("requisitionRoles")));
const tenderRoles = ref([]);
const meimsRoles = ref([]);

export const initRolesLists = async () => {

    try {

        const res = await Api().get(`/app/get-erp-system-roles-list`);

        if (res.data.status === "success") {

            requisitionRoles.value = res.data.requisitionSystemModuleRoles;
            tenderRoles.value = res.data.tenderSystemModuleRoles;
            meimsRoles.value = res.data.meimsSystemModuleRoles;

            localStorage.setItem("requisitionRoles", JSON.stringify(res.data.requisitionSystemModuleRoles));

            return { 'message': res.data.message, 'status': res.data.status };

        }

    } catch (err) {

        if (err.response) {

            return { 'message': err.response.data.message, 'status': 'error' };

        } else if (err.request) {

            return { 'message': err.request.data.message, 'status': 'error' };

        } else {

            return { 'message': err.message, 'status': 'error' };

        }
    }

};

// Getters
export const getRequisitionRoles = () => {

    if (!requisitionRoles.value) {

        requisitionRoles.value = JSON.parse(localStorage.getItem("requisitionRoles"));

    }

    return requisitionRoles;

};