import { computed, ref } from "vue";
import toast from "@/helpers/toast";
import {useLoading} from 'vue-loading-overlay'
import FundraisingHost from '@/helpers/FundraisingHost.js'

const $loading = useLoading();
// const FundraisingHost.url  =  'http://donorsweb.jms.co.ug/api/';
const resourceList = ref([])
const {success,error} = toast;
const selectedResource = ref();
const filter = ref('');


const create = async () => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
const f = document.getElementById('create-resource');
  // @ts-ignore
  const form  =  new FormData(f);
  const  res = await fetch(FundraisingHost.url+'create-project-resource',{
    method:'post',
    body: form,
    headers:{
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success'){
    if (f){
      // @ts-ignore
      f.reset();
    }
    success(data.message);
  }else{
    error(data.message);
  }
  loader.hide();

}


const update = async (user_id) => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const f = document.getElementById('create-resource');
  // @ts-ignore
  const form  =  new FormData(f);
  const  res = await fetch(`${FundraisingHost.url}update-project-resource/${selectedResource.value.id}/${user_id}`,{
    method:'POST',
    body: form,
    headers:{
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success'){
    success(data.message);
    loadResources(user_id)
  }else{
    error(data.message);
  }
  loader.hide();

}

const loadResources = async (user_id = 0) => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const res = await fetch(`${FundraisingHost.url}get-resources-list/${user_id}` ,{
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success') {
    resourceList.value = data.resources;
    success(data.message)
  }else{
    error(data.message)
  }
  loader.hide();
}

const loadProjectResources = async (project_id = 0) => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const res = await fetch(`${FundraisingHost.url}get-project-resources-list/${project_id}` ,{
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success') {
    resourceList.value = data.resources;
    success(data.message)
  }else{
    error(data.message)
  }
  loader.hide();
}


const deleteUser = async (user_id) => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const res = await fetch(`${FundraisingHost.url}delete-project-resource/${selectedResource.value.id}` ,{
    method:'PATCH',
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success') {
    success(data.message)
    loadResources(user_id);
  }else{
    error(data.message)
  }
  loader.hide();
}

const getResourceList = computed(()=>{
  return resourceList.value.filter((val)=>val.resource_name.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1)
})


export default {loadResources,resourceList,create,selectedResource,update,deleteUser,loadProjectResources,getResourceList,filter}