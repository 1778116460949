<template>
  <div>
    <Loading v-model:active="isLoading" />
    <div class=" mt-1 mb-2 flex justify-center items-center">
        <AlertSuccess
            v-if="successMessage"
            :message="successMessage"
            @hide="successMessage = null"
        />
        <AlertError
            v-if="errorMessage"
            :message="[errorMessage]"
            @hide="errorMessage = null"
        />
    </div>
    <div class="pb-5 border-b border-gray-200">
        <h3 class="text-center md:text-left text-lg leading-6 font-bold text-red-900">Accountability Records: 
            <span  v-if="status == '0'" class="text-teal-400 font-normal italic"> Pending   </span>
            <span  v-if="status =='1'"  class="text-green-400 font-normal italic"> Approved </span>
            <span  v-if="status == ''" class="text-gray-500 font-normal italic"> Not Submitted Yet </span>
            <span  v-if="status == '3'" class="text-red-400 font-normal italic"> Rejected  </span>
        </h3>
       
    </div> 
    <div>
        <dl class="pt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
        <div v-for="item in stats" @click="getAccountabilities(pageUrl,'',item.status)" :key="item.name" :class="item.class" class="cursor-pointer px-4 py-5 sm:p-6 overflow-hidden">
            <dt class="text-sm font-medium text-white truncate">
            {{ item.name }}
            </dt>
            <dd class="mt-1 text-3xl font-semibold text-white">
            {{ item.stat }}
            </dd>
        </div>
        </dl>
    </div>
    <div class="grid grid-rows-2 sm:flex sm:justify-between sm:items-center pt-4 pb-4">
        <!-- <div class="pr-3">
            <button type="button" @click="swapComponent('RequisitionerAddRequisitionComponent')" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Create New Requisition
            </button>

        </div> -->
        <!-- <label for="table-search" class="sr-only">Search</label> -->
        <div class="relative flex">
            <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
            </div>
            <input type="text" v-model="filter" id="table-search" class="block flex-1 p-2 pl-10 sm:w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items">
        </div>
    </div>
    <div class="flex flex-col">
        <div class="-my-2 sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
                    <table class="w-full divide-y divide-gray-200 table-auto">
                        <thead class="bg-teal-700">
                            <tr>
                                <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-white capitalize tracking-wider">Id</th>
                                <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-white  capitalize tracking-wider">Description</th>
                                <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-white  capitalize tracking-wider">Money Accountable </th>
                                <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-white  capitalize tracking-wider">Money Utilized</th>
                                <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-white  capitalize tracking-wider">Money Returned </th>
                                <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-white  capitalize tracking-wider">Balance Claimed</th>
                                <!-- <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-white  uppercase tracking-wider">Balance left (UGX)</th> -->
                                <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-white  capitalize tracking-wider">Status </th>
                                <th scope="col" class="relative px-3 py-3">
                                <span class="sr-only">account</span>
                                </th>
                                <th scope="col" class="relative px-6 py-3">
                                <span class="sr-only">statuses</span>
                                </th>
                                <!-- <th scope="col" class="relative px-6 py-3">
                                <span class="sr-only">Report</span>
                                </th> -->
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="(accountability,index) in filteredAccountabilitiesTable" :key="index">
                                <td class="px-3 py-4  text-xs text-gray-500">
                                {{ accountability.requisition_name }}
                                </td>
                                <td class="px-3 py-4 break-words text-xs text-gray-500">
                                {{ accountability.summary }} 
                                </td>
                                <td class="px-3 py-4 whitespace-nowrap text-xs text-gray-500">
                                {{ accountability.money_accountable? Number(accountability.money_accountable).toLocaleString() : '0.00' }}
                                </td>
                                <td class="px-3 py-4 break-words text-xs text-gray-500">
                                {{ accountability.money_utilized? Number(accountability.money_utilized).toLocaleString() : '0.00' }}
                                </td>
                                <td class="px-3 py-4 whitespace-nowrap text-xs text-gray-500">
                                {{ accountability.money_returned?  Number(accountability.money_returned).toLocaleString() : '0.00' }}
                                </td>
                                <td class="px-3 py-4 whitespace-nowrap text-xs text-gray-500">
                                {{ accountability.balance_claimed? Number(accountability.balance_claimed).toLocaleString() : '0.00' }}
                                </td>
                                <!-- <td class="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                {{ accountability.balance_left }}
                                </td> -->
                                <td class="px-3 py-4 whitespace-nowrap text-xs text-gray-500">
                                  {{ accountability.status }}
                                </td>
                                <td class="px-3 py-4 whitespace-nowrap text-right text-xs font-medium">
                                  <a v-if="accountability.status != 'Rejected'" :class="!accountability.id ? 'cursor-pointer bg-green-600':'pointer-events-none bg-gray-600 '" @click="successMessage =null; errorMessage = null; selectedAccountability = accountability.id;  showRequisition(accountability.requisition_id)" class="text-white py-1 px-2 rounded-md text-xs  hover:bg-green-900 shadow-md"> account</a>
                                  <a v-if="accountability.status == 'Rejected'" :class="accountability.id && accountability.status == 'Rejected'? 'cursor-pointer bg-green-600':'pointer-events-none bg-gray-600 '" @click="successMessage =null; errorMessage = null; selectedAccountability = accountability.id; showRequisition(accountability.requisition_id)" class="text-white py-1 px-2 rounded-md text-xs  hover:bg-green-900 shadow-md"> account</a>
                                </td>
                                <td class="px-2 py-4 whitespace-nowrap text-right text-xs font-medium">
                                 <a :class="accountability.id ? 'cursor-pointer bg-indigo-600':'pointer-events-none bg-gray-600 '" @click="successMessage = null; errorMessage = null; showTransitionStatuses(accountability.requisition_id)"  class=" text-white text-xs  py-1 px-2 rounded-md hover:bg-indigo-900 shadow-md">view</a>
                                </td>
                                <!-- <td class="px-2 py-4 whitespace-nowrap text-center text-xs font-medium">
                                <a href="#" class="bg-amber-600 text-white text-xs  py-1 px-2 rounded-md cursor-pointer hover:bg-amber-900 shadow-md">report</a>
                                </td> -->
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="9">
                                    <nav class="bg-white px-4 py-3 flex items-center justify-between sm:px-6" aria-label="Pagination">
                                        <div class="hidden sm:block">
                                        <p class="text-sm text-gray-700">
                                            Showing
                                            {{ ' ' }}
                                            <span class="font-medium">{{ fromPage }}</span>
                                            {{ ' ' }}
                                            to
                                            {{ ' ' }}
                                            <span class="font-medium">{{ toPage }}</span>
                                            {{ ' ' }}
                                            of
                                            {{ ' ' }}
                                            <span class="font-medium">{{ totalPageResults }}</span>
                                            {{ ' ' }}
                                            results
                                        </p>
                                        </div>
                                        <!-- <div class="flex-1 flex justify-between sm:justify-end"> -->
                                        <div class="flex-1 flex justify-start sm:justify-end">
                                          <a :class="prevPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="getAccountabilities( pageUrl,prevPageUrl,status)"  class="relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Previous </a>
                                          <a :class="nextPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="getAccountabilities( pageUrl,nextPageUrl,status)" class="ml-3 cursor-pointer relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Next </a> 
                                        </div>
                                        <div class="sm:hidden flex-1 flex justify-end sm:justify-end">
                                          <a :class="prevPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="getAccountabilities( pageUrl,prevPageUrl,status)"  class="relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Previous </a>
                                          <a :class="nextPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="getAccountabilities( pageUrl,nextPageUrl,status)" class="ml-3 cursor-pointer relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Next </a> 
                                        <!-- <a href="#" class="relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Previous </a>
                                        <a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Next </a> -->
                                        </div>
                                    </nav>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <RequisitionerAccountabilityModalComponent :requisition="requisitionProp" :show="showAccountabilityTansitionsModal" @dismiss="showAccountabilityTansitionsModal = false">
        <template v-slot:body>
            <div class="sm:col-span-3">
                <div class="flex flex-col">
                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div class="shadow overflow-hidden ">
                                <table class="min-w-full divide-y divide-gray-200 border border-slate-300 ">
                                    <thead class="bg-teal-700">
                                        <tr>
                                            <th colspan="5" scope="col" class="px-6 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">[ Accountability Amount Composition ]</th>
                                        </tr>
                                    </thead>
                                    <tbody class="bg-white divide-y divide-gray-200">
                                        <tr >
                                            <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300 whitespace-nowrap">
                                                
                                             <span class="font-bold"> {{ accountability_name }} </span>
                                            </td>
                                            <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300 whitespace-nowrap">
                                                <!-- <div class="text-xs text-gray-500">Money Utilized (UGX)</div>
                                                <div class="text-xs text-gray-500">1,750,200.00</div> -->
                                               <span class="font-bold"> Money Utilized (UGX):</span> <br/>  {{ Number(accountability_money_utilized).toLocaleString() }}
                                            </td>
                                            <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300 whitespace-nowrap">
                                             <span class="font-bold">Money Returned : UGX </span> <br/> {{ Number(accountability_money_returned).toLocaleString() }}
                                            </td>
                                            <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300 whitespace-nowrap">
                                              <span class="font-bold">Balance Claimed : UGX</span> <br/> {{ Number(accountability_balance_claimed).toLocaleString() }}
                                            </td>                            
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <div class="sm:col-span-3">
               <dt class="text-sm font-bold text-red-900">Accoutability Description</dt>
               <dd class="mt-1 text-sm text-gray-900" v-html="accountability_description" ></dd>
            </div>
            <div class="sm:col-span-2" data-html2canvas-ignore="true">
                <dt class="text-sm font-bold text-red-900">Attachments</dt>
                <dd class="mt-1 text-sm text-gray-900">
                    <ul role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li  v-for="(attachment,index) in attachments" :key="index" class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                        <div class="w-0 flex-1 flex items-center">
                        <PaperClipIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                        <span class="ml-2 flex-1 w-0 truncate"> {{ attachment.file_name }}</span>
                        </div>
                        <div class="ml-4 flex-shrink-0">
                        <a  :href="`${file_url}/${attachment.id}`" class="cursor-pointer font-medium text-indigo-600 hover:text-indigo-500"> Download </a>
                        </div>
                    </li>
                    </ul>
                </dd>
            </div>
           
        </template>
        <template v-slot:footer>
            <div class="my-5">
              <p class="text-sm font-bold text-red-900">Transition Status</p>
            </div>
              <div class="flex flex-col mx-10">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead class="bg-teal-700">
                                    <tr>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white capitilize tracking-wider">Level</th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white capitilize tracking-wider">Comment</th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white capitalize tracking-wider">Status</th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white capitalize tracking-wider">Created At</th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                    <tr v-for=" transition in accountabilityTransitions" :key="transition.id" >
                                        <td class="px-6 py-4 text-xs text-gray-500">
                                          {{ transition.role_name + '( ' +transition.name + ' )' }}	
                                        </td>
                                        <td class="px-6 py-4 text-xs text-gray-500">
                                         {{ transition.comment }}
                                        </td>
                                        <td class="px-6 py-4 text-xs text-gray-500">
                                          {{ transition.status }}
                                        </td>
                                        <td class="px-6 py-4 text-xs text-gray-500">
                                           {{ transition.status_date }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
              </div>
            <div data-html2canvas-ignore="true" class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:col-start-2 sm:text-sm" @click="showAccountabilityTansitionsModal = false">Close</button>
              <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm" @click="downloadRequisitionPdf(); showAccountabilityTansitionsModal = false">Download Accountability Report</button>
            </div>
        </template>
    </RequisitionerAccountabilityModalComponent> 
    <RequisitionerAccountabilityModalComponent :requisition="requisitionProp"  :show="showAccountabilityModal" @dismiss="resetValues()">
        <template v-slot:body>
            <div class="sm:col-span-3 border-t pt-5">
                <div class=" mt-1 mb-2 flex justify-center items-center">
                    <AlertSuccess
                        v-if="successMessage"
                        :message="successMessage"
                        @hide="successMessage = null"
                    />
                    <AlertError
                        v-if="errorMessage"
                        :message="[errorMessage]"
                        @hide="errorMessage = null"
                    />
                </div>
              <h3 class="block text-lg leading-6 font-bold text-center text-red-900">Provide Accountability Details</h3>
              <p class="block mt-1 text-xs text-center text-gray-900"><span class="text-green-900">Voucher Number:</span> {{ voucher_number }}  </p>
            </div>
            <div class="sm:col-span-1">
              <div>
                <label for="money_utilized" class="block whitespace-nowrap text-sm font-medium text-red-900">Money Utilized (UGX)</label>
                <input v-model="money_utilized" type="number" name="money_utilized" id="money_utilized" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
             </div>
            </div>
            <div class="sm:col-span-1">
              <div>
                <label for="money_returned" class="block whitespace-nowrap text-sm font-medium text-red-900">Money Returned (UGX)</label>
                <input v-model="money_returned" type="number" name="money_returned" id="money_returned" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
             </div>
            </div>
            <div class="sm:col-span-1">
              <div>
                <label for="balance_claimed" class="block whitespace-nowrap text-sm font-medium text-red-900">Balance Claimed (UGX)</label>
                <input v-model="balance_claimed" type="number" name="balance_claimed" id="balance_claimed" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
             </div>
            </div>
            
            <div class="sm:col-span-3"> 
                <div class="lg:grid lg:grid-cols-2 lg:gap-3 lg:items-start lg:pt-5 gap-4">
                    <div class="mt-1 sm:mt-0 sm:col-span-1">
                        <div class="max-w-full flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                            <div class="space-y-1 text-center">
                                <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                 <div class="flex text-sm text-gray-600 overflow-hidden">
                                    <label for="file-upload-1" class="flex-1 relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                    <span>{{ file_upload_1.length > 24 ? file_upload_1.slice(0, file_upload_1.length-5).concat('...') :  file_upload_1}}</span>
                                    <input accept="*/*" id="file-upload-1" @change="handleFile($event,'1')" name="file-upload-1" type="file" class="hidden" />
                                    </label>
                                </div>
                                <button  v-if="file_upload_1 != 'Expenditure Receipt / Field Work Report'" class="border border-1 border-gray-400 p-1 text-xs rounded-lg text-red-900 hover:text-red-500" @click="clearFileSelection('0')">clear</button>
                                <!-- <div class="flex text-sm text-gray-600">
                                    <label for="file-upload" class="relative cursor-pointer bg-white rounded-md font-medium text-red-900 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                    <span>Expenditure Receipt / Field Work Report</span>
                                    <input id="file-upload" name="file-upload" type="file" class="sr-only" />
                                    </label>
                                </div> -->
                                <!-- <p class="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p> -->
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 sm:mt-5 md:mt-5 lg:mt-0 sm:col-span-1">
                        <div class="max-w-full flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                            <div class="space-y-1 text-center">
                                <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <div class="flex text-sm text-gray-600 overflow-hidden">
                                    <label for="file-upload-2" class="flex-1 relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                    <span>{{ file_upload_2.length > 24 ? file_upload_2.slice(0, file_upload_2.length-5).concat('...') :  file_upload_2}}</span>
                                    <input id="file-upload-2" name="file-upload-2" @change="handleFile($event,'2')" type="file" class="sr-only" />
                                    </label>
                                </div>
                                <!-- <p class="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p> -->
                                <button  v-if="file_upload_2 != 'Cashier\'s Receipt'" class="border border-1 border-gray-400 p-1 text-xs rounded-lg text-red-900 hover:text-red-500" @click="clearFileSelection('1')">clear</button>
                                <!-- <div class="flex text-sm text-gray-600">
                                    <label for="file-upload" class="relative cursor-pointer bg-white rounded-md font-medium text-red-900 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                    <span>Cashier's Receipt</span>
                                    <input id="file-upload" name="file-upload" type="file" class="sr-only" />
                                    </label>
                                </div> -->
                                <!-- <p class="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div  class="sm:col-span-3">
                  <label for="country" class="block text-sm font-bold text-red-900 sm:mt-px pt-2"> Select who to send to</label>
                  <Combobox as="div" class="pt-2 w-full  lg:w-4/6" v-model="selectedPerson">
                      <!-- <ComboboxLabel class="block text-sm font-medium text-gray-700">Assigned to</ComboboxLabel> -->
                      <div class="relative mt-1">
                      <ComboboxInput placeholder="Click the arrows or start typing name here" class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm" @change="query = $event.target.value" :display-value="(person) => person.name" />
                      <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                          <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </ComboboxButton>

                      <ComboboxOptions v-if="filteredPeople.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          <ComboboxOption v-for="person in filteredPeople" :key="person.id" :value="person" as="template" v-slot="{ active, selected }">
                          <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                              <span :class="['block truncate', selected && 'font-semibold']">
                              {{ person.name }}
                              </span>

                              <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                              <CheckIcon class="h-5 w-5" aria-hidden="true" />
                              </span>
                          </li>
                          </ComboboxOption>
                      </ComboboxOptions>
                      </div>
                  </Combobox>
            </div>
           <!-- <div class="sm:col-span-3">
                <label for="about" class="block text-sm font-medium text-gray-700 py-3"> Detailed Description </label>
                <div class="mt-1">
                  <textarea id="about" name="about" rows="5" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md" />
                </div>
            </div> -->
            <div class="sm:col-span-3">
                <label for="about" class="block text-sm font-bold text-red-900 py-3"> Detailed Description </label>
                
                <div class="mt-1 p-3 border-2 rounded-xl border-gray-300">
                  <div v-if="editor" class="flex flex-wrap w-full">
                      <button @click="editor.chain().focus().toggleBold().run()" class="menu-button"
                      :class="{ 'is_active': editor.isActive('bold') }">
                      <BIconTypeBold class="h-4 w-4" />
                      </button>
                      <button @click="editor.chain().focus().toggleItalic().run()" class="menu-button"
                      :class="{ 'is_active': editor.isActive('italic') }">
                      <BIconTypeItalic class="h-4 w-4" />
                      </button>
                      <button @click="editor.chain().focus().toggleStrike().run()" class="menu-button"
                      :class="{ 'is_active': editor.isActive('strike') }">
                      <BIconTypeStrikethrough class="h-4 w-4" />
                      </button>
                      <!-- <button @click="editor.chain().focus().setParagraph().run()" class="menu-button"
                      :class="{ 'is_active': editor.isActive('paragraph') }">
                      <BIconParagraph class="h-4 w-4" />
                      </button> -->
                      <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" class="menu-button"
                      :class="{ 'is_active': editor.isActive('heading', { level: 1 }) }">
                      h1
                      </button>
                      <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" class="menu-button"
                      :class="{ 'is_active': editor.isActive('heading', { level: 2 }) }">
                      h2
                      </button>
                      <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" class="menu-button"
                      :class="{ 'is_active': editor.isActive('heading', { level: 3 }) }">
                      h3
                      </button>
                      <button @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" class="menu-button"
                      :class="{ 'is_active': editor.isActive('heading', { level: 4 }) }">
                      h4
                      </button>
                      <button @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" class="menu-button"
                      :class="{ 'is_active': editor.isActive('heading', { level: 5 }) }">
                      h5
                      </button>
                      <button @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" class="menu-button"
                      :class="{ 'is_active': editor.isActive('heading', { level: 6 }) }">
                      h6
                      </button>
                      <button @click="editor.chain().focus().toggleBulletList().run()" class="menu-button"
                      :class="{ 'is_active': editor.isActive('bulletList') }">
                      <BIconListUl class="h-4 w-4" />
                      </button>
                      <button @click="editor.chain().focus().toggleOrderedList().run()" class="menu-button"
                      :class="{ 'is_active': editor.isActive('orderedList') }">
                      <BIconListOl class="h-4 w-4" />
                      </button>
                      <button @click="editor.chain().focus().toggleUnderline().run()" class="menu-button"
                      :class="{ 'is_active': editor.isActive('underline') }">
                      <BIconTypeUnderline class="h-4 w-4" />
                      </button>
                      <button
                        class="menu-button"
                        @click="editor.chain().focus().setTextAlign('left').run()"
                        :class="{ 'is_active': editor.isActive({ textAlign: 'left' }) }"
                      >
                        <BIconTextLeft class="h-4 w-4" />
                      </button>
                      <button
                        class="menu-button"
                        @click="editor.chain().focus().setTextAlign('center').run()"
                        :class="{ 'is_active': editor.isActive({ textAlign: 'center' }) }"
                      >
                        <BIconTextCenter class="h-4 w-4" />
                      </button>
                      <button
                        class="menu-button"
                        @click="editor.chain().focus().setTextAlign('right').run()"
                        :class="{ 'is_active': editor.isActive({ textAlign: 'right' }) }"
                      >
                        <BIconTextRight class="h-4 w-4" />
                      </button>
                      <button
                        class="menu-button"
                        @click="editor.chain().focus().setTextAlign('justify').run()"
                        :class="{ 'is_active': editor.isActive({ textAlign: 'justify' }) }"
                      >
                        <BIconJustify class="h-4 w-4" />
                      </button>

                      <button
                        class="menu-button"
                        @click="editor.chain().focus().toggleHighlight().run()"
                        :class="{ 'is_active': editor.isActive('highlight') }"
                      >
                        <BIconPen class="h-4 w-4" />
                      </button>
                      <button
                        class="menu-button"
                        @click="
                          editor
                            .chain()
                            .focus()
                            .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                            .run()
                        "
                      >
                        <BIconTable class="h-4 w-4" />
                      </button>

                      <button
                        class="menu-button"
                        @click="editor.chain().focus().addColumnBefore().run()"
                        :disabled="!editor.can().addColumnBefore()"
                      >
                        <RiInsertColumnLeft class="h-4 w-4" />
                      </button>
                      <button
                        class="menu-button"
                        @click="editor.chain().focus().addColumnAfter().run()"
                        :disabled="!editor.can().addColumnAfter()"
                      >
                        <RiInsertColumnRight class="h-4 w-4" />
                      </button>
                      <button
                        class="menu-button"
                        @click="editor.chain().focus().deleteColumn().run()"
                        :disabled="!editor.can().deleteColumn()"
                      >
                        <RiDeleteColumn class="h-4 w-4" />
                      </button>
                      <button
                        class="menu-button"
                        @click="editor.chain().focus().addRowBefore().run()"
                        :disabled="!editor.can().addRowBefore()"
                      >
                        <RiInsertRowTop class="h-4 w-4" />
                      </button>
                      <button
                        class="menu-button"
                        @click="editor.chain().focus().addRowAfter().run()"
                        :disabled="!editor.can().addRowAfter()"
                      >
                        <RiInsertRowBottom class="h-4 w-4" />
                      </button>
                      <button
                        class="menu-button"
                        @click="editor.chain().focus().deleteRow().run()"
                        :disabled="!editor.can().deleteRow()"
                      >
                        <RiDeleteRow class="h-4 w-4" />
                      </button>
                      <button
                        class="menu-button"
                        @click="editor.chain().focus().mergeCells().run()"
                        :disabled="!editor.can().mergeCells()"
                      >
                        <RiMergeCellsHorizontal class="h-4 w-4" />
                      </button>
                      <button
                        class="menu-button"
                        @click="editor.chain().focus().splitCell().run()"
                        :disabled="!editor.can().splitCell()"
                      >
                        <RiSplitCellsHorizontal class="h-4 w-4" />
                      </button>
                      <button @click="editor.chain().focus().undo().run()" class="menu-button">
                      <RiArrowGoBackFill class="h-4 w-4" />
                      </button>
                      <button @click="editor.chain().focus().redo().run()" class="menu-button">
                      <RiArrowGoForwardFill class="h-4 w-4" />
                      </button>
                  </div>
                  <editor-content :editor="editor" /> 
              </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-900 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm" @click="submit">Submit</button>
              <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm" @click="resetValues()">close</button>
              <!-- <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm" @click="downloadRequisitionPdf(); showAccountabilityModal = false">Download Report</button> -->
            </div>
        </template>
    </RequisitionerAccountabilityModalComponent>
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue'
import RequisitionerAccountabilityModalComponent from "../../requisitionComponents/requisitionerComponents/RequisitionerAccountabilityModalComponent.vue";
import { PaperClipIcon } from '@heroicons/vue/solid'
import { useEditor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableHeader from "@tiptap/extension-table-header";
import TableCell from '@tiptap/extension-table-cell'
import TextAlign from "@tiptap/extension-text-align";
import Highlight from "@tiptap/extension-highlight";
import Underline from '@tiptap/extension-underline'
import {
  BIconTypeBold, BIconTypeItalic, BIconTypeStrikethrough, BIconListOl,
  BIconListUl, BIconTypeUnderline, BIconTextRight, BIconTextLeft,
  BIconTextCenter, BIconJustify, BIconPen, BIconTable,
} from "bootstrap-icons-vue";
import {
  RiArrowGoBackFill, RiArrowGoForwardFill, RiInsertColumnLeft, RiInsertColumnRight,
  RiDeleteColumn, RiInsertRowBottom, RiInsertRowTop, RiDeleteRow,
  RiMergeCellsHorizontal, RiSplitCellsHorizontal,
} from 'vue-remix-icons';
import Api from "@/components/api/Api";
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/vue'
import html2pdf from "html2pdf.js";
import host from '@/helpers/host';


export default {
    name: "RequisitionerAccountabilityComponent",
    components: {
        RequisitionerAccountabilityModalComponent,
        PaperClipIcon, EditorContent, BIconTypeBold, BIconTypeItalic,
        BIconTypeStrikethrough, BIconListOl, BIconListUl, BIconTypeUnderline,
        BIconTextRight, BIconTextLeft, BIconTextCenter, BIconJustify,
        BIconPen, BIconTable, RiArrowGoBackFill, RiArrowGoForwardFill,
        RiInsertColumnLeft, RiInsertColumnRight, RiDeleteColumn,
        RiInsertRowBottom, RiInsertRowTop, RiDeleteRow, RiMergeCellsHorizontal, RiSplitCellsHorizontal,
        AlertError,AlertSuccess, CheckIcon, Combobox, ComboboxButton,ComboboxInput, ComboboxOption, ComboboxOptions,
        SelectorIcon,
    },

    setup(){

      const file_url = host.url+'download-accountability-resource';
      let status_title = inject('status_title');
      status_title.value = ''
      let activePage = inject('activePage');
      activePage.value = 'Accountabilities'

       let loggedInUser =  inject("loggedInUser");
       const stats = ref([
        { name: 'Not Submitted', status: '', stat: '0', class: 'text-black bg-gradient-to-r from-gray-400 via-gray-500 to-gray-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-gray-300 dark:focus:ring-gray-800 shadow-lg shadow-gray-500/50 dark:shadow-lg dark:shadow-gray-800/80 font-medium rounded-lg' },
        { name: 'Pending',       status: '0', stat: '0', class: 'text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg' },
        { name: 'Approved',      status: '1', stat: '0', class: 'text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg' },
        { name: 'Rejected',      status: '3', stat: '0',  class: 'text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg' },
        ])


        const accountabilities = ref([]);
        const showAccountabilityModal  = ref(false);
        const showAccountabilityTansitionsModal  = ref(false);

        const editor = useEditor({
        editorProps: {
            attributes: {
            class:
                "prose prose-sm text-sm" 
            },
        },
        extensions: [
            StarterKit,
            TextAlign.configure({
              types: ["heading", "paragraph"],
              alignments: ["left", "center", "right", "justify"],
            }),
            Highlight,
            Table.configure({
              resizable: true,
            }),
            TableRow,
            TableHeader,
            TableCell,
            Underline
            
        ],
        })

        let status = ref('');
        let pageUrl = `/requisition/get-requisitioner-accountabilities/${loggedInUser.value.id}/`;
        let isLoading = ref(false);
        let errorMessage = ref("");
        let successMessage = ref("");
        let fromPage = ref(0)
        let toPage   = ref(0)
        let totalPageResults   = ref(0);
        let prevPageUrl = ref('')
        let nextPageUrl = ref('');

        let money_utilized  = ref('0')
        let money_returned  = ref('0')
        let balance_claimed = ref('0')
        let description     = ref(''); 

        const staticNewApprovalRoleId = 6;

         const people = ref([
          { id: 0, user_id: 0, name: '' }
          ])
          const query = ref('')
          const selectedPerson = ref(people.value[0])
          const filteredPeople = computed(() =>
          query.value === ''
              ? people.value
              : people.value.filter((person) => {
                  return person.name.toLowerCase().includes(query.value.toLowerCase())
              })
          )
        let requisitionProp = ref({});
        let attachments = ref([]);
        let accountabilityTransitions = ref([]);

        let voucher_number = ref('')
       // let requisitionTransitions   = ref([]);
       let selectedRequisition  = ref('0');
       let selectedAccountability  = ref('0');
        
      async function getAccountabilities(url = pageUrl,extra_url='',status_='') {
        isLoading.value = true;

        

        //if(status_){

         status.value = status_;

       // }

        if(extra_url){

            url = url + status.value + extra_url;

        }else{

            url = url + status.value;
            
        }

        stats.value[0].stat = 0;
        stats.value[1].stat = 0;
        stats.value[2].stat = 0;
        stats.value[3].stat = 0;

        // console.log(url)


        try{

          

          const response = await  Api().get(url);

          if (response.data.status === "success") {

               
                successMessage.value      = response.data.message
                accountabilities.value    = response.data.accountabilities.data;
                nextPageUrl.value         = response.data.accountabilities.next_page_url
                prevPageUrl.value         = response.data.accountabilities.prev_page_url
                fromPage.value            = response.data.accountabilities.from
                toPage.value              = response.data.accountabilities.to
                totalPageResults.value    = response.data.accountabilities.total
                //status.value = '';

                for(let i =0; i < response.data.stats.length; i++){

                  if(response.data.stats[i].status == '---'){

                        stats.value[0].stat = response.data.stats[i].stat

                    }else if(response.data.stats[i].status == 'Pending'){

                        stats.value[1].stat = response.data.stats[i].stat

                    }else if(response.data.stats[i].status == 'Approved'){

                        stats.value[2].stat = response.data.stats[i].stat

                    }else if(response.data.stats[i].status == 'Rejected'){

                        stats.value[3].stat = response.data.stats[i].stat
                        
                    }

                }
          }

        }catch (err) {

            if (err.response) {

              errorMessage.value = err.response.data.message;

                //return { 'message': err.response.data.message, 'status': 'error' };

            } else if (err.request) {

                errorMessage.value = err.request.data.message

                //return { 'message': err.request.data.message, 'status': 'error' };

            } else {

                errorMessage.value = err.message

              // return { 'message': err.message, 'status': 'error' };

            }
        }

        isLoading.value = false;

      }

      let file_upload_1 = ref('Expenditure Receipt / Field Work Report');
      let file_upload_2 = ref("Cashier's Receipt")
      let filesArray    = ref([null,null]);

      let accountability_name = ref('')
      let accountability_money_utilized  = ref('0')
      let accountability_money_returned  = ref('0')
      let accountability_balance_claimed = ref('0')
      let accountability_description     = ref('');
      
      const filter = ref("");
      const filteredAccountabilitiesTable = computed(() => {
        return accountabilities.value.filter(
          (val) =>{
            const name = val.requisition_name.toString().toLowerCase();
            const summary = val.summary.toString().toLowerCase();
            //const reason = val.purpose_of_requisition.toString().toLowerCase();
            
            return name.includes(filter.value.toLowerCase()) || summary.includes(filter.value.toLowerCase()); 
                   // || reason.includes(filter.value.toLowerCase());
          }
        );
      });
      

      function handleFile(e, fileNameVar){

          var files = e.target.files || e.dataTransfer.files;
          if(files[0]){

              let filename = files[0].name;
                  if(fileNameVar == '1'){
                      file_upload_1.value = filename;
                      filesArray.value[0] = files[0];

                  }else if(fileNameVar == '2'){

                      file_upload_2.value = filename
                      filesArray.value[1] = files[0];

                  }
          }

      }

      function clearFileSelection(file){

          if(file == '0'){

              file_upload_1.value = 'Expenditure Receipt / Field Work Report'
              filesArray.value[0] = null;

          }else if(file == '1'){
              
              file_upload_2.value = "Cashier's Receipt"
              filesArray.value[1] = null;

          }


      }

       async function submit (){

          //  showAccountabilityModal.value = false

            errorMessage.value = "";
            successMessage.value = "";
            description.value =  editor.value.getHTML();

            if(description.value.replace( /(<([^>]+)>)/ig, '').trim() == ''){

               errorMessage.value = "Description cannot be empty"
               return;

            }

            if(selectedPerson.value.id == '0'){

                errorMessage.value = "Please select a budget holder to send accountability to"
                return;

            }   

            let accountability = {

                'accountability_id' : selectedAccountability.value,
                'requisition_id' : selectedRequisition.value,
                'requisitioner_directorate_id' : loggedInUser.value.directorate_id,
                'money_utilized'  : money_utilized.value,
                'money_returned'  : money_returned.value,
                'balance_claimed' : balance_claimed.value,
                'description'     :  editor.value.getHTML(),
                'accountability_by'    : loggedInUser.value.id,
                'approver_user_id'    : selectedPerson.value.user_id,
                'erp_systems_role_id'    : selectedPerson.value.id,

            }
            
            isLoading.value =  true;

           const formData = new FormData();
           Object.entries(accountability).forEach(([key, value]) => {
                formData.append(key, value);
            });

           //formData.append('requisition',requisition);
           formData.append('file1', filesArray.value[0]);
           formData.append('file2', filesArray.value[1]);
            
            const options = {
                headers: {
                "Content-Type": "multipart/form-data",
                },
            };

            try {
            const res = await Api().post("/requisition/accountability/submit", formData,options)

            if (res.data.status === "success") {

                successMessage.value  = res.data.message;
                
                 resetValues()
                 clearAllFileSelections();
                 await getAccountabilities();


            }else{

               errorMessage.value = res.data.message;
            }

          } catch (err) {

              if (err.response) {

                 if (err.response.status === 422) {

                    //

                  }else{

                      errorMessage.value = "An error has occurred! " + err.response.message;

                  }

              } else if (err.request) {

                 errorMessage.value =  err.request.data.message;

              } else {

                 errorMessage.value =  err.message;

              }
          }

           isLoading.value = false;
          window.scrollTo({ top: 0, behavior: "smooth" });

       }

       async function loadApproverList(approval_level_role_id){

        try {

                const res = await Api().get(`/requisition/get-approvers/${approval_level_role_id}`);

                if (res.data.status === "success") {

                    people.value = res.data.approvers;

                }

            } catch (err) {

                if (err.response) {

                   //

                } else if (err.request) {

                   //

                } else {
                    
                     //

                }
            }


       }
       
       function clearAllFileSelections(){

                file_upload_1.value = 'Expenditure Receipt / Field Work Report';
                filesArray.value[0] = null;

                file_upload_2.value = "Cashier's Receipt";
                filesArray.value[1] = null;

               

       }

       function resetValues(){


         errorMessage.value = "";
         successMessage.value = "";
         selectedPerson.value = { id: 0, user_id:  0,  name: '' };
         editor.value.commands.clearContent();
         money_utilized.value  = 0;
         money_returned.value  = 0;
         balance_claimed.value = 0;
         selectedRequisition.value = '0';
         selectedAccountability.value = '0';
         clearAllFileSelections();


        showAccountabilityModal.value = false


       }
       async function showRequisition(requisition_id){

        selectedRequisition.value = requisition_id;

          isLoading.value = true;

          try{

            const response = await  Api().get(`/requisition/get-requisition-for-accountability/${requisition_id}`);

            if (response.data.status === "success") {

                  requisitionProp.value = response.data.requisition;
                  if(response.data.requisition){

                     voucher_number.value  = response.data.requisition.voucher_number

                  }
                  showAccountabilityModal.value = true;
                  // requisitionAttachmentsProp.value = response.data.attachments;
                  // requisitionTransitionsProp.value = response.data.transitions;
                
            }

          }catch (err) {

              if (err.response) {

                errorMessage.value = err.response.data.message;

                

              } else if (err.request) {

                  errorMessage.value = err.request.data.message


              } else {

                  errorMessage.value = err.message


              }
          }

          isLoading.value = false;
          

       }
       async function showTransitionStatuses(requisition_id){

        selectedRequisition.value = requisition_id;

          isLoading.value = true;

          try{

            const response = await  Api().get(`/requisition/get-requisition-accountability-transitions/${requisition_id}`);

            if (response.data.status === "success") {

                  requisitionProp.value = response.data.requisition;
                  if(response.data.requisition){

                     voucher_number.value      = response.data.requisition.voucher_number
                     accountability_name.value = response.data.requisition.accountability_name
                     accountability_money_utilized.value   = response.data.requisition.money_utilized
                     accountability_money_returned.value   = response.data.requisition.money_returned
                     accountability_balance_claimed.value  = response.data.requisition.balance_claimed
                     accountability_description.value      = response.data.requisition.description

                  }
                  
                  showAccountabilityTansitionsModal.value = true;
                   attachments.value = response.data.attachments;
                   accountabilityTransitions.value = response.data.transitions;
                
            }

          }catch (err) {

              if (err.response) {

                errorMessage.value = err.response.data.message;

                

              } else if (err.request) {

                  errorMessage.value = err.request.data.message


              } else {

                  errorMessage.value = err.message


              }
          }

          isLoading.value = false;
          

       }

      function downloadRequisitionPdf(){

        let element = document.getElementById('requisition-to-convert');
        let downloadFileName = Date.now();

        if(requisitionProp.value){

          downloadFileName = requisitionProp.value.requisition_name

        }

        let opt = {
          margin:       0,
          filename:     downloadFileName,
          image:        { type: 'jpeg', quality: 1 },
          // html2canvas:  { scale: 1 },
          jsPDF:        { unit: 'mm', orientation: 'portrait' },
          };

        html2pdf().from(element).set(opt).save();

       // html2pdf(document.getElementById('requisition-to-convert'));

      }

        loadApproverList(staticNewApprovalRoleId);

        getAccountabilities();

        
        return {
            accountabilities,showAccountabilityModal,showAccountabilityTansitionsModal,
            editor,getAccountabilities,
            errorMessage, successMessage, isLoading, 
            fromPage, toPage, totalPageResults, prevPageUrl,nextPageUrl,pageUrl,
            handleFile, clearFileSelection,file_upload_1,file_upload_2, submit,
            money_utilized, money_returned, balance_claimed,
            query,selectedPerson,filteredPeople,resetValues,
            requisitionProp, attachments,accountabilityTransitions,
            showRequisition, voucher_number, downloadRequisitionPdf,
            showTransitionStatuses, accountability_name,
            accountability_money_utilized, accountability_money_returned, accountability_balance_claimed,
            accountability_description, file_url,
            filter, filteredAccountabilitiesTable, selectedAccountability,stats, status


        }
    }

}
</script>

<style>
    .ProseMirror {
      margin-top: 0;
      overflow: auto;
      padding: 1.5rem;
      height: 30vh;
      border: 1px solid #ebeef5;
      border-bottom: 1;
      border-top: 1;
      border-right: 1;
      border-left: 1;
      color: #000;
    }
    .ProseMirror.prose {
      max-width: 100%;
    }

    ul,
    ol {
      padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.1;
    }

    .ProseMirror pre {
      background: #0d0d0d;
      color: #fff;
      font-family: JetBrainsMono, monospace;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;
      white-space: pre-wrap;
      
    }
    .ProseMirror pre code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }

    img {
      max-width: 100%;
      height: auto;
    }

    blockquote {
      padding-left: 1rem;
      border-left: 2px solid rgba(#0d0d0d, 0.1);
    }

    hr {
      border: none;
      border-top: 2px solid rgba(#0d0d0d, 0.1);
      margin: 2rem 0;
    }
    .menu-button {
      font-size: inherit;
      font-family: inherit;
      margin: 0.2rem;
      border-radius: 0.3rem;
      padding: 0.1rem 0.4rem;
      background: white;
      accent-color: black;
      min-height: 35px;
      min-width: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .is_active,
    .menu-button:hover {
      background: rgb(59 130 246 / 1);
      border-color: #ffffff;
      color: #fff;
      cursor: pointer;
      fill: #fff;
    }


    mark {
      background-color: #faf594;
    }

    .ProseMirror table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 85%;
      margin: 2px;
      overflow: hidden;
      padding: 2px;
    }
    .ProseMirror table td,
    .ProseMirror table th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;
      margin-bottom: 0;
    }

    .ProseMirror table th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .ProseMirror table .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    .ProseMirror table p {
      margin: 0;
    }

    .tableWrapper {
      overflow-x: auto;
    }

    .resize-cursor {
      cursor: ew-resize;
      cursor: col-resize;
    }

</style>