<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="$emit('dismiss')">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div :style="printpdf? 'width: 210mm; min-height: 297mm;' : ''" class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-fit sm:w-3/4 sm:p-6">
          <div id="requisition-to-convert">
            <div>
                <div class="bg-white shadow overflow-hidden sm:rounded-lg mb-3">
                    <div class="px-4 py-5 sm:px-3">
                        <h3 class="text-lg leading-6 font-bold text-red-900">Requisition Accountability</h3>
                        <p class="mt-1 max-w-2xl text-xs text-gray-500">Detailed Transition Status Of This Accountability</p>
                    </div>
                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-3">
                            <div class="sm:col-span-1">
                            <dt class="text-sm font-bold text-red-900">Requisitioner</dt>
                            <dd class="mt-1 text-sm text-gray-900"> {{ requisition.requisitioner }} </dd>
                            </div>
                            <div class="sm:col-span-1">
                            <dt class="text-sm font-bold text-red-900">ID Number</dt>
                            <dd class="mt-1 text-sm text-gray-900">{{ requisition.requisition_name }}</dd>
                            </div>
                            <div class="sm:col-span-1">
                            <dt class="text-sm font-bold text-red-900">Urgent</dt>
                            <dd class="mt-1 text-sm text-gray-900">{{ requisition.is_urgent == 0? 'No' : 'Yes' }}</dd>
                            </div>
                            <div class="sm:col-span-1">
                            <dt class="text-sm font-bold text-red-900">Requisition Type</dt>
                            <dd class="mt-1 text-sm text-gray-900"> {{ requisition.type_of_requisition }} </dd>
                            </div>
                            <div class="sm:col-span-1">
                            <dt class="text-sm font-bold text-red-900">Date</dt>
                            <dd class="mt-1 text-sm text-gray-900"> {{ requisition.date }}  </dd>
                            </div>
                            <div class="sm:col-span-1">
                            <dt class="text-sm font-bold text-red-900">Budget Line</dt>
                            <dd class="mt-1 text-sm text-gray-900"> {{ requisition.budget_line_name? requisition.budget_line_name : 'not yet assigned' }} </dd>
                            </div>
                            <div class="sm:col-span-3">
                                <div :class="printpdf? 'items-center justify-center': ''" class="flex flex-col">
                                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                            <div class="shadow overflow-hidden ">
                                                <table class="min-w-full divide-y divide-gray-200 border border-slate-300 ">
                                                    <thead class="bg-teal-700">
                                                        <tr>
                                                            <th colspan="5" scope="col" class="px-6 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">[ Requisition Amount Composition ]</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="bg-white divide-y divide-gray-200">
                                                        <tr >
                                                            <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300 whitespace-nowrap">
                                                               <span class="font-bold"> Currency:</span> <br/> {{ requisition.currency? requisition.currency : '--' }}
                                                            </td>
                                                            <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300 whitespace-nowrap">
                                                              <span class="font-bold">Exchange Rate:</span> <br/> {{ requisition.exchange_rate }}
                                                            </td>
                                                            <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300 whitespace-nowrap">
                                                              <span class="font-bold">Currency Expenses:</span> <br/>  {{ requisition.foreign_expenses }}
                                                            </td>
                                                            <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300 whitespace-nowrap">
                                                             <span class="font-bold">Currency Contingency Fund:</span> <br/> {{ requisition.foreign_contingency_fund }}
                                                            </td>
                                                            <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300 whitespace-nowrap">
                                                              <span class="font-bold">Currency Per Diem:</span> <br/> {{ requisition.foreign_per_diem }}
                                                            </td>                            
                                                        </tr>
                                                        <tr >
                                                            <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300 whitespace-nowrap">
                                                             <span class="font-bold">Expenses:</span> <br/> {{ Number(requisition.local_expenses).toLocaleString() }}
                                                            </td>
                                                            <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300 whitespace-nowrap">
                                                             <span class="font-bold">Contingency Fund:</span> <br/> {{ Number(requisition.local_contingency_fund).toLocaleString() }}
                                                            </td>
                                                            <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300 whitespace-nowrap">
                                                             <span class="font-bold">Per Diem:</span> <br/>  {{ Number(requisition.local_per_diem).toLocaleString()  }}
                                                            </td>
                                                            <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300 whitespace-nowrap">
                                                              <span class="font-bold">Requisition Total:</span> <br/>  {{ Number(requisition.local_total_amount).toLocaleString() }}
                                                            </td>
                                                            <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300 whitespace-nowrap">
                                                              <span class="font-bold">Accountable Amount:</span> <br/> {{ Number(requisition.accountable_amount).toLocaleString()  }}
                                                            </td>                            
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sm:col-span-3">
                              <dt class="text-sm font-bold text-red-900">Summary Description</dt>
                              <dd class="mt-1 text-sm text-gray-900">{{ requisition.summary }} </dd>
                            </div>
                            <div v-if="requisition.requisition_description" class="sm:col-span-3">
                              <dt class="text-sm font-bold text-red-900">Description</dt>
                              <dd class="mt-1 text-sm text-gray-900" v-html="requisition.requisition_description" ></dd>
                            </div>
                            <slot name="body"> body </slot>
                        </dl>
                    </div>
                </div>
            </div>
             <slot name="footer"> footer </slot>
          </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref , inject} from 'vue'
import { Dialog, DialogOverlay, 
// DialogTitle, 
TransitionChild, TransitionRoot } from '@headlessui/vue'
// import { PaperClipIcon } from '@heroicons/vue/solid'
// import { CheckIcon } from '@heroicons/vue/outline'

export default {
    name: "RequisitionerAccountabilityModalComponent",
    props: {

       requisition: {
          //required: true,
          type: Object,
       }
    },
    emits: ["dismiss"],
    components: {
        Dialog,
        DialogOverlay,
        // DialogTitle,
        TransitionChild,
        TransitionRoot,
        // CheckIcon,
        // PaperClipIcon,
    },
  setup() {
    const open = ref(true)

    let printpdf = inject('printpdf')


    return {
      open,printpdf
    }
  },
}
</script>