<template>
   <div>
     <Loading v-model:active="isLoading" />
     <div class=" mt-3 mb-3 flex justify-center items-center">
            <AlertSuccess
                v-if="successMessage"
                :message="successMessage"
                @hide="successMessage = null"
            />
            <AlertError
                v-if="errorMessage"
                :message="[errorMessage]"
                @hide="errorMessage = null"
            />
        </div>
        <div class="flex sm:flex-row flex-wrap flex-col justify-between items-center pb-4">
            <div class="pr-3 flex sm:flex-row flex-col w-full sm:w-fit sm:space-x-3 py-5">
               <input type="text" v-model="category_name" id="category_name" class="block w-full flex-1 p-2 pl-2 sm:w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Category Name">
           
                <button @click="selectedCategoryId = 0 ; addBudgetLineCategory()" type="button" class="inline-flex justify-center items-center px-2.5 my-3 sm:my-0 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-900 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Add Category
                </button>

            </div>
            <!-- <label for="table-search" class="sr-only">Search</label> -->
            <div class="relative w-full  sm:w-1/3 flex">
                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                </div>
                <input type="text" v-model="filter" id="table-search" class="block w-full sm:w-fit  flex-1 p-2 pl-10 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500" placeholder="Search for categories">
            </div>
        </div>
        <div class="flex flex-col">
            <div class="-my-2  sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
                        <table class="w-full divide-y divide-gray-200">
                            <thead class="bg-teal-700">
                                <tr>
                                    <!-- <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">#</th> -->
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Name</th>                                    
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Created By</th>                                    
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Created Date</th>                                    
                                    <th scope="col" class="relative px-6 py-3">
                                    <span class="sr-only">edit</span>
                                    </th>
                                    <th scope="col" class="relative px-6 py-3">
                                    <span class="sr-only">delete</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="(category,index) in filteredCategoriesTable" :key="index">
                                    <!-- <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {{ index+1 }}
                                    </td> -->
                                    <td class="px-6 py-4 break-words text-sm font-medium text-gray-900">
                                    {{ category.name }}
                                    </td>
                                    <td class="px-6 py-4 break-words text-sm font-medium text-gray-900">
                                    {{ category.created_by }}
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {{ category.created_date }}
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                      <a @click="selectedCategoryId = category.id; editCategoryName =category.name; showModal = true;" class="bg-indigo-600 text-white text-xs  py-1 px-2 rounded-md cursor-pointer hover:bg-indigo-900 shadow-md">edit</a>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    <a   @click="selectedCategoryId = category.id; showPrompt = true;" class="text-white bg-red-600 py-1 px-2 rounded-md text-xs cursor-pointer hover:bg-red-900 shadow-md">delete</a>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                  <td colspan="6">
                                      <nav class="bg-white px-4 py-3 flex items-center justify-between  sm:px-6" aria-label="Pagination">
                                          <div class="hidden sm:block">
                                          <p class="text-sm text-gray-700">
                                              Showing
                                              {{ ' ' }}
                                              <span class="font-medium">{{ fromPage }}</span>
                                              {{ ' ' }}
                                              to
                                              {{ ' ' }}
                                              <span class="font-medium"> {{ toPage }} </span>
                                              {{ ' ' }}
                                              of
                                              {{ ' ' }}
                                              <span class="font-medium">{{ totalPageResults }}</span>
                                              {{ ' ' }}
                                              results
                                          </p>
                                          </div>
                                          <div class="flex-1 flex justify-between sm:justify-end">
                                          <!-- <a href="#" class="relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-50"> Previous </a>
                                          <a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-50"> Next </a> -->
                                           <a :class="prevPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="loadBudgetLinesCategories( pageUrl+prevPageUrl)"  class="relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Previous </a>
                                           <a :class="nextPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="loadBudgetLinesCategories( pageUrl+nextPageUrl)" class="ml-3 cursor-pointer relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Next </a>
                                          </div>
                                      </nav>
                                  </td>
                              </tr>
                          </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <ManagerAccountsModalComponent :show="showModal" @dismiss="showModal = false">
        <template v-slot:header>
                    Edit
                    <AlertError
                    v-if="errorMessage"
                    :message="[errorMessage]"
                    @hide="errorMessage = null"
                />
                    
            </template>
            <template v-slot:body>
            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-1 p-1">
                <div class="sm:col-span-1 w-full">
                    <label for="budgetLineName" class="block text-sm font-medium text-red-900">Category Name</label>
                    <div class="mt-1">
                    <input id="editCategoryName" v-model="editCategoryName" name="editCategoryName" type="text" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                    </div>
                </div>
            </div>
            </template>
            <template v-slot:footer>
            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-1 sm:text-sm" @click="addBudgetLineCategory(false)">save</button>
                <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-2 sm:text-sm" @click="selectedCategoryId = '0'; editCategoryName =''; showModal = false" >Cancel</button>
            </div>
            </template>
        </ManagerAccountsModalComponent>
      <DeleteAlert :show="showPrompt"  item="category"  @cancel="showPrompt = false" @delete="deleteCategory(selectedCategoryId); showPrompt = false" />

    </div>
</template>

<script>
import { ref, inject, computed } from 'vue'
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import Api from "@/components/api/Api";
import ManagerAccountsModalComponent from "./ManagerAccountsModalComponent.vue";
import DeleteAlert from "@/components/alertComponents/DeleteAlert.vue";


export default {

     components: {
          AlertError, AlertSuccess,
          ManagerAccountsModalComponent,
          DeleteAlert
    },

    setup(){

        const categories = ref([])

        let category_name = ref('');
        let isLoading = ref(false);
        let errorMessage = ref("");
        let successMessage = ref("");
        let loggedInUser =  inject("loggedInUser");
        let selectedFinancialYear =  inject("selectedFinancialYear");

        let fromPage = ref(0)
        let toPage   = ref(0)
        let totalPageResults   = ref(0);
        let prevPageUrl = ref('#')
        let nextPageUrl = ref('#')
        let pageUrl = `/requisition/get-budget-line-categories/${selectedFinancialYear.value}`;

        const staticOldApprovalRoleId = 10;
        const filter = ref("");
        const filteredCategoriesTable = computed(() => {
            return categories.value.filter(
            (val) =>{
                const name = val.name.toString().toLowerCase();
               // const email = val.email.toString().toLowerCase();
                
                return name.includes(filter.value.toLowerCase()) ;//|| email.includes(filter.value.toLowerCase());
            }
            );
        });

        let showModal = ref(false);
        let editCategoryName = ref('');
        let selectedCategoryId = ref('0');

        const showPrompt = ref(false);
        
        
        async function addBudgetLineCategory(isadding = true){

            errorMessage.value = ''
            successMessage.value = ''

             if(selectedFinancialYear.value == 0){


                errorMessage.value  = 'You have not selected a financial year';
                return;

            }

            isLoading.value = true;

            let categoryObj = {

                'category_name' : isadding? category_name.value : editCategoryName.value ,
                'added_by_user_id': loggedInUser.value.id,
                'approval_level_role_id': staticOldApprovalRoleId,
                'financial_year_id': selectedFinancialYear.value,
            }

            try {
            
                const res = await Api().post(`/requisition/add-category-name/${selectedCategoryId.value}`,categoryObj);

                if (res.data.status === "success") {

                  successMessage.value = res.data.message;
                  category_name.value = '';
                  editCategoryName.value = '';
                  loadBudgetLinesCategories();

                  if(!isadding){

                        showModal.value = false

                  }

                }else{

                     errorMessage.value = res.data.message;

                }


            } catch (err) {

                if (err.response) {

                    errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                    errorMessage.value = err.request.data.message ;

                } else {

                    errorMessage.value = err.message;

                }
            }
            isLoading.value = false;


        }


        async function loadBudgetLinesCategories(url = pageUrl){

            try {

                const response = await Api().get(url);

                if(response.data.status === "success"){

                        categories.value         = response.data.categories.data;
                        nextPageUrl.value        = response.data.categories.next_page_url
                        prevPageUrl.value        = response.data.categories.prev_page_url
                        fromPage.value           = response.data.categories.from
                        toPage.value             = response.data.categories.to
                        totalPageResults.value   = response.data.categories.total
                        successMessage.value     = response.data.message;
                
                } 

            } catch (err) {

                if (err.response) {

                    errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                errorMessage.value =  err.request.data.message;

                } else {

                    errorMessage.value =  err.message;
                    
                }
            }

            // console.log('loaded')

        }

        async function deleteCategory(selectedCategoryId_){

            successMessage.value = '';
            errorMessage.value = '';

             isLoading.value = true;

          try {
            
              const res = await Api().delete(`/requisition/delete-budget-line-category/${selectedCategoryId_}`);

              if (res.data.status === "success") {

                successMessage.value = res.data.message;
                await  loadBudgetLinesCategories();

              }else{

                errorMessage.value = res.data.message;
              }


            } catch (err) {

                if (err.response) {

                  errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                    errorMessage.value = err.request.data.message ;

                } else {

                  errorMessage.value = err.message;

                }
            }
          isLoading.value = false;


            
        }

       loadBudgetLinesCategories();

        return {

            categories, addBudgetLineCategory, category_name,
             errorMessage, successMessage, isLoading, 
             fromPage, toPage, totalPageResults, prevPageUrl,nextPageUrl,pageUrl,
             filter, filteredCategoriesTable,loadBudgetLinesCategories,
             showModal, editCategoryName, selectedCategoryId, showPrompt,
             deleteCategory

        }
    }

}
</script>

<style>

</style>