import { ref } from "vue";
import Api from "@/components/api/Api";

// State
const directorates = ref(JSON.parse(localStorage.getItem('directorates')));

export const initDirectorates = async () => {

    try {

        const res = await Api().get(`/app/get-directorates`);

        if (res.data.status === "success") {

            directorates.value = res.data.directorates;

            localStorage.setItem("directorates", JSON.stringify(res.data.directorates));

            return { 'message': res.data.message, 'status': res.data.status };

        }

    } catch (err) {

        if (err.response) {

            return { 'message': err.response.data.message, 'status': 'error' };

        } else if (err.request) {

            return { 'message': err.request.data.message, 'status': 'error' };

        } else {

            return { 'message': err.message, 'status': 'error' };

        }
    }

};

// Getters
export const getDirectorates = () => {

    if (!directorates.value) {

        directorates.value = JSON.parse(localStorage.getItem("directorates"));

    }

    return directorates;

};
