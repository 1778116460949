<template>
  <div>
    <h1 class="text-center text-xl font-bold">BUDGET LINE TRANSACTIONS</h1>
     <div class="shadow drop-shadow-sm p-3">
    <h2 class="text-center text-md py-3">Budget Line Cashed Out Transactions</h2>
     
        <div class="flex flex-col">
            <div class="-my-2  sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
                        <table class="w-full table-auto divide-y divide-gray-200">
                            <thead class="bg-green-50">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Id</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>                                    
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Transacted Amount (UGX)</th>                                    
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Paid By</th>                                    
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date of Transaction</th>                                    
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="(cashedOutTransaction,index) in cashedOutTransactions" :key="index">
                                    <td class="px-6 py-4 break-words text-sm font-medium text-gray-900">
                                    {{ cashedOutTransaction.id }}
                                    </td>
                                    <td class="px-6 py-4 break-words text-sm  text-gray-500">
                                    {{ cashedOutTransaction.description }}
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {{ cashedOutTransaction.transacted_amount }}
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {{ cashedOutTransaction.paid_by }}
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {{ cashedOutTransaction.date_of_transaction }}
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="5">
                                        <nav class="bg-white px-4 py-3 flex items-center justify-between  sm:px-6" aria-label="Pagination">
                                            <div class="hidden sm:block">
                                            <p class="text-sm text-gray-700">
                                                Showing
                                                {{ ' ' }}
                                                <span class="font-medium">1</span>
                                                {{ ' ' }}
                                                to
                                                {{ ' ' }}
                                                <span class="font-medium">10</span>
                                                {{ ' ' }}
                                                of
                                                {{ ' ' }}
                                                <span class="font-medium">20</span>
                                                {{ ' ' }}
                                                results
                                            </p>
                                            </div>
                                            <div class="flex-1 flex justify-between sm:justify-end">
                                            <a href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> Previous </a>
                                            <a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> Next </a>
                                            </div>
                                        </nav>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
     </div>
      <div class="shadow drop-shadow-sm mt-5 p-3">
        <h2 class="text-center text-md py-5">Budget Line Additional Amount Transactions</h2>
            <div class="flex flex-col">
                <div class="-my-2  sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                        <div class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
                            <table class="w-full table-auto divide-y divide-gray-200">
                                <thead class="bg-green-50">
                                    <tr>                                   
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Transacted Amount (UGX)</th>                                    
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Added By</th>                                    
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date of Transaction</th>                                    
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                    <tr v-for="(additionalAmountTransaction,index) in additionalAmountTransactions" :key="index">
                                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {{ additionalAmountTransaction.transacted_amount }}
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {{ additionalAmountTransaction.added_by }}
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {{ additionalAmountTransaction.date_of_transaction }}
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="3">
                                            <nav class="bg-white px-4 py-3 flex items-center justify-between  sm:px-6" aria-label="Pagination">
                                                <div class="hidden sm:block">
                                                <p class="text-sm text-gray-700">
                                                    Showing
                                                    {{ ' ' }}
                                                    <span class="font-medium">1</span>
                                                    {{ ' ' }}
                                                    to
                                                    {{ ' ' }}
                                                    <span class="font-medium">10</span>
                                                    {{ ' ' }}
                                                    of
                                                    {{ ' ' }}
                                                    <span class="font-medium">20</span>
                                                    {{ ' ' }}
                                                    results
                                                </p>
                                                </div>
                                                <div class="flex-1 flex justify-between sm:justify-end">
                                                <a href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> Previous </a>
                                                <a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> Next </a>
                                                </div>
                                            </nav>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
      </div>
      <div class="shadow drop-shadow-sm p-3 mt-5">
        <h2 class="text-center text-md py-3">Budget Line Returned Amount Transactions</h2>
            <div class="flex flex-col">
                <div class="-my-2  sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                        <div class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
                            <table class="w-full table-auto divide-y divide-gray-200">
                                <thead class="bg-green-50">
                                    <tr>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Id</th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>                                    
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Transacted Amount (UGX)</th>                                    
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Return Approved By</th>                                    
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date of Transaction</th>                                    
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                    <tr v-for="(returnedAmountTransaction,index) in returnedAmountTransactions" :key="index">
                                        <td class="px-6 py-4 break-words text-sm font-medium text-gray-900">
                                        {{ returnedAmountTransaction.id }}
                                        </td>
                                        <td class="px-6 py-4 break-words text-sm  text-gray-500">
                                        {{ returnedAmountTransaction.description }}
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {{ returnedAmountTransaction.transacted_amount }}
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {{ returnedAmountTransaction.approved_by }}
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {{ returnedAmountTransaction.date_of_transaction }}
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="5">
                                            <nav class="bg-white px-4 py-3 flex items-center justify-between  sm:px-6" aria-label="Pagination">
                                                <div class="hidden sm:block">
                                                <p class="text-sm text-gray-700">
                                                    Showing
                                                    {{ ' ' }}
                                                    <span class="font-medium">1</span>
                                                    {{ ' ' }}
                                                    to
                                                    {{ ' ' }}
                                                    <span class="font-medium">10</span>
                                                    {{ ' ' }}
                                                    of
                                                    {{ ' ' }}
                                                    <span class="font-medium">20</span>
                                                    {{ ' ' }}
                                                    results
                                                </p>
                                                </div>
                                                <div class="flex-1 flex justify-between sm:justify-end">
                                                <a href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> Previous </a>
                                                <a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> Next </a>
                                                </div>
                                            </nav>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
      </div>
  </div>
</template>

<script>
export default {

    setup(){

        const cashedOutTransactions = [
        { id: 'JMS/DBME/2022-07-21 15:54:53/DB62', description:'Request for payment for loading and off load of cylinders to kawempe NRH', transacted_amount: '40,000.00', paid_by: 'Cashier - Cash Office ( Payments Office, All )', date_of_transaction: '2022-08-01 13:21:38' },
        { id: 'JMS/DBME/2022-07-21 15:54:53/DB62', description:'Request for payment for loading and off load of cylinders to kawempe NRH', transacted_amount: '40,000.00', paid_by: 'Cashier - Cash Office ( Payments Office, All )', date_of_transaction: '2022-08-01 13:21:38' },
        { id: 'JMS/DBME/2022-07-21 15:54:53/DB62', description:'Request for payment for loading and off load of cylinders to kawempe NRH', transacted_amount: '40,000.00', paid_by: 'Cashier - Cash Office ( Payments Office, All )', date_of_transaction: '2022-08-01 13:21:38' },
        { id: 'JMS/DBME/2022-07-21 15:54:53/DB62', description:'Request for payment for loading and off load of cylinders to kawempe NRH', transacted_amount: '40,000.00', paid_by: 'Cashier - Cash Office ( Payments Office, All )', date_of_transaction: '2022-08-01 13:21:38' },
        
        ]

        const additionalAmountTransactions = [
        { transacted_amount: '40,000.00', added_by: 'Cashier - Cash Office ( Payments Office, All )', date_of_transaction: '2022-08-01 13:21:38' },
        { transacted_amount: '40,000.00', added_by: 'Cashier - Cash Office ( Payments Office, All )', date_of_transaction: '2022-08-01 13:21:38' },
        { transacted_amount: '40,000.00', added_by: 'Cashier - Cash Office ( Payments Office, All )', date_of_transaction: '2022-08-01 13:21:38' },
       
        ]

         const returnedAmountTransactions = [
        { id: 'JMS/DBME/2022-07-21 15:54:53/DB62', description:'Request for payment for loading and off load of cylinders to kawempe NRH', transacted_amount: '40,000.00', approved_by: 'Cashier - Cash Office ( Payments Office, All )', date_of_transaction: '2022-08-01 13:21:38' },
        { id: 'JMS/DBME/2022-07-21 15:54:53/DB62', description:'Request for payment for loading and off load of cylinders to kawempe NRH', transacted_amount: '40,000.00', approved_by: 'Cashier - Cash Office ( Payments Office, All )', date_of_transaction: '2022-08-01 13:21:38' },
        { id: 'JMS/DBME/2022-07-21 15:54:53/DB62', description:'Request for payment for loading and off load of cylinders to kawempe NRH', transacted_amount: '40,000.00', approved_by: 'Cashier - Cash Office ( Payments Office, All )', date_of_transaction: '2022-08-01 13:21:38' },
        { id: 'JMS/DBME/2022-07-21 15:54:53/DB62', description:'Request for payment for loading and off load of cylinders to kawempe NRH', transacted_amount: '40,000.00', approved_by: 'Cashier - Cash Office ( Payments Office, All )', date_of_transaction: '2022-08-01 13:21:38' },
        
        ]


        return {

            cashedOutTransactions, additionalAmountTransactions, returnedAmountTransactions

        }
    }

}
</script>

<style>

</style>