<template>
  <div class="mt-1">
     <Loading v-model:active="isLoading" />
    <div class="mt-1 max-w-2xl mx-auto">
      <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <button
          @click.prevent="$router.push({ name: 'TenderList' })"
          class="capitalize flex text-blue-500 hover:underline"
        >
          <ArrowLeftIcon class="mr-2 h-6 w-6" />
          back
        </button>
          <div class="flex justify-center items-center space-y-4 flex-col">
            <p  v-if="returned_link" class="break-all">Copy this link: <span class="text-green-900 ">{{ returned_link }}</span></p>
              <AlertSuccess
                v-if="successMessage"
                :message="successMessage"
                @hide="successMessage = null"
              />
              <AlertError
                v-if="errorMessage"
                :message="[errorMessage]"
                @hide="errorMessage = null"
              />
           </div>
        <div class="flex justify-center items-center mt-5">
          <h2 class="text-xl leading-6 font-medium text-gray-900">
            Equipment List for Tender: <span class="text-red-900"> {{ tender_name }} </span>
          </h2>
        </div>
      </div>
    </div>
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between items-center">
        <h2 class="text-xl leading-6 font-medium text-gray-900"></h2>
        <div
          class="
            relative
            z-0
            flex-1
            px-2
            flex
            items-center
            justify-center
            sm:inset-0
            my-10
          "
        >
          <div class="w-full max-w-xl mx-auto">
            <label for="search" class="sr-only">Search</label>
            <div class="relative">
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  left-0
                  pl-3
                  flex
                  items-center
                "
              >
                <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                id="search"
                name="search"
                v-model="filter"
                class="
                  block
                  w-full
                  bg-gray-100
                  border border-transparent
                  rounded-md
                  py-3
                  pl-10
                  pr-3
                  text-sm
                  placeholder-gray-400
                  focus:outline-none
                  focus:bg-gray-50
                  focus:border-white
                  focus:ring-white
                  focus:text-gray-900
                  focus:placeholder-gray-500
                  sm:text-sm
                "
                placeholder="Search"
                type="search"
              />
            </div>
          </div>
        </div>
        <router-link
          :to="{ name: 'EditEquipment' }"
          class="
            block
            bg-red-900
            hover:bg-red-700
            p-2
            rounded-lg
            px-5
            text-white text-center
          "
          >Create</router-link
        >
      </div>
    </div>
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="mt-10 max-w-sm sm:max-w-none sm:flex sm:justify-center sm:space-x-3">
       
          <button
            @click.prevent="showModal"
            class="
              py-2 px-3 text-xs font-medium text-center
              rounded-md
              shadow-sm
              text-white
              bg-blue-600
              hover:bg-blue-500
            "
          >
            Send Link to Manufacturer
          </button>
       
          <button
            @click.prevent="compileBestOfferReport"
            href=""
            class="
              flex
              items-center
              justify-center
              px-4
              py-3
              border border-transparent
              text-xs
              sm:mt-0
              mt-2
              font-medium
              rounded-md
              shadow-sm
              text-white
              bg-green-600
              hover:bg-green-500
              sm:px-8
            "
          >
            compile best offer report
          </button>
        
      </div>
    </div>
    <ModalComponent v-show="isModalVisible" @close="closeModal">
      <!-- header -->
      <template v-slot:header>
        <h1 class="font-bold text-xl">
          Send Link
        </h1>
      </template>

      <!-- body -->
      <template v-slot:body>
        <input
          type="text"
          name="manufacturer_name"
          id="manufacturer_name"
          v-model="manufacturer_name"
          placeholder="Manufacturer's Name"
          class="rounded-2xl w-full p-3 my-1 shadow-md outline-none"
        />

        <input
          type="email"
          name="manufacturer_email"
          v-model="manufacturer_email"
          id="manufacturer_email"
          placeholder="Manufacturer's E-mail"
          class="rounded-2xl w-full p-3 my-1 shadow-md outline-none"
        />

        <button
          type="button"
          @click.prevent="sendLink()"
          class="rounded-2xl bg-red-900 hover:bg-red-700 shadow-md p-3 my-1 w-full text-white"
        >
          send
        </button>
      </template>

      <!-- footer -->
      <!-- <template v-slot:footer>
        <p class="text-purple-900 text-xs font-bold">
          Already have an account? <a href="#">Sign up</a>
        </p>
      </template> -->
    </ModalComponent>

    <div class="mt-12 max-w-6xl mx-auto">
      <div
        class="shadow overflow-auto max-h-96 border-b border-gray-200 sm:rounded-lg"
      >
        <table class="min-w-full divide-y divide-gray-200">
          <thead :class="isSticky" class="bg-teal-700">
            <tr>
              <th
                scope="col"
                class="
                  px-3
                  py-3
                  text-left text-xs
                  font-medium
                  text-white
                  capitalize
                  tracking-wider
                "
              >
                Created Date
              </th>
              <th
                scope="col"
                class="
                  px-6
                  py-3
                  text-left text-xs
                  font-medium
                  text-white
                  capitalize
                  tracking-wider
                "
              >
                Equipment Name
              </th>
              <th
                scope="col"
                class="
                  px-6
                  py-3
                  text-left text-xs
                  font-medium
                  text-white
                  capitalize
                  tracking-wider
                "
              >
                Added By
              </th>
              <th
                scope="col"
                class="
                  px-6
                  py-3
                  text-left text-xs
                  font-medium
                  text-white
                  capitalize
                  tracking-wider
                "
              >
                <div class="flex items-center w-full">
                    <input
                      id="select-all"
                      name="select-all"
                      type="checkbox"
                      v-model="checkAllBox"
                      @click="checkall"
                      class="
                        h-4
                        w-4
                        text-blue-600
                        focus:ring-blue-500
                        border-gray-300
                        rounded
                      "
                    />
                    <label for="select-all" class="ml-2 block whitespace-nowrap">
                      select all
                    </label>
                </div>
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(equipment, index) in getEquipmentList"
              :key="equipment.id"
              :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
            >
              <td
                class="
                  px-5
                  py-4
                  whitespace-nowrap
                  text-sm
                  font-medium
                  text-gray-900
                "
              >
                {{ equipment.date }}
              </td>
              <td class="px-6 py-4  text-sm text-gray-500">
                {{ equipment.name }}
              </td>
              <td class="px-6 py-4  text-sm text-gray-500">
                {{ equipment.added_by }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <div class="flex items-center w-6">
                  <input
                    id="select"
                    name="remember-me"
                    type="checkbox"
                    :value="equipment.id"
                    v-model="selectionArray"
                    class="
                      h-4
                      w-4
                      text-blue-600
                      focus:ring-blue-500
                      border-gray-300
                      rounded
                    "
                  />
                  <label for="select" class="ml-2 block text-sm text-gray-900">
                    select 
                  </label>
                </div>
              </td>

              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <button
                  @click="
                    selectedEquipment = equipment; setEquipment(equipment);
                    $router.push({name : 'ViewManufacturerList' });
                  "
                  class="text-white bg-green-600 py-1 px-2 rounded-md text-xs cursor-pointer hover:bg-green-900 shadow-md"
                >
                  manufacturer list
                </button>
                <button
                  @click="
                    selectedEquipment = equipment; setEquipment(equipment);
                    $router.push({ name: 'EditEquipment' });
                  "
                  class="bg-indigo-600 text-white text-xs  py-1 px-2 rounded-md cursor-pointer hover:bg-indigo-900 shadow-md ml-10"
                >
                  Edit
                </button>
                <button
                  @click="
                    selectedEquipment = equipment;
                    showPrompt = true;
                  "
                  class="text-white bg-red-600 py-1 px-2 rounded-md text-xs cursor-pointer hover:bg-red-900 shadow-md ml-10"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <DeleteAlert :show="showPrompt"  item="this equipment"  @cancel="showPrompt = false" @delete="deleteEquipment(selectedEquipment); showPrompt = false" />
</template>

<script>
import { SearchIcon, ArrowLeftIcon } from "@heroicons/vue/solid";
import { ref, computed, inject } from "vue";
import ModalComponent from "@/components/TenderComponents/ModalComponent.vue";
import DeleteAlert from "@/components/alertComponents/DeleteAlert.vue";
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import Api from "@/components/api/Api";
import { getTender, setEquipment } from "@/stores/Tender/Tender.js" 
// import { useRouter } from "vue-router";

export default {
  name: "EquipmentList",
  components: { SearchIcon, ArrowLeftIcon, ModalComponent, DeleteAlert , AlertSuccess, AlertError },
  setup() {

    // const router = useRouter();
    let isLoading  = ref(false);
    let isModalVisible = ref(false);
    let isSticky = ref('sticky top-0');

    const equipmentList = ref([]);

    const selectionArray = ref([]);
    
    const selectedEquipment = ref();
    setEquipment(null)
    const filter = ref("");
    let tender_id = ref("0");
    let manufacturer_name = ref('');
    let manufacturer_email = ref('');

    let tender_name = ref('');
    let selectedTender = getTender();

    // if(!selectedTender.value){

    //   router.push({name:'TenderList'});
    

    // }else{


    // }

    tender_id.value = selectedTender.value.id;
    tender_name.value =  selectedTender.value.name;
     const showPrompt = ref(false);
    let errorMessage = ref("");
    let successMessage = ref("");

     let loggedInUser =  inject("loggedInUser");   
     let userid = loggedInUser.value.id;


     let returned_link = ref('');
     let checkAllBox = ref(false);

    const getEquipmentList = computed(() => {
      return equipmentList.value.filter(
        (val) =>
          val.name.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
      );
    });

    function showModal() {

      if(selectionArray.value.length == 0){

        errorMessage.value   = "You should first select some equipment if you want to generate a link";
        return;
      }

      isSticky.value = '';
      isModalVisible.value = true;
    }
    function closeModal() {

      isModalVisible.value = false;
      isSticky.value = 'sticky top-0';

    }

    function loadListOfTenderEquipment() {

        isLoading.value = true;

        Api()
          .get(`/tenders/show-tender-equipment-list/${tender_id.value}`)
          .then((response) => {
            //Code for handling the response
            //add attribute editTeamName so that you can toggle showDetails row
            let status = response.data.status;
            //let message = response.data.message;
            if (status === "success") {
              equipmentList.value = response.data.equipment;
            } else {
              
              equipmentList.value = null;
              
            }
          })
          .catch((error) => {
              
              errorMessage.value = "An error has occurred! " + error.message;
          })
          .finally(() => {
            isLoading.value = false;
          });

    }

    function deleteEquipment(selectedEquipment){


      isLoading.value = true;
     
      let equipment_id_delete = selectedEquipment.id
     
            
            successMessage.value = "";
           
            errorMessage.value = null;

            Api()
              .post(`/delete-equipment/${equipment_id_delete}`)
              .then((response) => {

                let responseMessage = response.data.message;

                if (response.data.status === "success") {
                  successMessage.value = responseMessage;
                }else{
                  errorMessage.value   = responseMessage;
                }

              })
              .catch((error) => {

               

                 errorMessage.value = "An error has occured!" + error.message

                
              })
              .finally(() => {
                isLoading.value = false;
                loadListOfTenderEquipment();
                
              });
       



    }

    function sendLink(){

        isLoading.value = true;

        let link = {
            'manufacturer_name'  : manufacturer_name.value,
            'manufacturer_email':  manufacturer_email.value,
            'equipment': selectionArray.value,
            'sent_by_id': userid.toString(),
        };
           
        

        Api()
            .post("/equipment/send-link", link)
            .then((response) => {
                let message = response.data.message;

                let status = response.data.status;

                if (status === "error") {

                  errorMessage.value = message;
                    
                } else if (status === "success") {

                  successMessage.value = message;
                  selectionArray.value = [];
                  manufacturer_name.value  = "";
                  manufacturer_email.value = "";

                  returned_link.value = response.data.link;

                  if(response.data.other_status === "error"){

                    errorMessage.value = response.data.other_messages;

                  }
                  //closeModal();
        
                }
            })
            .catch((error) => {
                // error.value = "An error has occured! Please try again";
                errorMessage.value =  error.response.data.message + " Please make sure you have provided a valid email and name";

            })
            .finally(() => {

                isLoading.value = false;
                checkAllBox.value = false;
                closeModal();
            });        

    }

    function compileBestOfferReport(){

       
      isLoading.value =true;
      
      let options = {
        responseType: "blob",
      };
                  Api()
                    .post(
                      "/equipment/compile-best-offers",
                      {
                        'tender_id' : tender_id.value
                      },
                      options
                    )
                    .then((response) => {
                      const url = window.URL.createObjectURL(new Blob([response.data]));
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute("download", `test.docx`);
                      document.body.appendChild(link);
                      link.click();
                    })
                    .catch((error) => {

                      errorMessage.value = error.message;
                      
                    })
                    .finally(() => {
                      isLoading.value = false;
                    });
               

    }

    function checkall(){

      checkAllBox.value = !checkAllBox.value;

      if( checkAllBox.value){

        const selected = this.getEquipmentList.map((u) => u.id);
        selectionArray.value = selected;

      }else{

       selectionArray.value = [];

      }
      

    }
   
    loadListOfTenderEquipment();

    return {
      equipmentList,
      selectedEquipment,
      getEquipmentList,
      filter,
      isModalVisible,
      showModal,
      closeModal,
      tender_id,
      tender_name,
      showPrompt,
      deleteEquipment,
      successMessage,
      errorMessage,
      isLoading,
      selectionArray,
      sendLink,
      manufacturer_name,
      manufacturer_email,
      compileBestOfferReport,
      returned_link,
      checkall,
      checkAllBox,
      isSticky,
      setEquipment
    };
  },
};
</script>

<style>
</style>