<template>
    <div class="mt-8">
      <Loading v-model:active="isLoading" />
       <div class="flex justify-center items-center">
              <AlertSuccess
                v-if="successMessage"
                :message="successMessage"
                @hide="successMessage = null"
              />
              <AlertError
                v-if="errorMessage"
                :message="[errorMessage]"
                @hide="errorMessage = null"
              />
       </div>
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between items-center">
        <h2 class="text-xl leading-6 font-medium text-gray-900"></h2>
        <div class="relative z-0 flex-1 px-2 flex items-center justify-center sm:inset-0 my-10">
          <div class="w-full max-w-xl mx-auto">
            <label for="search" class="sr-only">Search</label>
            <div class="relative">
              <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input id="search" name="search" v-model="filter" class="block w-full bg-gray-100 border border-transparent rounded-md py-3 pl-10 pr-3 text-sm placeholder-gray-400 focus:outline-none focus:bg-gray-50 focus:border-white focus:ring-white focus:text-gray-900 focus:placeholder-gray-500 sm:text-sm" placeholder="Search" type="search" />
            </div>
          </div>
        </div>
                <!-- <button class="block bg-blue-600 hover:bg-blue-500 p-2 rounded-lg  px-5 text-white  text-center">Create</button> -->
                 <router-link
                      :to="{ name: 'EditTender' }"
                      class="
                        block
                        bg-red-900
                        hover:bg-red-700 
                        p-2
                        rounded-lg
                        px-5
                        text-white text-center
                      ">
                      Create</router-link>
      </div>
    </div>
    <div class="mt-12 max-w-6xl mx-auto">
      <div class="shadow overflow-auto max-h-96  border-b border-gray-200 sm:rounded-lg">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-teal-700 sticky top-0">
          <tr>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
              Created Date
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
              Tender Name
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
               Added By
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(tender,index ) in getTenderList" :key="tender.id" :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {{ tender.date }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ tender.name }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ tender.added_by }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              <button @click="setTender(tender); $router.push({name: 'EquipmentList'})"  class="text-white bg-green-600 py-1 px-2 rounded-md text-xs cursor-pointer hover:bg-green-900 shadow-md">view tender info</button>
              <!-- <button @click="setTender(tender); $router.push({ name: 'EditTender' });"  class="bg-indigo-600 text-white text-xs  py-1 px-2 rounded-md cursor-pointer hover:bg-indigo-900 shadow-md">Edit tender</button>
              <button @click="selectedTender=tender; showPrompt= true;" class="text-white bg-red-600 py-1 px-2 rounded-md text-xs cursor-pointer hover:bg-red-900 shadow-md ml-10">Delete</button> -->
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              <!-- <button @click="setTender(tender); $router.push({name: 'EquipmentList'})"  class="text-white bg-green-600 py-1 px-2 rounded-md text-xs cursor-pointer hover:bg-green-900 shadow-md mr-10">view tender info</button> -->
              <button @click="setTender(tender); $router.push({ name: 'EditTender' });"  class="bg-indigo-600 text-white text-xs  py-1 px-2 rounded-md cursor-pointer hover:bg-indigo-900 shadow-md">Edit tender</button>
              <!-- <button @click="selectedTender=tender; showPrompt= true;" class="text-white bg-red-600 py-1 px-2 rounded-md text-xs cursor-pointer hover:bg-red-900 shadow-md ml-10">Delete</button> -->
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              <!-- <button @click="setTender(tender); $router.push({name: 'EquipmentList'})"  class="text-white bg-green-600 py-1 px-2 rounded-md text-xs cursor-pointer hover:bg-green-900 shadow-md mr-10">view tender info</button>
              <button @click="setTender(tender); $router.push({ name: 'EditTender' });"  class="bg-indigo-600 text-white text-xs  py-1 px-2 rounded-md cursor-pointer hover:bg-indigo-900 shadow-md">Edit tender</button> -->
              <button @click="selectedTender=tender; showPrompt= true;" class="text-white bg-red-600 py-1 px-2 rounded-md text-xs cursor-pointer hover:bg-red-900 shadow-md">Delete</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <DeleteAlert :show="showPrompt"  item="tender"  @cancel="showPrompt = false" @delete="deleteTender(selectedTender); showPrompt = false" />
</template>

<script>
import { SearchIcon } from '@heroicons/vue/solid'
import {  ref , computed , inject} from "vue";
import DeleteAlert from "@/components/alertComponents/DeleteAlert.vue";
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import Api from "@/components/api/Api";
import { setTender } from "@/stores/Tender/Tender.js" 
export default {
    name: "TenderList",
    components:{SearchIcon, DeleteAlert, AlertSuccess, AlertError},
    setup(){

      let activePage = inject('activePage');
      activePage.value = 'Tenders'

       const tenderList = ref([])
       const showPrompt = ref(false);
       let errorMessage = ref("");
       let successMessage = ref("");
       const selectedTender = ref();
       const filter = ref('');
       let isLoading = ref(false);
       setTender(null);


        const getTenderList = computed(()=>{
            return tenderList.value.filter((val)=>val.name.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1)
        });

        function loadListOfTenders() {
             isLoading.value = true;

              Api()
                .get(`/tenders/show-tender-list`)
                .then((response) => {
                  //Code for handling the response
                  //add attribute editTeamName so that you can toggle showDetails row
                  let status = response.data.status;
                  //let message = response.data.message;
                  if (status === "success") {
                    tenderList.value = response.data.tenders;
                  } else {
                    
                   
                    tenderList.value = null;
                    
                  }
                })
                .catch((error) => {
                   errorMessage.value = "An error has occurred! " +error.message;
                })
                .finally(() => {
                  isLoading.value = false;
                 // loader.hide();
                });
        }

        function deleteTender(selectedTender){

          isLoading.value = true;
          
          let tender_id_delete = selectedTender.id
        
                
                successMessage.value = "";
                
                errorMessage.value = null;

                Api()
                  .post(`/delete-tender/${tender_id_delete}`)
                  .then((response) => {

                    let responseMessage = response.data.message;

                    if (response.data.status === "success") {
                      successMessage.value = responseMessage;
                    }else{
                      errorMessage.value   = responseMessage;
                    }

                  })
                  .catch((error) => {

                   

                    errorMessage.value = "An error has occured!" + error.message;

                    
                  })
                  .finally(() => {
                   
                    loadListOfTenders();
                    isLoading.value = false;
                  });
          
         }

        loadListOfTenders();
        
         return {
                tenderList,selectedTender,getTenderList,filter,deleteTender, 
                showPrompt, errorMessage,successMessage,isLoading,
                setTender
              
              }
    }

}
</script>

<style>

</style>