<template>
  <div class="mt-8">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between items-center">
        <h2 class="text-xl leading-6 font-medium text-gray-900">Projects</h2>
        <div class="relative z-0 flex-1 px-2 flex items-center justify-center sm:inset-0 my-10">
          <div class="w-full max-w-xl mx-auto">
            <label for="search" class="sr-only">Search</label>
            <div class="relative">
              <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input id="search" v-model="filter" name="search" class="block w-full bg-gray-100 border border-transparent rounded-md py-3 pl-10 pr-3 text-sm placeholder-gray-400 focus:outline-none focus:bg-gray-50 focus:border-white focus:ring-white focus:text-gray-900 focus:placeholder-gray-500 sm:text-sm" placeholder="Search" type="search" />
            </div>
          </div>
        </div>
        <router-link :to="{name:'dashboard-add-projects'}" class="block bg-blue-600 hover:bg-blue-500 p-2 rounded-lg  px-5 text-white  text-center">Create</router-link>
      </div>
      <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none pb-20 mb-20">
        <div v-for="project in getProjectList" :key="project.project_name" class="flex flex-col rounded-lg shadow-lg overflow-hidden">
          <div class="flex-shrink-0">
            <img class="h-48 w-full object-cover" :src="FundraisingHost.url+'get-image/'+project.project_photo_file_path" :alt="project.project_name" />
          </div>
          <div class="flex-1 bg-white p-6 pb-0 flex flex-col justify-between">
            <div class="flex-1">
              <div class="flex justify-between">
                <p class="text-xl font-semibold text-gray-900 hover:underline">
                  {{ project.project_name }}
                </p>
                <div>
                  <span>
                    <input v-if="project.is_main_project == 1" :checked="project.is_main_project == 1"
                                @click.prevent="setMainProject(project.id, loggedInUser.id,0); project.is_main_project = 0"
                                type="checkbox" >
                    <input v-if="project.is_main_project == 0"
                                @click.prevent="setMainProject(project.id, loggedInUser.id,1); project.is_main_project = 1"
                                type="checkbox" >            

                    </span>
                  <p class="text-xs text-gray-700">Feature</p>
                </div>
              </div>
                <p class="mt-3 text-base text-gray-500">
                  {{ project.project_summary }}
                </p>
            </div>
            <div class="mt-6  items-center font-extrabold text-black">
<!--              <div class="w-full bg-gray-50 rounded-full dark:bg-gray-100">-->
<!--                <div class="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" :style="'width:'+(project.raisedAmount/project.project_target_amount)*100+'%'"> {{(project.raisedAmount/project.project_target_amount)*100}}%</div>-->
<!--              </div>-->
              <div class="flex mt-5">
                <span><span class="uppercase mr-5">{{project.project_target_currency}}</span> {{format(project.project_target_amount)}} Target Amount</span>
              </div>
            </div>

            <div class="flex divide-x divide-gray-200 mt-2 justify-between">
              <div class="w-0 flex-1 flex">
                <button  @click="selectedProject = project; showPrompt = true" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-red-700 font-medium border border-transparent rounded-bl-lg hover:text-red-500">
                  <TrashIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                  <span class="ml-3">Delete</span>
                </button>
              </div>

              <div class="w-0 flex-1 flex">
                <button @click="selectedProject = project; $router.push({name:'dashboard-add-projects'})" class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-blue-700 font-medium border border-transparent rounded-bl-lg hover:text-blue-500">
                  <PencilIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
                  <span class="ml-3">Edit</span>
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <DeleteAlert item="project" :show="showPrompt" @cancel="showPrompt = false" @delete="deleteProject(loggedInUser.id);showPrompt = false" />
</template>

<script>


import { SearchIcon,PencilIcon,TrashIcon } from '@heroicons/vue/solid'
import {onMounted, ref, inject } from "vue";
import project from "@/stores/Fundraising/Project";
import {getUser} from "@/stores/User.js";
import DeleteAlert from "@/components/alertComponents/DeleteAlert";
import money from "@/helpers/money";
import FundraisingHost from '@/helpers/FundraisingHost.js'


export default {
  name: "DashboardProjects",
  components:{SearchIcon,PencilIcon,TrashIcon,DeleteAlert},
  setup(){
    const {loadProjects,projectList,selectedProject,deleteProject,setMainProject,getProjectList,filter}  = project;
    const loggedInUser = getUser();
    const showPrompt = ref(false)
    const {format} = money

    let activePage = inject('activePage');
    activePage.value = 'Projects'

    let checkMainFeature = ref(true);

    onMounted(() => {
      if (loggedInUser.value) {
        loadProjects(loggedInUser.value.id);
      }
    })

    return {
      projectList,selectedProject,showPrompt,deleteProject,
     loggedInUser,setMainProject,getProjectList,filter,format,
     FundraisingHost, checkMainFeature
    }
   }
};
</script>

<style scoped>

</style>