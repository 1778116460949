<template>
  <div class="border h-screen flex flex-col items-center justify-center  sm:px-6 lg:px-8">
     <Loading v-model:active="isLoading" />
     <div class=" mt-8 flex justify-center items-center">
        <AlertSuccess
          v-if="successMessage"
          :message="successMessage"
          @hide="successMessage = null"
        />
        <AlertError
          v-if="errorMessage"
          :message="[errorMessage]"
          @hide="errorMessage = null"
        />
    </div>
     <div class=" mt-8 flex justify-center items-center">
        
    </div>
    <div class="mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto h-20 w-auto"
        src="/JMS_LOGO2.webp"
        alt="jms logo"
      />
      <h2 class="mt-3 text-center text-3xl font-extrabold text-gray-900">
        Sign in to your account
      </h2>
    </div>

    <div class="mt-5 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow-2xl sm:rounded-lg sm:px-10">
        <form class="space-y-6" action="#" method="POST">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">
              Email address
            </label>
            <div class="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autocomplete="email"
                v-model="email"
                required=""
                class="
                  appearance-none
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  placeholder-gray-400
                  focus:outline-none focus:ring-blue-500 focus:border-blue-500
                  sm:text-sm
                "
              />
            </div>
          </div>

          <div>
            <label
              for="password"
              class="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <div class="mt-1">
              <input
                id="password"
                name="password"
                type="password"
                autocomplete="current-password"
                v-model="password"
                required=""
                class="
                  appearance-none
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  placeholder-gray-400
                  focus:outline-none focus:ring-blue-500 focus:border-blue-500
                  sm:text-sm
                "
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              @click.prevent="login()"
              class="
                w-full
                flex
                justify-center
                py-2
                px-4
                border border-transparent
                rounded-md
                shadow-sm
                text-sm
                font-medium
                text-white
                bg-red-900
                hover:bg-red-700
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-indigo-500
              "
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>


<script>
import { useRouter } from "vue-router";
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import { computed } from "vue";
import { ref } from "vue";
// import Api from "@/components/api/Api";

import {initUser} from "@/stores/User.js"

import {initLocations} from "@/stores/Locations.js"
import {initDirectorates} from "@/stores/Directorates.js"

export default {

  components: { AlertSuccess, AlertError},

  setup(){

    const router = useRouter();
    let isLoading = ref(false);
    let email = ref("");
    let password = ref("");    
    let generalMessage = ref("");
    let disableButton = ref(false);
    let errorMessage = ref('');
    let successMessage = ref(''); 

    
    const disableLoginButton = computed(() => {
      if (email.value.trim().length > 0 && password.value.trim().length > 0) {
        return false;
      }

      return true;
    });

    async function login() {

      isLoading.value = true;
      
      generalMessage.value = "";
      disableButton.value = true;
      

      let user = {
        email: email.value,
        password: password.value,
      };

    const res =  await initUser(user);

    isLoading.value = false;
    
    if(res.status === "success"){

        await initLocations();
        await initDirectorates()

     
        router.push({name:'ErpMenuView'});

    }else{

      if(res.message){

        errorMessage.value = res.message;

      }else{
      
        errorMessage.value = "An error has occurred! Please try again";

      }

      disableButton.value = false;


    }
   
   }

    
    return{
      login,
      email,
      password,
      disableLoginButton,
      generalMessage,
      disableButton,
      isLoading,
      errorMessage,
      successMessage,
    }
  }



};
</script>

<style></style>
