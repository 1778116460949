<template>
  <div>
     <Loading v-model:active="isLoading" />
    <div class=" mt-3 mb-3 flex justify-center items-center">
        <AlertSuccess
            v-if="successMessage"
            :message="successMessage"
            @hide="successMessage = null"
        />
        <AlertError
            v-if="errorMessage"
            :message="[errorMessage]"
            @hide="errorMessage = null"
        />
    </div>
    <div class="grid grid-cols-1 gap-3 lg:grid lg:grid-cols-4 lg:gap-4  md:grid md:grid-cols-2 md-gap-3 drop-shadow-sm border border-gray-300 rounded-r-md rounded-l-md p-5">

        <div class="sm:grid sm:grid-cols-1 sm:gap-4 sm:items-start">
            <!-- <label for="country" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> {{ select_who_text }} </label> -->
            <Combobox as="div" class="sm:mt-0 sm:col-span-1" v-model="selectedFinancialYear">
                <ComboboxLabel class="block text-sm font-bold text-red-900">Select Financial Year</ComboboxLabel>
                <div class="relative mt-1">
                <ComboboxInput placeholder="Type financial Year or Click arrows" class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm" 
                      @change="queryFinancialYear = $event.target.value" :display-value="(financialYear) => financialYear.name" />
                <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </ComboboxButton>

                <ComboboxOptions v-if="filteredFinancialYears.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    <ComboboxOption v-for="financialYear in filteredFinancialYears" :key="financialYear.id" :value="financialYear" as="template" v-slot="{ active, selected }">
                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                        <span :class="['block truncate', selected && 'font-semibold']">
                        {{ financialYear.name }}
                        </span>

                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                    </li>
                    </ComboboxOption>
                </ComboboxOptions>
                </div>
            </Combobox>
         </div>
        <div class="sm:grid sm:grid-cols-1 sm:gap-4 sm:items-start">
            <Listbox as="div" v-model="selectedStatus">
                <ListboxLabel class="block text-sm font-bold text-red-900"> Select Status </ListboxLabel>
                <div class="mt-1 relative">
                <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <span class="block truncate">{{ selectedStatus.name }}</span>
                    <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    <ListboxOption as="template" v-for="status in statuses" :key="status.id" :value="status" v-slot="{ active, selected }">
                        <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                            {{ status.name }}
                        </span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                        </li>
                    </ListboxOption>
                    </ListboxOptions>
                </transition>
                </div>
            </Listbox>
         </div>
        <div class="sm:grid sm:grid-cols-1 sm:gap-4 sm:items-start">
            <Listbox as="div" v-model="selectedPeriod">
                <ListboxLabel class="block text-sm font-bold text-red-900"> Select Period </ListboxLabel>
                <div class="mt-1 relative">
                <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <span class="block truncate">{{ selectedPeriod.name }}</span>
                    <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    <ListboxOption as="template" v-for="period in periods" :key="period.id" :value="period" v-slot="{ active, selected }">
                        <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                            {{ period.name }}
                        </span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                        </li>
                    </ListboxOption>
                    </ListboxOptions>
                </transition>
                </div>
            </Listbox>
         </div>
         <div  v-if="selectedPeriod.name == 'By Month'" class="sm:grid sm:grid-cols-1 sm:gap-4 sm:items-start">
            <Listbox as="div" v-model="selectedMonth">
                <ListboxLabel class="block text-sm font-medium text-red-900"> Select Month </ListboxLabel>
                <div class="mt-1 relative">
                <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <span class="block truncate">{{ selectedMonth.name }}</span>
                    <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    <ListboxOption as="template" v-for="month in months" :key="month.id" :value="month" v-slot="{ active, selected }">
                        <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                            {{ month.name }}
                        </span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                        </li>
                    </ListboxOption>
                    </ListboxOptions>
                </transition> 
                </div>
            </Listbox>
         </div>
         <div  v-if="selectedPeriod.name == 'By Range'" class="flex flex-col justify-between items-stretch" >
            <label class="block text-sm font-medium text-red-900 "> Select Date Range </label>
            <litepie-datepicker class="lg:h-9 h-10 text-gray-900 "
            separator=" to "
            :formatter="formatter"
            v-model="dateValue"
            ></litepie-datepicker>
        </div>
        <div class="flex items-end">
            <button @click="getRecords()" type="button" class=" w-full lg:h-9 h-10 px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-900 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Get Records
            </button>

        </div>

          
    </div>
    <div class="flex flex-col mt-8">
            <div class="-my-2  sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
                        <table class="w-full divide-y divide-gray-200">
                            <thead class="bg-teal-700">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"> Id Number</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Requisitioner</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Reason</th>
                                    <th scope="col" class="relative px-6 py-3">
                                    <span class="sr-only">statuses</span>
                                    </th>
                                    <th scope="col" class="relative px-6 py-3">
                                    <span class="sr-only">delete</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="(requisition) in requisitions" :key="requisition.id">
                                    <td class="px-6 py-4 break-all text-sm font-medium text-gray-900">
                                    <a class="text-blue-600 visited:text-purple-600 hover:underline cursor-pointer " @click="showRequisition(requisition.id)"> {{ requisition.requisition_name }} </a>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {{ requisition.requisitioner }}
                                    </td>
                                    <td class="px-6 py-4 break-words whitespace-normal text-sm text-gray-500">
                                    {{ requisition.summary }}
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    <a href="#" @click="showTransitionStatuses(requisition.id)" class="bg-indigo-600 text-white text-xs  py-1 px-2 rounded-md cursor-pointer hover:bg-indigo-900 shadow-md">Statuses</a>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    <a  @click="showRequisition(requisition.id)" class="text-white bg-green-600 py-1 px-2 rounded-md text-xs cursor-pointer hover:bg-green-900 shadow-md">Preview</a>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                  <td colspan="5">
                                      <nav class="bg-white px-4 py-3 flex items-center justify-between  sm:px-6" aria-label="Pagination">
                                          <div class="hidden sm:block">
                                          <p class="text-sm text-gray-700">
                                              Showing
                                              {{ ' ' }}
                                              <span class="font-medium">{{ fromPage }}</span>
                                              {{ ' ' }}
                                              to
                                              {{ ' ' }}
                                              <span class="font-medium">{{ toPage }}</span>
                                              {{ ' ' }}
                                              of
                                              {{ ' ' }}
                                              <span class="font-medium">{{ totalPageResults }}</span>
                                              {{ ' ' }}
                                              results
                                          </p>
                                          </div>
                                          <div class="flex-1 flex justify-between sm:justify-end">
                                          <a :class="prevPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="getRecords(prevPageUrl)"  class=" inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Previous </a>
                                          <a :class="nextPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="getRecords(nextPageUrl)" class="ml-3 cursor-pointer  inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Next </a>
                                          <!-- <a href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> Previous </a>
                                          <a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> Next </a> -->
                                          </div>
                                      </nav>
                                  </td>
                              </tr>
                          </tfoot>
                        </table>
                    </div>
                </div>
            </div>
    </div>
    <requisitioner-view-requisition-modal-component :requisition="requisitionProp" :requisitionAttachments="requisitionAttachmentsProp" :requisitionTransitions="requisitionTransitionsProp" :show="showRequisitionModal" @dismiss="showRequisitionModal = false">
           <template v-slot:footer>
              <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <button type="button" class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm" @click="showRequisitionModal = false">Close</button>
                    <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-red-900 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm" @click="downloadRequisitionPdf()">Download Requisition Report</button>
              </div>
           </template>
    </requisitioner-view-requisition-modal-component>
     <!-- <requisitioner-view-requisition-modal-component :show="showRequisitionModal" @dismiss="showRequisitionModal = false" /> -->
    <RequisitionerMultiPurposeModalComponent :show="showStatusesModal" @dismiss="showStatusesModal = false">
            <template v-slot:header>
                        Requisition Action Statuses
            </template>
            <template v-slot:body>
             <div class="flex flex-col">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead class="bg-teal-700">
                                    <tr>
                                        <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">#</th>
                                        <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Role</th>
                                        <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">User</th>
                                        <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Seen</th>
                                        <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Duration</th>
                                        <th scope="col" class="pl-1 pr-3 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Status</th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                    <tr v-for="(transition,index) in requisitionTransitions" :key="index" >
                                        <td class="pl-3 pr-2 py-4 text-sm text-gray-500">
                                           {{ index + 1 }}
                                        </td>
                                        <td class="px-4 py-4 text-sm text-gray-500">
                                         {{ transition.role_name }} 
                                        </td>
                                        <td class="px-4 py-4 text-sm text-gray-500">
                                          {{ transition.name }}
                                        </td>
                                        <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                                          {{ transition.seen_on? 'Yes, on ' + transition.seen_on : 'No' }}
                                        </td>
                                        <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                                           {{ transition.duration }} days
                                        </td>
                                        <td class="pl-1 pr-3 py-4 text-sm text-gray-500">
                                          {{ transition.status }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
              </div>
            </template>
        </RequisitionerMultiPurposeModalComponent>
  </div>
</template>

<script>
import { ref, computed, inject } from 'vue'
import { CheckIcon, SelectorIcon} from '@heroicons/vue/solid'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  ComboboxLabel,
  Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions 
} from '@headlessui/vue'
import LitepieDatepicker from 'litepie-datepicker-tw3';
import RequisitionerViewRequisitionModalComponent from "./requisitionerComponents/RequisitionerViewRequisitionModalComponent.vue";
import RequisitionerMultiPurposeModalComponent from "./requisitionerComponents/RequisitionerMultiPurposeModalComponent.vue";
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import Api from "@/components/api/Api";
import html2pdf from "html2pdf.js";

export default {
    name: "ArchivesComponent",
    components: {
        CheckIcon,
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxOption,
        ComboboxOptions,
        ComboboxLabel,
        SelectorIcon,
        Listbox,
        ListboxButton,
        ListboxLabel,
        ListboxOption,
        ListboxOptions,
        LitepieDatepicker,
        RequisitionerViewRequisitionModalComponent,
        RequisitionerMultiPurposeModalComponent,
        AlertError, AlertSuccess
    },

    setup(){

        let activePage = inject('activePage');
        activePage.value = 'Archives'
        const statuses = [
        { id: 1, name: 'Approved' },
        { id: 3, name: 'Rejected' },
        ]
        const selectedStatus = ref(statuses[1]) 
        let status_title = inject('status_title');
         status_title.value = ''
        const periods = [
        { id: 1, name: 'By All Times' },
        { id: 2, name: 'By Range' },
        { id: 3, name: 'By Month' },
        ]
        const selectedPeriod = ref(periods[0]) 
        let activeRole = inject('activeRole');

       
        const months = [
        { id: '01', name: 'January' },
        { id: '02', name: 'February' },
        { id: '03', name: 'March' },
        { id: '04', name: 'April' },
        { id: '05', name: 'May' },
        { id: '06', name: 'June' },
        { id: '07', name: 'July' },
        { id: '08', name: 'August' },
        { id: '09', name: 'September' },
        { id: '10', name: 'October' },
        { id: '11', name: 'November' },
        { id: '12', name: 'December' },
        ]
        const selectedMonth = ref(months[0]) 
        const formatter = ref({
        date: 'DD-MM-YYYY',
        month: 'MM'
        });
        const dateValue = ref([]);
        let isLoading = ref(false);
        let errorMessage = ref("");
        let successMessage = ref("");

        const financialYears = ref([
        { id: 0, name: '' }
        ])
        const queryFinancialYear = ref('')
        const selectedFinancialYear = ref(financialYears.value[0])
        const filteredFinancialYears = computed(() =>
        queryFinancialYear.value === ''
            ? financialYears.value
            : financialYears.value.filter((financialYear) => {
                return financialYear.name.toLowerCase().includes(queryFinancialYear.value.toLowerCase())
            })
        )

        const requisitions = ref([])

        let showRequisitionModal = ref(false);
        const showStatusesModal    = ref(false);
        let fromPage = ref(0)
        let toPage   = ref(0)
        let totalPageResults   = ref(0);
        let prevPageUrl = ref('#')
        let nextPageUrl = ref('#')
        let loggedInUser =  inject("loggedInUser");

        let requisitionProp = ref({});
        let requisitionAttachmentsProp = ref([]);
        let requisitionTransitionsProp = ref([]);
        let requisitionTransitions   = ref([]);

        async function showRequisition(requisition_id){

            isLoading.value = true;

            try{

            const response = await  Api().get(`/requisition/get-requisition/${requisition_id}`);

            if (response.data.status === "success") {

                    requisitionProp.value = response.data.requisition;
                    requisitionAttachmentsProp.value = response.data.attachments;
                    requisitionTransitionsProp.value = response.data.transitions;
                    
                
            }

            }catch (err) {

                if (err.response) {

                errorMessage.value = err.response.data.message;

                    //return { 'message': err.response.data.message, 'status': 'error' };

                } else if (err.request) {

                    errorMessage.value = err.request.data.message

                    //return { 'message': err.request.data.message, 'status': 'error' };

                } else {

                    errorMessage.value = err.message

                // return { 'message': err.message, 'status': 'error' };

                }
            }

            isLoading.value = false;
            showRequisitionModal.value = true;

        }
        async function showTransitionStatuses(requisition_id){


            isLoading.value = true;

            try{

            const response = await  Api().get(`/requisition/get-transition-statuses/${requisition_id}`);

            if (response.data.status === "success") {

                    requisitionTransitions.value = response.data.transitions;
                    
                
            }

            }catch (err) {

                if (err.response) {

                errorMessage.value = err.response.data.message;


                } else if (err.request) {

                    errorMessage.value = err.request.data.message


                } else {

                    errorMessage.value = err.message

                }
            }

            isLoading.value = false;
            showStatusesModal.value = true;

        }
            
        async function loadFinancialYears(){

            try {

            const response = await Api().get('/requisition/get-financial-years');

            if(response.data.status === "success"){

                    financialYears.value  = response.data.financialYears;
                    
                  //  successMessage.value = response.data.message;
            
            } 

            } catch (err) {

                if (err.response) {

                    errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                errorMessage.value =  err.request.data.message;

                } else {

                    errorMessage.value =  err.message;
                    
                }
            }


        }
        loadFinancialYears();

        async function getRecords(url = ''){


            
            errorMessage.value = '';
            successMessage.value = '';

           
            if(selectedFinancialYear.value.name == ''){

                errorMessage.value = "Please select a financial Year";
                window.scrollTo({ top: 0, behavior: "smooth" });
                return;
            }

            if(dateValue.value.length == 0 && selectedPeriod.value.name == 'By Range'){

                errorMessage.value = "Please select a date range";
                window.scrollTo({ top: 0, behavior: "smooth" });
                return;
            }

            isLoading.value = true;

            let  approval_level_role_id = 0;
            if(activeRole.value == 'Requisitioner'){

                approval_level_role_id = 2;

            }else if(activeRole.value == 'Supervisor'){

                approval_level_role_id = 3;

            }else if(activeRole.value == 'Procurement'){

                approval_level_role_id = 4;

            }else if(activeRole.value == 'Budget Holder'){

                approval_level_role_id = 6;

            }else if(activeRole.value == 'Accountant'){

                approval_level_role_id = 9;

            }else if(activeRole.value == 'Manager Accounts'){

                approval_level_role_id = 10;

            }else if(activeRole.value == 'Director Finance'){

                approval_level_role_id = 11;

            }else if(activeRole.value == 'Payments Office'){

                approval_level_role_id = 13;

            }

            let obj = {
                 'financial_year': selectedFinancialYear.value.name,
                 'status' : selectedStatus.value.id,
                 'period' : selectedPeriod.value.name,
                 'dateRange' : dateValue.value,
                 'month' : selectedMonth.value.id
            }

         try {

            const response = await Api().post(`/requisition/get-archived-requisitions/${approval_level_role_id}/${loggedInUser.value.id}/0${url}`,obj)

            if(response.data.status === "success"){

                    requisitions.value  = response.data.requisitions.data;
                    nextPageUrl.value = response.data.requisitions.next_page_url
                    prevPageUrl.value = response.data.requisitions.prev_page_url
                    fromPage.value = response.data.requisitions.from
                    toPage.value   = response.data.requisitions.to
                    totalPageResults.value   = response.data.requisitions.total
                    successMessage.value = response.data.message;
            
             } 

         } catch (err) {

                if (err.response) {

                    errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                errorMessage.value =  err.request.data.message;

                } else {

                    errorMessage.value =  err.message;
                    
                }
            }


            
            isLoading.value = false;

        }

        function downloadRequisitionPdf(){

            let element = document.getElementById('requisition-to-convert');
            // var element = document.getElementById('foo');
            // let positionInfo = element.getBoundingClientRect();
            // let height = positionInfo.height;
            // let width = positionInfo.width;
            let downloadFileName = Date.now();

            if(requisitionProp.value){

            downloadFileName = requisitionProp.value.requisition_name

            }

            // let opt = {
            // margin:       0,
            // filename:     downloadFileName,
            // image:        { type: 'jpeg', quality: 1 },
            // html2canvas:  { scale: 2 },
            // jsPDF:        { unit: 'in', format: [width/96, height/96], orientation: 'portrait' }
            // };
            let opt = {
             margin:       0,
             filename:     downloadFileName,
             image:        { type: 'jpeg', quality: 1 },
             // html2canvas:  { scale: 1 },
             jsPDF:        { unit: 'mm', orientation: 'portrait' },
            };

            html2pdf().from(element).set(opt).save();


      }
        

        return {

            statuses,selectedStatus,
            periods,selectedPeriod,
            months,selectedMonth,
            financialYears, queryFinancialYear, selectedFinancialYear, filteredFinancialYears,
            dateValue, formatter,
            requisitions, showRequisitionModal, showStatusesModal,
            errorMessage, successMessage, isLoading,
            getRecords, fromPage, toPage, totalPageResults, prevPageUrl,nextPageUrl,
            requisitionProp, requisitionAttachmentsProp,requisitionTransitionsProp,requisitionTransitions,
            showRequisition,showTransitionStatuses, downloadRequisitionPdf

        }
    }

}
</script>

<style>

</style>