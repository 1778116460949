<template>
  <div class="mt-8">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between items-center">
        <h2 class="text-xl leading-6 font-medium text-gray-900">Resources</h2>
        <div class="relative z-0 flex-1 px-2 flex items-center justify-center sm:inset-0 my-10">
          <div class="w-full max-w-xl mx-auto">
            <label for="search" class="sr-only">Search</label>
            <div class="relative">
              <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input id="search" name="search" v-model="filter" class="block w-full bg-gray-100 border border-transparent rounded-md py-3 pl-10 pr-3 text-sm placeholder-gray-400 focus:outline-none focus:bg-gray-50 focus:border-white focus:ring-white focus:text-gray-900 focus:placeholder-gray-500 sm:text-sm" placeholder="Search" type="search" />
            </div>
          </div>
        </div>
                <router-link  :to="{name:'dashboard-add-resources'}"  class="block bg-blue-600 hover:bg-blue-500 p-2 rounded-lg  px-5 text-white  text-center">Create</router-link>
      </div>
    </div>
    <div class="mt-12 max-w-6xl mx-auto">
      <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
          <tr>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Upload Date
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
               Project
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            </th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(resource,index ) in getResourceList" :key="resource.email" :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {{ resource.date }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ resource.resource_name }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ resource.project_name }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              <button @click="selectedResource=resource; showPrompt= true;" class="text-red-500 underline hover:text-red-700">Delete</button>
              <button @click="selectedResource=resource; $router.push({name:'dashboard-add-resources'})"  class="text-blue-500 underline hover:text-blue-700 ml-20">Edit</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <DeleteAlert :show="showPrompt"  item="Resource"  @cancel="showPrompt = false" @delete="deleteUser(loggedInUser.id); showPrompt = false" />
</template>

<script>
import { SearchIcon } from '@heroicons/vue/solid'
import resource from "@/stores/Fundraising/Resource";
import { onMounted, ref, inject } from "vue";
import {getUser} from "@/stores/User.js";
import DeleteAlert from "@/components/alertComponents/DeleteAlert";
export default {
  name: "DashboardResources",
  components:{SearchIcon,DeleteAlert},
  setup(){
    const {loadResources,resourceList,selectedResource,deleteUser,getResourceList,filter} = resource;
    const loggedInUser = getUser()
    const showPrompt = ref(false);

    let activePage = inject('activePage');
    activePage.value = 'Resources'

    onMounted(() => {
      if (loggedInUser.value) {
        loadResources(loggedInUser.value.id,showPrompt)
      }
    });
    return{resourceList,selectedResource,deleteUser,showPrompt,loggedInUser,getResourceList,filter}
  }
};
</script>

<style scoped>

</style>