<template>
  <div>
    <Loading v-model:active="isLoading" />
    <div class=" mt-2 mb-2 flex justify-center items-center">
        <AlertSuccess
            v-if="successMessage"
            :message="successMessage"
            @hide="successMessage = null"
        />
        <AlertError
            v-if="errorMessage"
            :message="[errorMessage]"
            @hide="errorMessage = null"
        />
    </div>
    <!-- <div class="mt-2 mb-2 flex justify-center items-center">
        <button
        v-if="dateRange"
        @click="dateRange = null; getRequisitions(reload_page_Url)"
          class="capitalize hover:bg-gray-300 border border-1 border-blue-500 rounded-lg p-1 flex text-blue-500 ">
          All Rejected
        </button>
    </div> -->
    <div class="sm:flex sm:justify-between sm:items-center pb-4">
      <div class="sm:flex sm:justify-center sm:items-end  mb-5">
          <div class="sm:mx-4  my-3">
              <label for="period_select" class="block text-sm font-bold text-red-900">Period</label>
              <select v-model="period_select" id="period_select" name="period_select" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded-md">
                <option selected value>All</option>
                <option value="today">Today</option>
                <option value="yesterday">Yesterday</option>
                <option value="this_week">This Week</option>
                <option value="this_month">This Month</option>
              </select>
          </div>
          <div class="sm:mx-4  my-3">
              <button @click="reloadRequisitions()" type="button" class="inline-flex items-center px-7 py-1.5 mb-0.5 border border-transparent text-xs font-medium rounded shadow-md text-white bg-red-900 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  load
              </button>
          </div>
      </div>
        <!-- <div class="pr-3 italic capitalize text-red-900">
            {{ dateRange? dateRange.split("_").join(" ") : '' }}
        </div> -->
        <div class="relative flex">
            <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
            </div>
            <input type="text" v-model="filter" id="table-search" class="block flex-1 p-2 pl-10 sm:w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items">
        </div>
    </div>
   <div class="flex flex-col">
        <div class="-my-2  sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
                    <table class="w-full divide-y divide-gray-200">
                        <thead class="bg-teal-700">
                            <tr>
                                <!-- <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">#</th> -->
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"> Id Number</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Reason</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Description</th>
                                <!-- <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">FY</th> -->
                                <th scope="col" class="relative px-6 py-3">
                                <span class="sr-only">statuses</span>
                                </th>
                                <th scope="col" class="relative px-6 py-3">
                                <span class="sr-only">delete</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="(requisition) in filteredRequisitionsTable" :key="requisition.id">
                                <!-- <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {{ index+1 }}
                                </td> -->
                                <td class="px-6 py-4 break-words text-sm font-medium text-gray-900">
                                  <a :id="requisition.requisition_name" :name="requisition.requisition_name" class="text-blue-600 visited:text-purple-600 
                                        hover:underline cursor-pointer" @click.prevent="showRequisition(requisition.id)">
                                          {{ requisition.requisition_name }}
                                  </a>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {{ requisition.purpose_of_requisition }}
                                </td>
                                <td class="px-6 py-4 break-words text-sm text-gray-500">
                                {{ requisition.summary }}
                                </td>
                                <!-- <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {{ requisition.financial_year }}
                                </td> -->
                                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <a  @click.prevent="showTransitionStatuses(requisition.id)" class="bg-indigo-600 text-white text-xs  py-1 px-2 rounded-md cursor-pointer hover:bg-indigo-900 shadow-md">statuses</a>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                   <a @click.prevent="showRequisition(requisition.id)" class="text-white bg-green-600 py-1 px-2 rounded-md text-xs cursor-pointer hover:bg-green-900 shadow-md">view</a>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                              <td colspan="9">
                                  <nav class="bg-white px-4 py-3 flex items-center justify-between  sm:px-6" aria-label="Pagination">
                                      <div class="hidden sm:block">
                                      <p class="text-sm text-gray-700">
                                          Showing
                                          {{ ' ' }}
                                          <span class="font-medium">{{ fromPage }}</span>
                                          {{ ' ' }}
                                          to
                                          {{ ' ' }}
                                          <span class="font-medium">{{ toPage }}</span>
                                          {{ ' ' }}
                                          of
                                          {{ ' ' }}
                                          <span class="font-medium">{{ totalPageResults }}</span>
                                          {{ ' ' }}
                                          results
                                      </p>
                                      </div>
                                      <div class="flex-1 flex justify-between sm:justify-end">
                                      <a :class="prevPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="getRequisitions( pageUrl+prevPageUrl)"  class="relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Previous </a>
                                      <a :class="nextPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="getRequisitions( pageUrl+nextPageUrl)" class="ml-3 cursor-pointer relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Next </a>
                                      </div>
                                  </nav>
                              </td>
                          </tr>
                      </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <RequisitionerViewRequisitionModalComponent :requisition="requisitionProp" :requisitionAttachments="requisitionAttachmentsProp" :requisitionTransitions="requisitionTransitionsProp" :show="showRequisitionModal" @dismiss="showRequisitionModal = false" >
        <template v-slot:footer>
          <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <!-- <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-1 sm:text-sm" @click="withdrawRequisition(requisitionProp.requisition_id)">Withdraw</button> -->
                <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-2 sm:text-sm" @click="showRequisitionModal = false">Close</button>
          </div>
        </template>
    </RequisitionerViewRequisitionModalComponent>
     <RequisitionerMultiPurposeModalComponent :show="showStatusesModal" @dismiss="showStatusesModal = false">
        <template v-slot:header>
                    Requisition Action Statuses
        </template>
        <template v-slot:body>
          <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-teal-700">
                                <tr>
                                    <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">#</th>
                                    <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Role</th>
                                    <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">User</th>
                                    <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Seen</th>
                                    <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Duration</th>
                                    <th scope="col" class="pl-1 pr-3 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Status</th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="(transition,index) in requisitionTransitions" :key="index" >
                                    <td class="pl-3 pr-2 py-4 text-sm text-gray-500">
                                        {{ index + 1 }}
                                    </td>
                                    <td class="px-4 py-4 text-sm text-gray-500">
                                      {{ transition.role_name }} 
                                    </td>
                                    <td class="px-4 py-4 text-sm text-gray-500">
                                      {{ transition.name }}
                                    </td>
                                    <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {{ transition.seen_on? 'Yes, on ' + transition.seen_on : 'No' }}
                                    </td>
                                    <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {{ transition.duration }} days
                                    </td>
                                    <td class="pl-1 pr-3 py-4 text-sm text-gray-500">
                                      {{ transition.status }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
          </div>
        </template>
    </RequisitionerMultiPurposeModalComponent>
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue'
import RequisitionerViewRequisitionModalComponent from "../requisitionerComponents/RequisitionerViewRequisitionModalComponent.vue";
import RequisitionerMultiPurposeModalComponent from "../requisitionerComponents/RequisitionerMultiPurposeModalComponent.vue";
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import Api from "@/components/api/Api";
import { useRoute} from "vue-router";

export default {
  name: "AccountantRejectedComponent",
  components: {
         RequisitionerViewRequisitionModalComponent,
         RequisitionerMultiPurposeModalComponent,
         AlertError, AlertSuccess
    },
  setup(){
    
     const showRequisitionModal = ref(false);
     const showStatusesModal    = ref(false);

      const requisitions = ref([
        { id: '', 
        requisition_name: '', 
        purpose_of_requisition: '', 
        summary: '' },
       
      ])
      const route = useRoute();
      let isLoading = ref(false);
      let errorMessage = ref("");
      let successMessage = ref("");
      let fromPage = ref(0)
      let toPage   = ref(0)
      let totalPageResults   = ref(0);
      let prevPageUrl = ref('#')
      let nextPageUrl = ref('#')
      // let status_title = inject('status_title');
      let loggedInUser =  inject("loggedInUser");
      let status = ref('3');
      let dateRange = ref(route.params.date);
      let period_select = ref('');

      if(dateRange.value){

         period_select.value = dateRange.value

      }
      const staticOldApprovalRoleId = 9;

      let activePage = inject('activePage');
      activePage.value = 'Rejected Requisitions'

      let requisitionProp = ref({});
      let requisitionAttachmentsProp = ref([]);
      let requisitionTransitionsProp = ref([]);
      let requisitionTransitions   = ref([]);
      let pageUrl = ref(`/requisition/get-approver-requisitions/${loggedInUser.value.id}/${staticOldApprovalRoleId}/${status.value}/${dateRange.value}`);

      async function getRequisitions(url = pageUrl.value) {
        isLoading.value = true;

        try{

          const response = await  Api().get(url);

          if (response.data.status === "success") {

               
                successMessage.value = response.data.message
                requisitions.value = response.data.requisitions.data;
                nextPageUrl.value = response.data.requisitions.next_page_url
                prevPageUrl.value = response.data.requisitions.prev_page_url
                fromPage.value = response.data.requisitions.from
                toPage.value   = response.data.requisitions.to
                totalPageResults.value   = response.data.requisitions.total
          }

        }catch (err) {

            if (err.response) {

              errorMessage.value = err.response.data.message;

            } else if (err.request) {

                errorMessage.value = err.request.data.message

            } else {

                errorMessage.value = err.message

            }
        }

        isLoading.value = false;

      }

      const filter = ref("");
      const filteredRequisitionsTable = computed(() => {
        return requisitions.value.filter(
          (val) =>{
            const name = val.requisition_name.toString().toLowerCase();
            const summary = val.summary.toString().toLowerCase();
            const reason = val.purpose_of_requisition.toString().toLowerCase();
            
            return name.includes(filter.value.toLowerCase()) || summary.includes(filter.value.toLowerCase()) 
                    || reason.includes(filter.value.toLowerCase());
          }
        );
      });
       async function showRequisition(requisition_id){

        isLoading.value = true;

        try{

          const response = await  Api().get(`/requisition/get-requisition/${requisition_id}`);

          if (response.data.status === "success") {

                requisitionProp.value = response.data.requisition;
                requisitionAttachmentsProp.value = response.data.attachments;
                requisitionTransitionsProp.value = response.data.transitions;
               
          }

        }catch (err) {

            if (err.response) {

              errorMessage.value = err.response.data.message;

            } else if (err.request) {

                errorMessage.value = err.request.data.message

            } else {

                errorMessage.value = err.message

            }
        }

        isLoading.value = false;
        showRequisitionModal.value = true;

      }

      async function showTransitionStatuses(requisition_id){


         isLoading.value = true;

        try{

          const response = await  Api().get(`/requisition/get-transition-statuses/${requisition_id}`);

          if (response.data.status === "success") {

               
                requisitionTransitions.value = response.data.transitions;
               
          }

        }catch (err) {

            if (err.response) {

              errorMessage.value = err.response.data.message;

            } else if (err.request) {

                errorMessage.value = err.request.data.message

            } else {

                errorMessage.value = err.message

            }
        }

        isLoading.value = false;
        showStatusesModal.value = true;

      }

      async function reloadRequisitions() {

        pageUrl.value = `/requisition/get-approver-requisitions/${loggedInUser.value.id}/${staticOldApprovalRoleId}/${status.value}/${period_select.value}`
        await getRequisitions();
        // console.log(period_select.value)
        // console.log(pageUrl.value)
      }

      getRequisitions();

    return {

      requisitions,
      showStatusesModal,
      showRequisitionModal,
      errorMessage, successMessage, isLoading, 
      fromPage, toPage, totalPageResults, prevPageUrl,nextPageUrl,pageUrl,
      filteredRequisitionsTable,filter,
      showRequisition,showTransitionStatuses,
      requisitionProp, requisitionAttachmentsProp,requisitionTransitionsProp, requisitionTransitions, dateRange,
      getRequisitions,
      period_select, reloadRequisitions

    }
  }

}
</script>

<style>

</style>