import { ref } from "vue";
import Api from "@/components/api/Api";

// State
const locations = ref(JSON.parse(localStorage.getItem('locations')));

export const initLocations = async () => {
    
       try {

           const res = await Api().get(`/app/get-locations`);

           if(res.data.status === "success"){

              locations.value = res.data.locations
             
              localStorage.setItem("locations", JSON.stringify(res.data.locations));

               return { 'message': res.data.message, 'status': res.data.status };
           
           } 

       } catch (err) {

           if (err.response) {

               return { 'message': err.response.data.message, 'status': 'error' };

           } else if (err.request) {

               return { 'message': err.request.data.message, 'status': 'error' };

           } else {

               return { 'message': err.message, 'status': 'error' };
               
           }
       }

};

// Getters
export const getLocations = () => { 

    if (!locations.value) {

        locations.value = JSON.parse(localStorage.getItem("locations"));

    }
   
    return locations;

 };
