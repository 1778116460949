<template>
  <div>
    <div class="mt-5 max-w-2xl mx-auto">
       <Loading v-model:active="isLoading" />
      <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <!-- <button @click.prevent="$router.push({ name: 'TenderList' })" class="capitalize flex text-blue-500 hover:underline"> -->
        <!-- <button @click.prevent="$router.back()" class="capitalize flex text-blue-500 hover:underline"> -->
        <button @click.prevent="id = tender_id; $router.push({name: 'EquipmentList', params: { id:id, tender_name:tender_name}})" class="capitalize flex text-blue-500 hover:underline">
        <ArrowLeftIcon class="mr-2 h-6 w-6" />
        back
      </button>
      <div class="flex justify-center items-center">
            <AlertSuccess
              v-if="successMessage"
              :message="successMessage"
              @hide="successMessage = null"
            />
            <AlertError
              v-if="errorMessage"
              :message="[errorMessage]"
              @hide="errorMessage = null"
            />
      </div>
      <div class="flex justify-center items-center">
        <h2 class="text-xl leading-6 font-medium text-red-900 underline">Add Equipment and Technical Specifications</h2>
      </div>
    </div>
      <form method="post" id="create-template">
        <input type="hidden" name="_method" value="PATCH" />

        <div class="space-y-8 divide-y divide-gray-200">
          <div>
            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div class="sm:col-span-6">
                <label
                  for="equipment_name"
                  class="block text-sm text-center font-medium text-gray-700"
                >
                  Equipment Name
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input
                    type="text"
                    name="equipment_name"
                    v-model="equipment_name"
                    id="equipment_name"
                    autocomplete="equipment_name"
                    class="
                      px-3
                      flex-1
                      focus:ring-indigo-500
                      border
                      py-3
                      focus:border-indigo-500
                      block
                      w-full
                      min-w-0
                      rounded-md
                      sm:text-sm
                      border-gray-300
                    "
                  />
                </div>
              </div>
              <div class="sm:col-span-6">
                <label
                  for="equipment_qty"
                  class="block text-sm text-center font-medium text-gray-700"
                >
                  Quantity
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input
                    type="number"
                    name="equipment_qty"
                    v-model="equipment_qty"
                    id="equipment_qty"
                    class="
                      px-3
                      flex-1
                      focus:ring-indigo-500
                      border
                      py-3
                      focus:border-indigo-500
                      block
                      w-full
                      min-w-0
                      rounded-md
                      sm:text-sm
                      border-gray-300
                    "
                  />
                </div>
              </div>
              <div class="sm:col-span-6">
                <label
                  for="about"
                  class="block text-sm text-center font-medium text-gray-700"
                >
                  Technical Specifications
                </label>
                <div class="mt-1 border rounded-lg overflow-y-auto h-96 overscroll-contain ">
                  <div
                    v-for="(field, index) in fields"
                    :key="index"
                    class="grid grid-cols-6 gap-y-6 gap-x-4 p-3"
                  >

                    <div class="col-span-5">
                      <label
                        for="username"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Specification {{ index  + 1}}
                      </label>
                      <div class="mt-1 flex rounded-md shadow-sm">
                        <!-- <input
                          type="text"
                          v-model="field.name"
                          name="field_name[]"
                          id="field_name"
                          autocomplete="field_name"
                          class="
                            px-3
                            flex-1
                            focus:ring-indigo-500
                            border
                            py-3
                            focus:border-indigo-500
                            block
                            w-full
                            min-w-0
                            rounded-md
                            sm:text-sm
                            border-gray-300
                          "
                        /> -->
                        <textarea
                          v-model="field.name"
                          name="field_name[]"
                          id="field_name"
                          autocomplete="field_name"
                          class="
                            px-3
                            flex-1
                            focus:ring-indigo-500
                            border
                            py-3
                            focus:border-indigo-500
                            block
                            w-full
                            min-w-0
                            rounded-md
                            sm:text-sm
                            border-gray-300
                          "
                        ></textarea>
                      </div>
                    </div>

                    <div class="col-span-1 flex justify-center items-center">
                      <button
                        class="text-red-500"
                        @click.prevent="deleteColumn(index,field['id'])"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          class="bi bi-x"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div class="flex justify-center">
                    <button
                      @click.prevent="addFields"
                      class="text-blue-500 hover:bg-gray-50 my-5"
                    >
                      Add Field
                    </button>
                  </div>
                </div>
              </div>
              
              <div class="flex justify-center sm:col-span-6">
                <button
                  @click.prevent="createTenderEquipment()"
                  class="
                    block
                    bg-red-900
                    hover:bg-red-700
                    p-2
                    rounded-lg
                    w-1/2
                    text-white
                    mt-4
                    px-40
                    text-center
                    mx-auto
                  "
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, inject } from "vue";
import {ArrowLeftIcon} from '@heroicons/vue/solid';
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import Api from "@/components/api/Api";
import { getTender, getEquipment } from "@/stores/Tender/Tender.js" 

    export default {
      name: "EditEquipment",
      props: {
        
      },
      components:{ArrowLeftIcon, AlertError,AlertSuccess},
      setup() {

        let isLoading = ref(false);
        let equipment_name = ref('');
        let equipment_qty = ref('false');
        let tender_id = ref('0');
        let tender_name = ref('');
        let equipment_id = ref('');

        let selectedTender = getTender();
        let selectedEquipment = getEquipment();
        let loggedInUser =  inject("loggedInUser");
        
        let userid = loggedInUser.value.id;
       
        let errorMessage = ref("");
        let successMessage = ref("");

        const fields = ref([]);

        tender_id.value    = selectedTender.value.id;
        tender_name.value  = selectedTender.value.name;

        if(selectedEquipment.value != null){
          equipment_id.value = selectedEquipment.value.id;
          loadTenderEquipmentInfo();

        }
        
        function addFields(){

          fields.value.push( {id: null,name:null})
          
        }

        const removeField = (index) => {
          fields.value.splice(index, 1); // 2nd parameter means remove one item only
        }

        function deleteColumn(index,id){

            if(id){
              deleteTender(id)
            }
            
            removeField(index);

        }

        function update(){

            removeField(0);

        }


        function createTenderEquipment() {

            isLoading.value = true;

            let equipment = {
                'equipment_id'  : equipment_id.value,
                'equipment_name': equipment_name.value,
                'equipment_qty': equipment_qty.value.toString(),
                'tender_id': tender_id.value.toString(),
                'created_by_id': userid.toString(),
                'specifications' : fields.value
            };
           

            Api()
                .post("/tenders/create-tender-equipment", equipment)
                .then((response) => {
                    let message = response.data.message;

                    let status = response.data.status;

                    if (status === "error") {
                      errorMessage.value = message;
                        
                    } else if (status === "success") {
                      successMessage.value = message;
            
                    }
                })
                .catch((error) => {
                   // error.value = "An error has occured! Please try again";
                   errorMessage.value =  error.response.data.message + " Please fill all the fields correctly";

                })
                .finally(() => {

                    isLoading.value = false;

                });
        }

        function loadTenderEquipmentInfo() {
           
           isLoading.value = true;

            Api()
              .get(`/tenders/show-tender-equipment-info/${equipment_id.value}`)
              .then((response) => {
                //Code for handling the response
                //add attribute editTeamName so that you can toggle showDetails row
                let status = response.data.status;
                //let message = response.data.message;
                if (status === "success") {
       
                    fields.value = response.data.specifications;

                  if(response.data.equipment){
                    equipment_name.value =  response.data.equipment.name;
                    equipment_qty.value =  response.data.equipment.quantity;
                  }

                } else {
                  
                  fields.value = [];
                  
                }
          })
          .catch((error) => {
            
            errorMessage.value = "An error has occurred! " + error.message;
            
          })
          .finally(() => {
            isLoading.value = false;
          });

        }

         function deleteTender(specification_id){

          isLoading.value = true;
          
          let spec_id = specification_id;
        
               
                successMessage.value = "";
                
                errorMessage.value = null;

                Api()
                  .post(`/delete-equipment-specification/${spec_id}`)
                  .then((response) => {

                    let responseMessage = response.data.message;

                    if (response.data.status === "success") {

                      successMessage.value = responseMessage;

                    }else{

                      errorMessage.value   = responseMessage;

                    }

                  })
                  .catch((error) => {

                    errorMessage.value = "An error has occured!" + error.message;
                    
                  })
                  .finally(() => {
                   
                    loadTenderEquipmentInfo();
                    isLoading.value = false;
                  });
          
         }

        return {
          fields,
          addFields,
          deleteColumn,
          update,
          createTenderEquipment,
          equipment_name,
          equipment_qty,
          errorMessage,
          successMessage,
          tender_id,
          tender_name,
          isLoading
        };
      },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
