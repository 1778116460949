<template>
<div>
    <div class="mb-5">
      <div>
        <button
          class="capitalize flex text-blue-500 hover:underline"
          v-if="currentComponent"
          @click="swapComponent(null)"
        >
          <ArrowLeftIcon class="mr-2 h-6 w-6" />
          back to reports
        </button>
      </div>
    </div>
    <component :is="currentComponent"></component>
</div>
<div v-if="!currentComponent">
  <div class="grid grid-rows-2 sm:flex sm:justify-around sm:items-center pb-4">
      <div class="pr-3">
          <button type="button" @click="reloadData()" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Refresh Records
          </button>

      </div>
      <!-- <label for="table-search" class="sr-only">Search</label> -->
      <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
            <label for="country" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Financial Year</label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
                <select id="financialYear" v-model="financialYear" name="financialYear" class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                    <option value="0">JMS-FY/2022 - 2023</option>
                    <option value="1">JMS-FY/2021 - 2022</option>
                    <option value="2">JMS-FY/2020 - 2021</option>
                </select>
            </div>
        </div>
  </div> 
  <div class="pb-5 pt-5 border-b border-gray-200">
        <h3 class="text-center md:text-left text-md leading-6 font-medium text-gray-900">Requisition Reports</h3>
  </div>
    <!-- end of div section heading -->
    <div>
        <dl class="pt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        <div v-for="item in stats" :key="item.name" @click="swapComponent(item.href)" class="px-4 py-5 hover:bg-gray-100 cursor-pointer bg-white drop-shadow-xl rounded-lg overflow-hidden sm:p-6">
            <dt class="text-sm font-medium text-gray-500 truncate">
            {{ item.name }}
            </dt>
            <dd class="mt-1 text-3xl font-semibold text-gray-900">
            {{ item.stat }}
            </dd>
        </div>
        </dl>
    </div>
  <div class="pb-5 pt-5 border-b border-gray-200">
        <h3 class="text-center md:text-left text-md leading-6 font-medium text-gray-900">Requisitions Submitted</h3>
  </div>
    <!-- end of div section heading -->
    <div>
        <dl class="pt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        <div v-for="item in stats1" :key="item.name" @click="swapComponent(item.href)" class="px-4 py-5 hover:bg-gray-100  cursor-pointer bg-white drop-shadow-xl rounded-lg overflow-hidden sm:p-6">
            <dt class="text-sm font-medium text-gray-500 truncate">
            {{ item.name }}
            </dt>
            <dd class="mt-1 text-3xl font-semibold text-gray-900">
            {{ item.stat }}
            </dd>
        </div>
        </dl>
    </div>
  <div class="pb-5 pt-5 border-b border-gray-200">
        <h3 class="text-center md:text-left text-md leading-6 font-medium text-gray-900">Budget Line Reports</h3>
  </div>
    <!-- end of div section heading -->
    <div>
        <dl class="pt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        <div v-for="item in stats2" :key="item.name" @click="swapComponent(item.href)" class="px-4 py-5 hover:bg-gray-100  cursor-pointer bg-white drop-shadow-xl rounded-lg overflow-hidden sm:p-6">
            <dt class="text-sm font-medium text-gray-500 truncate">
            {{ item.name }}
            </dt>
            <dd class="mt-1 text-3xl font-semibold text-gray-900">
            {{ item.stat }}
            </dd>
        </div>
        </dl>
    </div>
  <div class="pb-5 pt-5 border-b border-gray-200">
        <h3 class="text-center md:text-left text-md leading-6 font-medium text-gray-900">Accountability Reports</h3>
  </div>
    <!-- end of div section heading -->
    <div>
        <dl class="pt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        <div v-for="item in stats3" :key="item.name" @click="swapComponent(item.href)" class="px-4 py-5 hover:bg-gray-100  cursor-pointer bg-white drop-shadow-xl rounded-lg overflow-hidden sm:p-6">
            <dt class="text-sm font-medium text-gray-500 truncate">
            {{ item.name }}
            </dt>
            <dd class="mt-1 text-3xl font-semibold text-gray-900">
            {{ item.stat }}
            </dd>
        </div>
        </dl>
    </div>
</div>
</template>

<script>
import { ref } from 'vue'
import { ArrowLeftIcon } from "@heroicons/vue/outline";
import RequisitionReportComponent from "./RequisitionReportComponent.vue";
import BudgetLineReportComponent from "./BudgetLineReportComponent.vue";
import AccountabilityReportComponent from "./AccountabilityReportComponent.vue";

export default {
    name: "ReportsComponent",
    components: {
        RequisitionReportComponent,
        ArrowLeftIcon,
        BudgetLineReportComponent,
        AccountabilityReportComponent
    },
  setup(){

    const stats = ref([
    { name: 'All Requisitions', stat: '0', href: 'RequisitionReportComponent' },
    { name: 'Pending Approvals', stat: '5', href: 'RequisitionReportComponent' },
    { name: 'Fully Verified Requisitions', stat: '10', href: 'RequisitionReportComponent' }
    ])
    const stats1 = ref([
    { name: 'Today', stat: '0', href: 'RequisitionReportComponent' },
    { name: 'Yesterday', stat: '5', href: 'RequisitionReportComponent' },
    { name: 'This Month', stat: '10', href: 'RequisitionReportComponent' }
    ])
    const stats2= ref([
    { name: 'Expenditures Today', stat: '0', href: 'BudgetLineReportComponent' },
    { name: 'Expenditures Yesterday', stat: '5', href: 'BudgetLineReportComponent' },
    { name: 'Expenditures This Month', stat: '10', href: 'BudgetLineReportComponent' }
    ])
    const stats3 = ref([
    { name: 'To be accounted for', stat: '0', href: 'AccountabilityReportComponent' },
    { name: 'Accountability submitted', stat: '5', href: 'AccountabilityReportComponent'  },
    { name: 'Fully Verified Accountabiity', stat: '10', href: 'AccountabilityReportComponent'  }
    ])
    

    let financialYear = ref('0');
    let currentComponent = ref(null);
        

    function swapComponent(componentName){
       
            currentComponent.value = componentName;

    }

    function reloadData(){

        //

    }

     return {
        
        stats, stats1, stats2, stats3, financialYear,
        swapComponent, currentComponent, reloadData
        
     }
    


  }

}
</script>

<style>

</style>