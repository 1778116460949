<template>
  <div class="">
    <Loading v-model:active="isLoading" />
    <div class=" max-w-2xl mx-auto">
      <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <button
          v-if="equipment_id && manufacturer_id"
          @click.prevent="$router.push({ path: `/manufacturer/list/${encrypted_id}` })"
          class="capitalize flex text-blue-500 hover:underline">
          <ArrowLeftIcon class="mr-2 h-6 w-6" />
          back
        </button>
          <div class="flex justify-center items-center space-y-4 flex-col">
              <AlertSuccess
                v-if="successMessage"
                :message="successMessage"
                @hide="successMessage = null"
              />
              <AlertError
                v-if="errorMessage"
                :message="[errorMessage]"
                @hide="errorMessage = null"
              />
              <AlertError
                v-if="final_error_1"
                :message="[final_error_1]"
                @hide="final_error_1 = null"
              />
              <AlertError
                v-if="final_error_2"
                :message="[final_error_2]"
                @hide="final_error_2 = null"
              />
           </div>
      </div>
    </div>
    <div class=" max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 mt-2">
      <h2 class="text-center font-medium">
        Equipment Name:
        <span class="text-blue-700 text-lg">{{ equipment_name }}</span>
      </h2>

      <div class="md:grid md:grid-cols-5 text-center">
       <div class="flex items-center mt-2 md:col-span-1 md:mt-0">
            <p class="text-center font-normal text-lg">
                Quantity:
              <span class="text-blue-700">{{ equipment_qty }}</span>
            </p>
        </div>
        <div class="flex items-center mt-2 md:col-span-2 md:mt-0">
            <label for="price" class=" text-gray-900 dark:text-gray-300 text-sm">Unit Price (USD)</label>
            <input type="text" v-model="price_in_usd" id="price" class=" ml-2 w-1/2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block
              dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
        </div>
        <div class="flex items-center mt-2 md:col-span-2 md:mt-0">
          <label for="signee_title" class="text-red-900 dark:text-gray-300">Signee title</label>
          <input type="text" placeholder="title of signer" v-model="signee_title" id="signee_title" class=" ml-2 w-1/2 h-9 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block
            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
        </div>
      </div>
      
      <!-- <div class="flex mb-3 mt-3 flex-wrap">
          <div class="flex items-center mr-2">
            <p class="text-center font-normal text-sm">
                Quantity:
              <span class="text-blue-700">{{ equipment_qty }}</span>
            </p>
          </div>
           <div class="flex items-center">
              <label for="price" class=" text-gray-900 dark:text-gray-300 text-sm">Unit Price (USD)</label>
              <input type="text" v-model="price_in_usd" id="price" class=" ml-2 w-1/2 h-9 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block
               dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
          </div>
           <div class="flex items-center">
              <label for="signee_title" class="text-gray-900 dark:text-gray-300">Signee title</label>
              <input type="text" placeholder="title of signer" v-model="signee_title" id="signee_title" class=" ml-2 w-1/2 h-9 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block
               dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
          </div>
         
      </div> -->
      <div class="md:grid md:grid-cols-5 mt-2">
        <div class="md:flex md:col-span-3 md:space-x-3">
              <div class="flex items-center my-1">
                  <h2 class="text-sm whitespace-nowrap font-medium text-red-900 dark:text-gray-300">Select preferrable Incoterm</h2>
              </div>
              <div class="flex items-center my-1">
                  <input id="inline-2-cfr" v-model="incoterm" type="radio" value="CFR" name="inline-radio-group" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                  <label for="inline-2-cfr" class="ml-2 text-xs font-medium text-gray-900 dark:text-gray-300">CFR</label>
              </div>
              <div class="flex items-center my-1">
                  <input id="inline-checked-fob" v-model="incoterm" type="radio" value="FOB" name="inline-radio-group" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                  <label for="inline-checked-fob" class="ml-2 text-xs font-medium text-gray-900 dark:text-gray-300">FOB</label>
              </div>
              <div class="flex items-center my-1">
                  <input id="inline-2-works" v-model="incoterm"  type="radio" value="EX/WORKS" name="inline-radio-group" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                  <label for="inline-2-works" class="ml-2 text-xs font-medium text-gray-900 dark:text-gray-300">EX/WORKS</label>
              </div>
        </div>
        <div class="md:ml-5 col-span-2 md:mt-0 mt-2"> 
          <div class="flex">
              <div class="flex items-center">
                <label for="countries" class=" text-sm font-medium text-red-900 dark:text-gray-300">Country of Origin</label>
                <!-- <input type="number" id="price" class=" ml-2 w-1/2 h-9 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block
                dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required> -->
                <!-- <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Select an option</label> -->
                <select id="countries" v-model="country" class="ml-2 w-32 px-3 h-9 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block
                dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <option disabled value="">Choose</option>
                      <option>Afghanistan</option>
                      <option >Albania</option>
                      <option >Algeria</option>
                      <option >American Samoa</option>
                      <option >Andorra</option>
                      <option >Angola</option>
                      <option >Anguilla</option>
                      <option >Antarctica</option>
                      <option >Antigua and Barbuda</option>
                      <option >Argentina</option>
                      <option >Armenia</option>
                      <option >Aruba</option>
                      <option >Australia</option>
                      <option >Austria</option>
                      <option >Azerbaijan</option>
                      <option >Bahamas</option>
                      <option >Bahrain</option>
                      <option >Bangladesh</option>
                      <option >Barbados</option>
                      <option >Belarus</option>
                      <option >Belgium</option>
                      <option >Belize</option>
                      <option >Benin</option>
                      <option >Bermuda</option>
                      <option >Bhutan</option>
                      <option >Bolivia</option>
                      <option >Bosnia and Herzegowina</option>
                      <option >Botswana</option>
                      <option >Bouvet Island</option>
                      <option >Brazil</option>
                      <option >British Indian Ocean Territory</option>
                      <option >Brunei Darussalam</option>
                      <option >Bulgaria</option>
                      <option >Burkina Faso</option>
                      <option >Burundi</option>
                      <option >Cambodia</option>
                      <option >Cameroon</option>
                      <option >Canada</option>
                      <option >Cape Verde</option>
                      <option >Cayman Islands</option>
                      <option >Central African Republic</option>
                      <option >Chad</option>
                      <option >Chile</option>
                      <option >China</option>
                      <option >Christmas Island</option>
                      <option >Cocos (Keeling) Islands</option>
                      <option >Colombia</option>
                      <option >Comoros</option>
                      <option >Congo</option>
                      <option >Congo, the Democratic Republic of the</option>
                      <option >Cook Islands</option>
                      <option >Costa Rica</option>
                      <option >Cote d'Ivoire</option>
                      <option >Croatia (Hrvatska)</option>
                      <option >Cuba</option>
                      <option >Cyprus</option>
                      <option >Czech Republic</option>
                      <option >Denmark</option>
                      <option >Djibouti</option>
                      <option >Dominica</option>
                      <option >Dominican Republic</option>
                      <option >East Timor</option>
                      <option >Ecuador</option>
                      <option >Egypt</option>
                      <option >El Salvador</option>
                      <option >Equatorial Guinea</option>
                      <option >Eritrea</option>
                      <option >Estonia</option>
                      <option >Ethiopia</option>
                      <option >Falkland Islands (Malvinas)</option>
                      <option >Faroe Islands</option>
                      <option >Fiji</option>
                      <option >Finland</option>
                      <option >France</option>
                      <option >France, Metropolitan</option>
                      <option >French Guiana</option>
                      <option >French Polynesia</option>
                      <option >French Southern Territories</option>
                      <option >Gabon</option>
                      <option >Gambia</option>
                      <option >Georgia</option>
                      <option >Germany</option>
                      <option >Ghana</option>
                      <option >Gibraltar</option>
                      <option >Greece</option>
                      <option >Greenland</option>
                      <option >Grenada</option>
                      <option >Guadeloupe</option>
                      <option >Guam</option>
                      <option >Guatemala</option>
                      <option >Guinea</option>
                      <option >Guinea-Bissau</option>
                      <option >Guyana</option>
                      <option >Haiti</option>
                      <option >Heard and Mc Donald Islands</option>
                      <option >Holy See (Vatican City State)</option>
                      <option >Honduras</option>
                      <option >Hong Kong</option>
                      <option >Hungary</option>
                      <option >Iceland</option>
                      <option >India</option>
                      <option >Indonesia</option>
                      <option >Iran (Islamic Republic of)</option>
                      <option >Iraq</option>
                      <option >Ireland</option>
                      <option >Israel</option>
                      <option >Italy</option>
                      <option >Jamaica</option>
                      <option >Japan</option>
                      <option >Jordan</option>
                      <option >Kazakhstan</option>
                      <option >Kenya</option>
                      <option >Kiribati</option>
                      <option >Korea, Democratic People's Republic of</option>
                      <option >Korea, Republic of</option>
                      <option >Kuwait</option>
                      <option >Kyrgyzstan</option>
                      <option >Lao People's Democratic Republic</option>
                      <option >Latvia</option>
                      <option >Lebanon</option>
                      <option >Lesotho</option>
                      <option >Liberia</option>
                      <option >Libyan Arab Jamahiriya</option>
                      <option >Liechtenstein</option>
                      <option >Lithuania</option>
                      <option >Luxembourg</option>
                      <option >Macau</option>
                      <option >North Macedonia</option>
                      <option >Madagascar</option>
                      <option >Malawi</option>
                      <option >Malaysia</option>
                      <option >Maldives</option>
                      <option >Mali</option>
                      <option >Malta</option>
                      <option >Marshall Islands</option>
                      <option >Martinique</option>
                      <option >Mauritania</option>
                      <option >Mauritius</option>
                      <option >Mayotte</option>
                      <option >Mexico</option>
                      <option >Micronesia, Federated States of</option>
                      <option >Moldova, Republic of</option>
                      <option >Monaco</option>
                      <option >Mongolia</option>
                      <option >Montserrat</option>
                      <option >Morocco</option>
                      <option >Mozambique</option>
                      <option >Myanmar</option>
                      <option >Namibia</option>
                      <option >Nauru</option>
                      <option >Nepal</option>
                      <option >Netherlands</option>
                      <option >Netherlands Antilles</option>
                      <option >New Caledonia</option>
                      <option >New Zealand</option>
                      <option >Nicaragua</option>
                      <option >Niger</option>
                      <option >Nigeria</option>
                      <option >Niue</option>
                      <option >Norfolk Island</option>
                      <option >Northern Mariana Islands</option>
                      <option >Norway</option>
                      <option >Oman</option>
                      <option >Pakistan</option>
                      <option >Palau</option>
                      <option >Panama</option>
                      <option >Papua New Guinea</option>
                      <option >Paraguay</option>
                      <option >Peru</option>
                      <option >Philippines</option>
                      <option >Pitcairn</option>
                      <option >Poland</option>
                      <option >Portugal</option>
                      <option >Puerto Rico</option>
                      <option >Qatar</option>
                      <option >Reunion</option>
                      <option >Romania</option>
                      <option >Russian Federation</option>
                      <option >Rwanda</option>
                      <option >Saint Kitts and Nevis</option> 
                      <option >Saint LUCIA</option>
                      <option >Saint Vincent and the Grenadines</option>
                      <option >Samoa</option>
                      <option >San Marino</option>
                      <option >Sao Tome and Principe</option> 
                      <option >Saudi Arabia</option>
                      <option >Senegal</option>
                      <option >Seychelles</option>
                      <option >Sierra Leone</option>
                      <option >Singapore</option>
                      <option >Slovakia (Slovak Republic)</option>
                      <option >Slovenia</option>
                      <option >Solomon Islands</option>
                      <option >Somalia</option>
                      <option >South Africa</option>
                      <option >South Georgia and the South Sandwich Islands</option>
                      <option >Spain</option>
                      <option >Sri Lanka</option>
                      <option >St. Helena</option>
                      <option >St. Pierre and Miquelon</option>
                      <option >Sudan</option>
                      <option >Suriname</option>
                      <option >Svalbard and Jan Mayen Islands</option>
                      <option >Swaziland</option>
                      <option >Sweden</option>
                      <option >Switzerland</option>
                      <option >Syrian Arab Republic</option>
                      <option >Taiwan, Province of China</option>
                      <option >Tajikistan</option>
                      <option >Tanzania, United Republic of</option>
                      <option >Thailand</option>
                      <option >Togo</option>
                      <option >Tokelau</option>
                      <option >Tonga</option>
                      <option >Trinidad and Tobago</option>
                      <option >Tunisia</option>
                      <option >Turkey</option>
                      <option >Turkmenistan</option>
                      <option >Turks and Caicos Islands</option>
                      <option >Tuvalu</option>
                      <option >Uganda</option>
                      <option >Ukraine</option>
                      <option >United Arab Emirates</option>
                      <option >United Kingdom</option>
                      <option >United States</option>
                      <option >United States Minor Outlying Islands</option>
                      <option >Uruguay</option>
                      <option >Uzbekistan</option>
                      <option >Vanuatu</option>
                      <option >Venezuela</option>
                      <option >Viet Nam</option>
                      <option >Virgin Islands (British)</option>
                      <option >Virgin Islands (U.S.)</option>
                      <option >Wallis and Futuna Islands</option>
                      <option >Western Sahara</option>
                      <option >Yemen</option>
                      <option >Yugoslavia</option>
                      <option >Zambia</option>
                      <option >Zimbabwe</option>
                </select>              
              </div>
          </div> 
        </div>

      </div>
       <!-- <div class="flex mb-2 mt-2 flex-wrap">  
          <div class="flex">
              <div class="flex items-center mr-4">
                  <h2 class="text-sm font-medium text-gray-900 dark:text-gray-300">Select preferrable Incoterm</h2>
              </div>
              <div class="flex items-center mr-4">
                  <input id="inline-2-cfr" v-model="incoterm" type="radio" value="CFR" name="inline-radio-group" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                  <label for="inline-2-cfr" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">CFR</label>
              </div>
              <div class="flex items-center mr-4">
                  <input id="inline-checked-fob" v-model="incoterm" type="radio" value="FOB" name="inline-radio-group" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                  <label for="inline-checked-fob" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">FOB</label>
              </div>
              <div class="flex items-center mr-4">
                  <input id="inline-2-works" v-model="incoterm"  type="radio" value="EX/WORKS" name="inline-radio-group" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                  <label for="inline-2-works" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">EX/WORKS</label>
              </div>
              <div class="flex">
                  <div class="flex items-center">
                    <label for="countries" class=" text-sm font-medium text-gray-900 dark:text-gray-300">Country of Origin</label>
                    <select id="countries" v-model="country" class="ml-2 w-32 px-3 h-9 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block
                    dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                          <option disabled value="">Choose</option>
                          <option>Afghanistan</option>
                          <option >Albania</option>
                          <option >Algeria</option>
                          <option >American Samoa</option>
                          <option >Andorra</option>
                          <option >Angola</option>
                          <option >Anguilla</option>
                          <option >Antarctica</option>
                          <option >Antigua and Barbuda</option>
                          <option >Argentina</option>
                          <option >Armenia</option>
                          <option >Aruba</option>
                          <option >Australia</option>
                          <option >Austria</option>
                          <option >Azerbaijan</option>
                          <option >Bahamas</option>
                          <option >Bahrain</option>
                          <option >Bangladesh</option>
                          <option >Barbados</option>
                          <option >Belarus</option>
                          <option >Belgium</option>
                          <option >Belize</option>
                          <option >Benin</option>
                          <option >Bermuda</option>
                          <option >Bhutan</option>
                          <option >Bolivia</option>
                          <option >Bosnia and Herzegowina</option>
                          <option >Botswana</option>
                          <option >Bouvet Island</option>
                          <option >Brazil</option>
                          <option >British Indian Ocean Territory</option>
                          <option >Brunei Darussalam</option>
                          <option >Bulgaria</option>
                          <option >Burkina Faso</option>
                          <option >Burundi</option>
                          <option >Cambodia</option>
                          <option >Cameroon</option>
                          <option >Canada</option>
                          <option >Cape Verde</option>
                          <option >Cayman Islands</option>
                          <option >Central African Republic</option>
                          <option >Chad</option>
                          <option >Chile</option>
                          <option >China</option>
                          <option >Christmas Island</option>
                          <option >Cocos (Keeling) Islands</option>
                          <option >Colombia</option>
                          <option >Comoros</option>
                          <option >Congo</option>
                          <option >Congo, the Democratic Republic of the</option>
                          <option >Cook Islands</option>
                          <option >Costa Rica</option>
                          <option >Cote d'Ivoire</option>
                          <option >Croatia (Hrvatska)</option>
                          <option >Cuba</option>
                          <option >Cyprus</option>
                          <option >Czech Republic</option>
                          <option >Denmark</option>
                          <option >Djibouti</option>
                          <option >Dominica</option>
                          <option >Dominican Republic</option>
                          <option >East Timor</option>
                          <option >Ecuador</option>
                          <option >Egypt</option>
                          <option >El Salvador</option>
                          <option >Equatorial Guinea</option>
                          <option >Eritrea</option>
                          <option >Estonia</option>
                          <option >Ethiopia</option>
                          <option >Falkland Islands (Malvinas)</option>
                          <option >Faroe Islands</option>
                          <option >Fiji</option>
                          <option >Finland</option>
                          <option >France</option>
                          <option >France, Metropolitan</option>
                          <option >French Guiana</option>
                          <option >French Polynesia</option>
                          <option >French Southern Territories</option>
                          <option >Gabon</option>
                          <option >Gambia</option>
                          <option >Georgia</option>
                          <option >Germany</option>
                          <option >Ghana</option>
                          <option >Gibraltar</option>
                          <option >Greece</option>
                          <option >Greenland</option>
                          <option >Grenada</option>
                          <option >Guadeloupe</option>
                          <option >Guam</option>
                          <option >Guatemala</option>
                          <option >Guinea</option>
                          <option >Guinea-Bissau</option>
                          <option >Guyana</option>
                          <option >Haiti</option>
                          <option >Heard and Mc Donald Islands</option>
                          <option >Holy See (Vatican City State)</option>
                          <option >Honduras</option>
                          <option >Hong Kong</option>
                          <option >Hungary</option>
                          <option >Iceland</option>
                          <option >India</option>
                          <option >Indonesia</option>
                          <option >Iran (Islamic Republic of)</option>
                          <option >Iraq</option>
                          <option >Ireland</option>
                          <option >Israel</option>
                          <option >Italy</option>
                          <option >Jamaica</option>
                          <option >Japan</option>
                          <option >Jordan</option>
                          <option >Kazakhstan</option>
                          <option >Kenya</option>
                          <option >Kiribati</option>
                          <option >Korea, Democratic People's Republic of</option>
                          <option >Korea, Republic of</option>
                          <option >Kuwait</option>
                          <option >Kyrgyzstan</option>
                          <option >Lao People's Democratic Republic</option>
                          <option >Latvia</option>
                          <option >Lebanon</option>
                          <option >Lesotho</option>
                          <option >Liberia</option>
                          <option >Libyan Arab Jamahiriya</option>
                          <option >Liechtenstein</option>
                          <option >Lithuania</option>
                          <option >Luxembourg</option>
                          <option >Macau</option>
                          <option >North Macedonia</option>
                          <option >Madagascar</option>
                          <option >Malawi</option>
                          <option >Malaysia</option>
                          <option >Maldives</option>
                          <option >Mali</option>
                          <option >Malta</option>
                          <option >Marshall Islands</option>
                          <option >Martinique</option>
                          <option >Mauritania</option>
                          <option >Mauritius</option>
                          <option >Mayotte</option>
                          <option >Mexico</option>
                          <option >Micronesia, Federated States of</option>
                          <option >Moldova, Republic of</option>
                          <option >Monaco</option>
                          <option >Mongolia</option>
                          <option >Montserrat</option>
                          <option >Morocco</option>
                          <option >Mozambique</option>
                          <option >Myanmar</option>
                          <option >Namibia</option>
                          <option >Nauru</option>
                          <option >Nepal</option>
                          <option >Netherlands</option>
                          <option >Netherlands Antilles</option>
                          <option >New Caledonia</option>
                          <option >New Zealand</option>
                          <option >Nicaragua</option>
                          <option >Niger</option>
                          <option >Nigeria</option>
                          <option >Niue</option>
                          <option >Norfolk Island</option>
                          <option >Northern Mariana Islands</option>
                          <option >Norway</option>
                          <option >Oman</option>
                          <option >Pakistan</option>
                          <option >Palau</option>
                          <option >Panama</option>
                          <option >Papua New Guinea</option>
                          <option >Paraguay</option>
                          <option >Peru</option>
                          <option >Philippines</option>
                          <option >Pitcairn</option>
                          <option >Poland</option>
                          <option >Portugal</option>
                          <option >Puerto Rico</option>
                          <option >Qatar</option>
                          <option >Reunion</option>
                          <option >Romania</option>
                          <option >Russian Federation</option>
                          <option >Rwanda</option>
                          <option >Saint Kitts and Nevis</option> 
                          <option >Saint LUCIA</option>
                          <option >Saint Vincent and the Grenadines</option>
                          <option >Samoa</option>
                          <option >San Marino</option>
                          <option >Sao Tome and Principe</option> 
                          <option >Saudi Arabia</option>
                          <option >Senegal</option>
                          <option >Seychelles</option>
                          <option >Sierra Leone</option>
                          <option >Singapore</option>
                          <option >Slovakia (Slovak Republic)</option>
                          <option >Slovenia</option>
                          <option >Solomon Islands</option>
                          <option >Somalia</option>
                          <option >South Africa</option>
                          <option >South Georgia and the South Sandwich Islands</option>
                          <option >Spain</option>
                          <option >Sri Lanka</option>
                          <option >St. Helena</option>
                          <option >St. Pierre and Miquelon</option>
                          <option >Sudan</option>
                          <option >Suriname</option>
                          <option >Svalbard and Jan Mayen Islands</option>
                          <option >Swaziland</option>
                          <option >Sweden</option>
                          <option >Switzerland</option>
                          <option >Syrian Arab Republic</option>
                          <option >Taiwan, Province of China</option>
                          <option >Tajikistan</option>
                          <option >Tanzania, United Republic of</option>
                          <option >Thailand</option>
                          <option >Togo</option>
                          <option >Tokelau</option>
                          <option >Tonga</option>
                          <option >Trinidad and Tobago</option>
                          <option >Tunisia</option>
                          <option >Turkey</option>
                          <option >Turkmenistan</option>
                          <option >Turks and Caicos Islands</option>
                          <option >Tuvalu</option>
                          <option >Uganda</option>
                          <option >Ukraine</option>
                          <option >United Arab Emirates</option>
                          <option >United Kingdom</option>
                          <option >United States</option>
                          <option >United States Minor Outlying Islands</option>
                          <option >Uruguay</option>
                          <option >Uzbekistan</option>
                          <option >Vanuatu</option>
                          <option >Venezuela</option>
                          <option >Viet Nam</option>
                          <option >Virgin Islands (British)</option>
                          <option >Virgin Islands (U.S.)</option>
                          <option >Wallis and Futuna Islands</option>
                          <option >Western Sahara</option>
                          <option >Yemen</option>
                          <option >Yugoslavia</option>
                          <option >Zambia</option>
                          <option >Zimbabwe</option>
                    </select>              
                  </div>
              </div>   
          </div>     
        </div> -->
        <div class="md:grid md:grid-cols-3 my-3"> 
          <div class="flex items-center mr-1 w-48">
                <div class="flex items-center">
                  <label for="box_number" class=" text-sm font-medium text-red-900 dark:text-gray-300">P.O BOX</label>
                    <input type="number" v-model="box_number" id="box_number" class=" ml-1 w-2/3 h-9 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block
                        dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
                </div>
          </div>
          <div class="flex items-center mr-1 w-64 md:mt-0 mt-2">
            <div class="flex items-center">
              <label for="box_address" class=" mr-1 text-sm font-medium text-red-900 dark:text-gray-300">City, Country</label>
                <input type="text" v-model="box_address" id="box_address" class="w-1/2 h-9 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block
                    dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
            </div>
          </div>
          <div class="flex md:mt-0 mt-2">
            <div class="flex items-center w-96">
                <label for="lead_time" class=" text-sm font-medium text-red-900 dark:text-gray-300">lead time in weeks</label>
                <select v-model="lead_time" id="lead_time" class="ml-1 w-32ß h-9 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block
                dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <option disabled value="">Choose</option>
                      <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="7">7</option><option value="8">8</option><option value="9">9</option><option value="10">10</option><option value="11">11</option><option value="12">12</option><option value="13">13</option><option value="14">14</option><option value="15">15</option><option value="16">16</option><option value="17">17</option><option value="18">18</option><option value="19">19</option><option value="20">20</option><option value="21">21</option><option value="22">22</option><option value="23">23</option><option value="24">24</option><option value="25">25</option>
                      <option value="26">26</option><option value="27">27</option><option value="28">28</option><option value="29">29</option><option value="30">30</option><option value="31">31</option><option value="32">32</option><option value="33">33</option><option value="34">34</option><option value="35">35</option><option value="36">36</option><option value="37">37</option><option value="38">38</option><option value="39">39</option><option value="40">40</option><option value="41">41</option><option value="42">42</option><option value="43">43</option><option value="44">44</option><option value="45">45</option><option value="46">46</option><option value="47">47</option><option value="48">48</option><option value="49">49</option><option value="50">50</option>
                      <option value="51">51</option><option value="52">52</option><option value="53">53</option><option value="54">54</option><option value="55">55</option><option value="56">56</option><option value="57">57</option><option value="58">58</option><option value="59">59</option><option value="60">60</option><option value="61">61</option><option value="62">62</option><option value="63">63</option><option value="64">64</option><option value="65">65</option><option value="66">66</option><option value="67">67</option><option value="68">68</option><option value="69">69</option><option value="70">70</option><option value="71">71</option><option value="72">72</option><option value="73">73</option><option value="74">74</option><option value="75">75</option>
                      <option value="76">76</option><option value="77">77</option><option value="78">78</option><option value="79">79</option><option value="80">80</option><option value="81">81</option><option value="82">82</option><option value="83">83</option><option value="84">84</option><option value="85">85</option><option value="86">86</option><option value="87">87</option><option value="88">88</option><option value="89">89</option><option value="90">90</option><option value="91">91</option><option value="92">92</option><option value="93">93</option><option value="94">94</option><option value="95">95</option><option value="96">96</option><option value="97">97</option><option value="98">98</option><option value="99">99</option><option value="100">100</option>
                      
                </select>
              </div>
          </div>   

        </div>
        <!-- <div class="flex mb-2 mt-2 flex-wrap">  
              <div class="flex">
                 
                  <div class="flex items-center mr-1 w-48">
                       <div class="flex items-center">
                          <label for="box_number" class=" text-sm font-medium text-gray-900 dark:text-gray-300">P.O BOX</label>
                           <input type="number" v-model="box_number" id="box_number" class=" ml-1 w-2/3 h-9 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block
                                dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
                       </div>
                  </div>
                  <div class="flex items-center mr-1 w-64">
                       <div class="flex items-center">
                          <label for="box_address" class=" mr-1 text-sm font-medium text-gray-900 dark:text-gray-300">City, Country</label>
                           <input type="text" v-model="box_address" id="box_address" class="w-1/2 h-9 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block
                                dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
                       </div>
                  </div>
                  
                   <div class="flex">
                      <div class="flex items-center w-96">
                          <label for="lead_time" class=" text-sm font-medium text-gray-900 dark:text-gray-300">lead time in weeks</label>
                          <select v-model="lead_time" id="lead_time" class="ml-1 w-32ß h-9 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block
                          dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option disabled value="">Choose</option>
                                <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="7">7</option><option value="8">8</option><option value="9">9</option><option value="10">10</option><option value="11">11</option><option value="12">12</option><option value="13">13</option><option value="14">14</option><option value="15">15</option><option value="16">16</option><option value="17">17</option><option value="18">18</option><option value="19">19</option><option value="20">20</option><option value="21">21</option><option value="22">22</option><option value="23">23</option><option value="24">24</option><option value="25">25</option>
                                <option value="26">26</option><option value="27">27</option><option value="28">28</option><option value="29">29</option><option value="30">30</option><option value="31">31</option><option value="32">32</option><option value="33">33</option><option value="34">34</option><option value="35">35</option><option value="36">36</option><option value="37">37</option><option value="38">38</option><option value="39">39</option><option value="40">40</option><option value="41">41</option><option value="42">42</option><option value="43">43</option><option value="44">44</option><option value="45">45</option><option value="46">46</option><option value="47">47</option><option value="48">48</option><option value="49">49</option><option value="50">50</option>
                                <option value="51">51</option><option value="52">52</option><option value="53">53</option><option value="54">54</option><option value="55">55</option><option value="56">56</option><option value="57">57</option><option value="58">58</option><option value="59">59</option><option value="60">60</option><option value="61">61</option><option value="62">62</option><option value="63">63</option><option value="64">64</option><option value="65">65</option><option value="66">66</option><option value="67">67</option><option value="68">68</option><option value="69">69</option><option value="70">70</option><option value="71">71</option><option value="72">72</option><option value="73">73</option><option value="74">74</option><option value="75">75</option>
                                <option value="76">76</option><option value="77">77</option><option value="78">78</option><option value="79">79</option><option value="80">80</option><option value="81">81</option><option value="82">82</option><option value="83">83</option><option value="84">84</option><option value="85">85</option><option value="86">86</option><option value="87">87</option><option value="88">88</option><option value="89">89</option><option value="90">90</option><option value="91">91</option><option value="92">92</option><option value="93">93</option><option value="94">94</option><option value="95">95</option><option value="96">96</option><option value="97">97</option><option value="98">98</option><option value="99">99</option><option value="100">100</option>
                                
                          </select>
                       </div>
                  </div>   
              </div>     
        </div> -->

      <div
        class="
          p-6
          border border-gray-300
          sm:rounded-md
          
        "
      >
        <form method="POST" action="">
          <label class="block mb-3 text-center">
            <span class="text-blue-700 text-center font-bold"
              >Technical Specifications</span
            >
          </label>
          <div style="height: 45vh; " class=" h-96 overflow-y-auto overscroll-contain">
          <div v-for="(detail, index) in equipmentDetails" :key="index"  >
            <label class="block mb-6">
              <span>{{ index +1 + ".  "  }} &nbsp;</span><span class="text-pink-700 font-semibold break-all text-justify ">{{ " " + detail.name }}</span>
              <span class="text-pink-700 font-semibold">
                <button class="text-blue-500 px-2" @click.prevent="setSelectedIndex(index); $router.push({ name: 'ManufacturerViewInquiries', params: { equipment_id, equipment_name, 'quantity': equipment_qty, manufacturer_id , 'selected_index': index }})">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                          <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                        </svg>
                </button>
                
              </span>
            </label>
            
            <div class="mb-6">
              <div class="mt-2">
                <div>
                  <label class="inline-flex items-center">
                    <input
                      :name="index"
                      type="radio"
                      class="
                        text-indigo-600
                        border-gray-300
                        rounded-full
                        shadow-sm
                        focus:border-indigo-300
                        focus:ring
                        focus:ring-offset-0
                        focus:ring-indigo-200
                        focus:ring-opacity-50
                      "
                      @change="if(equipmentDetails[index].is_complaint === '1') equipmentDetails[index].clinical_significance = null;"
                      value="1" 
                      v-model="equipmentDetails[index].is_complaint"
                    />
                    <span class="ml-2">Compliant</span>
                  </label>
                </div>
                <div>
                  <label class="inline-flex items-center">
                    <input
                      :name="index"
                      type="radio"
                      class="
                        text-indigo-600
                        border-gray-300
                        rounded-full
                        shadow-sm
                        focus:border-indigo-300
                        focus:ring
                        focus:ring-offset-0
                        focus:ring-indigo-200
                        focus:ring-opacity-50
                      "
                      value="2"
                      v-model="equipmentDetails[index].is_complaint"
                    />
                    <span class="ml-2">Non-compliant</span>
                  </label>
                </div>
              </div>
            </div>
            <label v-if="equipmentDetails[index].is_complaint == 2" class="block mb-6 ">
              <span class="text-gray-700"> Clinical Significance / Notes</span>
              <textarea
                
                v-model="equipmentDetails[index].clinical_significance"
                class="
                  form-control
                  block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700
                  focus:bg-white
                  focus:border-blue-600
                  focus:outline-none
                "
                id="exampleFormControlTextarea1"
                rows="3"
                placeholder="Your message"
              ></textarea>
            </label>
          </div>
          </div>
          <div class="sm:col-span-6 mb-6 mt-5">
            <div class="sm:grid sm:grid-cols-6 sm:gap-y-6 gap-x-4 p-3">
                <div v-if="toggle_brochure" class="col-span-3 my-3 sm:my-0">
                    <label for="file" class="block text-sm font-medium text-gray-700">
                      Upload Brochure 
                    </label>
                      <div class="mt-1 flex rounded-md  ">
                        <input accept="image/*" type="file" @change="handleFile($event,'brochure')" name="brochure" id="file" class=" flex-1 px-3  focus:ring-indigo-500  py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                      </div>
                      <p class="text-xs text-gray-500 uppercase">
                        jpg,jpeg,png, up to 20MB
                      </p>
                </div>

                <div v-if="!toggle_brochure" class="col-span-3 my-3 sm:my-0">
                    <div class="grid grid-rows-2 grid-col-2 gap-1">
                        <div>
                            <a
                            
                            target="_blank"
                            :href="'http://jmstenderapp.com/api/equipment/get-image/' + manufacturer_submission_id + '/brochure'"
                            class="text-lg text-blue-500 
                                    hover:underline hover:text-red-500 
                                    active:text-yellow-500 "
                            >
                              click to view uploaded brochure
                           </a>
                        </div>
                       
                        <div>

                           <div class="flex items-center">
                              <input v-model="toggle_brochure" checked id="checked-brochure" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                              <label for="checked-brochure" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">change brochure</label>
                          </div>

                        </div>
                    </div>
                                    
                </div>

                <div v-if="toggle_signature" class="col-span-3 my-3 sm:my-0">
                  <label for="file" class="block text-sm font-medium text-gray-700">
                    Upload Siganture for Authorization 
                  </label>
                    <div class="mt-1 flex rounded-md  ">
                      <input accept="image/*" type="file" name="signature" @change="handleFile($event,'signature')" id="file" class=" flex-1 px-3  focus:ring-indigo-500  py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                    </div>
                    <p class="text-xs text-gray-500 uppercase">
                      jpg,jpeg,png, up to 20MB
                    </p>
                </div>

                <div v-if="!toggle_signature"  class="col-span-3 my-3 sm:my-0">

                     <div class="grid grid-rows-2 grid-col-2 gap-1">
                        <div>
                            <a
                              target="_blank"
                              :href="'http://jmstenderapp.com/api/equipment/get-image/' + manufacturer_submission_id + '/signature'"
                              class="text-lg text-blue-500 
                                      hover:underline hover:text-red-500 
                                      active:text-yellow-500 "
                              >
                                click to view uploaded signature
                            </a>
                        </div>
                       
                        <div>

                           <div class="flex items-center">
                              <input v-model="toggle_signature" checked id="checked-signature" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                              <label for="checked-signature" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">change signature</label>
                          </div>

                        </div>
                    </div>
  
                </div>

            </div>
          </div>
        <div class="sm:col-span-6 mb-6 mt-1">
            <div class="sm:grid sm:grid-cols-6 gap-y-6 gap-x-4 p-3">
                <div class="col-span-3 text-xs">
                   <button
                        v-if="equipment_id && manufacturer_id"
                        type="submit"
                        @click.prevent="submit()"
                        class="
                        w-full
                          h-10
                          px-5
                          text-indigo-100
                          bg-indigo-700
                          rounded-lg
                          transition-colors
                          duration-150
                          focus:shadow-outline
                          hover:bg-indigo-800
                        "
                      >
                        Submit response to JMS
                  </button>
                </div>
                <div class="col-span-3 text-xs mt-3 sm:mt-0">
                   <button
                          v-if="equipment_id && manufacturer_id"
                          type="button"
                          @click.prevent="save()"
                          class="
                          w-full
                            h-10
                            px-5
                            text-indigo-100
                            bg-green-700
                            rounded-lg
                            transition-colors
                            duration-150
                            focus:shadow-outline
                            hover:bg-green-800
                          "
                        >
                          save submission for later
                  </button>
                </div>
            </div>
        </div>
          <!-- <div class="mb-1 mt-3">
            <button
              type="submit"
              @click.prevent="submit()"
              class="
                h-10
                px-5
                text-indigo-100
                bg-indigo-700
                rounded-lg
                transition-colors
                duration-150
                focus:shadow-outline
                hover:bg-indigo-800
              "
            >
              Submit Answers to JMS
            </button>
          </div> -->
          
          <div>
            <div class="mt-2 text-green-700 text-right text-xs">
              prepared by
              <a
                href="https://jms.co.ug"
                class="hover:underline hover:text-blue-500"
                target="_blank"
                >JMS</a
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick, ref , watch} from "vue";
import { useRouter } from "vue-router";
import { ArrowLeftIcon } from "@heroicons/vue/solid";
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import Api from "@/components/api/Api";
import { getManufacturerID, getEquipment, setSelectedIndex, getManufacturerUrl } from "@/stores/Tender/Manufacturer.js" 

export default {
  name: "ManufacturerView",
  components:{ ArrowLeftIcon, AlertSuccess, AlertError},
 setup() {

    const router = useRouter();
    let equipment_name     = ref("");
    let equipment_qty      = ref("");
    let equipmentDetails   = ref([]);
    let equipment_id       = ref('');
    let manufacturer_id    = ref('');
    let toggle_brochure    = ref(true);
    let toggle_signature   = ref(true);
    let picked = ref("0")
    let isLoading = ref(false);

    // let brochure  = ref("");
    // let signature  = ref("");

    let errorMessage = ref("");
    let successMessage = ref("");

    let selectedEquipment = getEquipment();


    equipment_id.value     = selectedEquipment.value? selectedEquipment.value.id : '';
    equipment_name.value   = selectedEquipment.value? selectedEquipment.value.name : '';
    equipment_qty.value    = selectedEquipment.value? selectedEquipment.value.quantity : '';
    manufacturer_id.value  = getManufacturerID();
    
    let encrypted_id       = ref('');
    encrypted_id.value     = getManufacturerUrl();

    if (!equipment_id.value && !manufacturer_id.value){
       
       let link =  localStorage.getItem("link")
        router.push({ path: '/manufacturer/list/'+link });

    }else{


      loadEquipmentData();
      if(manufacturer_id.value){

        localStorage.setItem("link", encrypted_id.value);

      }
      
    }

    let manufacturer_submission_id = ref('');
    let price_in_usd = ref('');
    let incoterm = ref('');
    let country = ref('');
    let landing = ref('to jms uganda'); //default it to a value for now
    let box_address = ref(''); //123344
    let box_number = ref(''); //City, Country
    let signee_title = ref(''); //ceo, manager
    let lead_time = ref('');
    let link_to_brochure = ref('');
    let link_to_manufacturer_authorization = ref('');

    let final_error_1 = ref('');
    let final_error_2 = ref('');

    watch(price_in_usd, (value) => {
      if (value) {
        
        const result = value.toString().replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      
         nextTick(() => price_in_usd.value = result);
      }
    })

    function loadEquipmentData(){

        isLoading.value = true;

            Api()
              .get(`/equipment/get-specs-data/${equipment_id.value}/${manufacturer_id.value}`)
              .then((response) => {
                
                let status = response.data.status;
                //let message = response.data.message;
                if (status === "success") {
       
                    //fields.value = response.data.specifications;
                   // successMessage.value = response.data.message;
                    
                  if(response.data.manufacturer_submission){
                    
                     manufacturer_submission_id.value = response.data.manufacturer_submission.id;
                     price_in_usd.value = response.data.manufacturer_submission.price_in_usd;
                     incoterm.value = response.data.manufacturer_submission.incoterm;
                     country.value  = response.data.manufacturer_submission.country;
                    // landing.value  = response.data.manufacturer_submission.landing;
                     lead_time.value = response.data.manufacturer_submission.lead_time;

                     box_address.value = response.data.manufacturer_submission.box_address;
                     box_number.value = response.data.manufacturer_submission.box_number;
                     signee_title.value = response.data.manufacturer_submission.signee_title;

                     link_to_brochure.value = response.data.manufacturer_submission.link_to_brochure;
                     if(link_to_brochure.value){
                       
                          toggle_brochure.value = false;
                     }
                     link_to_manufacturer_authorization.value = response.data.manufacturer_submission.link_to_manufacturer_authorization;    
                     if(link_to_manufacturer_authorization.value){
                        
                        toggle_signature.value = false;
                     }
                  }

                   if(response.data.tech_submissions){

                        equipmentDetails.value = response.data.tech_submissions;

                    }

                } else {
                  
                 // fields.value = [];
                  
                }
          })
          .catch((error) => {
            
            errorMessage.value = "An error has occurred! " + error.message;
            
          })
          .finally(() => {

            isLoading.value = false;
          });



    }

    async function submit(){

      // isLoading.value = true;

      if(!price_in_usd.value){

        errorMessage.value = "Please enter a price in USD";
         isLoading.value = false;
        return;
      }

      if(!landing.value){

        errorMessage.value = "Please select a landing option";
        isLoading.value = false;
        return;
      }

      if(!incoterm.value){

        errorMessage.value = "Please select your preferrable incoterm";
        isLoading.value = false;
        return;
      }

      if(!country.value){

        errorMessage.value = "Please select a country of origin";
        isLoading.value = false;
        return;
      }

      if(!lead_time.value){

        errorMessage.value = "Please select a lead time";
        isLoading.value = false;
        return;
      }

      if(!box_address.value){

        errorMessage.value = "Please enter a box address";
        isLoading.value = false;
        return;
      }

      if(!box_number.value){

        errorMessage.value = "Please enter a box number";
        isLoading.value = false;
        return;
      }

      if(!signee_title.value){

        errorMessage.value = "Please enter the signer's title";
        isLoading.value = false;
        return;
      }



      let temp_message   = '';
      let temp_message_2 = '';
     
      for( let i = 0; i < equipmentDetails.value.length; i++){

        if(equipmentDetails.value[i]){

           
            if(equipmentDetails.value[i].is_complaint && equipmentDetails.value[i].is_complaint == 2){

              if(!equipmentDetails.value[i].clinical_significance){
              
                if((i + 1) == equipmentDetails.value.length ){

                    temp_message = temp_message + (i+1)

                }else{

                    temp_message = temp_message + (i+1) + ', '
                }

              }
            }else if(!equipmentDetails.value[i].is_complaint){

                if((i + 1) == equipmentDetails.value.length ){

                    temp_message_2 = temp_message_2 + (i+1)

                }else{

                    temp_message_2 = temp_message_2 + (i+1) + ', '
                }



            }

        }

      }

      if(temp_message.length != 0 || temp_message != ''){
        
        final_error_1.value = "Please provide the clinical significance for these non compliant specifications " + temp_message;

      }else{

          final_error_1.value = null;

      }

      if(temp_message_2.length != 0 || temp_message_2 != ''){
        
        final_error_2.value = "Please choose compliant or non compliant for the following the specifications " + temp_message_2

      }else{

           final_error_2.value  = null;

      }

      if(final_error_1.value || final_error_2.value){

        return;
      }

      if(!link_to_brochure.value){

        errorMessage.value = "Please upload a brochure";
        return;
      }

      if(!link_to_manufacturer_authorization.value){

        errorMessage.value = "Please upload an image of a signature";
        return;

      }

      await saveForAsync();
      submit_();
      
     
    }

    function submit_(){

       // save
      let data = {
          
          'manufacturer_submission_id': manufacturer_submission_id.value,
          'is_submitted' : 1,
        };

        Api()
            .post("/equipment/submit-manufacturer-submission", data)
            .then((response) => {
                let message = response.data.message;

                let status = response.data.status;

                if (status === "error") {

                    errorMessage.value = message; 
                   isLoading.value = false;

                } else if (status === "success") {

                    successMessage.value  = message;
                    isLoading.value = false;
                     
                  //  router.push({ path: `/manufacturer/list/${manufacturer_id.value}` });  
                }
            })
            .catch((error) => {

              errorMessage.value = "An error has occured! Please try again" + error.message;
              isLoading.value = false;

            });

        
    }

  
    function save(){

        isLoading.value = true;
        
        let data = {
          'equipment_id'    : equipment_id.value,
          'manufacturer_id' : manufacturer_id.value,
          'manufacturer_submission_id': manufacturer_submission_id.value,
          'price_in_usd': price_in_usd.value? price_in_usd.value.replace(/,/g, "") : null,
          'incoterm': incoterm.value,
          'country': country.value ,
          'landing': landing.value ,
          'lead_time': lead_time.value,
          'tech_submissions': equipmentDetails.value,
          'box_address' : box_address.value,
          'box_number' :  box_number.value,
          'signee_title' : signee_title.value,
          'is_submitted' : 0,
        };

        
        errorMessage.value   = null;
        successMessage.value = null;

        Api()
            .post("/equipment/save-manufacturer-submission", data)
            .then((response) => {
                let message = response.data.message;

                let status = response.data.status;
                
                if (status === "error") {

                    errorMessage.value = message; 
         
                } else if (status === "success") {

                    successMessage.value  = message;
                    
                }
            })
            .catch((error) => {

              errorMessage.value = "An error has occured! Please try again" + error.message;
                

            })
            .finally(() => {
                
                isLoading.value = false;
                loadEquipmentData();
               
            });


    }

   
    async function handleFile(e,file_option){

    
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      
      let image  = files[0];

      const formData = new FormData();
      formData.append('image',image);
      formData.append('file_option', file_option);
     
      
      const options = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      
      if(!manufacturer_submission_id.value){

        
        await saveForAsync();
        
        await loadEquipmentDataForAsync();
        
        formData.append('manufacturer_submission_id', manufacturer_submission_id.value);

        await uploadImage(formData,options);

        await loadEquipmentDataForAsync();

      }else{

          await saveForAsync();
          formData.append('manufacturer_submission_id', manufacturer_submission_id.value);
          await uploadImage(formData,options);
          await loadEquipmentDataForAsync();
      }

    }
  
    function uploadImage(formData,options){
      
      isLoading.value = true;
      return  Api()
              .post("/equipment/upload_image", formData, options)
              .then((response) => {
                
                let message = response.data.message;
                
                if(response.data.status === "success"){

                  successMessage.value = message;

                  if(response.data.file_option == "brochure"){
                    
                    toggle_brochure.value = false;

                  }else if(response.data.file_option == "signature"){

                    toggle_signature.value = false;

                  }

                }else{

                    errorMessage.value = message;

                }
                

              })
              .catch((error) => {
                error.value = error.message;
              })
              .finally(() => {
                isLoading.value = false;
              });

    }

    function saveForAsync(){

        isLoading.value = true;
        
        let data = {
          'equipment_id'    : equipment_id.value,
          'manufacturer_id' : manufacturer_id.value,
          'manufacturer_submission_id': manufacturer_submission_id.value,
          'price_in_usd': price_in_usd.value? price_in_usd.value.replace(/,/g, "") : null,
          'incoterm': incoterm.value,
          'country': country.value ,
          'landing': landing.value ,
          'lead_time': lead_time.value,
          'tech_submissions': equipmentDetails.value,
          'box_address' : box_address.value,
          'box_number' :  box_number.value,
          'signee_title' : signee_title.value,
          'is_submitted' : 0,
        };

        
        errorMessage.value   = null;
        successMessage.value = null;

      return  Api()
            .post("/equipment/save-manufacturer-submission", data)
            .then((response) => {
                let message = response.data.message;

                let status = response.data.status;
                
                if (status === "error") {

                    errorMessage.value = message; 
         
                } else if (status === "success") {

                    successMessage.value  = message;
                    
                }
            })
            .catch((error) => {

              errorMessage.value = "An error has occured! Please try again" + error.message;
                

            })
            .finally(() => {
                
                isLoading.value = false;
               
            });

    }

    function loadEquipmentDataForAsync(){

     isLoading.value = true;

     return   Api()
                .get(`/equipment/get-specs-data/${equipment_id.value}/${manufacturer_id.value}`)
                .then((response) => {
                  
                 

                  let status = response.data.status;
                  //let message = response.data.message;
                  if (status === "success") {
        
                      //fields.value = response.data.specifications;
                    // successMessage.value = response.data.message;
                      
                    if(response.data.manufacturer_submission){
                      
                      manufacturer_submission_id.value = response.data.manufacturer_submission.id;
                      price_in_usd.value = response.data.manufacturer_submission.price_in_usd;
                      incoterm.value = response.data.manufacturer_submission.incoterm;
                      country.value  = response.data.manufacturer_submission.country;
                      // landing.value  = response.data.manufacturer_submission.landing;
                      lead_time.value = response.data.manufacturer_submission.lead_time;

                      box_address.value = response.data.manufacturer_submission.box_address;
                      box_number.value = response.data.manufacturer_submission.box_number;
                      signee_title.value = response.data.manufacturer_submission.signee_title;

                      link_to_brochure.value = response.data.manufacturer_submission.link_to_brochure;
                      if(link_to_brochure.value){
                        
                            toggle_brochure.value = false;
                      }
                      link_to_manufacturer_authorization.value = response.data.manufacturer_submission.link_to_manufacturer_authorization;    
                      if(link_to_manufacturer_authorization.value){
                          
                          toggle_signature.value = false;
                      }
                    }

                    if(response.data.tech_submissions){

                          equipmentDetails.value = response.data.tech_submissions;

                      }

                  } else {
                    
                  // fields.value = [];
                    
                  }
                })
                .catch((error) => {
                  
                  errorMessage.value = "An error has occurred! " + error.message;
                  
                })
                .finally(() => {

                  isLoading.value = false;
                  
                });

    }

    //loadEquipmentData();

    return {
      equipment_name,
      equipment_qty,
      equipmentDetails,
      picked,
      manufacturer_id,
      equipment_id,
      isLoading,
      successMessage,
      errorMessage,
      submit,
      save,
      price_in_usd,
      country,
      incoterm,
      landing,
      lead_time,
      link_to_brochure,
      link_to_manufacturer_authorization,
      submit_,
      final_error_1,
      final_error_2,
      handleFile,
      manufacturer_submission_id,
      toggle_brochure,
      toggle_signature,
      box_address,
      box_number,
      signee_title,
      setSelectedIndex, encrypted_id
    };
  },
};
</script>

<style>
</style>