import { computed, ref } from "vue";
import toast from "@/helpers/toast";
import {useLoading} from 'vue-loading-overlay'
import FundraisingHost from '@/helpers/FundraisingHost.js'

const $loading = useLoading();
// const FundraisingHost.url  =  'http://donorsweb.jms.co.ug/api/';
const donationList = ref([])
const {success,error} = toast;
const selectedDonation = ref();
const filter = ref('');


const create = async (user_id= 0) => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const f = document.getElementById('create-donations');
  // @ts-ignore
  const form  =  new FormData(f);
  const  res = await fetch(FundraisingHost.url+'create-donation',{
    method:'post',
    body: form,
    headers:{
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success'){
    if (f){
      // @ts-ignore
      f.reset();
    }
    success(data.message);
    loadDonations(user_id)
  }else{
    error(data.message);
  }
  loader.hide();

}


const update = async (user_id) => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const f = document.getElementById('create-donations');
  // @ts-ignore
  const form  =  new FormData(f);
  const  res = await fetch(`${FundraisingHost.url}update-project-resource/${selectedDonation.value.id}/${user_id}`,{
    method:'PATCH',
    body: form,
    headers:{
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success'){
    success(data.message);
    loadDonations(user_id)
  }else{
    error(data.message);
  }
  loader.hide();

}

const loadDonations = async (user_id = 0) => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const res = await fetch(`${FundraisingHost.url}get-donations-list/${user_id}` ,{
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success') {
    donationList.value = data.donations;
    success(data.message)
  }else{
    error(data.message)
  }
  loader.hide();
}

const deleteUser = async (user_id) => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const res = await fetch(`${FundraisingHost.url}delete-project-resource/${selectedDonation.value.id}` ,{
    method:'PATCH',
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success') {
    success(data.message)
    loadDonations(user_id);
  }else{
    error(data.message)
  }
  loader.hide();
}



const loadProjectDonations = async (project_id,year, month) => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const res = await fetch(`${FundraisingHost.url}get-project-donations-list/${project_id}/${month}/${year}` ,{
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success') {
    donationList.value = data.donations;
    success(data.message)
  }else{
    error(data.message)
  }
  loader.hide();
}

const getDonationList = computed(() => {
  return donationList.value.filter((val)=>val.donor_name.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1)
})

export default { loadDonations, donationList, create, selectedDonation, update, loadProjectDonations, filter, getDonationList, deleteUser }