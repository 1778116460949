<template>
  <div>
    <Loading v-model:active="isLoading" />
    <div class=" mt-1 mb-2 flex justify-center items-center">
        <AlertSuccess
            v-if="successMessage"
            :message="successMessage"
            @hide="successMessage = null"
        />
        <AlertError
            v-if="errorMessage"
            :message="[errorMessage]"
            @hide="errorMessage = null"
        />
    </div>
    <div class="grid grid-rows-2 sm:flex sm:justify-between sm:items-center pb-4">
            <div class="pr-3">
              <!-- <button type="button" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Graphical Reports
             </button> -->

            </div>
            <!-- <label for="table-search" class="sr-only">Search</label> -->
            <div class="relative flex">
                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                </div>
                <input v-model="filter" type="text" id="table-search" class="block flex-1 p-2 pl-10 sm:w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items">
            </div>
      </div>
     <div class="flex flex-col">
        <div class="-my-2  sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-fit w-full divide-y divide-gray-200">
                        <thead class="bg-teal-700">
                            <tr>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white capitalize tracking-wider">Budget Line</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white capitalize tracking-wider">Total Amount (ugx)</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white capitalize tracking-wider">Supplementary</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white capitalize tracking-wider">Total Used</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white capitalize tracking-wider">Balance Left</th>
                                <th scope="col" class="relative px-6 py-3">
                                <span class="sr-only">request</span>
                                </th>
                                <th scope="col" class="relative px-6 py-3">
                                <span class="sr-only">download</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="(budgetLine) in filteredBudgetLinesTable" :key="budgetLine.id">
                                <td class="px-6 py-4 whitespace-nowrap text-xs font-medium text-gray-900">
                                  {{ budgetLine.name }}
                                </td>
                                <!-- <td class="px-6 py-4 text-xs text-gray-500">
                                {{( Number(budgetLine.total_amount) + Number(budgetLine.supplementary_amount)).toLocaleString() }}
                                </td> -->
                                <td class="px-6 py-4 text-xs text-gray-500">
                                {{( Number(budgetLine.total_amount)).toLocaleString() }}
                                </td>
                                <td class="px-6 py-4 text-xs text-gray-500">
                                {{ Number(budgetLine.supplementary_amount).toLocaleString() }}
                                </td>
                                <td class="px-6 py-4  text-xs text-gray-500">
                                {{ budgetLine.total_used? Number(budgetLine.total_used).toLocaleString() : '0.00' }}
                                </td>
                                <td class="px-6 py-4 text-xs text-gray-500">
                                {{ budgetLine.total_used? (( Number(budgetLine.total_amount) + Number(budgetLine.supplementary_amount)) - Number(budgetLine.total_used)).toLocaleString() : ( Number(budgetLine.total_amount) + Number(budgetLine.supplementary_amount)).toLocaleString() }}
                                </td>
                              
                                <td class="pl-2 pr-2 py-4 whitespace-nowrap text-right text-xs">
                                <a @click="selectedBudgetLine =  budgetLine; showRequestModal = true;" class="bg-indigo-600 text-white text-xs  py-1 px-2 rounded-md cursor-pointer hover:bg-indigo-900 shadow-md">Request For More</a>
                                </td>
                                <td class="pl-2 pr-2 py-4 whitespace-nowrap text-right text-xs font-medium">
                                  <a  :href="`${file_url}/${budgetLine.id}`"  class="text-white bg-green-600 py-1 px-2 rounded-md text-xs cursor-pointer hover:bg-green-900 shadow-md">download report</a>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                              <td colspan="8">
                                  <nav class="bg-white px-4 py-3 flex items-center justify-between  sm:px-6" aria-label="Pagination">
                                      <div class="hidden sm:block">
                                      <p class="text-sm text-gray-700">
                                          Showing
                                          {{ ' ' }}
                                          <span class="font-medium">{{ fromPage }}</span>
                                          {{ ' ' }}
                                          to
                                          {{ ' ' }}
                                          <span class="font-medium">{{ toPage }}</span>
                                          {{ ' ' }}
                                          of
                                          {{ ' ' }}
                                          <span class="font-medium">{{ totalPageResults }}</span>
                                          {{ ' ' }}
                                          results
                                      </p>
                                      </div>
                                      <div class="flex-1 flex justify-between sm:justify-end">
                                        <a :class="prevPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="loadAllMyBudgetLines( pageUrl+prevPageUrl)"  class="relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Previous </a>
                                        <a :class="nextPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="loadAllMyBudgetLines( pageUrl+nextPageUrl)" class="ml-3 cursor-pointer relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Next </a>
                                      </div>
                                  </nav>
                              </td>
                          </tr>
                      </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <RequisitionerMultiPurposeModalComponent :show="showRequestModal" @close="showRequestModal = false">
        <template v-slot:header>

            <span class="text-red-900"> Request for fund addition to budget line( {{ selectedBudgetLine.name}})</span>
              <AlertError
                    v-if="errorMessage"
                    :message="[errorMessage]"
                    @hide="errorMessage = null"
                />
        </template>
        <template v-slot:body>
           <div class="flex flex-col items-center justify-center">
            <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px lg:pt-2">Reason For the Request</label>
             <div class=" sm:border-gray-200 pt-1 w-1/2">
                    <label for="country" class="block text-sm font-bold text-red-900 sm:mt-px pt-2"> Select who to send request to</label>
                    <Combobox as="div" class="pt-2" v-model="selectedPerson">
                        <!-- <ComboboxLabel class="block text-sm font-medium text-gray-700">Assigned to</ComboboxLabel> -->
                        <div class="relative mt-1">
                        <ComboboxInput placeholder="Click the arrows or start typing name here" class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm" @change="query = $event.target.value" :display-value="(person) => person.name" />
                        <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                            <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </ComboboxButton>

                        <ComboboxOptions v-if="filteredPeople.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            <ComboboxOption v-for="person in filteredPeople" :key="person.id" :value="person" as="template" v-slot="{ active, selected }">
                            <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                                <span :class="['block truncate', selected && 'font-semibold']">
                                {{ person.name }}
                                </span>

                                <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                </span>
                            </li>
                            </ComboboxOption>
                        </ComboboxOptions>
                        </div>
                    </Combobox>
            </div>
            <div class="mt-2">
                <div class="mt-1 p-3 border-2 text-left max-w-full rounded-xl border-gray-300">
                    <div v-if="editor" class="flex flex-wrap w-full">
                        <button @click="editor.chain().focus().toggleBold().run()" class="menu-button"
                        :class="{ 'is_active': editor.isActive('bold') }">
                        <BIconTypeBold class="h-4 w-4" />
                        </button>
                        <button @click="editor.chain().focus().toggleItalic().run()" class="menu-button"
                        :class="{ 'is_active': editor.isActive('italic') }">
                        <BIconTypeItalic class="h-4 w-4" />
                        </button>
                        <button @click="editor.chain().focus().toggleStrike().run()" class="menu-button"
                        :class="{ 'is_active': editor.isActive('strike') }">
                        <BIconTypeStrikethrough class="h-4 w-4" />
                        </button>
                        <!-- <button @click="editor.chain().focus().setParagraph().run()" class="menu-button"
                        :class="{ 'is_active': editor.isActive('paragraph') }">
                        <BIconParagraph class="h-4 w-4" />
                        </button> -->
                        <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" class="menu-button"
                        :class="{ 'is_active': editor.isActive('heading', { level: 1 }) }">
                        h1
                        </button>
                        <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" class="menu-button"
                        :class="{ 'is_active': editor.isActive('heading', { level: 2 }) }">
                        h2
                        </button>
                        <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" class="menu-button"
                        :class="{ 'is_active': editor.isActive('heading', { level: 3 }) }">
                        h3
                        </button>
                        <button @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" class="menu-button"
                        :class="{ 'is_active': editor.isActive('heading', { level: 4 }) }">
                        h4
                        </button>
                        <button @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" class="menu-button"
                        :class="{ 'is_active': editor.isActive('heading', { level: 5 }) }">
                        h5
                        </button>
                        <button @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" class="menu-button"
                        :class="{ 'is_active': editor.isActive('heading', { level: 6 }) }">
                        h6
                        </button>
                        <button @click="editor.chain().focus().toggleBulletList().run()" class="menu-button"
                        :class="{ 'is_active': editor.isActive('bulletList') }">
                        <BIconListUl class="h-4 w-4" />
                        </button>
                        <button @click="editor.chain().focus().toggleOrderedList().run()" class="menu-button"
                        :class="{ 'is_active': editor.isActive('orderedList') }">
                        <BIconListOl class="h-4 w-4" />
                        </button>
                        <button @click="editor.chain().focus().toggleUnderline().run()" class="menu-button"
                        :class="{ 'is_active': editor.isActive('underline') }">
                        <BIconTypeUnderline class="h-4 w-4" />
                        </button>
                        <button
                            class="menu-button"
                            @click="editor.chain().focus().setTextAlign('left').run()"
                            :class="{ 'is_active': editor.isActive({ textAlign: 'left' }) }"
                        >
                            <BIconTextLeft class="h-4 w-4" />
                        </button>
                        <button
                            class="menu-button"
                            @click="editor.chain().focus().setTextAlign('center').run()"
                            :class="{ 'is_active': editor.isActive({ textAlign: 'center' }) }"
                        >
                            <BIconTextCenter class="h-4 w-4" />
                        </button>
                        <button
                            class="menu-button"
                            @click="editor.chain().focus().setTextAlign('right').run()"
                            :class="{ 'is_active': editor.isActive({ textAlign: 'right' }) }"
                        >
                            <BIconTextRight class="h-4 w-4" />
                        </button>
                        <button
                            class="menu-button"
                            @click="editor.chain().focus().setTextAlign('justify').run()"
                            :class="{ 'is_active': editor.isActive({ textAlign: 'justify' }) }"
                        >
                            <BIconJustify class="h-4 w-4" />
                        </button>

                        <button
                            class="menu-button"
                            @click="editor.chain().focus().toggleHighlight().run()"
                            :class="{ 'is_active': editor.isActive('highlight') }"
                        >
                            <BIconPen class="h-4 w-4" />
                        </button>
                        <button
                            class="menu-button"
                            @click="
                            editor
                                .chain()
                                .focus()
                                .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                                .run()
                            "
                        >
                            <BIconTable class="h-4 w-4" />
                        </button>

                        <button
                            class="menu-button"
                            @click="editor.chain().focus().addColumnBefore().run()"
                            :disabled="!editor.can().addColumnBefore()"
                        >
                            <RiInsertColumnLeft class="h-4 w-4" />
                        </button>
                        <button
                            class="menu-button"
                            @click="editor.chain().focus().addColumnAfter().run()"
                            :disabled="!editor.can().addColumnAfter()"
                        >
                            <RiInsertColumnRight class="h-4 w-4" />
                        </button>
                        <button
                            class="menu-button"
                            @click="editor.chain().focus().deleteColumn().run()"
                            :disabled="!editor.can().deleteColumn()"
                        >
                            <RiDeleteColumn class="h-4 w-4" />
                        </button>
                        <button
                            class="menu-button"
                            @click="editor.chain().focus().addRowBefore().run()"
                            :disabled="!editor.can().addRowBefore()"
                        >
                            <RiInsertRowTop class="h-4 w-4" />
                        </button>
                        <button
                            class="menu-button"
                            @click="editor.chain().focus().addRowAfter().run()"
                            :disabled="!editor.can().addRowAfter()"
                        >
                            <RiInsertRowBottom class="h-4 w-4" />
                        </button>
                        <button
                            class="menu-button"
                            @click="editor.chain().focus().deleteRow().run()"
                            :disabled="!editor.can().deleteRow()"
                        >
                            <RiDeleteRow class="h-4 w-4" />
                        </button>
                        <button
                            class="menu-button"
                            @click="editor.chain().focus().mergeCells().run()"
                            :disabled="!editor.can().mergeCells()"
                        >
                            <RiMergeCellsHorizontal class="h-4 w-4" />
                        </button>
                        <button
                            class="menu-button"
                            @click="editor.chain().focus().splitCell().run()"
                            :disabled="!editor.can().splitCell()"
                        >
                            <RiSplitCellsHorizontal class="h-4 w-4" />
                        </button>
                        <button @click="editor.chain().focus().undo().run()" class="menu-button">
                        <RiArrowGoBackFill class="h-4 w-4" />
                        </button>
                        <button @click="editor.chain().focus().redo().run()" class="menu-button">
                        <RiArrowGoForwardFill class="h-4 w-4" />
                        </button>
                    </div>
                    <editor-content :editor="editor" /> 
                </div>
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:col-start-1 sm:text-sm" @click="sendRequestForMore()">Send Request</button>
              <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-2 sm:text-sm" @click="showRequestModal = false">Close</button>
          </div>
        </template>
    </RequisitionerMultiPurposeModalComponent>
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue'
import RequisitionerMultiPurposeModalComponent from "../requisitionerComponents/RequisitionerMultiPurposeModalComponent.vue";
import { useEditor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableHeader from "@tiptap/extension-table-header";
import TableCell from '@tiptap/extension-table-cell'
import TextAlign from "@tiptap/extension-text-align";
import Highlight from "@tiptap/extension-highlight";
import Underline from '@tiptap/extension-underline'
import {
  BIconTypeBold, BIconTypeItalic, BIconTypeStrikethrough,  BIconListOl,
  BIconListUl, BIconTypeUnderline, BIconTextRight, BIconTextLeft, BIconTextCenter,
  BIconJustify, BIconPen, BIconTable,
} from "bootstrap-icons-vue";
import {
  RiArrowGoBackFill, RiArrowGoForwardFill, RiInsertColumnLeft, RiInsertColumnRight,
  RiDeleteColumn, RiInsertRowBottom, RiInsertRowTop, RiDeleteRow, RiMergeCellsHorizontal,
  RiSplitCellsHorizontal,
} from 'vue-remix-icons';
import Api from "@/components/api/Api";
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import { Combobox, ComboboxButton, ComboboxInput,
  ComboboxOption, ComboboxOptions,
} from '@headlessui/vue'
import {  CheckIcon, SelectorIcon } from '@heroicons/vue/solid'

import  host  from '@/helpers/host.js'

export default {
  name: "BudgetholderBudgetLinesComponent",
  components: {
         RequisitionerMultiPurposeModalComponent,
         EditorContent,   BIconTypeBold,     BIconTypeItalic,     BIconTypeStrikethrough,
          BIconListOl,   BIconListUl,  BIconTypeUnderline,   BIconTextRight,  BIconTextLeft,
          BIconTextCenter,  BIconJustify,   BIconPen,    BIconTable,
          RiArrowGoBackFill,   RiArrowGoForwardFill,  RiInsertColumnLeft,  RiInsertColumnRight,
          RiDeleteColumn, RiInsertRowBottom,  RiInsertRowTop,  RiDeleteRow,  RiMergeCellsHorizontal,
          RiSplitCellsHorizontal,  AlertError,AlertSuccess,
          Combobox,     ComboboxButton,
          ComboboxInput,    ComboboxOption,     ComboboxOptions,
          SelectorIcon, CheckIcon,
          
    },
  setup(){

    const budgetLines = ref([])
    const file_url = host.url+'get-budget-line-usage-report';

    
    let loggedInUser =  inject("loggedInUser");
     const showRequestModal    = ref(false);
     const editor = useEditor({
      editorProps: {
          attributes: {
          class:
              "prose prose-sm text-sm" 
          },
      },
      extensions: [
          StarterKit,
          TextAlign.configure({
            types: ["heading", "paragraph"],
            alignments: ["left", "center", "right", "justify"],
          }),
          Highlight,
          Table.configure({
            resizable: true,
          }),
          TableRow,
          TableHeader,
          TableCell,
          Underline
          
      ],
      })
      let isLoading = ref(false);
      let errorMessage = ref("");
      let successMessage = ref("");
      let fromPage = ref(0)
      let toPage   = ref(0)
      let totalPageResults   = ref(0);
      let prevPageUrl = ref('#')
      let nextPageUrl = ref('#')
      let activePage = inject('activePage');
      activePage.value = 'My Budget Lines'
      let pageUrl = `/requisition/load-all-my-budget-line-details/${loggedInUser.value.id}`;

      const filter = ref("");
      const filteredBudgetLinesTable = computed(() => {
        return budgetLines.value.filter(
          (val) =>{
            const name = val.name.toString().toLowerCase();
            // const summary = val.summary.toString().toLowerCase();
            // const reason = val.purpose_of_requisition.toString().toLowerCase();
            
            return name.includes(filter.value.toLowerCase()) ;
            // || summary.includes(filter.value.toLowerCase()) 
            //         || reason.includes(filter.value.toLowerCase());
          }
        );
      });

       async function loadAllMyBudgetLines(url = pageUrl) {
            isLoading.value = true;

            try{

        
                const response = await  Api().get(url);

                if (response.data.status === "success") {

                    
                        successMessage.value     = response.data.message
                        budgetLines.value        = response.data.budgetLines.data;
                        nextPageUrl.value        = response.data.budgetLines.next_page_url
                        prevPageUrl.value        = response.data.budgetLines.prev_page_url
                        fromPage.value           = response.data.budgetLines.from
                        toPage.value             = response.data.budgetLines.to
                        totalPageResults.value   = response.data.budgetLines.total
                }

            }catch (err) {

                if (err.response) {

                errorMessage.value = err.response.data.message;

                    //return { 'message': err.response.data.message, 'status': 'error' };

                } else if (err.request) {

                    errorMessage.value = err.request.data.message

                    //return { 'message': err.request.data.message, 'status': 'error' };

                } else {

                    errorMessage.value = err.message

                // return { 'message': err.message, 'status': 'error' };

                }
            }

            isLoading.value = false;

       }
      const staticNewApprovalRoleId = 10;
      let selectedBudgetLine  =ref(null);
        const people = ref([
        { id: 0, user_id: 0, name: '' }
        ])
        const query = ref('')
        const selectedPerson = ref(people.value[0])
        const filteredPeople = computed(() =>
        query.value === ''
            ? people.value
            : people.value.filter((person) => {
                return person.name.toLowerCase().includes(query.value.toLowerCase())
            })
        )

       async function sendRequestForMore(){


        if(editor.value.getHTML().toString().trim() == '<p></p>'){

                errorMessage.value = "Reason cannot be empty"
                return;

        }

        let requestMessage =  editor.value.getHTML();

        let requestObj = {

            'requestMessage':requestMessage,
            'budget_line_name': selectedBudgetLine.value.name,
            'request_by_name': loggedInUser.value.name,
            'send_to_user_id': selectedPerson.value.user_id
           
        }


            try {

            const res = await Api().post("/requisition/send-request-for-supplementary", requestObj)

            if (res.data.status === "success") {

                successMessage.value  = res.data.message;
                showRequestModal.value = false
                //selectedBudgetLine.value = null;
                selectedPerson.value = { id: 0, user_id: 0, name: '' };
                editor.value.commands.clearContent();



            }else{

               errorMessage.value = res.data.message;
            }

          } catch (err) {

              if (err.response) {

                 if (err.response.status === 422) {

                    //

                  }else{

                      errorMessage.value = "An error has occurred! " + err.response.message;

                  }

              } else if (err.request) {

                 errorMessage.value =  err.request.data.message;

              } else {

                 errorMessage.value =  err.message;

              }
          }

       }
       async function loadApproverList(approval_level_role_id){

        try {

                const res = await Api().get(`/requisition/get-approvers/${approval_level_role_id}`);

                if (res.data.status === "success") {

                    people.value = res.data.approvers;

                }

            } catch (err) {

                if (err.response) {

                   //

                } else if (err.request) {

                   //

                } else {
                    
                     //

                }
            }


       }

     loadAllMyBudgetLines();
     loadApproverList(staticNewApprovalRoleId)

    return {

      budgetLines, showRequestModal, editor,  errorMessage, successMessage, isLoading, 
      fromPage, toPage, totalPageResults, prevPageUrl,nextPageUrl,pageUrl,
      filteredBudgetLinesTable,filter,loadAllMyBudgetLines, sendRequestForMore,
      query,selectedPerson,filteredPeople, selectedBudgetLine,
      file_url

    }
  }

}
</script>

<style>

</style>