import { ref } from "vue";
const successMessage = ref();
const errorMessage = ref();
const showToast = ref(false);

const success = (message) => {
  successMessage.value = message;
  showToast.value = true;
  setTimeout(()=>{
    showToast.value = false;
    successMessage.value = null;
  },3000)

}


const error = (message) => {
  errorMessage.value = message;
  showToast.value = true;
  setTimeout(()=>{
    showToast.value = false;
    errorMessage.value = null;
  },3000)
}



export default {error,successMessage,success,errorMessage,showToast}