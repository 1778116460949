<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="fixed inset-0 flex z-40 md:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
          <div class="relative flex-1 flex flex-col max-w-xs w-full bg-white">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <div class="flex-shrink-0 flex items-center px-4">
                <img class="h-24 w-auto" src="/JMS_LOGO2.webp" alt="Workflow" />
              </div>
              <nav class="mt-5 px-2 space-y-1">
                 <router-link :to="{name: item.href }" v-for="item in navigation" :key="item.name" @click="activePage = item.name; sidebarOpen = false;" 
                  :class="[activePage == item.name ? 'bg-red-900 text-white' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']">
                  <component :is="item.icon" :class="[activePage == item.name ? 'text-white' : 'text-gray-600 group-hover:text-gray-500', 'mr-4 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                  {{ item.name }}
                 </router-link>
                <div class="border-t border-gray-200 ">
                    <!-- <a href="#" :class="[ 'whitespace-nowrap text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-3 text-sm font-medium rounded-md']">
                        <component :is="CogIcon" :class="['text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                        Change Password
                    </a>
                    <a href="#" :class="[ ' text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-3 text-sm font-medium rounded-md']">
                        <component :is="LocationMarkerIcon" :class="['text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                        Update Location
                    </a> -->
                    <a href="#" @click.stop="backToErpMenu()" :class="[ ' text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-3 text-sm font-medium rounded-md']">
                        <component :is="ArrowLeftIcon" :class="['text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                        ERP Menu
                    </a>
                    <a href="#" @click.stop="logout()" :class="[ ' text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-3 text-sm font-medium rounded-md']">
                        <component :is="LogoutIcon" :class="['text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                        logout
                    </a>
                  </div>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14">
          <!-- Force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 ">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex-1 flex flex-col min-h-0 border-r border-gray-200">
        <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto bg-white">
          <div class="flex items-center flex-shrink-0 px-4">
            <img class="h-24 w-auto" src="/JMS_LOGO2.webp" alt="Workflow" />
          </div>
          <nav class="mt-5 flex-1 px-2 space-y-1 bg-white">
            <router-link :to="{name: item.href }" v-for="item in navigation" :key="item.name" @click="activePage = item.name" 
              :class="[activePage == item.name ? 'bg-red-900 text-white' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']">
              <component :is="item.icon" :class="[activePage == item.name  ? 'text-white' : 'text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
              {{ item.name }}
            </router-link>
             
            <div class="border-t border-gray-200 ">

               <!-- <a href="#" :class="[ ' text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-3 text-sm font-medium rounded-md']">
                  <component :is="CogIcon" :class="['text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                  Change Password
               </a>
               <a href="#" :class="[ ' text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-3 text-sm font-medium rounded-md']">
                  <component :is="LocationMarkerIcon" :class="['text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                  Update Location
               </a> -->
               <a href="#" @click.stop="backToErpMenu()" :class="[ ' text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-3 text-sm font-medium rounded-md']">
                  <component :is="ArrowLeftIcon" :class="['text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                  ERP Menu
               </a>
               <a href="#" @click.stop="logout()" :class="[ ' text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-3 text-sm font-medium rounded-md']">
                  <component :is="LogoutIcon" :class="['text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                  logout
               </a>
                
            </div>
          </nav>
        </div>
        
      </div>
    </div>
    <div class="md:pl-64 flex flex-col flex-1">
      <div class="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
        <button type="button" class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <MenuIcon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <main class="flex-1">
        <div class="py-3">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 class="text-2xl text-center font-semibold text-red-900">{{ activePage }}</h1>
          </div>
          <div class="max-w-full px-5 sm:px-6 md:px-8">
             <Loading v-model:active="isLoading" />
              <div class=" mt-8 flex justify-center items-center">
                  <AlertSuccess
                      v-if="successMessage"
                      :message="successMessage"
                      @hide="successMessage = null"
                  />
                  <AlertError
                      v-if="errorMessage"
                      :message="[errorMessage]"
                      @hide="errorMessage = null"
                  />
              </div>
            <!-- Replace with your content -->
            <div class="py-4">
                <router-view />
            </div>
            <!-- /End replace -->
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ref, provide } from 'vue'
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import {
  AdjustmentsIcon,
  UserGroupIcon,
  MenuIcon,
  XIcon,
  CalendarIcon
} from '@heroicons/vue/outline'
import { LogoutIcon, CogIcon, LocationMarkerIcon, ArrowLeftIcon} from '@heroicons/vue/solid'
import { useRouter } from "vue-router";
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import {logoutUser, getUser} from "@/stores/User.js"
import {getLocations} from "@/stores/Locations.js"
import {getDirectorates} from "@/stores/Directorates.js"
import Api from "@/components/api/Api";

export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    MenuIcon,
    XIcon,
    Menu, MenuButton, MenuItem, MenuItems,
    UserGroupIcon,AdjustmentsIcon,
    LogoutIcon, CogIcon, LocationMarkerIcon, 
    ArrowLeftIcon,
    AlertError,AlertSuccess
  },
  setup() {

    const router = useRouter();

    const sidebarOpen = ref(false)
    const activePage = ref('Users');

    const navigation = ref([
      { name: 'Users', href: 'UsersComponent', icon: UserGroupIcon, current: true },
      { name: 'Financial Years', href: 'FinancialYearComponent', icon: CalendarIcon, current: true },
      // { name: 'General Settings', href: 'GeneralSettingsComponent', icon: AdjustmentsIcon, current: false },
      // { name: 'Roles', href: 'RolesComponent', icon: UserCircleIcon, current: false },
    ])

    const roles = ref([
      {'name': "Requisitioner", 'active': true  },
      {'name':"Supervisor",  'active': false },
      {'name': "Procurement", 'active' : false},
      {'name': "Budget Holder", 'active': false},
      {'name': "Accountant", 'active': false}, 
      {'name': "Manager Accounts", 'active': false}, 
      {'name': "Director Finance", 'active': false},
      {'name': "Executive Director", 'active' : false}, 
      {'name': "Payments Office", 'active': false}
    ]);
    let isLoading = ref(false);
    let errorMessage = ref("");
    let successMessage = ref("");
    let requisitionRoles = ref([]);
    let tenderRoles         = ref([]);
    let meimsRoles      = ref([]);
    let fundraisingRoles      = ref([]);

    provide("getLocations", getLocations);
    provide("getDirectorates", getDirectorates);
    provide("getUser",getUser);
    provide('activePage',activePage)

    loadErpSystemRolesLists();

    provide("requisitionRoles", requisitionRoles);
    provide("tenderRoles", tenderRoles);
    provide("meimsRoles", meimsRoles);
    provide("fundraisingRoles", fundraisingRoles);
    

    

    async function loadErpSystemRolesLists(){

        try {

          const res = await Api().get(`/app/get-erp-system-roles-list`);

          if (res.data.status === "success") {

              requisitionRoles.value  = res.data.requisitionSystemModuleRoles;
              tenderRoles.value       = res.data.tenderSystemModuleRoles;
              meimsRoles.value    = res.data.meimsSystemModuleRoles;
              fundraisingRoles.value  = res.data.fundraisingSystemModuleRoles;
              
          }

        } catch (err) {

            if (err.response) {

                return { 'message': err.response.data.message, 'status': 'error' };

            } else if (err.request) {

                return { 'message': err.request.data.message, 'status': 'error' };

            } else {

                return { 'message': err.message, 'status': 'error' };

            }
        }


    }


    function backToErpMenu(){

       router.push({name:'ErpMenuView'});
    }

     async function logout() { 

       isLoading.value = true;

       const res = await logoutUser();

        if(res.status === "success"){

          window.location.href = '/?nocache=' + (new Date()).getTime();
       //   isLoading.value = false;
        
        }else{

          if(res.message){

            errorMessage.value = res.message;

          }else{
          
            errorMessage.value = "An error has occurred! Please try again";

          }

        }
     }

    return {
      navigation,
      sidebarOpen,
      LogoutIcon,ArrowLeftIcon,
      CogIcon, LocationMarkerIcon,activePage,
      roles,
      logout,backToErpMenu,
      errorMessage,
      successMessage,
      isLoading,
    }
  },
}
</script>

<style>

</style>