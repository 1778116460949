import { ref } from "vue";

// State
const tender       = ref(null);
const equipment    = ref(null);
const manufacturer = ref(null);

//Setters
export const setTender = async (tenderobj) => {

   tender.value = tenderobj

};

export const setEquipment = async (equipmentobj) => {

    equipment.value = equipmentobj

};

export const setManufacturer = async (manufacturerobj) => {

    manufacturer.value = manufacturerobj

};

// Getters
export const getTender = () => {

    return tender;
};

export const getEquipment = () => {

    return equipment;

};

export const getManufacturer = () => {

    return manufacturer;

};