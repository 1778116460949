<template>
<div>
  <Loading v-model:active="isLoading" />
  <div class=" mt-8 flex justify-center items-center">
    <AlertSuccess
        v-if="successMessage"
        :message="successMessage"
        @hide="successMessage = null"
    />
    <AlertError
        v-if="errorMessage"
        :message="[errorMessage]"
        @hide="errorMessage = null"
    />
  </div>
   <form class="space-y-8 divide-y divide-gray-200">
    <div class="space-y-8 divide-y divide-gray-200">
      <div class="pt-4">
        <div>
          <h3 class="text-lg leading-6 font-bold text-red-900">User Profile</h3>
          <p class="mt-1 text-xs text-gray-500">Only use an official Joint Medical Store email address</p>
        </div>
        <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-3">
            <label for="first-name" class="block text-sm font-medium text-red-900"> Full name </label>
            <div class="mt-1">
              <input type="text" ref="errorMessageFullnameRef" v-model="fullname" name="first-name" id="first-name" autocomplete="given-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="sm:col-span-3">
            <label for="email" class="block text-sm font-medium text-red-900"> Email address </label>
            <div class="mt-1">
              <input id="email" ref="errorMessageEmailRef" v-model="email" name="email" type="email" autocomplete="email" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="sm:col-span-3">
            <label for="password" class="block text-sm font-medium text-red-900"> Temporary Password </label>
            <div class="mt-1">
              <input id="temp_password" ref="errorMessageTemp_passwordRef" v-model="temp_password" name="temp_password" type="text" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

          <!-- <div class="sm:col-span-2">
            <label for="phoneNumber" class="block text-sm font-medium text-red-900"> Phone Number </label>
            <div class="mt-1">
              <input type="text" ref="errorMessagePhoneNumberRef" v-model="phoneNumber" name="phoneNumber" id="phoneNumber" autocomplete="tel" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="sm:col-span-1">
            <label for="gender" class="block text-sm font-medium text-red-900"> Gender </label>
            <div class="mt-1">
              <select id="gender" ref="errorMessageGenderRef" v-model="gender" name="gender" autocomplete="sex" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                <option value="0">select</option>
                <option value="NA">NA</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
          </div>
          <div class="sm:col-span-1">
            <label for="location" class="block text-sm font-medium text-red-900"> Location </label>
            <div class="mt-1">
              <select id="location" ref="errorMessageLocationRef" v-model="location" name="location" autocomplete="sex" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                <option value="0">select</option>
                <option v-for="(location,index) in locationsList" :key="index" :value="location.id">{{ location.location_name }}</option>
              </select>
            </div>
          </div> -->

          <div class="sm:col-span-3">
            <label for="directorate" class="block text-sm font-medium text-red-900"> Directorate </label>
            <div class="mt-1">
              <select id="directorate" ref="errorMessageDirectorateRef" v-model="directorate" name="directorate" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                <option value="0">select</option>
                <option v-for="(directorate,index) in directoratesList" :key="index" :value="directorate.id">{{ directorate.directorate_name }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-8">
        <div>
          <h3 class="text-lg leading-6 font-medium text-red-900">ERP Modules</h3>
          <p class="mt-1 text-xs text-gray-500">Select Modules User can access </p>
        </div>
        <div class="mt-6">
          <!-- <div class="lg:grid lg:grid-cols-10 gap-4 my-2"> -->
          <div class="lg:flex lg:flex-row lg:space-x-4 my-2">
            <!-- <legend class="text-base font-medium text-gray-900">By Email</legend> -->
            <div class="flex-none space-y-4 pb-4">
              <div class="relative flex items-start">
                <div class="flex items-center h-5">
                  <input v-model="checkedRequisition_system" @change="closeShowSystemRoles('Requisition System')"  id="requisition_system" name="requisition_system" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="requisition_system" class="font-medium text-red-900">Requisition System</label>
                   <a href="#" @click.prevent="checkedRequisition_system? showSystemRoles('Requisition System') : ''" class=" block text-teal-600 hover:text-teal-900">set user roles</a>
                </div>
              </div>
              <div class="relative flex items-start">
                <div class="flex items-center h-5">
                  <input v-model="checkedFundraising_system" @change="closeShowSystemRoles('Fundraising System')"  id="fundraising_system" name="fundraising_system" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="fundraising_system" class="font-medium text-red-900">Fundraising System</label>
                   <a href="#" @click.prevent="checkedFundraising_system? showSystemRoles('Fundraising System') : ''" class=" block text-teal-600 hover:text-teal-900">set user roles</a>
                </div>
              </div>
              <div class="relative flex items-start">
                <div class="flex items-center h-5">
                  <input v-model="checkedTender_system" @change="closeShowSystemRoles('Tender Management System')"  id="tender_system" name="tender_system" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="tender_system" class="font-medium text-red-900">Tender Management System</label>
                    <a href="#" @click.prevent="checkedTender_system? showSystemRoles('Tender Management System'): '' " class=" block text-teal-600 hover:text-teal-900">set user roles</a>
                </div>
              </div>
              <div class="relative flex items-start">
                <div class="flex items-center h-5">
                  <input v-model="checkedMeims_system" @change="closeShowSystemRoles('Meims System')"  id="meims_system" name="meims_system" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="meims_system" class="font-medium text-red-900">Meims System</label>
                    <a href="#" @click.prevent="checkedMeims_system? showSystemRoles('Meims System'): '' " class=" block text-teal-600 hover:text-teal-900">set user roles</a>
                </div>
              </div>
              <!-- <div class="relative flex items-start">
                <div class="flex items-center h-5">
                  <input v-model="" @change="closeShowSystemRoles('Meims System')" id="meims_system" name="meims_system" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="meims_system" class="font-medium text-red-900">Meims System</label>
                    <a href="#" @click.prevent="showSystemRoles('Meims System')" class=" block text-teal-600 hover:text-teal-900">set user roles</a>
                </div>
              </div> -->
              <!-- <div class="relative flex items-start">
                <div class="flex items-center h-5">
                  <input v-model="checkedUser_management" @change="closeShowSystemRoles('User Management System')" id="user_management" name="user_management" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="user_management" class="font-medium text-gray-700">User Management System</label>
                   <a href="#" @click.prevent="showSystemRoles('User Management System')" class=" block text-indigo-600 hover:text-indigo-900">set user roles</a>
                </div>
              </div> -->
            </div>
            <div v-if="systemRole" class="lg:w-3/5">
              <h5 class="pb-2 text-xs text-red-900 font-medium text-center"> {{ systemRole }} Roles</h5>
              <div class="mt-2 sm:mt-0 border border-1 border-teal-600 h-48 overflow-x-auto py-2 px-5">
                  <div>
                    <div class="mt-2 flex flex-none justify-center">
                      <a @click.prevent="addRole(systemRole)" class="cursor-pointer flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded-md text-white bg-red-900 hover:bg-red-600"> Add Role </a>
                      <a v-if="systemRole == 'Requisition System'" @click.prevent="addRole(systemRole)" class=" pointer-events-none flex justify-center items-center mx-4 px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                        total roles ({{ roles[0].length }})
                      </a>
                      <a v-else-if="systemRole == 'Tender Management System'" @click.prevent="addRole(systemRole)" class="pointer-events-none flex justify-center items-center mx-4 px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                          total roles ({{ roles[1].length }})
                      </a>
                      <a v-else-if="systemRole == 'Meims System'" @click.prevent="addRole(systemRole)" class="pointer-events-none flex justify-center items-center mx-4 px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                          total roles ({{ roles[2].length }})
                      </a>
                       <a v-else-if="systemRole == 'Fundraising System'" @click.prevent="addRole(systemRole)" class="pointer-events-none flex justify-center items-center mx-4 px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                          total roles ({{ roles[3].length }})
                      </a>
                    </div>
                    <div class="flow-root mt-6">
                      <ul v-if="systemRole == 'Requisition System'" role="list" class="-my-5 divide-y divide-gray-200">
                        <li v-for="(role, index) in roles[0]" :key="role.id" class="py-4">
                          <div class="flex items-center space-x-4">
                            <div class="flex-1">
                                <select v-model="role.role_id" name="role_system_select" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                                  <option value="">select role</option>
                                  <option v-for="(specific_role, index) in roles_for_specific_system" :key="index" :value="specific_role.id">
                                    {{ specific_role.role_name }}
                                  </option>
                                </select>
                              </div>
                              <div class="flex-1">
                                  <select v-model="role.directorate_id" name="directorate_system" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                                    <option value="">Select Directorate</option>
                                    <option v-for="(directorate,index) in directoratesList" :key="index" :value="directorate.id">{{ directorate.directorate_name }}</option>
                                  </select>
                                </div>
                            <div>
                              <button
                                  class="text-red-500"
                                  @click.prevent="deleteRole(index,role.id)"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    fill="currentColor"
                                    class="bi bi-x"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                                    />
                                  </svg>
                                </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                       <ul v-else-if="systemRole == 'Tender Management System'" role="list" class="-my-5 divide-y divide-gray-200">
                        <li v-for="(role, index) in roles[1]" :key="role.id" class="py-4">
                          <div class="flex items-center space-x-4">
                            <div class="flex-1">
                                <select v-model="role.role_id" name="role_system_select" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                                  <option value="">select role</option>
                                  <option v-for="(specific_role, index) in roles_for_specific_system" :key="index" :value="specific_role.id">
                                    {{ specific_role.role_name }}
                                  </option>
                                </select>
                              </div>
                              <div class="flex-1">
                                  <select v-model="role.directorate_id" name="directorate_system" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                                    <option value="">Select Directorate</option>
                                    <option v-for="(directorate,index) in directoratesList" :key="index" :value="directorate.id">{{ directorate.directorate_name }}</option>
                                  </select>
                                </div>
                            <div>
                              <button
                                  class="text-red-500"
                                  @click.prevent="deleteRole(index,role.id)"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    fill="currentColor"
                                    class="bi bi-x"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                                    />
                                  </svg>
                                </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                       <ul v-else-if="systemRole == 'Meims System'" role="list" class="-my-5 divide-y divide-gray-200">
                        <li v-for="(role, index) in roles[2]" :key="role.id" class="py-4">
                          <div class="flex items-center space-x-4">
                            <div class="flex-1">
                                <select v-model="role.role_id"  name="role_system_select" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                                  <option value="">select role</option>
                                  <option v-for="(specific_role, index) in roles_for_specific_system" :key="index" :value="specific_role.id">
                                    {{ specific_role.role_name }}
                                  </option>
                                </select>
                              </div>
                              <div class="flex-1">
                                  <select v-model="role.directorate_id" name="directorate_system" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                                    <option value="">Select Directorate</option>
                                    <option v-for="(directorate,index) in directoratesList" :key="index" :value="directorate.id">{{ directorate.directorate_name }}</option>
                                  </select>
                                </div>
                            <div>
                              <button
                                  class="text-red-500"
                                  @click.prevent="deleteRole(index,role.id)"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    fill="currentColor"
                                    class="bi bi-x"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                                    />
                                  </svg>
                                </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                       <ul v-else-if="systemRole == 'Fundraising System'" role="list" class="-my-5 divide-y divide-gray-200">
                        <li v-for="(role, index) in roles[3]" :key="role.id" class="py-4">
                          <div class="flex items-center space-x-4">
                            <div class="flex-1">
                                <select v-model="role.role_id" name="role_system_select" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                                  <option value="">select role</option>
                                  <option v-for="(specific_role, index) in roles_for_specific_system" :key="index" :value="specific_role.id">
                                    {{ specific_role.role_name }}
                                  </option>
                                </select>
                              </div>
                              <div class="flex-1">
                                  <select v-model="role.directorate_id" name="directorate_system" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                                    <option value="">Select Directorate</option>
                                    <option v-for="(directorate,index) in directoratesList" :key="index" :value="directorate.id">{{ directorate.directorate_name }}</option>
                                  </select>
                                </div>
                            <div>
                              <button
                                  class="text-red-500"
                                  @click.prevent="deleteRole(index,role.id)"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    fill="currentColor"
                                    class="bi bi-x"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                                    />
                                  </svg>
                                </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    
                  </div>
                
              </div>
            </div>
          </div>
          <fieldset class="mt-1">
            <div>
              <legend class="text-lg leading-6 font-medium text-red-900">ERP Role</legend>
              <p class="text-xs text-gray-500">User's Role in Erp</p>
            </div>
            <div class="mt-4 space-y-4">
              <div class="flex items-center">
                <input id="super_admin_role" v-model="erp_role_id" value="1" name="erp_role" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                <label for="super_admin_role" class="ml-3 block text-sm font-normal text-red-900"> Super Admin </label>
              </div>
              <div class="flex items-center">
                <input id="admin_role"  v-model="erp_role_id" value="2" name="erp_role" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                <label for="admin_role" class="ml-3 block text-sm font-normal text-red-900"> Admin </label>
              </div>
              <div class="flex items-center">
                <input id="user_role"  v-model="erp_role_id" value="3" name="erp_role" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                <label for="user_role" class="ml-3 block text-sm font-normal text-red-900"> User </label>
              </div>
             
            </div>
          </fieldset>
        </div>
      </div>
    </div>

    <div class="pt-5">
      <div class="flex justify-end">
        <!-- <button type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Cancel</button> -->
        <button type="button" @click.prevent="addUser()" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-900 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          {{ isEditingUser.isEditing? 'update' : 'save' }}
        </button>
      </div>
    </div>
  </form>
</div>
</template>

<script>
import { ref, inject, onMounted } from 'vue'
// import { XIcon } from '@heroicons/vue/solid'
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import Api from "@/components/api/Api";

export default {

  components: {
      AlertError, AlertSuccess
   
  },

  setup(){

    let systemRole = ref(null);

    const roles = ref([[],[],[],[]])
    
    let isLoading = ref(false);
    let errorMessage = ref("");
    let successMessage = ref("");

    let fullname        = ref("")
    let email           = ref('');
    let temp_password   = ref('');
    let phoneNumber     = ref('');
    let gender          = ref('0');
    let location        = ref('0');
    let directorate     = ref('0');
    let userFromStorage = JSON.parse(localStorage.getItem("user"));
    let added_by_user_id      = userFromStorage.id.toString();
    let erp_role_id     = ref(0)

    const errorMessageFullnameRef = ref(null)
    const errorMessageEmailRef = ref(null)
    const errorMessageTemp_passwordRef = ref(null)
    const errorMessagePhoneNumberRef = ref(null)
    const errorMessageGenderRef = ref(null)
    const errorMessageLocationRef = ref(null)
    const errorMessageDirectorateRef = ref(null)

    const getLocations        = inject('getLocations');
    const getDirectorates     = inject('getDirectorates');
    const requisitionRoles    = inject('requisitionRoles');
    const tenderRoles         = inject('tenderRoles');
    const meimsRoles          = inject('meimsRoles');
    const fundraisingRoles    = inject('fundraisingRoles');
    const isEditingUser       = inject('isEditingUser');

    //loadUser();
    async function loadUser(userObj){

      if(userObj.value.isEditing){

        isLoading.value = true;
        
          try {

           

            const res = await Api().get(`/users/get-erp-user/${userObj.value.userid}`);

            if (res.data.status === "success") {

              fullname.value        = res.data.user.name;
              email.value           = res.data.user.email;   
              phoneNumber.value     = res.data.user.phone_number;
              gender.value          = res.data.user.gender;
              location.value        = res.data.user.location_id;
              directorate.value     = res.data.user.directorate_id;
              erp_role_id.value     = res.data.user.erp_role_id;

              let editSystems  = res.data.systems;

              if( !(typeof editSystems.requisitions == 'undefined')){

                checkedRequisition_system.value = true;

                 roles.value[0] = editSystems.requisitions;
              }

               if( !(typeof editSystems.tenders == 'undefined')){

                checkedTender_system.value = true;
                roles.value[1] = editSystems.tenders;

               }

                if( !(typeof editSystems.meims == 'undefined')){

                  checkedMeims_system.value = true;
                  roles.value[2] = editSystems.meims;

                }

                if( !(typeof editSystems.fundraising == 'undefined')){

                  checkedFundraising_system.value = true;

                  roles.value[3] = editSystems.fundraising;
                }

            }

          } catch (err) {

              if (err.response) {

                  errorMessage.value = err.response.data.message;

              } else if (err.request) {


                errorMessage.value = err.request.data.message;
               
              } else {

                errorMessage.value = err.message;

              }
          }

         isLoading.value = false;
      }


    }
    

     onMounted(()=>{

       loadUser(isEditingUser);
     
     })


    const locationsList      = getLocations();
    const directoratesList   = getDirectorates();

    let checkedRequisition_system  = ref(false);
    let checkedTender_system       = ref(false);
    let checkedMeims_system    = ref(false);
    let checkedUser_management     = ref(false);
    let checkedFundraising_system  = ref(false);

  
    let roles_for_specific_system = ref([]);

    function showSystemRoles(showSystemRoles){

      systemRole.value = showSystemRoles;

      if(systemRole.value == 'Requisition System'){
        
        roles_for_specific_system.value = requisitionRoles.value;

      }else if(systemRole.value == 'Tender Management System'){

        roles_for_specific_system.value = tenderRoles.value;

      }else if(systemRole.value == 'Meims System'){

        roles_for_specific_system.value = meimsRoles.value;

      }else if(systemRole.value == 'Fundraising System'){

        roles_for_specific_system.value = fundraisingRoles.value;

      }
      
    }

    function closeShowSystemRoles(systemName){

      if(systemName == 'Requisition System'){


        if(!checkedRequisition_system.value &&  systemRole.value == systemName){

          systemRole.value = null;
          roles.value[0] = [];

        }

      }else if(systemName == 'Tender Management System'){

         if(!checkedTender_system.value &&  systemRole.value == systemName){

          systemRole.value = null;
          roles.value[1] = [];

         }


      }else if(systemName == 'Meims System'){

         if(!checkedMeims_system.value &&  systemRole.value == systemName){

          systemRole.value = null;
          roles.value[2] = [];
 
         }


      }else if(systemName == 'User Management System'){

         if(!checkedUser_management.value &&  systemRole.value == systemName){

          systemRole.value = null;

         }


      }else if(systemName == 'Fundraising System'){


        if(!checkedFundraising_system.value &&  systemRole.value == systemName){

          systemRole.value = null;
          roles.value[3] = [];

        }

      }
      

    }

     function addRole(systemRole_){

        if(systemRole_ == 'Requisition System'){

             roles.value[0].push({"id":"0","role_id": '', 'directorate_id' : ''})

        }else if(systemRole_ == 'Tender Management System'){

             roles.value[1].push({"id":"0","role_id": '', 'directorate_id' : ''})

        }else if(systemRole_ == 'Meims System'){

              roles.value[2].push({"id":"0","role_id": '', 'directorate_id' : ''})

        }else if(systemRole_ == 'Fundraising System'){

             roles.value[3].push({"id":"0","role_id": '', 'directorate_id' : ''})
        }

     }

     async function deleteRole(index, id = 0){



       if(id != '0'){

         isLoading.value = true;

         try {
          
            const res = await Api().delete(`/users/delete-erp-role/${id}`);

            if (res.data.status === "success") {

              successMessage.value = res.data.message;

            }


          } catch (err) {

              if (err.response) {

                 errorMessage.value =  err.response.data.message;

              } else if (err.request) {

                  errorMessage.value = err.request.data.message ;

              } else {

                 errorMessage.value = err.message;

              }
          }
         

       }


      if(systemRole.value == 'Requisition System'){

          roles.value[0].splice(index,1)

      }else if(systemRole.value == 'Tender Management System'){

            roles.value[1].splice(index,1)

      }else if(systemRole.value == 'Meims System'){

            roles.value[2].splice(index,1)

      }else if(systemRole.value == 'Fundraising System'){

          roles.value[3].splice(index,1)

      }


      isLoading.value = false;

     }
      
     async  function addUser() {

          errorMessage.value = '';
          successMessage.value = '';

          if(!fullname.value || (fullname.value.toString().trim() == '')){

              errorMessage.value = "Full Name cannot be empty";
              errorMessageFullnameRef.value.focus();
              window.scrollTo({ top: 0, behavior: "smooth" });
              return;
          }

          if(!email.value || email.value.toString().trim() == ''){

              errorMessage.value = "Email cannot be empty";
              errorMessageEmailRef.value.focus();
              window.scrollTo({ top: 0, behavior: "smooth" });
              return;

          }

          if((!temp_password.value || temp_password.value.toString().trim() == '' ) && (!isEditingUser.value.isEditing)){

              errorMessage.value = "Temp Password cannot be empty";
              errorMessageTemp_passwordRef.value.focus();
              window.scrollTo({ top: 0, behavior: "smooth" });
              return;

          }
          

          if(!phoneNumber.value || phoneNumber.value.toString().trim() == ''){


              phoneNumber.value = 'NA';

              // errorMessage.value = "Phone Number cannot be empty";
              // errorMessagePhoneNumberRef.value.focus();
              // window.scrollTo({ top: 0, behavior: "smooth" });
              // return;

          }

          if(gender.value == '0'){

             gender.value = 'NA';
              // errorMessage.value = "Gender cannot be empty";
              // errorMessageGenderRef.value.focus();
              // window.scrollTo({ top: 0, behavior: "smooth" });
              // return;

          }

          if(location.value == '0'){

             location.value = 1;

              // errorMessage.value = "Location cannot be empty";
              // errorMessageLocationRef.value.focus();
              // window.scrollTo({ top: 0, behavior: "smooth" });
              // return;

          }

          if(directorate.value == '0'){

              errorMessage.value = "Directorate cannot be empty";
              errorMessageDirectorateRef.value.focus();
              window.scrollTo({ top: 0, behavior: "smooth" });
              return;

          }

          if(erp_role_id.value == '0'){

                errorMessage.value = "ERP Role cannot be empty";
               // errorMessageDirectorateRef.value.focus();
               window.scrollTo({ top: 0, behavior: "smooth" });
                return;

          }


          if(checkedRequisition_system.value && roles.value[0].length == 0){

            errorMessage.value = "Please assign user atleast one role in requisition system";
            
            window.scrollTo({ top: 0, behavior: "smooth" });
            return;

          }

          if(checkedTender_system.value && roles.value[1].length == 0){

            errorMessage.value = "Please assign user atleast one role in tender management system";
            
            window.scrollTo({ top: 0, behavior: "smooth" });
            return;
          }

          if(checkedMeims_system.value && roles.value[2].length == 0){

            errorMessage.value = "Please assign user atleast one role in Meims system";
            
            window.scrollTo({ top: 0, behavior: "smooth" });
            return;

          }

          if(checkedFundraising_system.value && roles.value[3].length == 0){

            errorMessage.value = "Please assign user atleast one role in fundraising system";
            
            window.scrollTo({ top: 0, behavior: "smooth" });
            return;

          }


          isLoading.value = true;

          let  user = {
                  'fullname': fullname.value.trim(),
                  'email': email.value.trim(),
                  'temp_password': temp_password.value.trim(),
                  'phoneNumber': phoneNumber.value.trim(),
                  'gender': gender.value,
                  'location_id': location.value,
                  'directorate_id'    : directorate.value,
                  'erp_role_id'    : erp_role_id.value,
                  'roles'         :  roles.value,
                  'added_by_user_id'    : added_by_user_id,
                  'user_to_edit' : isEditingUser.value.userid
              };

          try {
            const res = await Api().post("/users/register", user)

            if (res.data.status === "success") {

                successMessage.value  = res.data.message;

              if(!isEditingUser.value.userid){
               
                 fullname.value  = '';
                 email.value  = '';
                 temp_password.value  = '';
                 phoneNumber.value  = '';
                 gender.value  = '0';
                 location.value  = '0';
                 directorate.value  = '0';
                 erp_role_id.value  = '0';
                 roles.value  = [[],[],[],[]];
                 checkedRequisition_system.value = false;
                 checkedTender_system.value      = false;
                 checkedMeims_system.value   = false;
                 checkedFundraising_system.value   = false;
                 systemRole.value = null;
              }

            }else{

               errorMessage.value = res.data.message;
            }

          } catch (err) {

              if (err.response) {

                 if (err.response.status === 422) {

                      if(err.response.data.errors.email){

                            errorMessage.value = err.response.data.errors.email[0];
                            errorMessageEmailRef.value.focus();
                          //  window.scrollTo({ top: 0, behavior: "smooth" });

                      }else if(err.response.data.errors.temp_password){

                          errorMessage.value = err.response.data.errors.temp_password[0];
                          errorMessageTemp_passwordRef.value.focus();
                         // window.scrollTo({ top: 0, behavior: "smooth" });

                      }

                  }else{

                      errorMessage.value = "An error has occurred! " + err.response.message;

                  }

              } else if (err.request) {

                 errorMessage.value =  err.request.data.message;

              } else {

                 errorMessage.value =  err.message;

              }
          }

           isLoading.value = false;
           window.scrollTo({ top: 0, behavior: "smooth" });
      }

    return {
      showSystemRoles, systemRole,  roles, addRole,deleteRole,
      errorMessage, successMessage, isLoading, 
      addUser,fullname,email,temp_password,phoneNumber,gender,location,directorate,
      errorMessageFullnameRef,errorMessageEmailRef,errorMessageTemp_passwordRef,errorMessagePhoneNumberRef,
      errorMessageGenderRef,errorMessageLocationRef,errorMessageDirectorateRef,
      locationsList, directoratesList,
      checkedRequisition_system, checkedTender_system, checkedMeims_system, checkedUser_management,
      closeShowSystemRoles,roles_for_specific_system, erp_role_id, isEditingUser,
      checkedFundraising_system
      
    }
  }

}
</script>

<style>

</style>