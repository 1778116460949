<template>
  <div class="mt-8">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <button @click.prevent="$router.back()" class="capitalize flex text-blue-500 hover:underline">
        <ArrowLeftIcon class="mr-2 h-6 w-6" />
        back
      </button>

      <div class="flex justify-center items-center">
        <h2 class="text-xl leading-6 font-medium text-gray-900">Edit Template</h2>
      </div>
    </div>
    <div class="mt-12 max-w-2xl mx-auto">
      <form  method="post" id="create-template"  v-if="selectedTemplate">
        <input  type="hidden" name="_method" value="PATCH">
        <input v-if="loggedInUser" v-model="createdById" type="hidden" name="created_by_id" >
        <div class="space-y-8 divide-y divide-gray-200">
          <div>

            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div class="sm:col-span-6">
                <label for="template_name" class="block text-sm font-medium text-gray-700">
                  Template Name
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input type="text"  v-model="templateName"  name="template_name" id="template_name" autocomplete="template_name" class=" px-3 flex-1 focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                </div>
              </div>

              <div class="sm:col-span-6">
                <label for="about" class="block text-sm font-medium text-gray-700">
                  Template fields
                </label>
                <div class="mt-1 border rounded-lg">
                  <div v-for="(field,index) in fields"  :key="index" class="grid grid-cols-6 gap-y-6 gap-x-4 p-3">
                    <div class="col-span-2">
                      <label for="username" class="block text-sm font-medium text-gray-700">
                        Field Type
                      </label>
                      <div class="mt-1 flex rounded-md shadow-sm">
                        <select name="field_type[]" v-model="field.type" id="field_type"  class="shadow-sm focus:ring-indigo-500  py-3 border px-2 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                          <option>Choose type</option>
                          <option v-for="(type,index) in types" :key="index" :value="type">{{type}}</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-span-3">
                      <label for="username" class="block text-sm font-medium text-gray-700">
                        Field Name
                      </label>
                      <div class="mt-1 flex rounded-md shadow-sm">
                        <input type="text" v-model="field.new_name"  name="field_name[]" id="field_name" autocomplete="field_name" class=" px-3 flex-1 focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                      </div>
                    </div>

                    <div class="col-span-1 flex justify-center items-center">
                      <button class="text-red-500" @click.prevent="deleteColumn(field.new_name,loggedInUser.id,index)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div class="flex justify-center"><button @click.prevent="addFields" class="text-blue-500 hover:bg-gray-50 my-5">Add Field</button></div>
                </div>

              </div>

              <div class="sm:col-span-6">
                <label for="project_id" class="block text-sm font-medium text-gray-700">
                  Project
                </label>
                <div class="mt-1">
                  <select v-model="projectId"  name="template_project_id"
                          class="shadow-sm focus:ring-indigo-500  py-3 border px-2 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                    <option>Choose Project </option>
                    <option v-for="project in projectList" :key="project.id" :value="project.id.toString()">{{project.project_name}}</option>
                  </select>
                </div>
              </div>


              <div class="flex justify-center sm:col-span-6">
                <button @click.prevent="update(loggedInUser.id)"  class="block bg-blue-600 hover:bg-blue-500 p-2 rounded-lg w-1/2 mx-auto text-white mt-4 text-center px-40">Save</button>

              </div>

            </div>
          </div>


        </div>
      </form>
    </div>
  </div>

</template>

<script>
import {ref,onMounted} from "vue";
import {getUser} from "@/stores/User.js";
import project from "@/stores/Fundraising/Project";
const types  = ['string','integer','date'];
import template from "@/stores/Fundraising/Template";
import {ArrowLeftIcon} from '@heroicons/vue/solid'
export default {
  components:{ArrowLeftIcon},
  setup(){
    const tags = ref([]);
    const {loadProjects,projectList} = project
    const loggedInUser = getUser();

    const {fields,addFields,templateName,projectId,createdById,selectedTemplate,update,deleteColumn} = template;

    onMounted(() => {
      if (selectedTemplate) {
        fields.value = [];
        for (let i = 0; i < selectedTemplate.value.template_fields.length; i++) {
          let t = selectedTemplate.value.template_fields[i];
          t['old_name'] = selectedTemplate.value.template_fields[i].name;
          t['new_name'] = selectedTemplate.value.template_fields[i].name;
          fields.value.push(t);
        }
        projectId.value = selectedTemplate.value.project_id;
        templateName.value = selectedTemplate.value.template_name;
      }
      if (loggedInUser.value){
        createdById.value = loggedInUser.value.id;
        loadProjects(loggedInUser.value.id);
      }


    });
    return {tags,loggedInUser,projectList,types,update,fields,
      addFields,templateName,projectId,createdById,selectedTemplate,deleteColumn}
  }
};
</script>

<style>
.vue-tags-input{
  width: 100%;
  max-width: none !important;
}
.vue-tags-input .ti-input {
  border: none;
}
</style>