<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="fixed inset-0 flex z-40 md:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
          <div class="relative flex-1 flex flex-col max-w-xs w-full bg-white">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <div class="flex-shrink-0 flex items-center px-4">
                <img class="h-24 w-auto" src="/JMS_LOGO2.webp" alt="Workflow" />
              </div>
              <nav class="mt-5 px-2 space-y-1">
                 <router-link :to="{name: item.href }" v-for="item in navigation" :key="item.name" @click="activePage = item.name; sidebarOpen = false;" 
                  :class="[activePage == item.name ? 'bg-red-900 text-white' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']">
                  <component :is="item.icon" :class="[activePage == item.name ? 'text-white' : 'text-gray-600 group-hover:text-gray-500', 'mr-4 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                  {{ item.name }}
                 </router-link>
                <div class="border-t border-gray-200 ">
                     <a href="#" @click="showModal = true" :class="[ ' text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-3 text-sm font-medium rounded-md']">
                        <component :is="CogIcon" :class="['text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                        Change Password
                    </a>
                    <a href="#" @click.stop="backToErpMenu()" :class="[ ' text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-3 text-sm font-medium rounded-md']">
                        <component :is="ArrowLeftIcon" :class="['text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                        ERP Menu
                    </a>
                    <a href="#" @click.stop="logout()" :class="[ ' text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-3 text-sm font-medium rounded-md']">
                        <component :is="LogoutIcon" :class="['text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                        logout
                    </a>
                  </div>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14">
          <!-- Force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 ">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex-1 flex flex-col min-h-0 border-r border-gray-200">
        <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto bg-white">
          <div class="flex items-center flex-shrink-0 px-4">
            <img class="h-24 w-auto" src="/JMS_LOGO2.webp" alt="Workflow" />
          </div>
          <nav class="mt-5 flex-1 px-2 space-y-1 bg-white">
            <router-link :to="{name: item.href }" v-for="item in navigation" :key="item.name" @click="activePage = item.name" 
              :class="[activePage == item.name ? 'bg-red-900 text-white' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']">
              <component :is="item.icon" :class="[activePage == item.name  ? 'text-white' : 'text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
              {{ item.name }}
            </router-link>
             
            <div class="border-t border-gray-200 ">
               <a href="#" @click.stop="backToErpMenu()" :class="[ ' text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-3 text-sm font-medium rounded-md']">
                  <component :is="ArrowLeftIcon" :class="['text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                  ERP Menu
               </a>
               <a href="#" @click.stop="logout()" :class="[ ' text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-3 text-sm font-medium rounded-md']">
                  <component :is="LogoutIcon" :class="['text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                  logout
               </a>
                
            </div>
          </nav>
        </div>
        
      </div>
    </div>
    <div class="md:pl-64 flex flex-col flex-1">
      <div class="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
        <button type="button" class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <MenuIcon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <main class="flex-1">
        <div class="py-3">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 class="text-2xl text-center font-semibold text-red-900">{{ activePage }}</h1>
          </div>
          <div class="max-w-full px-5 sm:px-6 md:px-8">
             <Loading v-model:active="isLoading" />
              <div class=" mt-8 flex justify-center items-center">
                  <AlertSuccess
                      v-if="successMessage"
                      :message="successMessage"
                      @hide="successMessage = null"
                  />
                  <AlertError
                      v-if="errorMessage"
                      :message="[errorMessage]"
                      @hide="errorMessage = null"
                  />
              </div>
            <!-- Replace with your content -->
            <div class="py-4">
                <router-view />
            </div>
            <!-- /End replace -->
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ref, provide,computed } from 'vue'
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import {
  AdjustmentsIcon,
  UserGroupIcon,
  MenuIcon,
  XIcon,
  HomeIcon,
  ViewGridIcon, CurrencyDollarIcon,
  TemplateIcon,DocumentDuplicateIcon,
} from '@heroicons/vue/outline'
import { LogoutIcon, CogIcon, LocationMarkerIcon, ArrowLeftIcon, CashIcon} from '@heroicons/vue/solid'
import { useRouter } from "vue-router";
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import {logoutUser, getUser} from "@/stores/User.js"
import Api from "@/components/api/Api";

export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    MenuIcon,
    XIcon,
    Menu, MenuButton, MenuItem, MenuItems,
    UserGroupIcon,AdjustmentsIcon,
    LogoutIcon, CogIcon, LocationMarkerIcon, 
    ArrowLeftIcon,
    AlertError,AlertSuccess,
    CashIcon,
    HomeIcon, ViewGridIcon, CurrencyDollarIcon,
    TemplateIcon,DocumentDuplicateIcon,

  },
  setup() {

    const router = useRouter();

    const sidebarOpen = ref(false)
    const activePage = ref('Home');

    const navigation = [
    { name: 'Uptime Report', href: 'UptimeReport', icon: DocumentDuplicateIcon, current: true },
    { name: 'Job Cards', href: 'JobcardReport', icon: ViewGridIcon, current: false },
    // { name: 'Donations', href: 'dashboard-donations', icon: CurrencyDollarIcon, current: false },
    // { name: 'Templates', href: 'dashboard-tables', icon: TemplateIcon, current: false },
    // { name: 'Resources', href: 'dashboard-resources', icon: DocumentDuplicateIcon, current: false },
    // { name: 'Collections', href: 'dashboard-collections', icon: CashIcon, current: false },
    ]

   
    let isLoading = ref(false);
    let errorMessage = ref("");
    let successMessage = ref("");
    let loggedInUser = getUser();
    
    provide("loggedInUser",loggedInUser);
    provide('activePage',activePage)


    let showModal = ref(false);
    let old_password = ref('')
    let new_password = ref('')
    let confirm_password = ref('')

    

    function backToErpMenu(){

       router.push({name:'ErpMenuView'});
    }

     async function logout() { 

       isLoading.value = true;

       const res = await logoutUser();

        if(res.status === "success"){

          window.location.href = '/?nocache=' + (new Date()).getTime();
       //   isLoading.value = false;
        
        }else{

          if(res.message){

            errorMessage.value = res.message;

          }else{
          
            errorMessage.value = "An error has occurred! Please try again";

          }

        }
     }

    async function updatePassword(){

        errorMessage.value = '';
        successMessage.value = '';

            if((!old_password.value || old_password.value.toString().trim() == '' )){

                errorMessage.value = "Old Password cannot be empty";
                return;

            }

            if((!new_password.value || new_password.value.toString().trim() == '' )){

                errorMessage.value = "New Password cannot be empty";
                return;

            }
            if((new_password.value && new_password.value.toString().trim().length < 8 )){

                errorMessage.value = "New Password must be more than 8 characters long";
                return;

            }

            if((!confirm_password.value || confirm_password.value.toString().trim() == '' )){

                errorMessage.value = "Confirm Password cannot be empty";
                return;

            }

            if((confirm_password.value.toString().trim() !==  new_password.value.toString().trim() )){

                errorMessage.value = "New Password and Confirm Password must match";
                return;

            }

            
            isLoading.value = true;

            let  user = {
                    'old_password': old_password.value.trim(),
                    'new_password': new_password.value.trim(),
                    'confirm_password': confirm_password.value.trim(),
                    'user_to_edit' : loggedInUser.value.id
                };

            try {
                const res = await Api().post("/users/update-password", user)

                if (res.data.status === "success") {

                    successMessage.value  = res.data.message;

                    old_password.value = '';
                    new_password.value = '';
                    confirm_password.value = '';
                    showModal.value = false;
                

                }else{

                errorMessage.value = res.data.message;
                }

            } catch (err) {

                if (err.response) {

                        errorMessage.value = "An error has occurred! " + err.response.message;

                } else if (err.request) {

                    errorMessage.value =  err.request.data.message;

                } else {

                    errorMessage.value =  err.message;

                }
            }

            isLoading.value = false;


    }

    let zxcvbn = require('zxcvbn');

     const passwordStrength = computed(() => {

         let result = zxcvbn( new_password.value )
       return result.score;
     });

    return {
      navigation,
      sidebarOpen,
      LogoutIcon,ArrowLeftIcon,
      CogIcon, LocationMarkerIcon,activePage,
      logout,backToErpMenu,
      errorMessage,
      successMessage,
      isLoading,
      showModal,
      old_password, new_password, confirm_password, updatePassword, passwordStrength
    }
  },
}
</script>

<style>

</style>