<template>
  <div class="mt-2">
       <Loading v-model:active="isLoading" />
    <div class="mt-2 max-w-2xl mx-auto">
      <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <button
          @click.prevent="
            $router.push({name: 'ManufacturerView', params: { 'id' : equipment_id, equipment_name, quantity, manufacturer_id }})
          "
          class="capitalize flex text-blue-500 hover:underline"
        >
          <ArrowLeftIcon class="mr-2 h-6 w-6" />
          back
        </button>
        <div class="flex justify-center items-center space-y-4 flex-col">
              <AlertSuccess
                v-if="successMessage"
                :message="successMessage"
                @hide="successMessage = null"
              />
              <AlertError
                v-if="errorMessage"
                :message="[errorMessage]"
                @hide="errorMessage = null"
              />
        </div>
        <div class="flex justify-center items-center">
          <h2 class="text-xl leading-6 font-medium text-gray-900">
           <span class="text-blue-400"> {{ manufacturer_name }} </span> Inquiries
          </h2>
        </div>
        <div class="flex justify-center items-center">
          <h3 class="text-xl pt-3 leading-6 font-extrabold text-blue-400">
            <span class="text-red-900"> {{ equipment_name }} </span>
          </h3>
        </div>
      </div>
    </div>
    <!-- component -->
      <div>
        <!-- <div class="w-full h-32" style="background-color: #449388"></div> -->

        <div class="container max-w-6xl mx-auto" >
            <div class="py-6" style="height: 600px;">
                <div class="flex rounded h-full">

                    <!-- Left -->
                    <div class="w-1/3 border flex flex-col">

                        <!-- Header -->
                        <div class=" px-3 bg-grey-lighter flex flex-row justify-between items-center">
                            
                        </div>

                        <!-- Search -->
                        <div class="py-2 px-2 bg-grey-lightest">
                                <!-- <input type="search" id="search" name="search" v-model="filter"  class="border-gray-200 focus:ring-0 w-full px-2 py-2 text-sm" placeholder="Type Here to Search "/> -->
                        </div>

                        <!-- Contacts -->
                        <div class="bg-grey-lighter flex-1 overflow-auto break-all">
                            
                            <div v-for="(inquiry, index) in inquiries" :key="index" :class="activeIndex == index? 'bg-gray-200' :'bg-white'" :style="index == selected_index? '--tw-bg-opacity: 1; background-color: rgb(229 231 235 / var(--tw-bg-opacity));': ''"  class=" px-3 flex items-center hover:bg-gray-100 cursor-pointer">
                                
                                <div  @click="displayMessage(inquiries[index]['inquiries'],inquiry.spec_id,index)" class="ml-4 flex-1 border-b border-grey-lighter py-4">
                                    <div class="flex items-bottom justify-between">
                                        <p class="text-grey-darkest">
                                            {{ inquiry.spec_name }}
                                        </p>
                                    </div>
                                    
                                </div>
                            </div>
                            
                            
                        </div>

                    </div>


                    <!-- Right -->
                    <div class="w-2/3 border flex flex-col">
                        <!-- Messages -->
                        <div class="flex-1 overflow-auto bg-gray-200" >
                            <div class="py-2 px-3">

                                <div v-for="(msg, index_) in messages" :key="index_" >
                                    <div v-if="index_ == 0" class="flex justify-center mb-2">
                                        <div class="rounded py-2 px-4" style="background-color: #DDECF2">
                                            <p class="text-sm uppercase">
                                                 {{ msg.date? new Date(msg.date).toDateString(): '' }}
                                            </p>
                                        </div>
                                    </div>
         
                                    <div v-if="msg.reply_by_user_id" class="flex mb-2">
                                        <div  class="rounded py-2 px-3" style="background-color: #F2F2F2">
                                            <p class="text-sm text-teal font-bold">
                                                {{ msg.username }}
                                            </p>
                                            <p class="text-sm mt-1">
                                                {{ msg.inquiry }}
                                            </p>
                                            <p class="text-right text-xs text-grey-dark mt-1">
                                                {{ msg.timestamp? new Date(msg.timestamp).toLocaleTimeString() : '' }}
                                            </p>
                                        </div>
                                    </div>
                                    <div v-else-if="msg.reply_by_manufacturer_id" class="flex justify-end mb-2">
                                        <div class="rounded py-2 px-3" style="background-color: #E2F7CB">
                                            <p class="text-sm text-teal font-bold">
                                                 {{ msg.manufacturer_name }}
                                            </p>
                                            <p class="text-sm mt-1">
                                                 {{ msg.inquiry }}
                                            </p>
                                            <p class="text-right text-xs text-grey-dark mt-1">
                                               {{ msg.timestamp? new Date(msg.timestamp).toLocaleTimeString() : '' }}
                                            </p>
                                        </div>
                                    </div>

                                    <div v-if="messages[index_+1] && messages[index_].date != messages[index_+1].date" class="flex justify-center mb-2">
                                        <div class="rounded py-2 px-4" style="background-color: #DDECF2">
                                            <p class="text-sm uppercase">
                                                 {{ messages[index_+1].date? new Date(messages[index_+1].date).toDateString(): '' }}
                                            </p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <!-- Input -->
                        <div class="bg-grey-lighter px-4 py-4 flex items-center">
                            <div class="flex-1 mx-2">
                                  <textarea id="chat" v-model="chat_message" rows="3" class="block mx-2 px-2 py-2 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Your message..."></textarea>

                                <!-- <input class="w-full border rounded px-2 py-2" type="text"/> -->
                            </div>
                            <div>
                                <button  type="submit" :disabled="chat_message == null || chat_message.trim() == ''"  @click.prevent="makeInquiry" class="inline-flex justify-center p-2 text-blue-600 rounded-full cursor-pointer hover:bg-blue-100 dark:text-blue-500 dark:hover:bg-gray-600">
                                    <svg aria-hidden="true" class="w-6 h-6 rotate-90" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path></svg>
                                    <span class="sr-only">Send message</span>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
      </div>
    
  </div>
</template>

<script>
import { ArrowLeftIcon } from "@heroicons/vue/solid";
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import Api from "@/components/api/Api";
import { ref, computed } from "vue";
import { getManufacturerID, getEquipment, getSelectedIndex, getManufacturerName } from "@/stores/Tender/Manufacturer.js" 

export default {
  name: "ManufacturerViewInquiries",
  components: {  ArrowLeftIcon, AlertSuccess, AlertError},
  setup() {

    let errorMessage = ref("");
    let successMessage = ref("");
   // let tender_id  = ref("");
    let equipment_id = ref("");
    let equipment_name = ref("");
  //  let tender_name  = ref("");
    let manufacturer_name  = ref("");
    let manufacturer_id  = ref("");
    let isLoading  = ref(false);
    let quantity  = ref("");
    let inquiries = ref([]);
    let messages = ref([]);
    let selected_index = ref(null);
    let chat_message = ref('');
    let specification_id = ref('');
    const filter = ref('');

    let activeIndex = ref(null);
    let selectedEquipment = getEquipment();
    equipment_id.value     = selectedEquipment.value.id;
    equipment_name.value   = selectedEquipment.value.name;
    quantity.value         = selectedEquipment.value.quantity;
   // tender_id.value            = route.params.tender_id;
   // tender_name.value          = route.params.tender_name;
    manufacturer_name.value    =  getManufacturerName();
    manufacturer_id.value      =  getManufacturerID();
    selected_index.value       =  getSelectedIndex();

    const getInquiriesList = computed(()=>{

            return inquiries.value.filter((val)=>val.spec_name.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1)

   });
   
    function loadInquiries(){

        isLoading.value = true;

              Api()
                .get(`/equipment/get-inquiries/${manufacturer_id.value}/${equipment_id.value}`)
                .then((response) => {
                  //Code for handling the response
                  //add attribute editTeamName so that you can toggle showDetails row
                  let status = response.data.status;
                  //let message = response.data.message;
                  if (status === "success") {

                    inquiries.value = response.data.inquiries;
                    
                    if(selected_index.value || (selected_index.value == 0)){
                     
                       displayMessageSetup(inquiries.value[selected_index.value]['inquiries'],inquiries.value[selected_index.value]['spec_id']);

                    }
                    if(activeIndex.value || (activeIndex.value == 0)){

                      displayMessageSetup(inquiries.value[activeIndex.value]['inquiries'],inquiries.value[activeIndex.value]['spec_id']);
                    }

                  } else {
                   
                    //equipmentList.value = null;
                    
                  }
                })
                .catch((error) => {
                  errorMessage.value = "An error has occurred! " +error.message;
                })
                .finally(() => {
                  isLoading.value = false;
                 // loader.hide();
                });

    }

    function displayMessage(message_array, spec_id, active_index){

        messages.value = message_array;
        specification_id.value = spec_id;

        selected_index.value = null;  
        activeIndex.value = active_index;

       
    }

    function displayMessageSetup(message_array,spec_id){

        messages.value = message_array;
        specification_id.value = spec_id;
       
       
    }

    function makeInquiry(){

         isLoading.value = true;

        let data = {

            'manufacturer_id': manufacturer_id.value,
            'specification_id': specification_id.value,
            'inquiry_text': chat_message.value.trim(),
            'reply_by_manufacturer_id': manufacturer_id.value,
            'reply_by_user_id': null
        }



        Api()
            .post("/equipment/make-inquiry", data)
            .then((response) => {
                let message = response.data.message;

                let status = response.data.status;

                if (status === "error") {

                    errorMessage.value = message; 
                   isLoading.value = false;

                } else if (status === "success") {

                    successMessage.value  = message;
                    isLoading.value = false;

                    loadInquiries();
                   
                  //  router.push({ path: `/manufacturer/list/${manufacturer_id.value}` });  
                }
            })
            .catch((error) => {

              errorMessage.value = "An error has occured! Please try again" + error.message;
              isLoading.value = false;

            });

        chat_message.value = '';

    }


    loadInquiries();

    return {

        errorMessage,
        successMessage,
       // tender_id,
        equipment_id,
        equipment_name,
       // tender_name,
        manufacturer_name,
        isLoading,
        quantity,
        manufacturer_id,
        inquiries,
        messages,
        displayMessage,
        selected_index,
        makeInquiry,
        chat_message,
        specification_id,
        activeIndex,
        getInquiriesList,
        filter
        

    }
  }


}
</script>

<style>

</style>