import { ref } from "vue";
import Api from "@/components/api/Api";
// State
const token    = ref(null);
const user     = ref(JSON.parse(localStorage.getItem("user")));
const systems  = ref(JSON.parse(localStorage.getItem("systems")));
const loggedIn = ref(false)

export const initUser = async (user) => {

    try {

        const res = await Api().post("/login", user)

        if (res.data.status === "success") {

            token.value   = res.data.token;
            user.value    = res.data.user
            systems.value = res.data.systems
            localStorage.setItem("token", token.value);
            localStorage.setItem("user", JSON.stringify(user.value));
            localStorage.setItem("systems", JSON.stringify(systems.value));
            loggedIn.value = true;

            return { 'message': res.data.message, 'status': res.data.status };

        }else{

            return {'message': res.data.message, 'status': res.data.status};
        }

    } catch (err) {

        if (err.response) {

            return { 'message': err.response.data.message, 'status': 'error' };

            //return err.response.data.message

        } else if (err.request) {

            return { 'message': err.request.data.message, 'status': 'error' };

        } else {

            return { 'message': err.message, 'status': 'error' };

        }
    }

};

// Getters
export const getUser = () => {
    
    if(!user.value){

        user.value = JSON.parse(localStorage.getItem("user"));

    }

    return user;
};
export const getUserSytems = () => {
    // locations.value = JSON.parse(localStorage.getItem('locations'));
    if (!systems.value) {

        systems.value = JSON.parse(localStorage.getItem("systems"));

    }

    return systems;
};

export const logoutUser = async () => {

    try {

        const res = await Api().post("/logout")

        if (res.data.status === "success") {

            localStorage.removeItem("token");
            localStorage.removeItem("user");
            localStorage.removeItem("systems");
            localStorage.removeItem("locations");
            localStorage.removeItem("directorates");
            localStorage.removeItem("requisitionRoles");
            localStorage.removeItem("current_active_user_role");

            loggedIn.value = false;

            return { 'message': res.data.message, 'status': res.data.status };

        } else {

            return { 'message': res.data.message, 'status': res.data.status };
        }

    } catch (err) {

        if (err.response) {

            return { 'message': err.response.data.message, 'status': 'error' };

            //return err.response.data.message

        } else if (err.request) {

            return { 'message': err.request.data.message, 'status': 'error' };

        } else {

            return { 'message': err.message, 'status': 'error' };

        }
    }

};

export const updateUserPasswordUpdate = () => {

    user.value.update_password = 0;
    localStorage.setItem("user", JSON.stringify(user.value));

};

