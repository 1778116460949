<template>
  <div>
    <Loading v-model:active="isLoading" />
    <div class=" mt-2 mb-2 flex justify-center items-center">
        <AlertSuccess
            v-if="successMessage"
            :message="successMessage"
            @hide="successMessage = null"
        />
        <AlertError
            v-if="errorMessage"
            :message="[errorMessage]"
            @hide="errorMessage = null"
        />
    </div>
     <!-- div section heading -->
    <div class="pb-5 border-b border-gray-200">
        <h3 class="text-center md:text-left text-lg leading-6 font-bold text-red-900">Accountability Records: 
            <span  v-if="status == '0'" class="text-teal-400 font-normal italic"> Pending   </span>
            <span  v-if="status =='1'"  class="text-green-400 font-normal italic"> Approved </span>
            <span  v-if="status == '2'" class="text-amber-500 font-normal italic"> Sent Back </span>
            <span  v-if="status == '3'" class="text-red-400 font-normal italic"> Rejected  </span>
        </h3>
       
    </div> 
    <!-- end of div section heading -->
    <div>
        <dl class="pt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        <div v-for="item in stats" @click="getRequisitions(pageUrl,'',item.status)" :key="item.name" :class="item.class" class="cursor-pointer px-4 py-5 sm:p-6 overflow-hidden">
            <dt class="text-sm font-medium text-white truncate">
            {{ item.name }}
            </dt>
            <dd class="mt-1 text-3xl font-semibold text-white">
            {{ item.stat }}
            </dd>
        </div>
        </dl>
    </div>
    <div class="grid grid-rows-1 sm:flex sm:justify-between sm:items-center py-5">
        <div class="relative flex">
            <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
            </div>
            <input type="text" v-model="filter" id="table-search" class="block flex-1 p-2 pl-10 sm:w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items">
        </div>
    </div>
    <div class="flex flex-col">
        <div class="-my-2 sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
                    <table class="w-full divide-y divide-gray-200">
                        <thead class="bg-teal-700">
                            <tr>
                                <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-white capitalize tracking-wider">Id Number</th>
                                <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-white capitalize tracking-wider">Requisition Description</th>
                                <th scope="col" class="px-3 py-3 text-left text-xs whitespace-nowrap font-medium text-white capitalize tracking-wider">Accountable Amount</th>
                                <th scope="col" class="relative px-3 py-3">
                                <span class="sr-only">view</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="(accountability,index) in filteredAccountabilitiesTable" :key="index">
                                <td class="px-3 py-4  text-xs text-gray-500">
                                {{ accountability.requisition_name }}
                                </td>
                                <td class="px-3 py-4 break-words text-xs text-gray-500">
                                {{ accountability.summary }} 
                                </td>
                                <td class="px-3 py-4 whitespace-nowrap text-xs text-gray-500">
                                {{ Number(accountability.money_accountable).toLocaleString() }}
                                </td>
                                <td class="px-3 py-4 whitespace-nowrap text-right text-xs font-medium">
                                <a href="#" 
                                    @click="successMessage =null; errorMessage = null; selectedAccountability = accountability.accountability_id; selectedAccountabilityApprovalLevel = accountability.approval_level_role_id; 
                                      selectedAccountabilityStatus = accountability.transition_status;  showTransitionStatuses(accountability.requisition_id)" 
                                    class="cursor-pointer bg-green-600 text-white py-1 px-2 rounded-md text-xs  hover:bg-green-900 shadow-md">
                                    view
                                </a>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="5">
                                    <nav class="bg-white px-4 py-3 flex items-center justify-between sm:px-6" aria-label="Pagination">
                                        <div class="hidden sm:block">
                                        <p class="text-sm text-gray-700">
                                            Showing
                                            {{ ' ' }}
                                            <span class="font-medium">{{ fromPage }}</span>
                                            {{ ' ' }}
                                            to
                                            {{ ' ' }}
                                            <span class="font-medium">{{ toPage }}</span>
                                            {{ ' ' }}
                                            of
                                            {{ ' ' }}
                                            <span class="font-medium">{{ totalPageResults }}</span>
                                            {{ ' ' }}
                                            results
                                        </p>
                                        </div>
                                        <!-- <div class="flex-1 flex justify-between sm:justify-end"> -->
                                        <div class="flex-1 flex justify-start sm:justify-end">
                                          <a :class="prevPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="getRequisitions( pageUrl,prevPageUrl,status)"  class="relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Previous </a>
                                          <a :class="nextPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="getRequisitions( pageUrl,nextPageUrl,status)" class="ml-3 cursor-pointer relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Next </a>
                                        </div>
                                       
                                    </nav>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <RequisitionerAccountabilityModalComponent :requisition="requisitionProp" :show="showAccountabilityModal" @close="resetValues(); showAccountabilityModal = false">
        <template v-slot:body>
            <div class="sm:col-span-3">
              <dt class="text-sm font-bold text-red-900">Voucher Number</dt>
              <dd class="mt-1 text-sm text-gray-900">{{ voucher_number }}  </dd>
            </div>
            <div class="sm:col-span-3">
                <div :class="printpdf?'items-center justify-center': ''" class="flex flex-col ">
                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div class="shadow overflow-hidden ">
                                <table class="min-w-full divide-y divide-gray-200 border border-slate-300 ">
                                    <thead class="bg-teal-700">
                                        <tr>
                                            <th colspan="5" scope="col" class="px-6 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">[ Accountability Amount Composition ]</th>
                                        </tr>
                                    </thead>
                                    <tbody class="bg-white divide-y divide-gray-200">
                                        <tr >
                                            <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300">
                                                
                                             <span class="font-bold"> {{ accountability_name }} </span>
                                            </td>
                                            <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300 whitespace-nowrap">
                                                <!-- <div class="text-xs text-gray-500">Money Utilized (UGX)</div>
                                                <div class="text-xs text-gray-500">1,750,200.00</div> -->
                                               <span class="font-bold"> Money Utilized (UGX):</span> <br/>  {{ Number(accountability_money_utilized).toLocaleString() }}
                                            </td>
                                            <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300 whitespace-nowrap">
                                             <span class="font-bold">Money Returned : UGX </span> <br/> {{ Number(accountability_money_returned).toLocaleString() }}
                                            </td>
                                            <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300 whitespace-nowrap">
                                              <span class="font-bold">Balance Claimed : UGX</span> <br/> {{ Number(accountability_balance_claimed).toLocaleString() }}
                                            </td>                            
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <div class="sm:col-span-3">
               <dt class="text-sm font-bold text-red-900">Accoutability Description</dt>
               <dd class="mt-1 text-sm text-gray-900" v-html="accountability_description" ></dd>
            </div>
            <div class="sm:col-span-2" data-html2canvas-ignore="true">
                <dt class="text-sm font-bold text-red-900">Attachments</dt>
                <dd class="mt-1 text-sm text-gray-900">
                    <ul role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li  v-for="(attachment,index) in attachments" :key="index" class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                        <div class="w-0 flex-1 flex items-center">
                        <PaperClipIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                        <span class="ml-2 flex-1 w-0 truncate"> {{ attachment.file_name }}</span>
                        </div>
                        <div class="ml-4 flex-shrink-0">
                        <a  :href="`${file_url}/${attachment.id}`" class="cursor-pointer font-medium text-indigo-600 hover:text-indigo-500"> Download </a>
                        </div>
                    </li>
                    </ul>
                </dd>
            </div>
        </template>
        <template v-slot:footer>
            <div class="my-5">
              <p class="text-sm font-bold text-red-900">Transition Status</p>
            </div>
            <div class="flex flex-col mx-10">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-teal-700">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white capitilize tracking-wider">Level</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white capitilize tracking-wider">Comment</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white capitalize tracking-wider">Status</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white capitalize tracking-wider">Created At</th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for=" transition in accountabilityTransitions" :key="transition.id" >
                                    <td class="px-6 py-4 text-xs text-gray-500">
                                        {{ transition.role_name + '( ' +transition.name + ' )' }}	
                                    </td>
                                    <td class="px-6 py-4 text-xs text-gray-500">
                                        {{ transition.comment }}
                                    </td>
                                    <td class="px-6 py-4 text-xs text-gray-500">
                                        {{ transition.status }}
                                    </td>
                                    <td class="px-6 py-4 text-xs text-gray-500">
                                        {{ transition.status_date }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            </div>
            <div class="pt-3 mt-2">
            <AlertError
                v-if="errorMessage"
                :message="[errorMessage]"
                @hide="errorMessage = null"
             />
            </div>
            <div v-if="(selectedAccountabilityApprovalLevel == staticOldApprovalRoleId && selectedAccountabilityStatus == '0')" class=" sm:mt-4 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <div class="w-full pl-3 pb-2 my-3 sm:col-span-1 rounded border border-gray-200 dark:border-gray-700">
                    <label for="actionToBeTaken" class="block text-sm font-medium text-red-900 sm:mt-px pt-2">Action to be Taken </label>
                    <div class="mt-1 px-3">
                        <select v-model="actionToBeTaken" id="actionToBeTaken" name="actionToBeTaken" class="block focus:ring-indigo-500 focus:border-indigo-500 shadow-sm w-full sm:text-sm border-gray-300 rounded-md">
                            <option value="0">Make a choice</option>
                            <option value="1">Approve</option>
                            <option value="3">Reject</option>
                        </select>
                    </div>
                </div>
            </div>
             <div v-if="selectedAccountabilityApprovalLevel == staticOldApprovalRoleId && selectedAccountabilityStatus == '0'" class="mt-1">
                    <label for="about" class="block text-sm font-medium text-red-900 sm:mt-px lg:pt-2"> Accountability Comments </label>
                    <div class="mt-2 col-span-1">
                        <textarea  maxlength="300" v-model="comment"  id="about" name="summary" rows="5" class=" shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md" />
                    </div>
            </div>
            <div data-html2canvas-ignore="true" class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button type="button" class="w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 border-gray-300  text-base font-medium text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm" @click="resetValues(); showAccountabilityModal = false">Close</button>
              <button v-if="selectedAccountabilityApprovalLevel == staticOldApprovalRoleId && selectedAccountabilityStatus != '0'"  type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:col-start-1 sm:text-sm" @click="downloadRequisitionPdf()">Download</button>

              <button v-if="actionToBeTaken == 1"  type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:col-start-1 sm:text-sm" @click="accountabilityAction(selectedAccountability)">Approve & Forward</button>
              <button v-if="actionToBeTaken == 3"  type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:col-start-1 sm:text-sm" @click="accountabilityAction(selectedAccountability)">Reject</button>

            </div>
        </template>
    </RequisitionerAccountabilityModalComponent> 
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue'
import RequisitionerAccountabilityModalComponent from "../../requisitionComponents/requisitionerComponents/RequisitionerAccountabilityModalComponent.vue";
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import { PaperClipIcon } from '@heroicons/vue/solid';
import Api from "@/components/api/Api";
import html2pdf from "html2pdf.js";
import  host  from '@/helpers/host.js'

export default {
  name: "PaymentsOfficeAccountabilitiesComponent",
  components : {
    RequisitionerAccountabilityModalComponent,
    AlertError, AlertSuccess,
    PaperClipIcon,
  },
   setup(){

    const accountabilities = ref([]);
    const showAccountabilityModal  = ref(false);
    const actionToBeTaken  = ref(0);

    let activePage = inject('activePage');
    activePage.value = 'Accountabilities';
    let isLoading = ref(false);
    let errorMessage = ref("");
    let successMessage = ref("");
    let selectedAccountabilityApprovalLevel = ref('0')
    let selectedAccountabilityStatus = ref('0')

    const stats = ref([
        { name: 'Pending', status: '0', stat: '0', class: 'text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg' },
        { name: 'Approved',  status: '1', stat: '0', class: 'text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg' },
        { name: 'Rejected',  status: '3', stat: '0',  class: 'text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg' },
        ])
    let loggedInUser =  inject("loggedInUser");
    const staticOldApprovalRoleId = 13;
    // const staticNewApprovalRoleId = 13;
    let status = ref('0');
    // let dateRange = ref('');
    let selectedAccountability  = ref('0');
    let voucher_number = ref('')
    let requisitionProp = ref({});
    let attachments = ref([]);
    let accountabilityTransitions = ref([]);
    let accountability_name = ref('')
    let accountability_money_utilized  = ref('0')
    let accountability_money_returned  = ref('0')
    let accountability_balance_claimed = ref('0')
    let accountability_description     = ref('');
    const file_url = host.url+'download-accountability-resource';
    let comment = ref('');
    let printpdf = inject('printpdf');
    



    let fromPage = ref(0)
    let toPage   = ref(0)
    let totalPageResults   = ref(0);
    let prevPageUrl = ref('#')
    let nextPageUrl = ref('#')

    let pageUrl = `/requisition/get-approver-accountabilities/${loggedInUser.value.id}/${staticOldApprovalRoleId}/`;
    const filter = ref("");
    const filteredAccountabilitiesTable = computed(() => {
        return accountabilities.value.filter(
          (val) =>{
            const name = val.requisition_name.toString().toLowerCase();
            const summary = val.summary.toString().toLowerCase();
            const amount = Number(val.money_accountable).toLocaleString().toString().toLowerCase();
            
            return name.includes(filter.value.toLowerCase()) || summary.includes(filter.value.toLowerCase())
                   || amount.includes(filter.value.toLowerCase());
          }
        );
      });

    async function getRequisitions(url = pageUrl,extra_url='',status_='') {

    isLoading.value = true;
    if(status_){
        status.value = status_;
    }

    if(extra_url){

        url = url + status.value + extra_url;

    }else{

        url = url + status.value;
        
    }

     stats.value[0].stat = 0;
     stats.value[1].stat = 0;
     stats.value[2].stat = 0;

    try{

        

        const response = await  Api().get(url);

        if (response.data.status === "success") {

            successMessage.value = response.data.message
            for(let i =0; i < response.data.stats.length; i++){

                if(response.data.stats[i].status == 'Pending'){

                    stats.value[0].stat = response.data.stats[i].stat

                }else if(response.data.stats[i].status == 'Approved'){

                    stats.value[1].stat = response.data.stats[i].stat

                }else if(response.data.stats[i].status == 'Rejected'){

                    stats.value[2].stat = response.data.stats[i].stat
                    
                }

            }


            accountabilities.value = response.data.requisitions.data;

            nextPageUrl.value = response.data.requisitions.next_page_url
            prevPageUrl.value = response.data.requisitions.prev_page_url
            fromPage.value = response.data.requisitions.from
            toPage.value   = response.data.requisitions.to
            totalPageResults.value   = response.data.requisitions.total
            
        }

    }catch (err) {

        if (err.response) {

            errorMessage.value = err.response.data.message;

        } else if (err.request) {

            errorMessage.value = err.request.data.message

        } else {

            errorMessage.value = err.message

        }
    }

    isLoading.value = false;

    }

    async function showTransitionStatuses(accountability_id){

        isLoading.value = true;

        try{

        const response = await  Api().get(`/requisition/get-requisition-accountability-transitions/${accountability_id}`);

        if (response.data.status === "success") {

                requisitionProp.value = response.data.requisition;
                if(response.data.requisition){

                    voucher_number.value      = response.data.requisition.voucher_number
                    accountability_name.value = response.data.requisition.accountability_name
                    accountability_money_utilized.value   = response.data.requisition.money_utilized
                    accountability_money_returned.value   = response.data.requisition.money_returned
                    accountability_balance_claimed.value  = response.data.requisition.balance_claimed
                    accountability_description.value      = response.data.requisition.description

                }
                
                showAccountabilityModal.value = true;
                attachments.value = response.data.attachments;
                accountabilityTransitions.value = response.data.transitions;
            
        }

        }catch (err) {

            if (err.response) {

            errorMessage.value = err.response.data.message;

            

            } else if (err.request) {

                errorMessage.value = err.request.data.message


            } else {

                errorMessage.value = err.message


            }
        }

        isLoading.value = false;
        

    }

    // async function loadApproverList(approval_level_role_id){

    // try {

    //         const res = await Api().get(`/requisition/get-approvers/${approval_level_role_id}`);

    //         if (res.data.status === "success") {

    //             people.value = res.data.approvers;

    //         }

    //     } catch (err) {

    //         if (err.response) {

    //             //

    //         } else if (err.request) {

    //             //

    //         } else {
                
    //                 //

    //         }
    //     }


    // }

    function resetValues(){

        errorMessage.value = "";
        successMessage.value = "";
        actionToBeTaken.value = '0'
        comment.value = "";
        selectedAccountabilityApprovalLevel.value = '0';
        selectedAccountability.value = '0'
        selectedAccountabilityStatus.value = '0'



    }

    async function accountabilityAction(accountability_id){


        let actionObj = {
        //   'new_approver_user_id'    : selectedPerson.value.user_id,
          'current_approver_user_id'   : loggedInUser.value.id,
          'old_approval_level_role_id'    : staticOldApprovalRoleId,
        //   'erp_systems_role_id'    : selectedPerson.value.id,
          'comment' : comment.value,
          'transition_status' : actionToBeTaken.value,
        //   'new_approval_level_role_id'    : staticNewApprovalRoleId,

        }

         isLoading.value = true;
         successMessage.value = '';
         errorMessage.value = '';

            try {
            
              const res = await Api().patch(`/requisition/accountability/approve/${accountability_id}`,actionObj);

              if (res.data.status === "success") {

                successMessage.value = res.data.message;
                await getRequisitions();
                resetValues();
                showAccountabilityModal.value = false;
                

               

              }else{

                errorMessage.value = res.data.message;

              }


            } catch (err) {

                if (err.response) {

                  errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                    errorMessage.value = err.request.data.message ;

                } else {

                  errorMessage.value = err.message;

                }
            }

            isLoading.value = false;
      
    }
    
    async function downloadRequisitionPdf(){

        printpdf.value = true;

        setTimeout(() => { 

             let element = document.getElementById('requisition-to-convert');
                // var element = document.getElementById('foo');
                let positionInfo = element.getBoundingClientRect();
                let height = positionInfo.height;
                let width = positionInfo.width;
                let downloadFileName = Date.now();

                if(requisitionProp.value){

                downloadFileName = requisitionProp.value.requisition_name

                }

                let opt = {
                margin:       0,
                filename:     downloadFileName,
                image:        { type: 'jpeg', quality: 1 },
                html2canvas:  { scale: 2 },
                // html2canvas:  { scale: 2, width: width },
                // jsPDF:        { unit: 'in', format: 'a4', orientation: 'portrait' }
                jsPDF:        { unit: 'in', format: [width/96, height/96], orientation: 'portrait' }
                // jsPDF:        { unit: 'px', format: [width, height], orientation: 'portrait' }
                };

            html2pdf().from(element).set(opt).save();

            printpdf.value = false;

    
        
        }, 100);
        
        
       // html2pdf(document.getElementById('requisition-to-convert'));

      }
      
     //loadApproverList(staticNewApprovalRoleId)
      getRequisitions();

    return {

      accountabilities,showAccountabilityModal,actionToBeTaken,stats,
      fromPage, toPage, totalPageResults, prevPageUrl,nextPageUrl,pageUrl,
      filteredAccountabilitiesTable,filter, getRequisitions, status,
      errorMessage, successMessage, isLoading,  selectedAccountability,
      showTransitionStatuses, voucher_number, accountability_name,
      requisitionProp, attachments,accountabilityTransitions,
      accountability_money_utilized, accountability_money_returned, accountability_balance_claimed,
      accountability_description, file_url,comment,
      resetValues, accountabilityAction, staticOldApprovalRoleId,
      selectedAccountabilityApprovalLevel, selectedAccountabilityStatus, downloadRequisitionPdf,printpdf


    }


   }

}
</script>

<style>

</style>