<template>
  <div class="mt-8">
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <button @click.prevent="$router.back()" class="capitalize flex text-blue-500 hover:underline">
        <ArrowLeftIcon class="mr-2 h-6 w-6" />
        back
      </button>
      <div class="flex justify-center items-center">
        <h2 class="text-xl leading-6 font-medium text-gray-900">Add Resource</h2>
      </div>
    </div>
    <div class="mt-12 max-w-2xl mx-auto">
      <form class="" id="create-resource" enctype="multipart/form-data" method="post">
        <input v-if="loggedInUser" type="hidden" name="created_by_id" :value="loggedInUser.id">
        <div class="space-y-8 divide-y divide-gray-200">
          <div>
            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <input v-if="selectedResource" type="hidden" name="_method" value="PATCH">
              <div class="sm:col-span-6">
                <label for="resource_name" class="block text-sm font-medium text-gray-700">
                  Resource Name
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input type="text" :value="selectedResource? selectedResource.resource_name : ''" name="resource_name" id="resource_name" autocomplete="resource_name" class="flex-1 px-3 focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                </div>
              </div>
              <div class="sm:col-span-6">
                <label for="resource_project_id" class="block text-sm font-medium text-gray-700">
                  Project
                </label>
                <div class="mt-1">
                  <select name="resource_project_id"  :value="selectedResource? selectedResource.project_id : ''"  autocomplete="country-name" class="shadow-sm focus:ring-indigo-500  py-3 border px-2 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                    <option>Choose Project</option>
                    <option v-for="project in projectList" :key="project.id" :value="project.id">{{project.project_name}}</option>
                  </select>
                </div>
              </div>


              <div class="sm:col-span-6">
                <label for="file" class="block text-sm font-medium text-gray-700">
                  Upload File
                </label>
                  <div class="mt-1 flex rounded-md  ">
                    <input  type="file" name="file" id="file" class=" flex-1 px-3  focus:ring-indigo-500  py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                  </div>
                  <p class="text-xs text-gray-500 uppercase">
                    ppt,pptx,doc,docx,pdf,xls,xlsx,zip up to 20MB
                  </p>
                </div>



              <div class="flex justify-center sm:col-span-6">
                <button v-if="selectedResource" @click.prevent="update(loggedInUser.id)" class="block bg-blue-600 hover:bg-blue-500 p-2 rounded-lg w-1/2 mx-auto text-white mt-4 text-center px-40">Save</button>
                <button v-else @click.prevent="create" class="block bg-blue-600 hover:bg-blue-500 p-2 rounded-lg w-1/2 mx-auto text-white mt-4 text-center px-40">Save</button>
              </div>
            </div>
          </div>
        </div>
      </form>
            </div>
  </div>
</template>

<script>
import project from "@/stores/Fundraising/Project";
import resource from "@/stores/Fundraising/Resource";
import {onMounted,onBeforeUnmount} from "vue";
import {getUser} from "@/stores/User.js";
import {ArrowLeftIcon} from '@heroicons/vue/solid'

export default {
  name: "DashboardAddResource",
  components:{ArrowLeftIcon},
  setup(){
    const {loadProjects,projectList} = project;
    const {create,selectedResource,update} = resource;
    const loggedInUser = getUser()
    onMounted(() => {
      if (loggedInUser.value){
        loadProjects(loggedInUser.value.id);
      }
    });

    onBeforeUnmount(() => {
      selectedResource.value = null;
    })

    return {create,projectList,loggedInUser,selectedResource,update}
  }
};
</script>

<style scoped>

</style>