<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="$emit('dismiss')">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-fit sm:w-9/12 sm:p-6">
            <div id="requisition-to-convert">
                <div class="bg-white shadow overflow-hidden sm:rounded-lg mb-3">
                    <div class="px-4 py-5 sm:px-3">
                        <h3 class="text-lg leading-6 font-extrabold text-red-900">Requisition Information</h3>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">ID No: {{ requisition.requisition_name }}</p>
                    </div>
                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl class="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-4">
                        <div class="sm:col-span-1">
                          <dt class="text-sm font-bold text-red-900">Name</dt>
                          <dd class="mt-1 text-sm text-gray-900">{{ requisition.requisitioner }}</dd>
                        </div>
                        <!-- <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500">ID No</dt>
                        <dd class="mt-1 text-sm text-gray-900">JMS/DTS/2022-06-29 09:19:33/4F985</dd>
                        </div> -->
                        <div class="sm:col-span-1">
                          <dt class="text-sm font-bold text-red-900">Reason</dt>
                          <dd class="mt-1 text-sm text-gray-900"> {{ requisition.purpose_of_requisition }} </dd>
                        </div>
                        <div class="sm:col-span-1">
                          <dt class="text-sm font-bold text-red-900">Date</dt>
                          <dd class="mt-1 text-sm text-gray-900"> {{ requisition.date }} </dd>
                        </div>
                        <div class="sm:col-span-1">
                          <dt class="text-sm font-bold text-red-900">Urgent</dt>
                          <dd class="mt-1 text-sm text-gray-900">{{ requisition.is_urgent == 0? 'No' : 'Yes' }} </dd>
                        </div>
                        <div class="sm:col-span-1">
                          <dt class="text-sm font-bold text-red-900">Requisition Type</dt>
                          <dd class="mt-1 text-sm text-gray-900">  {{ requisition.type_of_requisition }} </dd>
                        </div>
                        <div class="sm:col-span-1">
                          <!-- <dt class="text-sm font-medium text-gray-500">Budget Line</dt> -->
                          <dt class="text-sm font-bold text-red-900">Budget Line</dt>
                          <dd class="mt-1 text-sm text-gray-900">  {{ requisition.budget_line_name? requisition.budget_line_name : 'not yet assigned' }}   </dd>
                          <!-- <dd class="mt-1 text-sm text-gray-900">Budget Line: 5010127 [ IFS & other Software Licences | ( 545,830,000 /=) ]</dd> -->
                        </div>
                        <div v-if="requisition.expenditure_type" class="sm:col-span-1">
                          <dt class="text-sm font-bold text-red-900">Expenditure Type</dt>
                          <dd class="mt-1 text-sm text-gray-900">  {{ requisition.expenditure_type }} </dd>
                        </div>
                        <div v-if="requisition.payment_option" class="sm:col-span-1">
                          <!-- <dt class="text-sm font-medium text-gray-500">Budget Line</dt> -->
                          <dt class="text-sm font-bold text-red-900">Payment Option</dt>
                          <dd class="mt-1 text-sm text-gray-900">  {{ requisition.payment_option? requisition.payment_option : '' }}   </dd>
                          <!-- <dd class="mt-1 text-sm text-gray-900">Budget Line: 5010127 [ IFS & other Software Licences | ( 545,830,000 /=) ]</dd> -->
                        </div>
                        
                        <div v-if="requisition.voucher_number" class="sm:col-span-1">
                          <dt class="text-sm font-bold text-red-900">Voucher Number</dt>
                          <dd class="mt-1 text-sm text-gray-900">  {{ requisition.voucher_number }} </dd>
                        </div>


                        
                        <div class="sm:col-span-4">
                          <!-- <dt class="text-sm font-medium text-gray-500">Budget Line</dt>
                          <dd class="mt-1 text-sm text-gray-900">Budget Line: 5010127 [ IFS & other Software Licences | ( 545,830,000 /=) ]</dd> -->
                            <div class="flex flex-col">
                                 <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div class="shadow overflow-hidden ">
                                            <table class="min-w-full divide-y divide-gray-200 border border-slate-300 ">
                                                <thead class="bg-teal-700">
                                                    <tr>
                                                        <th colspan="5" scope="col" class="px-6 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">[ Requisition Amount Composition ]</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="bg-white divide-y divide-gray-200">
                                                    <tr >
                                                        <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300">
                                                         <span class="font-bold"> Currency:</span> <br/> {{ requisition.currency? requisition.currency : '--' }}
                                                        </td>
                                                        <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300">
                                                         <span class="font-bold">Exchange Rate: </span> <br/>{{ requisition.exchange_rate }}
                                                        </td>
                                                        <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300">
                                                         <span class="font-bold"> Currency Expenses: </span> <br/> {{ requisition.foreign_expenses }}
                                                        </td>
                                                        <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300">
                                                         <span class="font-bold"> Currency Contingency Fund: </span>  <br/>{{ requisition.foreign_contingency_fund }}
                                                        </td>
                                                        <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300">
                                                         <span class="font-bold"> Currency Per Diem: </span> <br/>{{ requisition.foreign_per_diem }}
                                                        </td>                            
                                                    </tr>
                                                    <tr >
                                                        <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300">
                                                         <span class="font-bold"> Expenses: </span> <br/>{{ Number(requisition.local_expenses).toLocaleString() }}
                                                        </td>
                                                        <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300">
                                                         <span class="font-bold"> Contingency Fund: </span> <br/>{{ Number(requisition.local_contingency_fund).toLocaleString() }}
                                                        </td>
                                                        <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300">
                                                         <span class="font-bold"> Per Diem: </span> <br/> {{ Number(requisition.local_per_diem).toLocaleString()  }}
                                                        </td>
                                                        <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300">
                                                         <span class="font-bold"> Requisition Total: </span> <br/>{{ Number(requisition.local_total_amount).toLocaleString() }}
                                                        </td>
                                                        <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300">
                                                         <span class="font-bold"> Accountable Amount: </span> <br/>{{ Number(requisition.accountable_amount).toLocaleString()  }}
                                                        </td>                            
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sm:col-span-4">
                          <dt class="text-sm font-bold text-red-900">Summary Description</dt>
                          <dd class="mt-1 text-sm text-gray-900"> {{ requisition.summary }} </dd>
                        </div>
                        <div class="sm:col-span-4">
                          <dt class="text-sm font-bold text-red-900">Detailed Description</dt>
                          <dd  id="custom_description" class="mt-1 text-sm text-gray-900" v-html="requisition.description"> </dd>
                        </div>
                        <div class="sm:col-span-3" data-html2canvas-ignore="true">
                        <dt class="text-sm font-bold text-red-900">Attachments</dt>
                        <dd class="mt-1 text-sm text-gray-900">
                            <ul role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200">
                            <li  v-for="(attachment,index) in requisitionAttachments" :key="index"  class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                <div class="w-0 flex-1 flex items-center">
                                <PaperClipIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                                <span class="ml-2 flex-1 w-0 truncate"> {{ attachment.file_name }} </span>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                <!-- <a @click.prevent="downloadResource(attachment.id)" class=" cursor-pointer font-medium text-indigo-600 hover:text-indigo-500"> Download </a> -->
                                <a  
                                   :href="`${file_url}/${attachment.id}`"
                                class=" cursor-pointer font-medium text-indigo-600 hover:text-indigo-500"> Download </a>
                                </div>
                            </li>
                            <!-- <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                <div class="w-0 flex-1 flex items-center">
                                <PaperClipIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                                <span class="ml-2 flex-1 w-0 truncate"> invoice.pdf </span>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500"> Download </a>
                                </div>
                            </li> -->
                            </ul>
                        </dd>
                        </div>
                    </dl>
                    </div>
                </div>
                <div class="my-5 pl-2">
                    <p class="text-sm font-bold text-red-900">Transition Status</p>
                </div>
              <div class="flex flex-col mx-10">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-200 table-auto">
                                <thead class="bg-teal-700">
                                    <tr>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Name</th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Role</th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Directorate</th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Status</th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Comment</th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Status Date</th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                    <tr v-for="(requisitionTransition,index) in requisitionTransitions" :key="index">
                                        <td class="px-6 py-4 text-xs  text-gray-500">
                                          {{ requisitionTransition.name }}
                                        </td>
                                        <td class="px-6 py-4 text-xs text-gray-500">
                                         {{ requisitionTransition.role_name }} 
                                        </td>
                                        <td class="px-6 py-4 text-xs text-gray-500">
                                          {{ requisitionTransition.directorate_name }}
                                        </td>
                                        <td class="px-6 py-4 text-xs text-gray-500">
                                          {{ requisitionTransition.status }}
                                        </td>
                                        <td class="px-6 py-4 text-xs text-gray-500">
                                          {{ requisitionTransition.comment }}
                                        </td>
                                        <td class="px-6 py-4 text-xs text-gray-500">
                                          {{ requisitionTransition.status_date }}
                                        </td>
                                    </tr> 
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            <slot name="footer"> 

            </slot>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, 
// DialogTitle, 
TransitionChild, TransitionRoot } from '@headlessui/vue'
import { PaperClipIcon } from '@heroicons/vue/solid'
// import { CheckIcon } from '@heroicons/vue/outline'
import Api from "@/components/api/Api";
import host from '@/helpers/host';

export default {
    name: "RequisitionerViewRequisitionModalComponent",
    props: {

       requisition: {
          //required: true,
          type: Object,
       },
        requisitionAttachments: {
         // required: true,
          type: Array,
       },
        requisitionTransitions: {
         // required: true,
          type: Array,
       },

    },
    emits: ["dismiss"],
    components: {
        Dialog,
        DialogOverlay,
        // DialogTitle,
        TransitionChild,
        TransitionRoot,
        // CheckIcon,
        PaperClipIcon,
    },
  setup() {
    const open = ref(true);   
    const file_url = host.url+'download-resource';

   async  function downloadResource(resource){


      try{
          let options = {
            responseType: "blob",
          };

          const response = await  Api().get(`/download-resource/${resource}`,{},options);
          // const url = window.URL.createObjectURL(new Blob([response.data]));
          // const link = document.createElement("a");
          // link.href = url;
          // link.setAttribute("download", this.document.name);
          // document.body.appendChild(link);
          // link.click();
          //link.remove();

            const blob = new Blob([response.data], { type: 'application/pdf' })
           let reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onload = (e) => {
              let a = document.createElement('a')
              a.download = `fileName.pdf`
              a.href = e.target.result
              document.body.appendChild(a)
              window.location =  a.href 
              a.click()
              document.body.removeChild(a)
            }

          // const blob = new Blob([response.data], { type: 'application/pdf' })
          // const link = document.createElement('a')
          // link.href = URL.createObjectURL(blob)
          // link.setAttribute('download', 'remaining_fee.pdf');
          // //link.download = 'test'
          // document.body.appendChild(link);
          // link.click()
          // URL.revokeObjectURL(link.href)


        }catch (err) {

            if (err.response) {

            //  errorMessage.value = err.response.data.message;

                //return { 'message': err.response.data.message, 'status': 'error' };

            } else if (err.request) {

               // errorMessage.value = err.request.data.message

                //return { 'message': err.request.data.message, 'status': 'error' };

            } else {

               // errorMessage.value = err.message

              // return { 'message': err.message, 'status': 'error' };

            }
        }



    }

    return {
      open,
      downloadResource,
      file_url
     // requisition
      // requisitionAttachments

    }
  },
}
</script>
<style>

#custom_description table {

  border: 1px solid black;
  border-collapse: collapse; 
  width: 100%;
  padding: 2px;
  margin-top: 10px;

}
#custom_description td {

  border: 1px solid black;
  border-collapse: collapse; 
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 10px;

}

#custom_description th {

  border: 1px solid black;
  border-collapse: collapse; 
  font-weight: normal;
  padding: 6px;

}



</style>