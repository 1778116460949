<template>
  <div>
     <Loading v-model:active="isLoading" />
        <div class=" mt-2 mb-2 flex justify-center items-center">
            <AlertSuccess
                v-if="successMessage"
                :message="successMessage"
                @hide="successMessage = null"
            />
            <AlertError
                v-if="errorMessage"
                :message="[errorMessage]"
                @hide="errorMessage = null"
            />
        </div>
        
      <!-- <div class="mt-2 mb-2 flex justify-center items-center">
        <button
        v-if="dateRange"
        @click="dateRange = null; getRequisitions(reload_page_Url)"
          class="capitalize hover:bg-gray-300 border border-1 border-blue-500 rounded-lg p-1 flex text-blue-500 ">
          All Pending
        </button>
      </div> -->
         
       <div class="sm:flex sm:justify-between sm:items-center pb-4 sm:flex-wrap">
        <div class="sm:flex sm:justify-center sm:items-end  mb-5">
            <div class="sm:mx-4  my-3">
                <label for="period_select" class="block text-sm font-bold text-red-900">Period</label>
                <select v-model="period_select" id="period_select" name="period_select" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded-md">
                  <option selected value>All</option>
                  <option value="today">Today</option>
                  <!-- <option value="yesterday">Yesterday</option>
                  <option value="this_week">This Week</option>
                  <option value="this_month">This Month</option> -->
                </select>
            </div>
            <div class="sm:mx-4  my-3">
                <button @click="reloadRequisitions()" type="button" class="inline-flex items-center px-7 py-1.5 mb-0.5 border border-transparent text-xs font-medium rounded shadow-md text-white bg-red-900 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    load
                </button>
            </div>
        </div>
            <!-- <div class="pr-3 italic capitalize text-red-900">
               {{ dateRange? dateRange.split("_").join(" ") : '' }}
            </div> -->
            <div class="relative flex">
                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                </div>
                <input type="text" v-model="filter" id="table-search" class="block flex-1 p-2 pl-10 sm:w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items">
            </div>
        </div>
    <div class="flex flex-col">
        <div class="-my-2  sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
                    <table class="w-full divide-y divide-gray-200">
                        <thead class="bg-teal-700">
                            <tr>
                                <!-- <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">#</th> -->
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"> Id Number</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Reason</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Description</th>
                                <!-- <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">FY</th> -->
                                <th scope="col" class="relative px-6 py-3">
                                <span class="sr-only">statuses</span>
                                </th>
                                <th scope="col" class="relative px-6 py-3">
                                <span class="sr-only">delete</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="(requisition) in filteredRequisitionsTable" :key="requisition.id" :class="requisition.is_urgent == 1? 'border-2 border-red-600' : ''">
                                <!-- <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {{ index+1 }}
                                </td> -->
                                <td class="px-6 py-4 break-words text-sm font-medium text-gray-900">
                                  <a :id="requisition.requisition_name" :name="requisition.requisition_name" class="text-blue-600 visited:text-purple-600 
                                        hover:underline cursor-pointer" @click.prevent="showRequisition(requisition.id)">
                                          {{ requisition.requisition_name }}
                                  </a>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {{ requisition.purpose_of_requisition }}
                                </td>
                                <td class="px-6 py-4 break-words text-sm text-gray-500">
                                {{ requisition.summary }}
                                </td>
                                <!-- <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {{ requisition.financial_year }}
                                </td> -->
                                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <a @click.prevent="showTransitionStatuses(requisition.id)" class="bg-indigo-600 text-white text-xs  py-1 px-2 rounded-md cursor-pointer hover:bg-indigo-900 shadow-md">statuses</a>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <button  @click.prevent="showRequisition(requisition.id)"  class="text-white bg-green-600 py-1 px-2 rounded-md text-xs cursor-pointer hover:bg-green-900 shadow-md">view</button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                              <td colspan="9">
                                  <nav class="bg-white px-4 py-3 flex items-center justify-between  sm:px-6" aria-label="Pagination">
                                      <div class="hidden sm:block">
                                      <p class="text-sm text-gray-700">
                                          Showing
                                          {{ ' ' }}
                                          <span class="font-medium">{{ fromPage }}</span>
                                          {{ ' ' }}
                                          to
                                          {{ ' ' }}
                                          <span class="font-medium">{{ toPage }}</span>
                                          {{ ' ' }}
                                          of
                                          {{ ' ' }}
                                          <span class="font-medium">{{ totalPageResults }}</span>
                                          {{ ' ' }}
                                          results
                                      </p>
                                      </div>
                                      <div class="flex-1 flex justify-between sm:justify-end">
                                      <a :class="prevPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="getRequisitions( pageUrl+prevPageUrl)"  class="relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Previous </a>
                                      <a :class="nextPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="getRequisitions( pageUrl+nextPageUrl)" class="ml-3 cursor-pointer relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Next </a>
                                      </div>
                                  </nav>
                              </td>
                          </tr>
                      </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <RequisitionerViewRequisitionModalComponent :requisition="requisitionProp" :requisitionAttachments="requisitionAttachmentsProp" :requisitionTransitions="requisitionTransitionsProp" :show="showRequisitionModal" @dismiss="resetValues(); showRequisitionModal = false">
    
      <template v-slot:footer>
        <div class="mt-3 p-5">
            <input class="hidden" ref="requisitionModalRef" >
            <label class="text-sm font-bold text-red-900">Accepted Currencies</label>
            <fieldset class="mt-4">
            <!-- <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10"> -->
            <div class="flex flex-wrap items-center">
                <div v-for="acceptedCurrency in acceptedCurrencies" :key="acceptedCurrency.id" class="flex items-center p-2">
                <input :id="acceptedCurrency.id" v-model="accepted_currency"  @change="empty_values()" :value="acceptedCurrency.id" name="acceptedCurrency" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                <label :for="acceptedCurrency.id" class="px-2 block text-xs font-medium text-gray-700">
                    {{ acceptedCurrency.title }}
                </label>
                </div>
            </div>
            </fieldset>
        </div>
        <div class="p-5">
            <div v-if="accepted_currency && accepted_currency != 'ugx'" class="grid grid-cols-4 gap-6 ">
                <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <label for="foreign_expenses" class="block text-xs font-bold text-red-900">Expenses ({{ accepted_currency }})</label>
                    <input type="text" name="foreign_expenses" id="foreign_expenses" v-model="foreign_expenses_" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                </div>

                <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <label for="foreign_contingency" class="block text-xs font-bold text-red-900">Contingency Fund ({{ accepted_currency }})</label>
                    <input type="text" name="foreign_contingency" id="foreign_contingency" v-model="foreign_contingency_"  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                </div>

                <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <label for="foreign_per_diem" class="block text-xs font-bold text-red-900">Per Diem ({{ accepted_currency }})</label>
                    <input type="text" name="foreign_per_diem" id="foreign_per_diem" v-model="foreign_per_diem_" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                </div>

                <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <label for="exchange_rate" class="block text-xs font-bold text-red-900">Exchange Rate</label>
                    <input disabled type="text" v-model="exchange_rate" name="exchange_rate" id="exchange_rate" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                </div>

            </div>
            <div class="grid grid-cols-4 gap-6" :class="accepted_currency && accepted_currency != 'ugx'? 'mt-5' : ''">

                <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <label for="local_expenses" class="block text-xs font-bold text-red-900">Expenses (UGX)</label>
                    <input  :disabled="accepted_currency != 'ugx' " type="number"  name="local_expenses" id="local_expenses" v-model="local_expenses_" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                </div>

                <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <label for="local_contingency" class="block text-xs font-bold text-red-900">Contingency Fund (UGX)</label>
                    <input :disabled="accepted_currency != 'ugx'"  type="number" name="local_contingency" id="local_contingency" v-model="local_contingency_" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                </div>

                <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <label for="local_per_diem" class="block text-xs font-bold text-red-900">Per Diem (UGX)</label>
                    <input :disabled="accepted_currency != 'ugx' " type="number" name="local_per_diem" id="local_per_diem" v-model="local_per_diem_" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                </div>

                <div class="col-span-4 sm:col-span-2 lg:col-span-1">
                    <label for="total_amount" class="block text-xs font-bold text-red-900">Total Amount (UGX)</label>
                    <input disabled='true' type="text" name="total_amount" id="total_amount" v-model="total_amount" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                </div>

            </div>
        </div>
        <div class="p-5">
            <div class="sm:grid sm:grid-cols-4 sm:gap-2">
                <div class="sm:col-span-2 lg:col-span-1 border border-1 border-dashed p-3">
                    <label for="file" class="block text-xs font-bold text-red-900">
                      Upload Attachment 1
                    </label>
                      <div class="mt-1 flex rounded-md  ">
                        <input accept="*/*" type="file" name="attachment_1" @change="handleFile($event,'1')" id="file" class=" flex-1 px-3  focus:ring-indigo-500  py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md text-xs border-gray-300" />
                      </div>
                </div>
                <div class="sm:col-span-2 lg:col-span-1 border border-1 border-dashed  p-3">
                    <label for="file" class="block text-xs font-bold text-red-900">
                      Upload Attachment 2
                    </label>
                      <div class="mt-1 flex rounded-md  ">
                        <input accept="*/*" type="file" name="attachment_2" @change="handleFile($event,'2')" id="file" class=" flex-1 px-3  focus:ring-indigo-500  py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md text-xs border-gray-300" />
                      </div>
                </div>
                <div class="sm:col-span-2 lg:col-span-1 border border-1 border-dashed p-3">
                    <label for="file" class="block text-xs font-bold text-red-900">
                      Upload Attachment 3
                    </label>
                      <div class="mt-1 flex rounded-md  ">
                        <input accept="*/*" type="file" name="attachment_3" @change="handleFile($event,'3')" id="file" class=" flex-1 px-3  focus:ring-indigo-500  py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md text-xs border-gray-300" />
                      </div>
                </div>
                <div class="sm:col-span-2 lg:col-span-1 border border-1 border-dashed  p-3">
                    <label for="file" class="block text-xs font-bold text-red-900">
                      Upload Attachment 4
                    </label>
                      <div class="mt-1 flex rounded-md  ">
                        <input accept="*/*" type="file" name="attachment_4" @change="handleFile($event,'4')" id="file" class=" flex-1 px-3  focus:ring-indigo-500  py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md text-xs border-gray-300" />
                      </div>
                </div>
            </div>
        </div>
        <div class="flex items-center justify-center mt-2">
                      <AlertError class="text-center"
                            v-if="error_message_prop"
                            :message="[error_message_prop]"
                           @hide="error_message_prop = null"
                        />
        </div>
        <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <div class="w-full p-3 rounded border border-gray-200 dark:border-gray-700">
                <label for="country" class="block text-sm font-bold text-red-900 sm:mt-px sm:pt-2">Action to be Taken </label>
                <div class="mt-1">
                    <select id="actionToBeTaken" v-model="actionToBeTaken" name="actionToBeTaken" class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                        <option value="0">Make a choice</option>
                        <option value="1">Approve</option>
                        <option value="2">Send Back</option>
                        <option value="3">Reject</option>
                    </select>
                </div>
              </div>
               <div v-if="actionToBeTaken == 1" class=" sm:border-gray-200 pt-1 mt-2 sm:mt-0 px-2 border border-gray-200">
                    <label for="country" class="block text-sm font-bold text-red-900 sm:mt-px pt-2"> Select who to send to</label>
                    <Combobox as="div" class="pt-2 pb-2" v-model="selectedPerson">
                        <!-- <ComboboxLabel class="block text-sm font-medium text-gray-700">Assigned to</ComboboxLabel> -->
                        <div class="relative mt-1">
                        <ComboboxInput placeholder="Click the arrows or start typing name here" class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm" @change="query = $event.target.value" :display-value="(person) => person.name" />
                        <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                            <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </ComboboxButton>

                        <ComboboxOptions v-if="filteredPeople.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            <ComboboxOption v-for="person in filteredPeople" :key="person.id" :value="person" as="template" v-slot="{ active, selected }">
                            <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                                <span :class="['block truncate', selected && 'font-semibold']">
                                {{ person.name }}
                                </span>

                                <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                </span>
                            </li>
                            </ComboboxOption>
                        </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>
              
        </div>
        <div class="sm:grid sm:grid-cols-1">
          <div class="pt-2">
            <label for="about" class="block text-sm font-bold text-red-900 pb-2 sm:mt-px lg:pt-2"> Comment </label>
            <div class="mt-1 sm:mt-0 sm:col-span-1">
              <textarea  maxlength="300" v-model="comment"  id="about" name="summary" rows="5" class="shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md" />
              <!-- <p class="mt-2 text-sm text-gray-500">Characters left: </p> -->
            </div>
            
          </div>
        </div>
        <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <button v-if="(actionToBeTaken == 1) " type="button"  :class="actionToBeTaken == 1?'bg-green-600 focus:ring-green-500  hover:bg-green-700': ''" class=" w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="requisitionAction(requisitionProp.requisition_id)">Approve</button>
            <button v-if="(actionToBeTaken == 3)" type="button"  :class="actionToBeTaken == 3?'bg-red-600 focus:ring-red-500  hover:bg-red-700': ''" class=" w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="requisitionAction(requisitionProp.requisition_id)">Reject</button>
            <button v-if="(actionToBeTaken == 2)" type="button"  :class="actionToBeTaken == 2?'bg-amber-600 focus:ring-amber-500  hover:bg-amber-700': ''" class=" w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="requisitionAction(requisitionProp.requisition_id)">Send Back</button>
            <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-red-900 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm" @click="downloadRequisitionPdf()">Download Requisition</button>
        </div>
        <!-- <div class="mt-5 text-center">
              <button v-if="(actionToBeTaken == 1) " type="button"  :class="actionToBeTaken == 1?'bg-green-600 focus:ring-green-500  hover:bg-green-700': ''" class=" w-1/3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm" @click="requisitionAction(requisitionProp.requisition_id)">Approve</button>
              <button v-if="(actionToBeTaken == 3)" type="button"  :class="actionToBeTaken == 3?'bg-red-600 focus:ring-red-500  hover:bg-red-700': ''" class=" w-1/3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm" @click="requisitionAction(requisitionProp.requisition_id)">Reject</button>
              <button v-if="(actionToBeTaken == 2)" type="button"  :class="actionToBeTaken == 2?'bg-amber-600 focus:ring-amber-500  hover:bg-amber-700': ''" class=" w-1/3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm" @click="requisitionAction(requisitionProp.requisition_id)">Send Back</button>
              <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm" @click="$emit('dismiss')">Download Requisition Report</button>
        </div> -->
      </template>
    </RequisitionerViewRequisitionModalComponent>
    <RequisitionerMultiPurposeModalComponent :show="showStatusesModal" @dismiss="showStatusesModal = false">
        <template v-slot:header>
                    Requisition Action Statuses
        </template>
        <template v-slot:body>
          <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-teal-700">
                                <tr>
                                    <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">#</th>
                                    <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Role</th>
                                    <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">User</th>
                                    <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Seen</th>
                                    <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Duration</th>
                                    <th scope="col" class="pl-1 pr-3 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Status</th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="(transition,index) in requisitionTransitions" :key="index" >
                                    <td class="pl-3 pr-2 py-4 text-sm text-gray-500">
                                        {{ index + 1 }}
                                    </td>
                                    <td class="px-4 py-4 text-sm text-gray-500">
                                      {{ transition.role_name }} 
                                    </td>
                                    <td class="px-4 py-4 text-sm text-gray-500">
                                      {{ transition.name }}
                                    </td>
                                    <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {{ transition.seen_on? 'Yes, on ' + transition.seen_on : 'No' }}
                                    </td>
                                    <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {{ transition.duration }} days
                                    </td>
                                    <td class="pl-1 pr-3 py-4 text-sm text-gray-500">
                                      {{ transition.status }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
          </div>
        </template>
    </RequisitionerMultiPurposeModalComponent>
  </div>
</template>

<script>
import { ref, computed, inject, watch,provide } from 'vue'
import RequisitionerViewRequisitionModalComponent from "../requisitionerComponents/RequisitionerViewRequisitionModalComponent.vue";
import RequisitionerMultiPurposeModalComponent from "../requisitionerComponents/RequisitionerMultiPurposeModalComponent.vue";
import { Combobox, ComboboxButton, ComboboxInput,
  ComboboxOption, ComboboxOptions,
} from '@headlessui/vue'
import {  CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import Api from "@/components/api/Api";
// import { useRoute, useRouter} from "vue-router";
import { useRoute } from "vue-router";
import html2pdf from "html2pdf.js";


export default {

  name: "ProcurementPendingComponent",
  components: {
          RequisitionerViewRequisitionModalComponent,
          RequisitionerMultiPurposeModalComponent,
          Combobox,     ComboboxButton,
          ComboboxInput,    ComboboxOption,     ComboboxOptions,
          SelectorIcon, CheckIcon,
           AlertError, AlertSuccess
  },

  setup(){
      
     const showRequisitionModal = ref(false);
     const showStatusesModal    = ref(false);
    //  const router = useRouter();
     const people = ref([
      { id: 0, user_id: 0, name: '' }
      ])
      const query = ref('')
      const selectedPerson = ref(people.value[0])
      const filteredPeople = computed(() =>
      query.value === ''
          ? people.value
          : people.value.filter((person) => {
              return person.name.toLowerCase().includes(query.value.toLowerCase())
          })
      )

      const requisitions = ref([
        { id: '', 
        requisition_name: '', 
        purpose_of_requisition: '', 
        summary: '' },
       
      ])
      const route = useRoute();
      let isLoading = ref(false);
      let errorMessage = ref("");
      let successMessage = ref("");
      let fromPage = ref(0)
      let toPage   = ref(0)
      let totalPageResults   = ref(0);
      let prevPageUrl = ref('#')
      let nextPageUrl = ref('#')
      let status_title = inject('status_title');
      let loggedInUser =  inject("loggedInUser");
      let status = ref('0');
      let dateRange = ref(route.params.date);
      let period_select = ref('');

      if(dateRange.value){

         period_select.value = dateRange.value

      }
      const staticOldApprovalRoleId = 4;
      const staticNewApprovalRoleId = 6;

      const acceptedCurrencies = [
        { id: 'ugx', title: 'UGX' },
        { id: 'usd', title: 'USD' },
        { id: 'eur', title: 'EUR' },
        { id: 'gbp', title: 'GBP' },
        { id: 'kshs', title: 'KSHS' },
        { id: 'inr', title: 'INR' },
        { id: 'zar', title: 'ZAR' },
        { id: 'aed', title: 'AED' },
      ]
      let accepted_currency = ref(null)
      let local_contingency_  = ref('0')
      let local_expenses_     = ref('0')
      let local_per_diem_     = ref('0')

      let foreign_contingency_  = ref('0')
      let foreign_expenses_     = ref('0')
      let foreign_per_diem_     = ref('0')
      
       const local_contingency = computed(() => {

            if(accepted_currency.value == 'ugx'){

              return local_contingency_.value ? local_contingency_.value : '0'

            }else{

                 return  (parseFloat(foreign_contingency.value.replace(/,/g,'')) * parseFloat(exchange_rate.value.replace(/,/g,'')));

            }

         })

       const local_expenses = computed(() => {

            if(accepted_currency.value == 'ugx'){

             return local_expenses_.value ? local_expenses_.value : '0'

            }else{

                return (parseFloat(foreign_expenses.value.replace(/,/g,'')) * parseFloat(exchange_rate.value.replace(/,/g,'')));

            }

         })

       const local_per_diem = computed(() => {

          if(accepted_currency.value == 'ugx'){

            return local_per_diem_.value ? local_per_diem_.value : '0'

          }else{

             return (parseFloat(foreign_per_diem.value.replace(/,/g,'')) * parseFloat(exchange_rate.value.replace(/,/g,'')));
          }

         })

       const total_amount = computed(() => {

             return  (parseFloat(local_contingency.value) + parseFloat(local_expenses.value) + 
                   parseFloat(local_per_diem.value)).toLocaleString()

       })

       let exchange_rate = ref('0')
        async function getExchangeRate(){

            try {
                     isLoading.value = true;
                    const res = await Api().get(`/requisition/get-exchange-rate/${accepted_currency.value}`);

                    if (res.data.status === "success") {

                        exchange_rate.value = res.data.exchangeRate[0].amount;
                       

                    }

                } catch (err) {

                    if (err.response) {

                       //
                    } else if (err.request) {

                        //

                    } else {
                        
                           //

                    }
                }

            isLoading.value = false;
        }

        async function empty_values(){

            local_contingency_.value = 0;
            local_expenses_.value = 0;
            local_per_diem_.value = 0;

            foreign_contingency_.value = 0;
            foreign_expenses_.value = 0;
            foreign_per_diem_.value = 0;
            if(accepted_currency.value != 'ugx')
              await getExchangeRate();

        }

        const foreign_contingency = computed(() => {

           return foreign_contingency_.value ? foreign_contingency_.value : '0'

         })

         const foreign_expenses = computed(() => {

           return foreign_expenses_.value ? foreign_expenses_.value : '0'

         })

         const foreign_per_diem = computed(() => {

              return foreign_per_diem_.value ? foreign_per_diem_.value : '0'

         })

         watch(foreign_contingency_, (newX) => {

            local_contingency_.value = newX * exchange_rate.value

         })

         watch(foreign_per_diem_, (newX) => {

            local_per_diem_.value = newX * exchange_rate.value

         })

         watch(foreign_expenses_, (newX) => {

            local_expenses_.value = newX * exchange_rate.value

         })

        let filesArray    = ref([null,null,null,null])

        function handleFile(e, fileNameVar){

            var files = e.target.files || e.dataTransfer.files;
            if(files[0]){

                // let filename = files[0].name;
                    if(fileNameVar == '1'){
                        // file_upload_1.value = filename;
                        filesArray.value[0] = files[0];

                    }else if(fileNameVar == '2'){

                        // file_upload_2.value = filename
                        filesArray.value[1] = files[0];

                    }else if (fileNameVar == '3'){

                        // file_upload_3.value = filename
                        filesArray.value[2] = files[0];

                    }else if (fileNameVar == '4'){

                        // file_upload_4.value = filename
                        filesArray.value[3] = files[0];

                    }
            }

        }

        let error_message_prop = ref('');

        provide('message',error_message_prop)

        const requisitionModalRef = ref(null)

      let activePage = inject('activePage');
      activePage.value = 'Pending Requisitions'

       let requisitionProp = ref({});
       let requisitionAttachmentsProp = ref([]);
       let requisitionTransitionsProp = ref([]);
       let requisitionTransitions   = ref([]);
      //  let selectedRequisition  = ref('0');

      function setRequisitionStatusFromUrl(){

        if(route.params.status == 'pending'){

          //status_title.value = 'Pending'

          status.value = 0;

        }else if(route.params.status == 'approved'){

          status_title.value = 'Approved'

          status.value = 1;

        }else if(route.params.status == 'sent back'){

          status_title.value = 'Sent Back'

          status.value = 2;
        
        }else if(route.params.status == 'rejected'){

          status_title.value = 'Rejected'

          status.value = 3;

        }

      }
      setRequisitionStatusFromUrl();
      // let pageUrl = `/requisition/get-requisitioner-requisitions/${loggedInUser.value.id}/${status.value}/${dateRange.value}`;
      let pageUrl = ref(`/requisition/get-approver-requisitions/${loggedInUser.value.id}/${staticOldApprovalRoleId}/${status.value}/${dateRange.value}`);
      let reload_page_Url = ref('');
      
      async function getRequisitions(url = pageUrl.value) {

        reload_page_Url.value = url;

        isLoading.value = true;

        try{

          

          const response = await  Api().get(url);

          if (response.data.status === "success") {

               
                successMessage.value = response.data.message
                requisitions.value = response.data.requisitions.data;
                nextPageUrl.value = response.data.requisitions.next_page_url
                prevPageUrl.value = response.data.requisitions.prev_page_url
                fromPage.value = response.data.requisitions.from
                toPage.value   = response.data.requisitions.to
                totalPageResults.value   = response.data.requisitions.total
                
          }

        }catch (err) {

            if (err.response) {

              errorMessage.value = err.response.data.message;

            } else if (err.request) {

                errorMessage.value = err.request.data.message

            } else {

                errorMessage.value = err.message

            }
        }

        isLoading.value = false;

      }

      const filter = ref("");
      const filteredRequisitionsTable = computed(() => {
        return requisitions.value.filter(
          (val) =>{
            const name = val.requisition_name.toString().toLowerCase();
            const summary = val.summary.toString().toLowerCase();
            const reason = val.purpose_of_requisition.toString().toLowerCase();
            
            return name.includes(filter.value.toLowerCase()) || summary.includes(filter.value.toLowerCase()) 
                    || reason.includes(filter.value.toLowerCase());
          }
        );
      });
      async function showRequisition(requisition_id){

        isLoading.value = true;

        try{

          const response = await  Api().get(`/requisition/get-requisition/${requisition_id}/${loggedInUser.value.id}/${staticOldApprovalRoleId}`);

          if (response.data.status === "success") {

                requisitionProp.value = response.data.requisition;
                requisitionAttachmentsProp.value = response.data.attachments;
                requisitionTransitionsProp.value = response.data.transitions;
               
          }

        }catch (err) {

            if (err.response) {

              errorMessage.value = err.response.data.message;

               

            } else if (err.request) {

                errorMessage.value = err.request.data.message


            } else {

                errorMessage.value = err.message


            }
        }

        isLoading.value = false;
        showRequisitionModal.value = true;

      }

      async function showTransitionStatuses(requisition_id){


         isLoading.value = true;

        try{

          const response = await  Api().get(`/requisition/get-transition-statuses/${requisition_id}`);

          if (response.data.status === "success") {

               
                requisitionTransitions.value = response.data.transitions;
               
          }

        }catch (err) {

            if (err.response) {

              errorMessage.value = err.response.data.message;

            } else if (err.request) {

                errorMessage.value = err.request.data.message

            } else {

                errorMessage.value = err.message

            }
        }

        isLoading.value = false;
        showStatusesModal.value = true;

      }

      async function loadApproverList(approval_level_role_id){

        try {

                const res = await Api().get(`/requisition/get-approvers/${approval_level_role_id}`);

                if (res.data.status === "success") {

                    people.value = res.data.approvers;

                }

            } catch (err) {

                if (err.response) {

                   //

                } else if (err.request) {

                   //

                } else {
                    
                     //

                }
            }


      }

      async function requisitionAction(requisition_id){

         successMessage.value = '';
         errorMessage.value = '';


        if(!accepted_currency.value && actionToBeTaken.value == '1'){

                error_message_prop.value = "Please select a currency"
                requisitionModalRef.value.focus()
                return;

        }

        if(total_amount.value <= 0 && actionToBeTaken.value == '1'){
             
              error_message_prop.value = "Total Amount cannot be empty. Please fill in the fields correctly";
              requisitionModalRef.value.focus()
            return;

        }
            

        let actionObj = {
          'new_approver_user_id'    : selectedPerson.value.user_id,
          'current_approver_user_id'   : loggedInUser.value.id,
          'old_approval_level_role_id'    : staticOldApprovalRoleId,
          'erp_systems_role_id'    : selectedPerson.value.id,
          'comment' : comment.value,
          'transition_status' : actionToBeTaken.value,
          'new_approval_level_role_id'    : staticNewApprovalRoleId,

            'accepted_currency' : accepted_currency.value,
            'exchange_rate' : exchange_rate.value,
            'local_expenses' : local_expenses.value? local_expenses.value : '0',
            'local_contingency' : local_contingency.value? local_contingency.value : '0',
            'local_per_diem'    : local_per_diem.value? local_per_diem.value: '0',
            'foreign_expenses' : foreign_expenses.value? foreign_expenses.value : '0',
            'foreign_contingency' : foreign_contingency.value? foreign_contingency.value: '0',
            'foreign_per_diem'    : foreign_per_diem.value? foreign_per_diem.value: '0',
        }

         isLoading.value = true;

         const formData = new FormData();
            Object.entries(actionObj).forEach(([key, value]) => {
                formData.append(key, value);
            });

           //formData.append('requisition',requisition);
           formData.append('file1', filesArray.value[0]);
           formData.append('file2', filesArray.value[1]);
           formData.append('file3', filesArray.value[2]);
           formData.append('file4', filesArray.value[3]);
            
            
            const options = {
                headers: {
                "Content-Type": "multipart/form-data",
                },
            };


             try {
            
            //   const res = await Api().patch(`/requisition/approve/${requisition_id}`,actionObj);
              const res = await Api().post(`/requisition/procurement_approve/${requisition_id}`,formData, options);

              if (res.data.status === "success") {

                successMessage.value = res.data.message;
                //maybe put a small timer here so that user can see message
                resetValues();
                showRequisitionModal.value = false;
                await getRequisitions(reload_page_Url.value);
               

              }else{

                error_message_prop.value = res.data.message;

              }


            } catch (err) {

                if (err.response) {

                  errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                    errorMessage.value = err.request.data.message ;

                } else {

                  errorMessage.value = err.message;

                }
            }

            isLoading.value = false;
      
      }

      let comment = ref('');
      let actionToBeTaken = ref('0');

      function resetValues(){

          actionToBeTaken.value = '0'
          comment.value = ''
          accepted_currency.value     = null
          local_contingency_.value    = '0'
          local_expenses_.value       = '0'
          local_per_diem_.value       = '0'

          foreign_contingency_.value  = '0'
          foreign_expenses_.value     = '0'
          foreign_per_diem_.value     = '0'
          exchange_rate.value         = '0'

          selectedPerson.value = { id: 0, user_id: 0, name: '' };

        

      }
     
     async function reloadRequisitions() {

        pageUrl.value = `/requisition/get-approver-requisitions/${loggedInUser.value.id}/${staticOldApprovalRoleId}/${status.value}/${period_select.value}`
        await getRequisitions();
        // console.log(period_select.value)
        // console.log(pageUrl.value)


      }

      function downloadRequisitionPdf(){

          let element = document.getElementById('requisition-to-convert');
          let downloadFileName = Date.now();

          if(requisitionProp.value){

            downloadFileName = requisitionProp.value.requisition_name

          }

          let opt = {
          margin:       0,
          filename:     downloadFileName,
          image:        { type: 'jpeg', quality: 1 },
          // html2canvas:  { scale: 1 },
          jsPDF:        { unit: 'mm', orientation: 'portrait' },
          };

          html2pdf().from(element).set(opt).save();


      }

      loadApproverList(staticNewApprovalRoleId)
      getRequisitions();

    return{
      requisitions,
      showStatusesModal,
      showRequisitionModal,
      query,selectedPerson,filteredPeople,
       errorMessage, successMessage, isLoading, 
      fromPage, toPage, totalPageResults, prevPageUrl,nextPageUrl,pageUrl,
      filteredRequisitionsTable,filter,
      showRequisition,showTransitionStatuses,
      requisitionProp, requisitionAttachmentsProp,requisitionTransitionsProp, requisitionTransitions, dateRange,
      getRequisitions,reload_page_Url,
      comment,actionToBeTaken,
      requisitionAction,acceptedCurrencies, accepted_currency,
      local_contingency_,local_expenses_,local_per_diem_,total_amount,
      empty_values,foreign_contingency_, foreign_expenses_, foreign_per_diem_,
      exchange_rate,foreign_contingency,foreign_expenses, foreign_per_diem,
      handleFile, error_message_prop, requisitionModalRef,
      period_select, reloadRequisitions,
      resetValues, downloadRequisitionPdf


    }
  }

}
</script>

<style>

</style>