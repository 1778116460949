<template>
<div >
    <Loading v-model:active="isLoading" />
    <div class=" mt-3 mb-3 flex justify-center items-center">
        <AlertSuccess
            v-if="successMessage"
            :message="successMessage"
            @hide="successMessage = null"
        />
        <AlertError
            v-if="errorMessage"
            :message="[errorMessage]"
            @hide="errorMessage = null"
        />
    </div>
    <div class="grid grid-rows-2 sm:flex sm:justify-between sm:items-center pb-4">
        <div class="pr-3">
            <button type="button" @click="showModal = true" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-900 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Add Financial Year
            </button>

        </div>
        <!-- <label for="table-search" class="sr-only">Search</label> -->
        <div class="relative flex">
            <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
            </div>
            <input type="text" v-model="filter" id="table-search" class="block flex-1 p-2 pl-10 sm:w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for financial Year">
        </div>
    </div>
    <div class="flex flex-col">
        <div class="-my-2  sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-teal-700">
                            <tr>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white capitalize tracking-wider">Name</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white capitalize tracking-wider"> From</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white capitalize tracking-wider">To</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white capitalize tracking-wider">Status</th>
                                <th scope="col" class="relative px-6 py-3">
                                <span class="sr-only">activate</span>
                                </th>
                                <th scope="col" class="relative px-6 py-3">
                                <span class="sr-only">edit</span>
                                </th>
                                <th scope="col" class="relative px-6 py-3">
                                <span class="sr-only">delete</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="(financialYear) in filteredFinancialYearsTable" :key="financialYear.id">
                                <td class="px-6 py-4 whitespace-nowrap text-sm  text-gray-900">
                                {{ financialYear.name }}
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm  text-gray-900">
                                {{ financialYear.from }}
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                {{ financialYear.to }}
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm  text-gray-900">
                                {{ financialYear.status == 0? 'active' : 'inactive' }}
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-center text-sm ">
                                 <a @click.prevent="enableFinancialYear(financialYear.id)" :class="financialYear.status == 0? 'bg-red-600 hover:bg-red-900' : 'bg-green-600 hover:bg-green-900' " class="cursor-pointer text-white text-xs py-1 px-2 rounded-md shadow-md">{{  financialYear.status == 0? 'disable' : 'enable' }}</a>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-center text-sm ">
                                <a @click.prevent="editFinancialYear(financialYear.id,financialYear.from,financialYear.to)" class=" cursor-pointer bg-indigo-600 hover:bg-indigo-900 text-white text-xs py-1 px-2 rounded-md shadow-md">edit</a>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-center text-sm ">
                                 <a @click.prevent="deleteFinancialYear(financialYear.id)" class="cursor-pointer border border-red-600 text-xs py-1 px-2 rounded-md shadow-md text-red-600 hover:text-red-900 hover:border-red-900">delete</a>
                                </td>
                                
                            </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                            <td colspan="7">
                                <nav class="bg-white px-4 py-3 flex items-center justify-between  sm:px-6" aria-label="Pagination">
                                    <div class="hidden sm:block">
                                    <p class="text-sm text-gray-700">
                                            Showing
                                            {{ ' ' }}
                                            <span class="font-medium">{{ fromPage }} </span>
                                            {{ ' ' }}
                                            to
                                            {{ ' ' }}
                                            <span class="font-medium"> {{ toPage }} </span>
                                            {{ ' ' }}
                                            of
                                            {{ ' ' }}
                                            <span class="font-medium">{{ totalPageResults }}</span>
                                            {{ ' ' }}
                                            results
                                    </p>
                                    </div>
                                    <div class="flex-1 flex justify-between sm:justify-end">
                                    <a :class="prevPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="loadFinancialYears( pageUrl+prevPageUrl)"  class="relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Previous </a>
                                    <a :class="nextPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="loadFinancialYears( pageUrl+nextPageUrl)" class="ml-3 cursor-pointer relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Next </a>
                                    <!-- <a href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> Previous </a>
                                    <a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> Next </a> -->
                                    </div>
                                </nav>
                            </td>
                        </tr>
                    </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <ErpManagementModalComponent :show="showModal" @dismiss="showModal = false; dateValue = [];editingFinancialYear_id = '0';">
        <template v-slot:title>
            <span class="text-red-900">
               Add Financial Year
            </span>
        </template>
        <template v-slot:body>
            <div class="py-5 flex flex-col justify-between items-stretch" >
                <label class="py-3 block text-sm font-medium text-red-900 "> Select Date Range</label>
                <litepie-datepicker class="lg:h-9 h-10 text-gray-900 "
                separator=" to "
                :formatter="formatter"
                v-model="dateValue"
                ></litepie-datepicker>
            </div>
        </template>
        <template v-slot:footer>
             <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button type="button" :disabled="disableSave" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-900 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-1 sm:text-sm" @click="save()">save</button>
              <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-2 sm:text-sm" @click="showModal=false; dateValue = []; editingFinancialYear_id = '0';" >Cancel</button>
            </div>
        </template>
    </ErpManagementModalComponent>
</div>
</template>

<script>
import ErpManagementModalComponent from "./ErpManagementModalComponent.vue"
import LitepieDatepicker from 'litepie-datepicker-tw3';
import { ref, computed, inject } from 'vue'
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import Api from "@/components/api/Api";

export default {
   name: "FinancialYearComponent",
    components: {
         ErpManagementModalComponent,
         LitepieDatepicker,
          AlertError, AlertSuccess
    },

    setup() {

      let showModal = ref(false);
      let activePage = inject('activePage')
      activePage.value = 'Financial Years'
      const financialYears = ref([]);
     
        let currentComponent = ref(null);
        let isLoading = ref(false);
        let errorMessage = ref("");
        let successMessage = ref("");
        const getUser = inject('getUser');
        let fromPage = ref(0)
        let toPage   = ref(0)
        let totalPageResults   = ref(0);
        let prevPageUrl = ref('#')
        let nextPageUrl = ref('#')
        let pageUrl = '/app/get-financial-years';

        let loggedInUser = getUser();
        let editingFinancialYear_id = ref('0');

        const filter = ref("");
        const filteredFinancialYearsTable = computed(() => {
            return financialYears.value.filter(
            (val) =>{
                const name = val.name.toString().toLowerCase();
               // const email = val.email.toString().toLowerCase();
                
                return name.includes(filter.value.toLowerCase()) ;//|| email.includes(filter.value.toLowerCase());
            }
            );
        });

       

      function swapComponent(component){

         currentComponent.value = component;

      }



      const formatter = ref({
        date: 'DD-MM-YYYY',
        month: 'MMM'
        });
        const dateValue = ref([]);

         const disableSave = computed(() => {
            if (dateValue.value.length > 0){ 
                return false;
             }
            return true;
         });
       
       async function save(){

        isLoading.value = true;

        let dateObj = {

            'from': dateValue.value[0],
            'to': dateValue.value[1],
            'added_by_user_id': loggedInUser.value.id,
            'financialYear_id' : editingFinancialYear_id.value
        }



        try {
          
            const res = await Api().post(`/app/add-financial-year`,dateObj);

            if (res.data.status === "success") {

              successMessage.value = res.data.message;
              editingFinancialYear_id.value = '0';
              dateValue.value = [];
              loadFinancialYears();

            }


          } catch (err) {

              if (err.response) {

                 errorMessage.value =  err.response.data.message;

              } else if (err.request) {

                  errorMessage.value = err.request.data.message ;

              } else {

                 errorMessage.value = err.message;

              }
          }

        showModal.value = false;
        isLoading.value = false;

        

       }

       async function loadFinancialYears(url = pageUrl){

        try {

           const response = await Api().get(url);

           if(response.data.status === "success"){

                financialYears.value  = response.data.financialYears.data;
                nextPageUrl.value = response.data.financialYears.next_page_url
                prevPageUrl.value = response.data.financialYears.prev_page_url
                fromPage.value = response.data.financialYears.from
                toPage.value   = response.data.financialYears.to
                totalPageResults.value   = response.data.financialYears.total
                // financialYears.value = res.data.financialYears
                successMessage.value = response.data.message;
           
           } 

        } catch (err) {

            if (err.response) {

                errorMessage.value =  err.response.data.message;

            } else if (err.request) {

               errorMessage.value =  err.request.data.message;

            } else {

                errorMessage.value =  err.message;
                
            }
        }


       }

        async function deleteFinancialYear (id = 0){

            isLoading.value = true;

            successMessage.value = '';
            errorMessage.value = '';

             try {
            
              const res = await Api().delete(`/app/delete-financial-year/${id}`);

              if (res.data.status === "success") {

                successMessage.value = res.data.message;
                await loadFinancialYears();

              }else if(res.data.status === "error"){

                errorMessage.value = res.data.message;


              }


            } catch (err) {

                if (err.response) {

                  errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                    errorMessage.value = err.request.data.message ;

                } else {

                  errorMessage.value = err.message;

                }
            }

            isLoading.value = false;

        }

        async function enableFinancialYear (id = 0){

            isLoading.value = true;
            successMessage.value = '';
            errorMessage.value = '';

             try {
            
              const res = await Api().patch(`/app/enable-financial-year/${id}`);

              if (res.data.status === "success") {

                successMessage.value = res.data.message;
                await loadFinancialYears();

              }


            } catch (err) {

                if (err.response) {

                  errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                    errorMessage.value = err.request.data.message ;

                } else {

                  errorMessage.value = err.message;

                }
            }

            isLoading.value = false;

        }

        function editFinancialYear(id,from,to){

           editingFinancialYear_id.value =  id;
           dateValue.value[0] = from;
           dateValue.value[1] = to;
           showModal.value = true;

        }

        isLoading.value = true;
        loadFinancialYears();
        isLoading.value = false;

      return {

        showModal, financialYears, swapComponent, currentComponent,
        formatter, dateValue,save, disableSave,
         errorMessage, successMessage, isLoading, 
         loadFinancialYears,deleteFinancialYear,enableFinancialYear,
         fromPage, toPage, totalPageResults, prevPageUrl,nextPageUrl,pageUrl,editFinancialYear,
         filter, filteredFinancialYearsTable


      }
    }

}
</script>

<style>

</style>