import { ref } from "vue";
// import Api from "@/components/api/Api";

// State
// const current_active_user_role = ref('');
const current_active_user_role = ref(JSON.parse(localStorage.getItem('current_active_user_role')));

export const initCurrent_active_user_role = async (role) => {

    current_active_user_role.value = role;
    localStorage.setItem("current_active_user_role", JSON.stringify(role));
   
};

// Getters
export const getCurrent_active_user_role = () => {


    if (!current_active_user_role.value) {
       
        current_active_user_role.value = JSON.parse(localStorage.getItem("current_active_user_role"));

    }

    return current_active_user_role;

};
