import { ref } from "vue";
import toast from "@/helpers/toast";
import {useLoading} from 'vue-loading-overlay'
import FundraisingHost from '@/helpers/FundraisingHost.js'

const $loading = useLoading();
// const FundraisingHost.url  =  'https://donorsweb.jms.co.ug/api/';
const homeDetails  = ref()
const transactions = ref([]);
const total_donation_amount = ref();
const total_donors = ref();
const total_projects = ref();
const {success,error} = toast;


const loadHome = async () => {
  const loader = $loading.show({
    color: "#2581ef",
    opacity: .7,
  });
  const res = await fetch(`${FundraisingHost.url}home` ,{
    headers:{
      'Content-Type': 'application/json',
      'Accept':'application/json, text/plain',
      'Authorization' : 'Bearer '+localStorage.getItem('token')
    }
  });

  const data =  await res.json();
  if (data.status === 'success') {
    transactions.value = data.transactions;
     total_donation_amount.value =data.total_donation_amount ;
     total_donors.value =data.total_donors;
     total_projects.value = data.total_projects;
     homeDetails.value = true;
    success(data.message)
  }else{
    error(data.message)
  }
  loader.hide();
}

export default {loadHome,homeDetails,transactions,total_donation_amount,total_donors,total_projects}