<template>
 <div>
    <div class="grid grid-rows-1 sm:flex sm:justify-between sm:items-center pb-4">

        <div class="relative flex">
            <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
            </div>
            <input type="text" id="table-search" class="block flex-1 p-2 pl-10 sm:w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items">
        </div>
    </div>
     <div class="flex flex-col">
        <div class="-my-2  sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Budget Line</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Expenditure</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Budget To Date</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actual Date</th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="(budgetLine) in budgetLines" :key="budgetLine.id">
                                <td class="px-6 py-4 text-sm font-medium text-gray-900">
                                 {{ budgetLine.name }} 
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                {{ budgetLine.total_expenditure }}
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                {{ budgetLine.budget_to_date }}
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {{ budgetLine.actual_to_date }}
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="4">
                                    <nav class="bg-white px-4 py-3 flex items-center justify-between  sm:px-6" aria-label="Pagination">
                                        <div class="hidden sm:block">
                                        <p class="text-sm text-gray-700">
                                            Showing
                                            {{ ' ' }}
                                            <span class="font-medium">1</span>
                                            {{ ' ' }}
                                            to
                                            {{ ' ' }}
                                            <span class="font-medium">10</span>
                                            {{ ' ' }}
                                            of
                                            {{ ' ' }}
                                            <span class="font-medium">20</span>
                                            {{ ' ' }}
                                            results
                                        </p>
                                        </div>
                                        <div class="flex-1 flex justify-between sm:justify-end">
                                        <a href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> Previous </a>
                                        <a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> Next </a>
                                        </div>
                                    </nav>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>


<script>
export default {

   name: "BudgetLineReportComponent",
    setup(){

        const budgetLines = [
            {   name: 'Direct Project Expenses', 
                total_expenditure: '403,292,250.00', 
                budget_to_date: '403,292,250.00', 
                actual_to_date: '8,095,000.00',
            },
            {   name: 'Research and Development	', 
                total_expenditure: '103,292,250.00', 
                budget_to_date: '43,292,250.00', 
                actual_to_date: '18,095,000.00',
            },
            {   name: 'Direct Project Expenses', 
                total_expenditure: '63,292,250.00', 
                budget_to_date: '600,292,250.00', 
                actual_to_date: '58,095,000.00',
            }
        ]

        return {
            budgetLines
            
        }
    }


}
</script>

<style>

</style>