<template>
 <div> 
    <Loading v-model:active="isLoading" />
    <div class=" mt-1 mb-2 flex justify-center items-center">
        <AlertSuccess
            v-if="successMessage"
            :message="successMessage"
            @hide="successMessage = null"
        />
        <AlertError
            v-if="errorMessage"
            :message="[errorMessage]"
            @hide="errorMessage = null"
        />
    </div>
    <form @submit.prevent class="space-y-8 divide-y divide-gray-200 shadow-md p-6 rounded-lg border border-gray-200">
      <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div class="pt-1 space-y-6 sm:pt-1 sm:space-y-5">
            <div>
                <h3 class="text-lg leading-6 font-medium text-red-900">Create Requisition Here</h3>
                <p class="mt-1 max-w-2xl text-sm text-gray-500">Please fill in the information below correctly</p>
            </div>
            <div class="space-y-6 sm:space-y-5">
                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label for="country" class="block text-sm font-medium text-red-900 sm:mt-px sm:pt-2"> Type of Requisition *</label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <select id="typeOfRequisition" v-model="typeOfRequisition" @change="changeTypeOfRequisition" name="typeOfRequisition" class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                            <option value="0">Select type of Requisition</option>
                            <option value="1">Procurable Requisition</option>
                            <option value="2">Non Procurable Requisition</option>
                            <!-- <option value="3">Stock Adjustment Requisition</option>
                            <option value="4">Excess Goods Requisition</option> -->
                        </select>
                    </div>
                </div>
                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label for="purposeForRequisition" class="block text-sm font-medium text-red-900 sm:mt-px sm:pt-2"> Select Purpose of Requisition * </label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <select id="purposeForRequisition" v-model="purposeForRequisitionSelected" name="purposeForRequisition" class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                        <option v-for="(purpose,index) in purposeForRequisition" :key="index" :value="purpose">{{ purpose }}</option>
                    </select>
                    </div>
                </div>
                <div v-if="typeOfRequisition == 3" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label for="username" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Search By Part Number / Description</label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <div class="max-w-lg flex rounded-md shadow-sm">
                            <!-- <span class="inline-flex flex-none items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-xs"> type here: </span> -->
                            <input type="text" name="inputPartNumberOrDescription" id="inputPartNumberOrDescription" class="flex-auto block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md  sm:text-sm border-gray-300" />
                        </div>
                    </div>
                </div>
                <div v-if="typeOfRequisition == 3" class="flex flex-col pt-6 sm:pt-2">
                    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table class="min-w-full divide-y divide-gray-200">
                                    <thead class="bg-gray-50">
                                        <tr>
                                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">Part No</th>
                                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">Desc</th>
                                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">Quantity</th>
                                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">Unit Cost</th>
                                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">Total Cost</th>
                                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">Budget Line</th>
                                            <th scope="col" class="relative px-6 py-3">
                                            <span class="sr-only">delete</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="bg-white divide-y divide-gray-200">
                                        <tr >
                                            <td class="pr-1 pl-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                BPRMEDI006
                                            </td>
                                            <td class="px-2 py-4 break-words text-sm font-normal text-gray-900">
                                                ARTEMETHER 20MG + LUMEFANTRINE 120MG
                                            </td>
                                            <td class="px-2 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <input type="number" name="qty" id="qty" placeholder="Enter quantity" class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none  sm:text-sm border-gray-300" />
                                            </td>
                                            <td class="px-2 py-4 break-words text-sm text-gray-500">
                                                <input type="number" name="qty" id="qty"  class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none  sm:text-sm border-gray-300" />
                                            </td>
                                            <td class="px-2 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <input type="number" name="qty" id="qty"  class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none  sm:text-sm border-gray-300" />
                                            </td>
                                            <td class="px-2 py-4 whitespace-nowrap text-sm text-gray-500">
                                               <div class="mt-1 sm:mt-0 sm:col-span-2">
                                                    <select v-model="budgetLineSelected" id="budget_line" name="budget_line" class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                                                        <option v-for="budgetline in budgetLines" :key="budgetline.id" :value="budgetline.id">{{ budgetline.name }}</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td class="pl-1 pr-3 py-4 whitespace-nowrap text-right text-sm font-medium">
                                              <a href="#" class="text-red-600 hover:text-red-900">remove</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="typeOfRequisition == 2" class="pt-6 sm:pt-5 sm:border-t sm:border-gray-200">
                    <div role="group" aria-labelledby="label-notifications">
                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                            <div>
                               <div class="text-base font-medium text-red-900 sm:text-sm sm:text-red-900 " id="label-notifications">Accepted Currencies *</div>
                            </div>
                            <div class="sm:col-span-2">
                                <div class="max-w-lg ">
                                    <div class="mt-2 sm:space-y-3 sm:grid sm:grid-cols-4 sm:items-baseline">
                                        <div class="flex items-center">
                                            <input v-model="accepted_currency" @change="empty_values()" id="accepted-ugx" value="UGX" name="accepted-ugx" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                                            <label for="accepted-ugx" class="ml-3 block text-sm font-medium text-gray-700"> UGX </label>
                                        </div>
                                        <div class="flex items-center">
                                            <input v-model="accepted_currency" @change="empty_values()" id="accepted-usd" value="USD" name="accepted-usd" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                                            <label for="accepted-usd" class="ml-3 block text-sm font-medium text-gray-700"> USD </label>
                                        </div>
                                        <div class="flex items-center">
                                            <input v-model="accepted_currency" @change="empty_values()" id="accepted-eur" value="EUR" name="accepted-eur" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                                            <label for="accepted-eur" class="ml-3 block text-sm font-medium text-gray-700"> EUR</label>
                                        </div>
                                        <div class="flex items-center">
                                            <input v-model="accepted_currency" @change="empty_values()" id="accepted-gbp" value="GBP" name="accepted-gbp" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                                            <label for="accepted-gbp" class="ml-3 block text-sm font-medium text-gray-700"> GBP</label>
                                        </div>
                                        <div class="flex items-center">
                                            <input v-model="accepted_currency" @change="empty_values()" id="accepted-kshs" value="KSHS" name="accepted-kshs" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                                            <label for="accepted-kshs" class="ml-3 block text-sm font-medium text-gray-700"> KSHS</label>
                                        </div>
                                        <div class="flex items-center">
                                            <input v-model="accepted_currency" @change="empty_values()" id="accepted-inr" value="INR" name="accepted-inr" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                                            <label for="accepted-inr" class="ml-3 block text-sm font-medium text-gray-700"> INR</label>
                                        </div>
                                        <div class="flex items-center">
                                            <input v-model="accepted_currency" @change="empty_values()" id="accepted-zar" value="ZAR" name="accepted-zar" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                                            <label for="accepted-zar" class="ml-3 block text-sm font-medium text-gray-700"> ZAR</label>
                                        </div>
                                        <div class="flex items-center">
                                            <input v-model="accepted_currency" @change="empty_values()" id="accepted-aed" value="AED" name="accepted-aed" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                                            <label for="accepted-aed" class="ml-3 block text-sm font-medium text-gray-700"> AED</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div v-if="typeOfRequisition == 2 && accepted_currency && accepted_currency != 'UGX'" class="pt-4 sm:pt-2 sm:border-t sm:border-gray-200">
                    <div role="group">
                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                            <div>
                               <div class="text-base font-medium text-red-900 sm:text-sm sm:text-red-900" id="label-email">Foreign Currency({{ accepted_currency }}) *</div>
                            </div>
                            <div class="mt-4 sm:mt-0 sm:col-span-2">
                                <div class="max-w-lg space-y-4 lg:grid lg:grid-cols-2 sm:grid sm:grid-cols-1 lg:items-baseline">
                                    <div class=" flex items-start">
                                         <div class="max-w-lg flex rounded-md shadow-sm sm:mr-3">
                                            <span class="inline-flex items-center px-1 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-xs"> Exchange Rate  </span>
                                            <input disabled type="text" name="exchange_rate" id="exchange_rate" v-model="exchange_rate" class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" />
                                         </div>
                                    </div>
                                    <div>
                                        <div class="flex items-start">
                                             <div class="max-w-lg flex rounded-md shadow-sm">
                                                <span class="inline-flex items-center px-1 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-xs"> Expenses  </span>
                                                <input type="number" name="foreign_expenses" id="foreign_expenses" v-model="foreign_expenses_" class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="flex items-start sm:mr-3">
                                            <div class="max-w-lg flex rounded-md shadow-sm">
                                                <span class="inline-flex items-center px-1 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-xs"> Contingency Fund  </span>
                                                <input type="number" name="foreign_contingency" id="foreign_contingency" v-model="foreign_contingency_" class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class=" flex items-start">
                                            <div class="max-w-lg flex rounded-md shadow-sm">
                                                <span class="inline-flex items-center px-1 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-xs"> Per Diem  </span>
                                                <input type="number" name="foreign_per_diem" id="foreign_per_diem" v-model="foreign_per_diem_" class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div v-if="typeOfRequisition == 2" class="pt-4 sm:pt-2 sm:border-t sm:border-gray-200">
                    <div role="group">
                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                            <div>
                               <div class="text-base font-medium text-red-900 sm:text-sm sm:text-red-900" id="label-email">Local Currency(UGX) *</div>
                            </div>
                            <div class="mt-4 sm:mt-0 sm:col-span-2">
                                <div class="max-w-lg space-y-4 lg:grid lg:grid-cols-2 sm:grid sm:grid-cols-1 lg:items-baseline">
                                    <div class=" flex items-start">
                                         <div class="max-w-lg flex rounded-md shadow-sm sm:mr-3">
                                            <span class="inline-flex items-center px-1 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-xs"> Expenses  </span>
                                            <input :disabled="accepted_currency != 'UGX' " type="number" name="local_expenses" id="local_expenses" v-model="local_expenses_" class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" />
                                         </div>
                                    </div>
                                    <div>
                                        <div class="flex items-start">
                                             <div class="max-w-lg flex rounded-md shadow-sm">
                                                <span class="inline-flex items-center px-1 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-xs"> Contingency Fund  </span>
                                                <input :disabled="accepted_currency != 'UGX' " type="number" name="local_contingency" id="local_contingency" v-model="local_contingency_"  class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="flex items-start sm:mr-3">
                                            <div class="max-w-lg flex rounded-md shadow-sm">
                                                <span class="inline-flex items-center px-1 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-xs"> Per Diem  </span>
                                                <input :disabled="accepted_currency != 'UGX' " type="number" name="local_per_diem" id="local_per_diem" v-model="local_per_diem_" class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class=" flex items-start">
                                            <div class="max-w-lg flex rounded-md shadow-sm">
                                                <span class="inline-flex items-center px-1 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-xs font-bold"> Total Amount  </span>
                                                <input disabled='true' type="text" name="total_amount" id="total_amount" v-model="total_amount" class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div v-if="(typeOfRequisition == 1) || (typeOfRequisition == 2)" class="pt-6 sm:pt-5 sm:border-t sm:border-gray-200">
                    <div role="group" aria-labelledby="label-email">
                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                            <div>
                               <div class="text-base font-medium text-red-900 sm:text-sm sm:text-red-900" id="label-email">Choose Accountability Action *</div>
                            </div>
                            <div class="mt-4 sm:mt-0 sm:col-span-2">
                                <div class="max-w-lg space-y-4 sm:grid sm:grid-cols-2 sm:items-baseline">
                                    <div class="relative flex items-start">
                                        <div class="flex items-center h-5">
                                            <input id="expenses" name="expenses" value="expenses"  @change="checkAccountabilityAction()"  v-model="checkedActions" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                                        </div>
                                        <div class="ml-3 text-sm">
                                            <label for="expenses" class="font-medium text-gray-700">Expenses</label>
                                            <!-- <p class="text-gray-500">Get notified when someones posts a comment on a posting.</p> -->
                                        </div>
                                    </div>
                                    <div>
                                        <div class="relative flex items-start">
                                            <div class="flex items-center h-5">
                                            <input id="contingency_fund" name="contingency_fund" @change="checkAccountabilityAction()"  value="contingency_fund" v-model="checkedActions" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                                            </div>
                                            <div class="ml-3 text-sm">
                                            <label for="contingency_fund" class="font-medium text-gray-700">Contingency Fund</label>
                                            <!-- <p class="text-gray-500">Get notified when a candidate applies for a job.</p> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="relative flex items-start">
                                            <div class="flex items-center h-5">
                                                <input id="per_diem" name="per_diem" value="per_diem" @change="checkAccountabilityAction()" v-model="checkedActions" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                                            </div>
                                            <div class="ml-3 text-sm">
                                                <label for="per_diem" class="font-medium text-gray-700">Per Diem</label>
                                                <!-- <p class="text-gray-500">Get notified when a candidate accepts or rejects an offer.</p> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="relative flex items-start">
                                            <div class="flex items-center h-5">
                                                <input id="not_accountable" name="not_accountable" @change="checkAccountabilityAction()"  value="not_accountable" v-model="not_accountable" type="checkbox" true-value="1" false-value="0" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                                            </div>
                                            <div class="ml-3 text-sm">
                                                <label for="not_accountable" class="font-medium text-gray-700">Requisition Not Accountable</label>
                                                <!-- <p class="text-gray-500">Get notified when a candidate accepts or rejects an offer.</p> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label for="summary" class="block text-sm font-medium text-red-900 sm:mt-px sm:pt-2"> Summary Description *</label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <textarea  maxlength="300" v-model="summary" id="about" name="summary" rows="5" class="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md" />
                    <p class="mt-2 text-sm text-gray-500">Characters left: {{ 300 - summary.length  }} </p>
                    </div>
                </div>
                <div class="lg:grid lg:grid-cols-3 lg:gap-4 lg:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label for="about" class="block text-sm font-medium text-red-900 sm:mt-px lg:pt-2"> Detailed Description </label>
                    <div class="mt-2 lg:mt-0 lg:col-span-2">
                        <div class="mt-1 p-3 border-2 max-w-4xl rounded-xl border-gray-300">
                            <div v-if="editor" class="flex flex-wrap w-full">
                                <button @click="editor.chain().focus().toggleBold().run()" class="menu-button"
                                :class="{ 'is_active': editor.isActive('bold') }">
                                <BIconTypeBold class="h-4 w-4" />
                                </button>
                                <button @click="editor.chain().focus().toggleItalic().run()" class="menu-button"
                                :class="{ 'is_active': editor.isActive('italic') }">
                                <BIconTypeItalic class="h-4 w-4" />
                                </button>
                                <button @click="editor.chain().focus().toggleStrike().run()" class="menu-button"
                                :class="{ 'is_active': editor.isActive('strike') }">
                                <BIconTypeStrikethrough class="h-4 w-4" />
                                </button>
                                <!-- <button @click="editor.chain().focus().setParagraph().run()" class="menu-button"
                                :class="{ 'is_active': editor.isActive('paragraph') }">
                                <BIconParagraph class="h-4 w-4" />
                                </button> -->
                                <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" class="menu-button"
                                :class="{ 'is_active': editor.isActive('heading', { level: 1 }) }">
                                h1
                                </button>
                                <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" class="menu-button"
                                :class="{ 'is_active': editor.isActive('heading', { level: 2 }) }">
                                h2
                                </button>
                                <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" class="menu-button"
                                :class="{ 'is_active': editor.isActive('heading', { level: 3 }) }">
                                h3
                                </button>
                                <button @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" class="menu-button"
                                :class="{ 'is_active': editor.isActive('heading', { level: 4 }) }">
                                h4
                                </button>
                                <button @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" class="menu-button"
                                :class="{ 'is_active': editor.isActive('heading', { level: 5 }) }">
                                h5
                                </button>
                                <button @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" class="menu-button"
                                :class="{ 'is_active': editor.isActive('heading', { level: 6 }) }">
                                h6
                                </button>
                                <button @click="editor.chain().focus().toggleBulletList().run()" class="menu-button"
                                :class="{ 'is_active': editor.isActive('bulletList') }">
                                <BIconListUl class="h-4 w-4" />
                                </button>
                                <button @click="editor.chain().focus().toggleOrderedList().run()" class="menu-button"
                                :class="{ 'is_active': editor.isActive('orderedList') }">
                                <BIconListOl class="h-4 w-4" />
                                </button>
                                <button @click="editor.chain().focus().toggleUnderline().run()" class="menu-button"
                                :class="{ 'is_active': editor.isActive('underline') }">
                                <BIconTypeUnderline class="h-4 w-4" />
                                </button>
                                <button
                                    class="menu-button"
                                    @click="editor.chain().focus().setTextAlign('left').run()"
                                    :class="{ 'is_active': editor.isActive({ textAlign: 'left' }) }"
                                >
                                    <BIconTextLeft class="h-4 w-4" />
                                </button>
                                <button
                                    class="menu-button"
                                    @click="editor.chain().focus().setTextAlign('center').run()"
                                    :class="{ 'is_active': editor.isActive({ textAlign: 'center' }) }"
                                >
                                    <BIconTextCenter class="h-4 w-4" />
                                </button>
                                <button
                                    class="menu-button"
                                    @click="editor.chain().focus().setTextAlign('right').run()"
                                    :class="{ 'is_active': editor.isActive({ textAlign: 'right' }) }"
                                >
                                    <BIconTextRight class="h-4 w-4" />
                                </button>
                                <button
                                    class="menu-button"
                                    @click="editor.chain().focus().setTextAlign('justify').run()"
                                    :class="{ 'is_active': editor.isActive({ textAlign: 'justify' }) }"
                                >
                                    <BIconJustify class="h-4 w-4" />
                                </button>

                                <button
                                    class="menu-button"
                                    @click="editor.chain().focus().toggleHighlight().run()"
                                    :class="{ 'is_active': editor.isActive('highlight') }"
                                >
                                    <BIconPen class="h-4 w-4" />
                                </button>
                                <button
                                    class="menu-button"
                                    @click="
                                    editor
                                        .chain()
                                        .focus()
                                        .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                                        .run()
                                    "
                                >
                                    <BIconTable class="h-4 w-4" />
                                </button>

                                <button
                                    class="menu-button"
                                    @click="editor.chain().focus().addColumnBefore().run()"
                                    :disabled="!editor.can().addColumnBefore()"
                                >
                                    <RiInsertColumnLeft class="h-4 w-4" />
                                </button>
                                <button
                                    class="menu-button"
                                    @click="editor.chain().focus().addColumnAfter().run()"
                                    :disabled="!editor.can().addColumnAfter()"
                                >
                                    <RiInsertColumnRight class="h-4 w-4" />
                                </button>
                                <button
                                    class="menu-button"
                                    @click="editor.chain().focus().deleteColumn().run()"
                                    :disabled="!editor.can().deleteColumn()"
                                >
                                    <RiDeleteColumn class="h-4 w-4" />
                                </button>
                                <button
                                    class="menu-button"
                                    @click="editor.chain().focus().addRowBefore().run()"
                                    :disabled="!editor.can().addRowBefore()"
                                >
                                    <RiInsertRowTop class="h-4 w-4" />
                                </button>
                                <button
                                    class="menu-button"
                                    @click="editor.chain().focus().addRowAfter().run()"
                                    :disabled="!editor.can().addRowAfter()"
                                >
                                    <RiInsertRowBottom class="h-4 w-4" />
                                </button>
                                <button
                                    class="menu-button"
                                    @click="editor.chain().focus().deleteRow().run()"
                                    :disabled="!editor.can().deleteRow()"
                                >
                                    <RiDeleteRow class="h-4 w-4" />
                                </button>
                                <button
                                    class="menu-button"
                                    @click="editor.chain().focus().mergeCells().run()"
                                    :disabled="!editor.can().mergeCells()"
                                >
                                    <RiMergeCellsHorizontal class="h-4 w-4" />
                                </button>
                                <button
                                    class="menu-button"
                                    @click="editor.chain().focus().splitCell().run()"
                                    :disabled="!editor.can().splitCell()"
                                >
                                    <RiSplitCellsHorizontal class="h-4 w-4" />
                                </button>
                                <button @click="editor.chain().focus().undo().run()" class="menu-button">
                                <RiArrowGoBackFill class="h-4 w-4" />
                                </button>
                                <button @click="editor.chain().focus().redo().run()" class="menu-button">
                                <RiArrowGoForwardFill class="h-4 w-4" />
                                </button>
                            </div>
                            <editor-content :editor="editor" /> 
                        </div>
                    </div>
                </div>
                <div class="sm:grid lg:grid-cols-5 lg:gap-3 lg:items-start lg:pt-5 gap-2 sm:border-t sm:border-gray-200 ">
                    <label for="cover-photo" class="block text-sm font-medium text-red-900 sm:mt-px sm:pt-2"> Attachments *</label>
                    <div class="mt-1 sm:mt-0 sm:col-span-1">
                        <div class="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-red-900 border-dashed rounded-md">
                            <div class="w-full space-y-1 text-center">
                                <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <div class="flex text-sm text-gray-600 overflow-hidden">
                                    <label for="file-upload-1" class="flex-1 relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                    <span>{{ file_upload_1.length > 19 ? file_upload_1.slice(0, 16).concat('...') :  file_upload_1}}</span>
                                    <input id="file-upload-1" @change="handleFile($event,'1')" name="file-upload-1" type="file" class="sr-only" />
                                    </label>
                                </div>
                                <!-- <p class="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p> -->
                                <button  v-if="file_upload_1 != 'Upload Attachment 1'" class="border border-1 border-gray-400 p-1 text-xs rounded-lg text-red-900 hover:text-red-500" @click="clearFileSelection('0')">clear</button>
                            </div>
                        </div>
                    </div>
                    <div class="mt-1 sm:mt-0 sm:col-span-1">
                        <div class="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-red-900 border-dashed rounded-md">
                            <div class="w-full space-y-1 text-center">
                                <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <div class="flex text-sm text-gray-600 overflow-hidden">
                                    <label for="file-upload-2" class="flex-1 relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                    <span>{{ file_upload_2.length > 19 ? file_upload_2.slice(0, 16).concat('...') :  file_upload_2}}</span>
                                    <input id="file-upload-2" name="file-upload-2" @change="handleFile($event,'2')" type="file" class="sr-only" />
                                    </label>
                                </div>
                                <!-- <p class="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p> -->
                                <button  v-if="file_upload_2 != 'Upload Attachment 2'" class="border border-1 border-gray-400 p-1 text-xs rounded-lg text-red-900 hover:text-red-500" @click="clearFileSelection('1')">clear</button>
                            </div>
                        </div>
                    </div>
                    <div class="mt-1 sm:mt-0 sm:col-span-1">
                        <div class="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-red-900 border-dashed rounded-md">
                            <div class="w-full space-y-1 text-center">
                                <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <div class="flex text-sm text-gray-600 overflow-hidden">
                                    <label for="file-upload-3" class="flex-1 relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                    <span>{{ file_upload_3.length > 19 ? file_upload_3.slice(0, 16).concat('...') :  file_upload_3}}</span>
                                    <input id="file-upload-3" name="file-upload-3" @change="handleFile($event,'3')"  type="file" class="sr-only" />
                                    </label>
                                </div>
                                <!-- <p class="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p> -->
                                <button  v-if="file_upload_3 != 'Upload Attachment 3'" class="border border-1 border-gray-400 p-1 text-xs rounded-lg text-red-900 hover:text-red-500" @click="clearFileSelection('2')">clear</button>
                            </div>
                        </div>
                    </div>
                    <div class="mt-1 sm:mt-0 sm:col-span-1">
                        <div class="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-red-900 border-dashed rounded-md">
                            <div class="w-full space-y-1 max-w-fit text-center">
                                <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <div class="flex text-sm text-gray-600 overflow-hidden">
                                    <label for="file-upload-4" class="flex-1 relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                    <span>{{ file_upload_4.length > 19 ? file_upload_4.slice(0, 16).concat('...') :  file_upload_4}}</span>
                                    <input id="file-upload-4" name="file-upload-4" @change="handleFile($event,'4')"  type="file" class="sr-only" />
                                    </label>
                                </div>
                                <!-- <p class="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p> -->
                                <button v-if="file_upload_4 != 'Upload Attachment 4'" class="border border-1 border-gray-400 p-1 text-xs rounded-lg text-red-900 hover:text-red-500" @click="clearFileSelection('3')">clear</button>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label for="country" class="block text-sm font-medium text-red-900 sm:mt-px sm:pt-2"> {{ select_who_text }} *</label>
                    <Combobox as="div" class="sm:mt-0 sm:col-span-2" v-model="selectedPerson">
                        <!-- <ComboboxLabel class="block text-sm font-medium text-gray-700">Assigned to</ComboboxLabel> -->
                        <div class="relative mt-1">
                        <ComboboxInput placeholder="Click the arrows or start typing name here" class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm" @change="query =$event.target.value" :display-value="(person) => person.name" />
                        <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"> 
                            <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </ComboboxButton>

                        <ComboboxOptions v-if="filteredPeople.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            <ComboboxOption v-for="person in filteredPeople" :key="person.id" :value="person" as="template" v-slot="{ active, selected }">
                            <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                                <span :class="['block truncate', selected && 'font-semibold']">
                                {{ person.name }}
                                </span>

                                <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                </span>
                            </li>
                            </ComboboxOption>
                        </ComboboxOptions>
                        </div>
                    </Combobox>
                </div>
                
            </div>
        </div>

        </div>

        <div class="pt-5">
        <div class="flex justify-end">
            <button type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Cancel</button>
            <button  @click.prevent="submit()" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-900 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">submit</button>
        </div>
      </div>
    </form>
 </div>
</template>

<script>
import { ref, computed, watch, inject, onMounted } from 'vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/vue'
import { useEditor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableHeader from "@tiptap/extension-table-header";
import TableCell from '@tiptap/extension-table-cell'
import TextAlign from "@tiptap/extension-text-align";
import Highlight from "@tiptap/extension-highlight";
import Underline from '@tiptap/extension-underline'
import {
  BIconTypeBold, BIconTypeItalic, BIconTypeStrikethrough, BIconListOl,
  BIconListUl, BIconTypeUnderline, BIconTextRight, BIconTextLeft, BIconTextCenter,
  BIconJustify, BIconPen, BIconTable,
} from "bootstrap-icons-vue";
import {
  RiArrowGoBackFill, RiArrowGoForwardFill, RiInsertColumnLeft, RiInsertColumnRight,
  RiDeleteColumn, RiInsertRowBottom, RiInsertRowTop, RiDeleteRow,
  RiMergeCellsHorizontal, RiSplitCellsHorizontal,
} from 'vue-remix-icons';
import Api from "@/components/api/Api";
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
export default {
    name: "RequisitionerAddRequisitionComponent",
    components: {
        CheckIcon, Combobox, ComboboxButton,ComboboxInput, ComboboxOption, ComboboxOptions,
        SelectorIcon, EditorContent, BIconTypeBold, BIconTypeItalic, BIconTypeStrikethrough,
        BIconListOl, BIconListUl, BIconTypeUnderline, BIconTextRight, BIconTextLeft, BIconTextCenter,
        BIconJustify, BIconPen, BIconTable, RiArrowGoBackFill, RiArrowGoForwardFill, RiInsertColumnLeft,
        RiInsertColumnRight, RiDeleteColumn, RiInsertRowBottom, RiInsertRowTop, RiDeleteRow, RiMergeCellsHorizontal,
        RiSplitCellsHorizontal, AlertError,AlertSuccess
    },
    setup(){

        let accepted_currency = ref(null);

        let budgetLineSelected = ref('0')
        let budgetLines = ref([{'id' : '0', 'name': 'select budget line'}])

        let purposeForRequisition = ref(['Select Purpose']);
        let purposeForRequisitionSelected = ref('Select Purpose');


        let purposeForRequisitionProcurable = ['Select Purpose','Field Trip','Office Requirement','Medical Refund',
                                                'Customer Refund','Employee Refund', 'Contingency Fund','Other'];

        let purposeForStockAdjustmentRequisition = ['Select Purpose','Damages','Donations','Expiries',
                                                   'Count In / Count Out','Replacement', 'Marketing/Sample',
                                                   'Purchase Return','Staff Use', 'Quality Assurance'];

        let purposeForExcessGoodsRequisition = ['Select Purpose',
                                                'Quantities beyond stipulated on the Released Local Purchase Order',
                                                'Items not ordered for, but delivered by the supplier',
                                                'Complimentary items',
                                                'Items donated to JMS by suppliers',
                                                'Others'];

        let select_who_text = ref('Select Supervisor to send to')
        let supervisors = ref([{ id: 0, user_id: 0, name: '' }])

        let warehouseOfficers = [
        { id: 0, name: '' },
        { id: 7, name: 'Run Town' },
        { id: 8, name: 'Run Town' },
        { id: 9, name: 'Run Town' },
        { id: 10, name: 'Run Town' },
        { id: 11, name: 'Run Town' },
        { id: 12, name: 'Run Town' },
        // More users...
        ]

        const people = ref([
        { id: 0, user_id: 0, name: '' }
        ])
        const query = ref('')
        const selectedPerson = ref(people.value[0])
        const filteredPeople = computed(() =>
        query.value === ''
            ? people.value
            : people.value.filter((person) => {
                return person.name.toLowerCase().includes(query.value.toLowerCase())
            })
        )
        let typeOfRequisition = ref("0");

        function changeTypeOfRequisition(){

            if(typeOfRequisition.value == 0){

                purposeForRequisitionSelected.value = 'Select Purpose';
                purposeForRequisition.value = ['Select Purpose'];
                select_who_text.value = 'Select Supervisor to send to';
                people.value = [{ id: 0, user_id: 0, name: '' }]

            }else if(typeOfRequisition.value == 1 || typeOfRequisition.value == 2){

                purposeForRequisitionSelected.value = 'Select Purpose';
                purposeForRequisition.value = purposeForRequisitionProcurable;
                select_who_text.value = 'Select Supervisor to send to';
                people.value = supervisors.value;

            }else if(typeOfRequisition.value == 3){

                purposeForRequisitionSelected.value = 'Select Purpose';
                purposeForRequisition.value = purposeForStockAdjustmentRequisition;
                select_who_text.value = "Select warehouse officer to send to";
                people.value =  warehouseOfficers;
                

            }else if(typeOfRequisition.value == 4){

                purposeForRequisitionSelected.value = 'Select Purpose';
                purposeForRequisition.value = purposeForExcessGoodsRequisition;
                select_who_text.value = "Select warehouse officer to send to";
                people.value = warehouseOfficers;

            }

            checkedActions.value   = [];
            not_accountable.value  = "0";

        }

        const editor = useEditor({
        editorProps: {
            attributes: {
            class:
                "prose prose-sm text-sm" 
            },
        },
        extensions: [
            StarterKit,
            TextAlign.configure({
              types: ["heading", "paragraph"],
              alignments: ["left", "center", "right", "justify"],
            }),
            Highlight,
            Table.configure({
              resizable: true,
            }),
            TableRow,
            TableHeader,
            TableCell,
            Underline
            
        ],
        })

        let checkedActions = ref([])
        let not_accountable = ref('0')

        let local_contingency_  = ref('0')
        let local_expenses_     = ref('0')
        let local_per_diem_     = ref('0')
        //let total_amount       = ref(0)
        let loggedInUser =  inject("loggedInUser");

        let isEditing  = inject('isEditing')
         
         const local_contingency = computed(() => {

            if(accepted_currency.value == 'UGX'){

              return local_contingency_.value ? local_contingency_.value : '0'

            }else{

                //  return  (parseFloat(foreign_contingency.value.replace(/,/g,'')) * parseFloat(exchange_rate.value.replace(/,/g,'')));
                 return  (parseFloat(foreign_contingency.value) * parseFloat(exchange_rate.value.replace(/,/g,'')));

            }

         })

         const local_expenses = computed(() => {

            if(accepted_currency.value == 'UGX'){

             return local_expenses_.value ? local_expenses_.value : '0'

            }else{

                // return (parseFloat(foreign_expenses.value.replace(/,/g,'')) * parseFloat(exchange_rate.value.replace(/,/g,'')));
                return (parseFloat(foreign_expenses.value) * parseFloat(exchange_rate.value.replace(/,/g,'')));

            }

         })

         const local_per_diem = computed(() => {

          if(accepted_currency.value == 'UGX'){

            return local_per_diem_.value ? local_per_diem_.value : '0'

          }else{

            //  return (parseFloat(foreign_per_diem.value.replace(/,/g,'')) * parseFloat(exchange_rate.value.replace(/,/g,'')));
             return (parseFloat(foreign_per_diem.value) * parseFloat(exchange_rate.value.replace(/,/g,'')));

          }

         })
        
        let foreign_contingency_  = ref('0')
        let foreign_expenses_     = ref('0')
        let foreign_per_diem_     = ref('0')
        //let total_amount       = ref(0)
        let exchange_rate = ref('0')
        let isLoading = ref(false);
        let errorMessage = ref("");
        let successMessage = ref("");

         const foreign_contingency = computed(() => {

           return foreign_contingency_.value ? foreign_contingency_.value : '0'

         })

         const foreign_expenses = computed(() => {

           return foreign_expenses_.value ? foreign_expenses_.value : '0'

         })

         const foreign_per_diem = computed(() => {

              return foreign_per_diem_.value ? foreign_per_diem_.value : '0'

         })

         watch(foreign_contingency_, (newX) => {

            local_contingency_.value = newX * exchange_rate.value

         })

         watch(foreign_per_diem_, (newX) => {

            local_per_diem_.value = newX * exchange_rate.value

         })

         watch(foreign_expenses_, (newX) => {

            local_expenses_.value = newX * exchange_rate.value

         })

         const total_amount = computed(() => {

             return  (parseFloat(local_contingency.value) + parseFloat(local_expenses.value) + 
                   parseFloat(local_per_diem.value)).toLocaleString()

         })

         let file_upload_1 = ref('Upload Attachment 1')
         let file_upload_2 = ref('Upload Attachment 2')
         let file_upload_3 = ref('Upload Attachment 3')
         let file_upload_4 = ref('Upload Attachment 4')
         let filesArray    = ref([null,null,null,null])
         let fileInput4Ref = ref(null)

         let summary = ref("");

        async function loadSupervisiorList(){

            try {

                    const res = await Api().get(`/requisition/get-supervisors`);

                    if (res.data.status === "success") {

                        supervisors.value = res.data.supervisors;

                    }

                } catch (err) {

                    if (err.response) {

                      //

                    } else if (err.request) {

                      //

                    } else {
                        
                       //

                    }
                }


         }
        async function getExchangeRate(){

            try {
                     isLoading.value = true;
                    const res = await Api().get(`/requisition/get-exchange-rate/${accepted_currency.value}`);

                    if (res.data.status === "success") {

                        exchange_rate.value = res.data.exchangeRate[0].amount;
                       

                    }

                } catch (err) {

                    if (err.response) {

                       //
                    } else if (err.request) {

                        //

                    } else {
                        
                           //

                    }
                }

            isLoading.value = false;
        }
        
        function handleFile(e, fileNameVar){

            var files = e.target.files || e.dataTransfer.files;
            if(files[0]){

                let filename = files[0].name;
                    if(fileNameVar == '1'){
                        file_upload_1.value = filename;
                        filesArray.value[0] = files[0];

                    }else if(fileNameVar == '2'){

                        file_upload_2.value = filename
                        filesArray.value[1] = files[0];

                    }else if (fileNameVar == '3'){

                        file_upload_3.value = filename
                        filesArray.value[2] = files[0];

                    }else if (fileNameVar == '4'){

                        file_upload_4.value = filename
                        filesArray.value[3] = files[0];

                    }
            }

        }

        function clearFileSelection(file){

            if(file == '0'){

                file_upload_1.value = 'Upload Attachment 1'
                filesArray.value[0] = null;

            }else if(file == '1'){
                
                file_upload_2.value = 'Upload Attachment 2'
                filesArray.value[1] = null;

            }else if(file == '2'){

                file_upload_3.value = 'Upload Attachment 3'
                filesArray.value[2] = null;
                
            }else if(file == '3'){
                
                // fileInput4Ref.value = null;
                
                file_upload_4.value = 'Upload Attachment 4'
                filesArray.value[3] = null;

            }



        }
        function clearAllFileSelections(){

                file_upload_1.value = 'Upload Attachment 1'
                filesArray.value[0] = null;

                file_upload_2.value = 'Upload Attachment 2'
                filesArray.value[1] = null;

                file_upload_3.value = 'Upload Attachment 3'
                filesArray.value[2] = null;
            
                file_upload_4.value = 'Upload Attachment 4'
                filesArray.value[3] = null;

        }

       async function empty_values(){

            local_contingency_.value = 0;
            local_expenses_.value = 0;
            local_per_diem_.value = 0;

            foreign_contingency_.value = 0;
            foreign_expenses_.value = 0;
            foreign_per_diem_.value = 0;
            if(accepted_currency.value != 'UGX')
              await getExchangeRate();

        }

        function checkAccountabilityAction(){

            if(not_accountable.value == '1'){
                
                checkedActions.value = [];

            }

        }

        async function submit (){

            errorMessage.value = "";
            successMessage.value = "";

             let typeOfRequisitionForSubmit = '';

            if(typeOfRequisition.value == '0'){

                errorMessage.value = "Type of requisition cannot be empty"
                window.scrollTo({ top: 0, behavior: "smooth" });

                return;

            }else if(typeOfRequisition.value == '1'){

                typeOfRequisitionForSubmit = 'procurable'

            }else if ((typeOfRequisition.value == '2')){

                typeOfRequisitionForSubmit = 'non procurable'

            }else if (typeOfRequisition.value == '3'){

                typeOfRequisitionForSubmit = 'stock adjustment'

            }else if (typeOfRequisition.value == '4'){

                typeOfRequisitionForSubmit = 'excess goods requisition'

            }


            if(purposeForRequisitionSelected.value == "Select Purpose"){

                errorMessage.value = "Please select purpose for requisition"
                window.scrollTo({ top: 0, behavior: "smooth" });
                return;

            }

            if(!accepted_currency.value && typeOfRequisition.value == '2'){

                errorMessage.value = "Please select a currency"
                window.scrollTo({ top: 0, behavior: "smooth" });
                return;

            }

            if(checkedActions.value.length == 0 && not_accountable.value == '0'){

                errorMessage.value = "Please select an accountability action"
                window.scrollTo({ top: 0, behavior: "smooth" });
                return;

            }

            if((!local_expenses.value || local_expenses.value == 0) && checkedActions.value.includes('expenses') && typeOfRequisition.value == '2'){

                if(accepted_currency.value != 'UGX'){

                   errorMessage.value = "Please enter a value for foreign currency expenses";

                }else{

                  errorMessage.value = "Please enter a value for local currency expenses"

                }
                window.scrollTo({ top: 0, behavior: "smooth" });
                return;

            }

            if((!local_contingency.value || local_contingency.value == 0) && checkedActions.value.includes('contingency_fund') && typeOfRequisition.value == '2'){

                if(accepted_currency.value != 'UGX'){

                   errorMessage.value = "Please enter a value for foreign currency contingency fund";

                }else{

                   errorMessage.value = "Please enter a value for local currency contingency fund";

                }

                window.scrollTo({ top: 0, behavior: "smooth" });
                return;

            }

            if((!local_per_diem.value || local_per_diem.value == 0) && checkedActions.value.includes('per_diem') && typeOfRequisition.value == '2'){

                if(accepted_currency.value != 'UGX'){

                     errorMessage.value = "Please enter a value for foreign currency per diem";

                }else{

                 errorMessage.value = "Please enter a value for local currency per diem";

                }

                window.scrollTo({ top: 0, behavior: "smooth" });
                return;

            }
            
            if(summary.value.trim() == ''){

                errorMessage.value = "Summary Description cannot be empty"
                window.scrollTo({ top: 0, behavior: "smooth" });
                return;

            }


            if(selectedPerson.value.id == '0'){

                errorMessage.value = "Please select a supervisor to send requisition to"
                window.scrollTo({ top: 0, behavior: "smooth" });
                return;

            }   

            // let checkIfAllFilesNull = true;
            // for(let j = 0; j < filesArray.value.length; j++){

            //     if(filesArray.value[j]){

            //         checkIfAllFilesNull = false;

            //     }

            // }

            // if(checkIfAllFilesNull){

            //     errorMessage.value = "Please add atleast one Attachment"
            //     window.scrollTo({ top: 0, behavior: "smooth" });
            //     return;

            // }

            let requisition = {
                'requisition_id': isEditing.value ,
                'requisitioner_directorate_id' : loggedInUser.value.directorate_id,
                'type_of_requisition' : typeOfRequisitionForSubmit,
                'purposeForRequisitionSelected' : purposeForRequisitionSelected.value,
                 'accountability_actions' : JSON.stringify(checkedActions.value),
                'not_accountable' : not_accountable.value,
                'accepted_currency' : accepted_currency.value,
                'exchange_rate' : exchange_rate.value,
                'summary':  summary.value,
                'description':  editor.value.getHTML(),
                'local_expenses' : local_expenses.value? local_expenses.value : '0',
                'local_contingency' : local_contingency.value? local_contingency.value : '0',
                'local_per_diem'    : local_per_diem.value? local_per_diem.value: '0',
                'foreign_expenses' : foreign_expenses.value? foreign_expenses.value : '0',
                'foreign_contingency' : foreign_contingency.value? foreign_contingency.value: '0',
                'foreign_per_diem'    : foreign_per_diem.value? foreign_per_diem.value: '0',
                'requisition_by'    : loggedInUser.value.id,
                'approver_user_id'    : selectedPerson.value.user_id,
                'erp_systems_role_id'    : selectedPerson.value.id,

            }
            
            isLoading.value =  true;

           const formData = new FormData();
           Object.entries(requisition).forEach(([key, value]) => {
                formData.append(key, value);
            });

           //formData.append('requisition',requisition);
           formData.append('file1', filesArray.value[0]);
           formData.append('file2', filesArray.value[1]);
           formData.append('file3', filesArray.value[2]);
           formData.append('file4', filesArray.value[3]);
            
            
            const options = {
                headers: {
                "Content-Type": "multipart/form-data",
                },
            };

            try {
            const res = await Api().post("/requisition/submit", formData,options)

            if (res.data.status === "success") {

                successMessage.value  = res.data.message;
                
                typeOfRequisition.value = '0';
                purposeForRequisitionSelected.value = "Select Purpose";
                checkedActions.value = [];
                not_accountable.value = '0';
                accepted_currency.value = null;
                exchange_rate.value = '0';
                summary.value = '';
                editor.value.commands.clearContent();
                local_contingency_.value = 0;
                local_expenses_.value = 0;
                local_per_diem_.value = 0;
                foreign_contingency_.value = 0;
                foreign_expenses_.value = 0;
                foreign_per_diem_.value = 0;
                people.value = [{ id: 0, user_id:  0,  name: '' }];
                selectedPerson.value = people.value[0];
                clearAllFileSelections();


            }else{

               errorMessage.value = res.data.message;
            }

          } catch (err) {

              if (err.response) {

                 if (err.response.status === 422) {

                    //

                  }else{

                      errorMessage.value = "An error has occurred! " + err.response.message;

                  }

              } else if (err.request) {

                 errorMessage.value =  err.request.data.message;

              } else {

                 errorMessage.value =  err.message;

              }
          }

           isLoading.value = false;
           window.scrollTo({ top: 0, behavior: "smooth" });

        }

        async function showRequisition(requisition_id){

            isLoading.value = true;

            try{

            const response = await  Api().get(`/requisition/get-requisition-for-editing/${requisition_id}`);

            if (response.data.status === "success") {

                     let requisition = response.data.requisition;
                    // requisitionAttachmentsProp.value = response.data.attachments;
                    // requisitionTransitionsProp.value = response.data.transitions;
                    if(requisition.type_of_requisition == 'procurable'){

                        typeOfRequisition.value = 1;
                        changeTypeOfRequisition();

                    }else if (requisition.type_of_requisition == 'non procurable'){

                        typeOfRequisition.value = 2;
                        changeTypeOfRequisition();

                    }else if (requisition.type_of_requisition == 'stock adjustment'){

                        typeOfRequisition.value = 3;
                        changeTypeOfRequisition();

                    }else if (requisition.type_of_requisition == 'excess goods requisition'){

                        typeOfRequisition.value = 4;
                        changeTypeOfRequisition();

                    }

                    purposeForRequisitionSelected.value = requisition.purpose_of_requisition;
                    accepted_currency.value = requisition.currency? (requisition.currency).toUpperCase() : null;
                    if(accepted_currency.value != 'UGX'){
                     empty_values();
                      foreign_contingency_.value    = requisition.foreign_contingency_fund;
                      foreign_expenses_.value       = requisition.foreign_expenses;
                      foreign_per_diem_.value       = requisition.foreign_per_diem;
                    }
                    local_contingency_.value      = requisition.local_contingency_fund;
                    local_expenses_.value         = requisition.local_expenses;
                    local_per_diem_.value         = requisition.local_per_diem;

                   

                    summary.value = requisition.summary;
                    editor.value.commands.setContent(requisition.description)

                    if(requisition.is_contingency_fund_accountable == 1){

                        checkedActions.value.push('contingency_fund')

                    }

                    if(requisition.is_per_diem_accountable == 1){

                        checkedActions.value.push('per_diem')

                    }

                    if(requisition.is_expenses_accountable == 1){

                        checkedActions.value.push('expenses')

                    }

                    if(requisition.is_accountable == 1){

                         not_accountable.value = '0';

                    }else if(requisition.is_accountable == 0){

                        not_accountable.value = '1';

                    }
                    
                
            }

            }catch (err) {

                if (err.response) {

                errorMessage.value = err.response.data.message;

                    //return { 'message': err.response.data.message, 'status': 'error' };

                } else if (err.request) {

                    errorMessage.value = err.request.data.message

                    //return { 'message': err.request.data.message, 'status': 'error' };

                } else {

                    errorMessage.value = err.message

                // return { 'message': err.message, 'status': 'error' };

                }
            }

            isLoading.value = false;
        }

        

        onMounted(()=>{

          if(isEditing.value > 0){


            // console.log(isEditing.value)
            showRequisition(isEditing.value)

          }
            
        })

        loadSupervisiorList();

        return{

            purposeForRequisition, purposeForRequisitionSelected,
            query,
            selectedPerson,
            filteredPeople,
            changeTypeOfRequisition,
            typeOfRequisition,
            select_who_text,
            accepted_currency,
            budgetLineSelected, budgetLines,
            editor,
            submit,checkedActions,checkAccountabilityAction,not_accountable,
            local_contingency_,local_expenses_,local_per_diem_,total_amount,
            empty_values, foreign_contingency_, foreign_expenses_, foreign_per_diem_,
            exchange_rate,foreign_contingency,foreign_expenses, foreign_per_diem,
            handleFile,file_upload_1,file_upload_2,file_upload_3,file_upload_4,
            loadSupervisiorList,errorMessage, successMessage, isLoading, filesArray,clearFileSelection,
            fileInput4Ref, summary
            
        }
    }

}
</script>

<style>

</style>