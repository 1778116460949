import axios from "axios";

let BaseApi = axios.create({
    baseURL: "https://bridgeapp.jms.co.ug/api/",
    // baseURL: "http://localhost:8001/api/"
});

// Add a request interceptor
BaseApi.interceptors.request.use(
    function (config) {
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// Add a response interceptor
BaseApi.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);

let Api = function () {
    let token = localStorage.getItem("token");

    if (token) {
        BaseApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    return BaseApi;
};

export default Api;
