<template>
<div >
    <Loading v-model:active="isLoading" />
    <div class=" mt-2 mb-2 flex justify-center items-center">
        <AlertSuccess
            v-if="successMessage"
            :message="successMessage"
            @hide="successMessage = null"
        />
        <AlertError
            v-if="errorMessage"
            :message="[errorMessage]"
            @hide="errorMessage = null"
        />
    </div>
    <!-- div section heading -->
    <div class="pb-5 border-b border-gray-200">
        <h3 class="text-center md:text-left text-lg leading-6 font-medium text-gray-900">Today's Requisition Statistics</h3>
    </div>
    <!-- end of div section heading -->
    <div>
        <dl class="pt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
        <div v-for="item in stats" :key="item.name" >
            <div @click="goToRequisitionList(item.name,'today')" :class="item.class" class="cursor-pointer px-4 py-5 sm:p-6 overflow-hidden">
                <dt class="text-sm font-medium text-white truncate">
                {{ item.name }}
                </dt>
                <dd class="mt-1 text-3xl font-semibold text-white">
                {{ item.stat }}
                </dd>
            </div>
        </div>
        </dl>
    </div>
    <!-- statistics tabs -->
    <div class="pb-5 pt-10 border-b border-gray-200 sm:pb-0">
        <h3 class="text-sm leading-6 font-medium text-gray-900">Requisitions Worked On: </h3>
        <div class="mt-3 sm:mt-4">
            <div class="sm:hidden">
            <label for="current-tab" class="sr-only">Select a tab</label>
            <select id="current-tab" v-model="requisitionsWorkedOn" @change="changeStatsCardDataForRequisitionsWorkedOn(requisitionsWorkedOn)" name="current-tab" class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
            </select>
            </div>
            <div class="hidden sm:block">
            <nav class="-mb-px flex space-x-8">
                <a v-for="tab in tabs" @click="currentTab = tab.name; changeStatsCardDataForRequisitionsWorkedOn(currentTab)" :key="tab.name" :href="tab.href" :class="[currentTab == tab.name ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm']" :aria-current="tab.current ? 'page' : undefined">
                {{ tab.name }}
                </a>
            </nav>
            </div>
        </div>
        </div>

    <!-- statistics for requisitions worked on  -->
    <div>
    <dl class="pt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
        <!-- <div v-for="item in stats1" :key="item.name" class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6"> -->
        <div  v-for="item in stats1" :key="item.name">
            <div @click="goToRequisitionList(item.name,currentTab)"   :class="item.class" class="cursor-pointer px-4 py-5 overflow-hidden sm:p-6">
                <dt class="text-sm font-medium text-white truncate">
                    {{ item.name }}
                </dt>
                <dd class="mt-1 text-3xl font-semibold text-white">
                    {{ item.stat }}
                </dd>
            </div>
        </div>
    </dl>
    </div>

    <div class="pb-5 pt-10 border-b border-gray-200 sm:pb-0">
    <h3 class="text-sm leading-6 font-medium text-gray-900">How long Requisition has been with status: </h3>
    <div class="mt-3 sm:mt-4">
        <div class="sm:hidden">
        <label for="current-tab1" class="sr-only">Select a tab</label>
        <select id="current-tab1" v-model="timeSpentOnStatus" @change="changeStatsCardData(timeSpentOnStatus)" name="current-tab1" class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
            <option v-for="tab in tabs1"  :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
        </select>
        </div>
        <div class="hidden sm:block">
        <nav class="-mb-px flex space-x-8">
            <a v-for="tab in tabs1"  @click="currentTab1 = tab.name; changeStatsCardData(currentTab1)" :key="tab.name" :href="tab.href" :class="[ currentTab1 == tab.name ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm']" :aria-current="tab.current ? 'page' : undefined">
            {{ tab.name }}
            </a>
        </nav>
        </div>
    </div>
    </div>
    <!-- statistics time stats for pending requisitions -->
    <div>
    <dl class="pt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
        <div v-for="item in stats2" :key="item.name">
            <div  :class="item.class" class="px-4 py-5 overflow-hidden sm:p-6">
                <dt class="text-sm font-medium text-white truncate">
                    {{ item.name }}
                </dt>
                <dd class="mt-1 text-3xl font-semibold text-white">
                    {{ item.stat }}
                </dd>
            </div>
        </div>
    </dl>
    </div>

</div> 
</template>

<script>
import { ref, inject } from 'vue';
import { useRouter } from "vue-router";
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import Api from "@/components/api/Api";
// import {initCurrent_active_user_role} from "@/stores/Requisition.js"

export default {

     components: {
        
          AlertError, AlertSuccess
    },

    setup(){

        let status_title = inject('status_title');
        let activeRole = inject('activeRole');
        let loggedInUser =  inject("loggedInUser");

        let isLoading = ref(false);
        let errorMessage = ref("");
        let successMessage = ref("");

        status_title.value = ''

        const stats = ref([
        { name: 'Pending', stat: '0' ,   class: 'text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg'},
        { name: 'Approved', stat: '0',   class: 'text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg' },
        { name: 'Sent Back', stat: '0', class: 'text-white bg-gradient-to-r from-amber-500 via-amber-600 to-amber-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-amber-300 dark:focus:ring-amber-800 shadow-lg shadow-amber-500/50 dark:shadow-lg dark:shadow-amber-800/80 font-medium rounded-lg' },
        { name: 'Rejected', stat: '0',  class: 'text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg' },
        ])
        const stats1 = ref([
        { name: 'Approved', stat: '-', class: 'text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg' },
        { name: 'Sent Back', stat: '-', class: 'text-white bg-gradient-to-r from-amber-500 via-amber-600 to-amber-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-amber-300 dark:focus:ring-amber-800 shadow-lg shadow-amber-500/50 dark:shadow-lg dark:shadow-amber-800/80 font-medium rounded-lg' },
        { name: 'Rejected', stat: '-', class: 'text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg' },
        ])
        const stats2 = ref([
        { name: 'Pending', stat: '0', class: 'text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg' },
        { name: 'Sent Back', stat: '0', class: 'text-white bg-gradient-to-r from-amber-500 via-amber-600 to-amber-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-amber-300 dark:focus:ring-amber-800 shadow-lg shadow-amber-500/50 dark:shadow-lg dark:shadow-amber-800/80 font-medium rounded-lg' }
        ])
        const tabs = [
        { name: 'Yesterday', href: '#', current: false },
        { name: 'This Week', href: '#', current: false },
        { name: 'This Month', href: '#', current: true }
        ]

        const tabs1 = [
        { name: 'Less than a week', href: '#', current: true },
        { name: 'A week', href: '#', current: false },
        { name: 'Two Weeks', href: '#', current: false },
        { name: 'A month', href: '#', current: false },
        { name: 'Older than a month', href: '#', current: false }
        ]

        let currentTab  = ref("Yesterday");
        let currentTab1 = ref("Less than a week");
        let timeSpentOnStatus = ref('A week');

        const yesterdayData =   ref([{ name: 'Approved', stat: '0', class: 'text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg' }, 
                                 { name: 'Sent Back', stat: '0', class: 'text-white bg-gradient-to-r from-amber-500 via-amber-600 to-amber-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-amber-300 dark:focus:ring-amber-800 shadow-lg shadow-amber-500/50 dark:shadow-lg dark:shadow-amber-800/80 font-medium rounded-lg'  }, 
                                 { name: 'Rejected', stat: '0', class: 'text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg'  }]);
        const thisWeekData  =   ref([{ name: 'Approved', stat: '0', class: 'text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg' }, 
                                 { name: 'Sent Back', stat: '0', class: 'text-white bg-gradient-to-r from-amber-500 via-amber-600 to-amber-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-amber-300 dark:focus:ring-amber-800 shadow-lg shadow-amber-500/50 dark:shadow-lg dark:shadow-amber-800/80 font-medium rounded-lg'  }, 
                                 { name: 'Rejected', stat: '0', class: 'text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg' }]);
        const thisMonthData =    ref([{ name: 'Approved', stat: '0', class: 'text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg'  }, 
                                 { name: 'Sent Back', stat: '0', class: 'text-white bg-gradient-to-r from-amber-500 via-amber-600 to-amber-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-amber-300 dark:focus:ring-amber-800 shadow-lg shadow-amber-500/50 dark:shadow-lg dark:shadow-amber-800/80 font-medium rounded-lg'  }, 
                                 { name: 'Rejected', stat: '0',class: 'text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg' }]);
        stats1.value = yesterdayData.value;

        const lessthanAweek = ref([
        { name: 'Pending', stat: '0', class: 'text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg' },
        { name: 'Sent Back', stat: '0', class: 'text-white bg-gradient-to-r from-amber-500 via-amber-600 to-amber-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-amber-300 dark:focus:ring-amber-800 shadow-lg shadow-amber-500/50 dark:shadow-lg dark:shadow-amber-800/80 font-medium rounded-lg' }
        ])
        const aWeek = ref([
        { name: 'Pending', stat: '0', class: 'text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg' },
        { name: 'Sent Back', stat: '0', class: 'text-white bg-gradient-to-r from-amber-500 via-amber-600 to-amber-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-amber-300 dark:focus:ring-amber-800 shadow-lg shadow-amber-500/50 dark:shadow-lg dark:shadow-amber-800/80 font-medium rounded-lg' }
        ])
        const twoWeeks = ref([
        { name: 'Pending', stat: '0', class: 'text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg' },
        { name: 'Sent Back', stat: '0', class: 'text-white bg-gradient-to-r from-amber-500 via-amber-600 to-amber-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-amber-300 dark:focus:ring-amber-800 shadow-lg shadow-amber-500/50 dark:shadow-lg dark:shadow-amber-800/80 font-medium rounded-lg' }
        ])
        const aMonth = ref([
        { name: 'Pending', stat: '0', class: 'text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg' },
        { name: 'Sent Back', stat: '0', class: 'text-white bg-gradient-to-r from-amber-500 via-amber-600 to-amber-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-amber-300 dark:focus:ring-amber-800 shadow-lg shadow-amber-500/50 dark:shadow-lg dark:shadow-amber-800/80 font-medium rounded-lg' }
        ])
        const olderThanMonth = ref([
        { name: 'Pending', stat: '0', class: 'text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 shadow-lg shadow-teal-500/50 dark:shadow-lg dark:shadow-teal-800/80 font-medium rounded-lg' },
        { name: 'Sent Back', stat: '0', class: 'text-white bg-gradient-to-r from-amber-500 via-amber-600 to-amber-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-amber-300 dark:focus:ring-amber-800 shadow-lg shadow-amber-500/50 dark:shadow-lg dark:shadow-amber-800/80 font-medium rounded-lg' }
        ])
        // const lessthanAweek = ref([
        // { name: 'Pending', stat: '0'},
        // { name: 'Sent Back', stat: '0'}
        // ])
        stats2.value = lessthanAweek.value;
        function changeStatsCardData(period){

           
            if(period == 'Less than a week'){
                 stats2.value = lessthanAweek.value
            }else if(period == 'A week'){
                stats2.value = aWeek.value
            }else if(period == 'Two Weeks'){
                 stats2.value = twoWeeks.value
            }else if(period == 'A month'){
                 stats2.value = aMonth.value
            }else if(period == 'Older than a month'){
                 stats2.value = olderThanMonth.value
            }
           

        }

        let requisitionsWorkedOn = ref('Yesterday');
        function changeStatsCardDataForRequisitionsWorkedOn(period){

            if(period == 'Yesterday'){

                stats1.value = yesterdayData.value;

            }else if(period == 'This Week'){

                stats1.value = thisWeekData.value;

            }else if(period == 'This Month'){


                stats1.value = thisMonthData.value;

            }

           

        }

        const router = useRouter();
        function goToRequisitionList(status,dateRange){

           
            
            if(status == 'Pending'){

               router.push({name: 'DirectorFinancePendingComponent', params: { 'status':status.toLowerCase(), 'date': dateRange.toLowerCase().split(' ').join('_') }})
            
            }else if(status == 'Approved'){

               router.push({name: 'DirectorFinanceApprovedComponent', params: { 'status':status.toLowerCase(), 'date': dateRange.toLowerCase().split(' ').join('_') }})
    

            }else if(status == 'Rejected'){

               router.push({name: 'DirectorFinanceRejectedComponent', params: { 'status':status.toLowerCase(), 'date': dateRange.toLowerCase().split(' ').join('_') }})

                
            }
       
       }

        async function getDashboardStats(role_name){


            let role_id = 0;

            if(role_name == 'Director Finance'){

                role_id = 11;

            }

            isLoading.value = true;

            try{ 

            const response = await  Api().get(`/requisition/get-dashboard-stats/${loggedInUser.value.id}/${role_id}`);

            if (response.data.status === "success") {

                // stats.value[0].stat = response.data.pendingToday.Total;
                // stats.value[1].stat = response.data.approvedToday.Total;
                // stats.value[2].stat = response.data.sentBackToday.Total;
                // stats.value[3].stat = response.data.rejectedToday.Total;

                for ( let i =0 ; i <response.data.todayStats.length; i++ ){

                    if(response.data.todayStats[i].status == 'Pending'){

                        stats.value[0].stat = response.data.todayStats[i].stat; 

                    }else if(response.data.todayStats[i].status == 'Approved'){

                        stats.value[1].stat = response.data.todayStats[i].stat; 

                    }else if(response.data.todayStats[i].status == 'Sent Back'){

                        stats.value[2].stat = response.data.todayStats[i].stat; 

                    }else if(response.data.todayStats[i].status == 'Rejected'){

                        stats.value[3].stat = response.data.todayStats[i].stat; 
                    }
                }

                for ( let i =0 ; i <response.data.yesterdayStats.length; i++ ){

                    if(response.data.yesterdayStats[i].status == 'Approved'){

                        yesterdayData.value[0].stat = response.data.yesterdayStats[i].stat; 

                    }else if(response.data.yesterdayStats[i].status == 'Sent Back'){

                        yesterdayData.value[1].stat = response.data.yesterdayStats[i].stat; 

                    }else if(response.data.yesterdayStats[i].status == 'Rejected'){

                        yesterdayData.value[2].stat = response.data.yesterdayStats[i].stat; 
                    }
                }

                for ( let i =0 ; i <response.data.thisWeekStats.length; i++ ){

                    if(response.data.thisWeekStats[i].status == 'Approved'){

                        thisWeekData.value[0].stat = response.data.thisWeekStats[i].stat; 

                    }else if(response.data.thisWeekStats[i].status == 'Sent Back'){

                        thisWeekData.value[1].stat = response.data.thisWeekStats[i].stat; 

                    }else if(response.data.thisWeekStats[i].status == 'Rejected'){

                        thisWeekData.value[2].stat = response.data.thisWeekStats[i].stat; 
                    }
                }

                for ( let i =0 ; i <response.data.thisMonthData.length; i++ ){

                    if(response.data.thisMonthData[i].status == 'Approved'){

                        thisMonthData.value[0].stat = response.data.thisMonthData[i].stat; 

                    }else if(response.data.thisMonthData[i].status == 'Sent Back'){

                        thisMonthData.value[1].stat = response.data.thisMonthData[i].stat; 

                    }else if(response.data.thisMonthData[i].status == 'Rejected'){

                        thisMonthData.value[2].stat = response.data.thisMonthData[i].stat; 
                    }
                }

                //3rd cards
                for ( let i =0 ; i <response.data.lessthanAweek.length; i++ ){

                    if(response.data.lessthanAweek[i].status == 'Pending'){

                        lessthanAweek.value[0].stat = response.data.lessthanAweek[i].stat; 

                    }else if(response.data.lessthanAweek[i].status == 'Sent Back'){

                        lessthanAweek.value[1].stat = response.data.lessthanAweek[i].stat; 

                    }
                }

                for ( let i =0 ; i <response.data.aWeek.length; i++ ){

                    if(response.data.aWeek[i].status == 'Pending'){

                        aWeek.value[0].stat = response.data.aWeek[i].stat; 

                    }else if(response.data.aWeek[i].status == 'Sent Back'){

                        aWeek.value[1].stat = response.data.aWeek[i].stat; 

                    }
                }

                for ( let i =0 ; i <response.data.twoWeeks.length; i++ ){

                    if(response.data.twoWeeks[i].status == 'Pending'){

                        twoWeeks.value[0].stat = response.data.twoWeeks[i].stat; 

                    }else if(response.data.twoWeeks[i].status == 'Sent Back'){

                        twoWeeks.value[1].stat = response.data.twoWeeks[i].stat; 

                    }
                }

                for ( let i =0 ; i <response.data.aMonth.length; i++ ){

                    if(response.data.aMonth[i].status == 'Pending'){

                        aMonth.value[0].stat = response.data.aMonth[i].stat; 

                    }else if(response.data.aMonth[i].status == 'Sent Back'){

                        aMonth.value[1].stat = response.data.aMonth[i].stat; 

                    }
                }

                for ( let i =0 ; i <response.data.olderThanMonth.length; i++ ){

                    if(response.data.olderThanMonth[i].status == 'Pending'){

                        olderThanMonth.value[0].stat = response.data.olderThanMonth[i].stat; 

                    }else if(response.data.olderThanMonth[i].status == 'Sent Back'){

                        olderThanMonth.value[1].stat = response.data.olderThanMonth[i].stat; 

                    }
                }

                
               
                   
            }

            }catch (err) {
                
               
                if (err.response) {


                   errorMessage.value = err.response.data.message;

                    //return { 'message': err.response.data.message, 'status': 'error' };

                } else if (err.request) {

                    errorMessage.value = err.request.data.message

                    //return { 'message': err.request.data.message, 'status': 'error' };

                } else {

                    errorMessage.value = err.message

                // return { 'message': err.message, 'status': 'error' };

                }
            }

            isLoading.value = false;

        }

        getDashboardStats(activeRole.value);
        
        return {
        
        stats,tabs,tabs1,stats1,stats2,
        currentTab,currentTab1,changeStatsCardData,timeSpentOnStatus,
        requisitionsWorkedOn, changeStatsCardDataForRequisitionsWorkedOn,
        goToRequisitionList, isLoading, successMessage, errorMessage
        
        }
    
    }

}
</script>

<style>

</style>