<template>
  <div>
    <div class="sm:hidden">
      <label for="tabs" class="sr-only">Select a tab</label>
      <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
      <select id="tabs" name="tabs" class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
        <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
      </select>
    </div>
    <div class="hidden sm:block">
      <nav class="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
        <a v-for="(tab, tabIdx) in tabs" :key="tab.name" @click="currentTab = tab.name; swapComponent(tab.component)" :href="tab.href" :class="[currentTab == tab.name ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700', tabIdx === 0 ? 'rounded-l-lg' : '', tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10']" :aria-current="tab.current ? 'page' : undefined">
          <span>{{ tab.name }}</span>
          <span aria-hidden="true" :class="[currentTab == tab.name ? 'bg-indigo-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
        </a>
      </nav>
    </div>
    <component class="pt-5" :is="currentComponent"></component>
    <div  class="pt-5" v-if="!currentComponent">
      general
    </div>
  </div>
</template>

<script>

import { ref } from 'vue'
import GeneralSettingsRequisitionComponent from './GeneralSettingsRequisitionComponent.vue'
import GeneralSettingsTenderComponent from './GeneralSettingsTenderComponent.vue'
import GeneralSettingsInvoicingComponent from './GeneralSettingsInvoicingComponent.vue'
export default {

  components: {
         GeneralSettingsRequisitionComponent,
         GeneralSettingsTenderComponent,
         GeneralSettingsInvoicingComponent
    },


  setup() {

    let currentTab  = ref("General");
    const tabs = [
      { name: 'General', href: '#', component: null, current: true },
      { name: 'Requisition System', href: '#', component: 'GeneralSettingsRequisitionComponent', current: false },
      { name: 'Tender System', href: '#', component: 'GeneralSettingsTenderComponent', current: false },
      { name: 'Invoicing System', href: '#', component: 'GeneralSettingsInvoicingComponent', current: false },
    ]

    let currentComponent = ref(null);

    function swapComponent(component){
       
       currentComponent.value = component;

    }

    return {
      tabs,currentTab, swapComponent, currentComponent
    }
  },
}
</script>

<style>

</style>