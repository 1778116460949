<template>
 <div>
    <div class="mb-5">
      <div>
        <button
          class="capitalize flex text-indigo-600 hover:underline"
          v-if="currentComponent"
          @click="swapComponent(null)"
        >
          <ArrowLeftIcon class="mr-2 h-6 w-6" />
          back
        </button>
      </div>
    </div>

    <component :is="currentComponent"></component>
    <div v-if="!currentComponent">
      <Loading v-model:active="isLoading" />
      <div class=" mt-8 flex justify-center items-center">
          <AlertSuccess
              v-if="successMessage"
              :message="successMessage"
              @hide="successMessage = null"
          />
          <AlertError
              v-if="errorMessage"
              :message="[errorMessage]"
              @hide="errorMessage = null"
          />
      </div>
      <div class="grid grid-rows-2 sm:flex sm:justify-between sm:items-center pb-4">
          <div class="pr-3">
              <button type="button" @click="swapComponent('AddUserComponent')" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-900 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Create New User
              </button>

          </div>
          <!-- <label for="table-search" class="sr-only">Search</label> -->
          <div class="relative flex">
              <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
              </div>
              <input type="text" v-model="filter" id="table-search" class="block flex-1 p-2 pl-10 sm:w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search by Name or Email">
          </div>
      </div>
      <div class="w-full sm:flex sm:justify-between sm:mb-2"> 
        <div class="w-full">
        </div>
        <div class="flex items-center justify-center sm:justify-end w-full lg:w-1/2 sm:flex-wrap">
          <label class="px-5 text-sm w-fit text-red-900"> Rows per page </label>
          <select @change="resetTableRows()" v-model="skip" class="block py-1 mb-2 px-0 w-16 text-sm text-gray-800 bg-transparent border-0 border-b-2 border-gray-500 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer">
              <option  :value="5">5</option>
              <option  :value="10">10</option>
              <option  :value="20">20</option>
              <option  :value="50">50</option>
              <option  :value="100">100</option>
          </select>

        </div>
      </div>
      <!-- <div class="w-1/2">
        <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="small_size">Upload Excel sheet with Users Here</label>
        <div class="sm:flex sm:flex-row sm:flex-wrap">
          <input @change="handleFile($event)" class="flex-none block mb-5 text-xs text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none" id="small_size" type="file">
          <button  :disabled="!userFile" type="button" @click="uploadUsersByExcel()" class="mb-2 inline-flex items-center h-7 p-1.5 mx-2 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-900 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Upload
          </button>
        </div>

      </div> -->
      <div class="flex flex-col">
          <div class="-my-2  sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                  <div class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
                      <table class="min-w-full divide-y divide-gray-200">
                          <thead class="bg-teal-700">
                              <tr>
                                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Name</th>
                                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white  uppercase tracking-wider"> Email</th>
                                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white  uppercase tracking-wider">Erp Role</th>
                                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white  uppercase tracking-wider">Directorate</th>
                                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white  uppercase tracking-wider">Status</th>
                                  <th scope="col" class="relative px-6 py-3">
                                  <span class="sr-only">edit</span>
                                  </th>
                                  <th scope="col" class="relative px-6 py-3">
                                  <span class="sr-only">block</span>
                                  </th>
                              </tr>
                          </thead>
                          <tbody class="bg-white divide-y divide-gray-200">
                              <tr v-for="(user) in filteredUsersTable.slice(filter? 0 : startPagination,filter? filteredUsersTable.length : stopPagination)" :key="user.email">
                                  <td class="px-6 py-4 capitalize whitespace-nowrap text-sm  text-gray-900">
                                  {{ user.name }}
                                  </td>
                                  <td class="px-6 py-4 whitespace-nowrap text-sm  text-gray-900">
                                  {{ user.email }}
                                  </td>
                                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                  {{ user.erp_role }}
                                  </td>
                                  <td class="px-6 py-4 whitespace-nowrap text-sm  text-gray-900">
                                  {{ user.directorate }}
                                  </td>
                                  <td v-if="user.status == 0" class="px-6 py-4 whitespace-nowrap text-xs  text-teal-400">
                                    <span class="border border-1 border-teal-400 py-1 px-0.5 rounded-lg">active</span>
                                      
                                  </td>
                                  <td v-else-if="user.status == 1" class="px-6 py-4 whitespace-nowrap text-xs text-red-500">
                                     <span class="border border-1 border-red-500 py-1 px-0.5 rounded-lg">
                                      blocked
                                     </span>
                                  </td>
                                  <td class="px-6 py-4 whitespace-nowrap text-right text-sm ">
                                  <a v-if="user.status == 0" @click.prevent="swapComponent('AddUserComponent',user.id)" class="cursor-pointer bg-indigo-600 shadow-md text-xs text-white  py-1 px-2 rounded-md hover:bg-indigo-900">edit</a>
                                  </td>
                                  <td class="px-6 py-4 whitespace-nowrap text-right text-sm ">
                                  <a @click.prevent="user.status == 0? blockUser(user.id) : unBlockUser(user.id)" :class="user.status == 0? 'bg-red-500 hover:bg-red-700':'bg-teal-400 hover:bg-teal-700'" class="cursor-pointer text-white text-xs py-1 px-2 rounded-md shadow-md">{{ user.status == 0? 'block':'unblock' }}</a>
                                  </td>
                              </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                                <td colspan="7">
                                    <nav class="bg-white px-4 py-3 flex items-center justify-between  sm:px-6" aria-label="Pagination">
                                        <div class="hidden sm:block">
                                        <p class="text-sm text-gray-700">
                                            Showing
                                            {{ ' ' }}
                                            <span class="font-medium">{{ filter ? filteredUsersTable.length > 0 ? 1 : 0 : (Number(startPagination) + 1) }} </span>
                                            {{ ' ' }}
                                            to
                                            {{ ' ' }}
                                            <span class="font-medium"> {{filter ? filteredUsersTable.length : stopPagination > filteredUsersTable.length ? filteredUsersTable.length :  stopPagination}} </span>
                                            {{ ' ' }}
                                            of
                                            {{ ' ' }}
                                            <span class="font-medium">{{ filteredUsersTable.length }}</span>
                                            {{ ' ' }}
                                            results
                                        </p>
                                        </div>
                                        <div class="flex-1 flex justify-between sm:justify-end">
                                        <!-- <a :class="prevPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="getUsers( pageUrl+prevPageUrl)"  class="relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Previous </a> -->
                                        <!-- <a :class="nextPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="getUsers( pageUrl+nextPageUrl)" class="ml-3 cursor-pointer relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Next </a> -->
                                        <a :class="startPagination > 0 ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="previousPagination()"  class="relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Previous </a>
                                        <a :class="stopPagination < filteredUsersTable.length ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="nextPagination()" class="ml-3 cursor-pointer relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Next </a>
                                        </div>
                                    </nav>
                                </td>
                            </tr>
                        </tfoot>
                      </table>
                  </div>
              </div>
          </div>
      </div>
      <ErpManagementModalComponent :show="showModal" @dismiss="showModal = false"></ErpManagementModalComponent>
    </div>
  </div>
</template>

<script>
import ErpManagementModalComponent from "./ErpManagementModalComponent.vue"
import AddUserComponent from "./AddUserComponent.vue"
import { ArrowLeftIcon } from "@heroicons/vue/outline";
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import Api from "@/components/api/Api";

import { ref, computed, provide, watch, inject } from 'vue'

export default {
   name: "UsersComponent",
    components: {
         ErpManagementModalComponent,
         AddUserComponent,
         ArrowLeftIcon,
         AlertError, AlertSuccess
    },

    setup() {

      let showModal = ref(false);
      let isLoading = ref(false);
      let errorMessage = ref("");
      let successMessage = ref("");
      let users  = ref([]);
      let pageUrl = '/users/show-erp-users';
      let activePage = inject('activePage')
      activePage.value = 'Users'

      let isEditingUser = ref({'userid':'', 'isEditing' : false})
      provide("isEditingUser", isEditingUser);

      let userFromStorage = JSON.parse(localStorage.getItem("user"));
      let added_by_user_id      = userFromStorage.id.toString();
      
      async function getUsers(url = pageUrl) {
        isLoading.value = true;

        try{

          const response = await  Api().get(url);

          if (response.data.status === "success") {

                users.value = response.data.users.data;
                nextPageUrl.value = response.data.users.next_page_url
                prevPageUrl.value = response.data.users.prev_page_url
                fromPage.value = response.data.users.from
                toPage.value   = response.data.users.to
                // totalPageResults.value   = response.data.users.total
                successMessage.value = response.data.message
          }

        }catch (err) {

            if (err.response) {

              errorMessage.value = err.response.data.message;

                //return { 'message': err.response.data.message, 'status': 'error' };

            } else if (err.request) {

                errorMessage.value = err.request.data.message

                //return { 'message': err.request.data.message, 'status': 'error' };

            } else {

                errorMessage.value = err.message

              // return { 'message': err.message, 'status': 'error' };

            }
        }

        isLoading.value = false;

      }
      
      let fromPage = ref(0)
      let toPage   = ref(0)
      let totalPageResults   = ref(0);
      let prevPageUrl = ref('#')
      let nextPageUrl = ref('#')

      const filter = ref("");
      const filteredUsersTable = computed(() => {
        return users.value.filter(
          (val) =>{
            const name = val.name.toString().toLowerCase();
            const email = val.email.toString().toLowerCase();
            
            return name.includes(filter.value.toLowerCase()) || email.includes(filter.value.toLowerCase());
          }
        );
      });
     
      let currentComponent = ref(null);

      function swapComponent(component, userid= ''){

        if(userid){
          isEditingUser.value.userid = userid;
          isEditingUser.value.isEditing = true;
        }else{

          isEditingUser.value.userid = '';
          isEditingUser.value.isEditing = false;

        }

         currentComponent.value = component;

      }

      watch(currentComponent, async (newComponent) => {
        if (!newComponent) {
         
         getUsers();
        }
      })

      async function blockUser(id = 0){

        if(id != '0'){

          isLoading.value = true;

          try {
            
              const res = await Api().delete(`/users/block-erp-user/${id}`);

              if (res.data.status === "success") {

                successMessage.value = res.data.message;

              }


            } catch (err) {

                if (err.response) {

                  errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                    errorMessage.value = err.request.data.message ;

                } else {

                  errorMessage.value = err.message;

                }
            }
          
            isLoading.value = false;
        }

        getUsers();

      }

       async function unBlockUser(id = 0){

        if(id != '0'){

          isLoading.value = true;

          try {
            
              const res = await Api().delete(`/users/unblock-erp-user/${id}`);

              if (res.data.status === "success") {

                successMessage.value = res.data.message;

              }


            } catch (err) {

                if (err.response) {

                  errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                    errorMessage.value = err.request.data.message ;

                } else {

                  errorMessage.value = err.message;

                }
            }
          
            isLoading.value = false;
            
        }

        getUsers();

      }

      let userFile = ref(null)

      let startPagination = ref(0)
      let skip = ref(10);
      let stopPagination  = ref(skip.value)
      

      function nextPagination(){

        startPagination.value = Number(startPagination.value) + Number(skip.value);
        stopPagination.value = Number(stopPagination.value) + Number(skip.value);

      }

      function previousPagination(){

        startPagination.value = Number(startPagination.value) - Number(skip.value);
        stopPagination.value = Number(stopPagination.value) - Number(skip.value);

      }

      function resetTableRows(){

        startPagination.value = 0;
        stopPagination.value = skip.value;

      }

      async function uploadUsersByExcel(){


            errorMessage.value = "";
            successMessage.value = "";
            
            isLoading.value =  true;

           const formData = new FormData();

           formData.append('added_by_user_id',added_by_user_id);
           formData.append('userFile', userFile.value);
            
            const options = {
                headers: {
                "Content-Type": "multipart/form-data",
                },
            };

            try {

              const res = await Api().post("/users/uploadByExcel", formData,options)

              if (res.data.status === "success") {

                  successMessage.value  = res.data.message;
                  userFile.value = null;
                  await getUsers();
                  
              }else{

                errorMessage.value = res.data.message;
              }

            } catch (err) {

                if (err.response) {

                  if (err.response.status === 422) {

                      //

                    }else{

                        errorMessage.value = "An error has occurred! " + err.response.message;

                    }

                } else if (err.request) {

                  errorMessage.value =  err.request.data.message;

                } else {

                  errorMessage.value =  err.message;

                }
            }

           isLoading.value = false;


      }

      function handleFile(e){

            var files = e.target.files || e.dataTransfer.files;
            if(files[0]){

              userFile.value = files[0];

            }else{

              userFile.value = null;


            }

      }

      getUsers();

      return {

        showModal, users, swapComponent, currentComponent,
        errorMessage, successMessage, isLoading, 
        fromPage, toPage, totalPageResults, prevPageUrl,nextPageUrl,getUsers,pageUrl,
        filter,filteredUsersTable,blockUser,unBlockUser, 
        uploadUsersByExcel, handleFile, userFile,
        startPagination, stopPagination, nextPagination, previousPagination, skip,
        resetTableRows


      }
    }

}
</script>

<style>

</style>