<template>
    <div class="mt-2">
      <Loading v-model:active="isLoading" />
       <div class="flex justify-center items-center">
              <AlertSuccess
                v-if="successMessage"
                :message="successMessage"
                @hide="successMessage = null"
              />
              <AlertError
                v-if="errorMessage"
                :message="[errorMessage]"
                @hide="errorMessage = null"
              />
       </div>
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between items-center">
        <h2 class="text-xl leading-6 font-medium text-gray-900"></h2>
        <div class="relative z-0 flex-1 px-2 flex items-center justify-center sm:inset-0 my-2">
          <div class="w-full max-w-xl mx-auto">
            <label for="search" class="sr-only">Search</label>
            <div class="relative">
              <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input id="search" name="search" v-model="filter" class="block w-full bg-gray-100 border border-transparent rounded-md py-3 pl-10 pr-3 text-sm placeholder-gray-400 focus:outline-none focus:bg-gray-50 focus:border-white focus:ring-white focus:text-gray-900 focus:placeholder-gray-500 sm:text-sm" placeholder="Search" type="search" />
            </div>
          </div>
        </div>
                <!-- <button class="block bg-blue-600 hover:bg-blue-500 p-2 rounded-lg  px-5 text-white  text-center">Create</button> -->
                 <!-- <router-link
                      
                      class="
                        block
                        bg-blue-600
                        hover:bg-blue-500
                        p-2
                        rounded-lg
                        px-5
                        text-white text-center
                      ">
                      Create</router-link> -->
      </div>
    </div>
    <div class="mt-12 max-w-6xl sm:mx-auto mx-4">
      <div class="shadow overflow-auto border-b border-gray-200 sm:rounded-lg">
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-teal-700">
          <tr>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
              Sent On
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
              Equipment Name
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
              Quantity Needed
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
            </th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(equipment,index ) in getEquipmentList" :key="equipment.id" :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {{ equipment.date }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ equipment.name }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ equipment.quantity }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              <button @click="selectedEquipment=equipment; setEquipment(equipment); setManufacturerID(manufacturer_id); $router.push({name: 'ManufacturerView' })"  class="text-white bg-green-600 py-1 px-2 rounded-md text-xs cursor-pointer hover:bg-green-900 shadow-md">respond</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- <DeleteAlert :show="showPrompt"  item="this tender"  @cancel="showPrompt = false" @delete="deleteTender(selectedTender); showPrompt = false" /> -->
</template>

<script>
import { SearchIcon } from '@heroicons/vue/solid'
import {  ref , computed } from "vue";
import { useRoute } from "vue-router";
// import DeleteAlert from "@/components/DeleteAlert";
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import Api from "@/components/api/Api";
import { setManufacturerID, setEquipment , setManufacturerUrl } from "@/stores/Tender/Manufacturer.js" 

//import {useLoading} from 'vue-loading-overlay'
export default {
    name: "ListOfEquipmentSentToManufacturer",
    components:{SearchIcon, AlertSuccess, AlertError},
    setup(){

       
       const equipmentList = ref([])
    //    const showPrompt = ref(false);
       let errorMessage = ref("");
       let successMessage = ref("");
       const selectedEquipment = ref();
       const filter = ref('');
       let isLoading = ref(false);
       //let equipment_id = ref('');
       let manufacturer_id = ref('');

       const route = useRoute();
       //equipment_id.value = route.params.equipment_id;
       let encrypted_id      = ref(route.params.manufacturer_id);
       setManufacturerUrl(encrypted_id.value)
       //manufacturer_id.value = route.params.manufacturer_id;
      // console.log(equipment_id.value)
      // console.log(manufacturer_id.value)

        const getEquipmentList = computed(()=>{
            return equipmentList.value.filter((val)=>val.name.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1)
        });

        function loadList() {
             isLoading.value = true;

              Api()
                .get(`/equipment/get-equipment-sent-to-manufacturer/${encrypted_id.value}`)
                .then((response) => {
                  //Code for handling the response
                  //add attribute editTeamName so that you can toggle showDetails row
                  let status = response.data.status;
                  //let message = response.data.message;
                  if (status === "success") {

                    equipmentList.value    = response.data.equipment;
                    manufacturer_id.value  = response.data.manufacturer_id;

                  } else {
                   
                    equipmentList.value = null;
                    
                  }
                })
                .catch((error) => {
                   errorMessage.value = "An error has occurred! " +error.message;
                })
                .finally(() => {
                  isLoading.value = false;
                 // loader.hide();
                });
        }

        loadList();
        
         return {
                 equipmentList,selectedEquipment,getEquipmentList,filter, 
                 errorMessage,successMessage,isLoading,manufacturer_id,
                 setManufacturerID, setEquipment
             
             }
    }

}
</script>

<style>

</style>