<template>
   <div>
      <Loading v-model:active="isLoading" />
      <div class=" mt-3 mb-3 flex justify-center items-center">
              <AlertSuccess
                  v-if="successMessage"
                  :message="successMessage"
                  @hide="successMessage = null"
              />
              <AlertError
                  v-if="errorMessage"
                  :message="[errorMessage]"
                  @hide="errorMessage = null"
              />
       </div>
        <div class="flex sm:flex-row flex-col justify-between items-center pb-4">
            <div class="pr-3 flex sm:flex-row flex-col w-full sm:w-fit sm:space-x-3 py-5">
               <!-- <input type="text" id="category_name" class="block w-full flex-1 p-2 pl-2 sm:w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Category Name"> -->
           
                <button type="button" @click="successMessage = null; errorMessage = null; showModal = true" class="inline-flex justify-center items-center px-2.5 my-3 sm:my-0 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-900 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Add Restriction
                </button>

            </div>
            <!-- <label for="table-search" class="sr-only">Search</label> -->
            <div class="relative  w-full sm:w-1/3 flex">
                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                </div>
                <input v-model="filter2" type="text" id="table-search" class="block w-full sm:w-fit  flex-1 p-2 pl-10 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items">
            </div>
        </div>
        <div class="flex flex-col">
            <div class="-my-2  sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
                        <table class="w-full table-auto divide-y divide-gray-200">
                            <thead class="bg-teal-700">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Ref Number</th>                                    
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Name</th>                                    
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Restricted Amount</th>                                    
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Period</th>                                    
                                    <th scope="col" class="relative px-6 py-3">
                                    <span class="sr-only">delete</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="(restriction,index) in filteredRestrictions" :key="index">
                                   
                                    <td class="px-6 py-4 break-words text-sm  text-gray-900">
                                    {{ restriction.ref_number }}
                                    </td>
                                    <td class="px-6 py-4 break-words text-sm text-gray-900">
                                    {{ restriction.name }}
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    {{ restriction.restriction_amount?  Number(restriction.restriction_amount).toLocaleString() : '0.0' }}
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                    {{ toMonthName(restriction.period) }}
                                    </td>
                                   
                                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                        <span class="hidden">{{ enabled[index] = restriction.is_active==1? true : false }} </span>
                                     <Switch @click="toggleRestriction(restriction.id,enabled[index],index)" v-model="enabled[index]" :class="[enabled[index] ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                                        <span class="sr-only">Use setting</span>
                                        <span aria-hidden="true" :class="[enabled[index] ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                                    </Switch>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                  <td colspan="5">
                                      <nav class="bg-white px-4 py-3 flex items-center justify-between  sm:px-6" aria-label="Pagination">
                                          <div class="hidden sm:block">
                                          <p class="text-sm text-gray-700">
                                              Showing
                                              {{ ' ' }}
                                              <span class="font-medium">{{ fromPage }}</span>
                                              {{ ' ' }}
                                              to
                                              {{ ' ' }}
                                              <span class="font-medium"> {{ toPage }}  </span>
                                              {{ ' ' }}
                                              of
                                              {{ ' ' }}
                                              <span class="font-medium">{{ totalPageResults }}</span>
                                              {{ ' ' }}
                                              results
                                          </p>
                                          </div>
                                          <div class="flex-1 flex justify-between sm:justify-end">
                                            <a :class="prevPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="loadBudgetLineRestrictions( `/requisition/get-budget-line-restrictions/${selectedFinancialYear}`+prevPageUrl)"  class="relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Previous </a>
                                            <a :class="nextPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="loadBudgetLineRestrictions(`/requisition/get-budget-line-restrictions/${selectedFinancialYear}`+nextPageUrl)" class="ml-3 cursor-pointer relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Next </a>
                                          </div>
                                      </nav>
                                  </td>
                              </tr>
                          </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
            <ManagerAccountsModalComponent :showOverflow="true" :show="showModal" @dismiss="showModal = false">
            <template v-slot:header>
                        Add Restriction
                    <div class=" mt-3 mb-3 flex justify-center items-center">
                            <AlertSuccess
                                v-if="successMessage"
                                :message="successMessage"
                                @hide="successMessage = null"
                            />
                            <AlertError
                                v-if="errorMessage"
                                :message="[errorMessage]"
                                @hide="errorMessage = null"
                            />
                    </div>
                </template>
                <template v-slot:body>
                <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-3 border border-1 border-gray-200 p-5">
                    <!-- <div class="">
                        <label for="budgetLineCategory" class="block text-sm font-medium text-red-900"> Budget Line </label>
                        <div class="mt-1">
                            <select id="budgetLineCategory" name="budgetLineCategory" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                            <option>select</option>
                            <option>Support to Student Interns -(Training costs)	</option>
                            <option>Directors' Internal & External Meetings</option>
                            <option>Internet & other related costs</option>
                            <option>Research and Development</option>
                            </select>
                        </div>
                    </div> -->
                    <div  class="w-full">
                        <label for="country" class="block text-center text-sm font-medium text-red-900"> Select Budget Line</label>
                        <Combobox  as="div" class="flex justify-center items-center text-center" v-model="selectedBudgetLine">
                          <div class="relative mt-1 ">
                            <ComboboxInput placeholder="Click the arrows or start typing name here" class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm" @change="query = $event.target.value" :display-value="(budgetline) => budgetline.name" />
                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </ComboboxButton>

                            <ComboboxOptions v-if="filteredBudgetLines.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="budgetline in filteredBudgetLines" :key="budgetline.id" :value="budgetline" as="template" v-slot="{ active, selected }">
                                <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                                    <span :class="['block truncate', selected && 'font-semibold']">
                                    {{ budgetline.name }}
                                    </span>

                                    <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                    </span>
                                </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                            </div>
                        </Combobox>
                    </div>
                    <!-- <div class="">
                        <label for="budgetLineName" class="block text-sm font-medium text-red-900">Budget Line Ref</label>
                        <div class="mt-1">
                        <input id="budgetLineName" name="budgetLineName" type="text" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                        </div>
                    </div> -->

                    <div class="w-full">
                        <label for="restricted_amount" class="block text-sm font-medium text-red-900"> Restricted Amount </label>
                        <div class="mt-1">
                            <input v-model="restricted_amount" id="restricted_amount" name="restricted_amount" type="number" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                        </div>
                    </div>

                    <div class="w-full">
                        <label for="month" class="block text-sm font-medium text-red-900"> Period </label>
                        <div class="mt-1">
                            <select id="month" v-model="month" name="month" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                            <option value="0">select</option>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                            </select>
                        </div>
                    </div>
                </div>
                </template>
                <template v-slot:footer>
                <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-900 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-1 sm:text-sm" @click="saveBudgetLineRestriction()">save</button>
                    <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-2 sm:text-sm" @click="showModal = false" >Cancel</button>
                </div>
                </template>
            </ManagerAccountsModalComponent>
    </div>
</template>

<script>
import { ref, inject, computed } from 'vue'
import ManagerAccountsModalComponent from "./ManagerAccountsModalComponent.vue";
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Switch } from '@headlessui/vue'
import {  CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import Api from "@/components/api/Api";
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
export default {

     components: {
            Switch,ManagerAccountsModalComponent,
            Combobox,     ComboboxButton,
            ComboboxInput,    ComboboxOption,     ComboboxOptions,
            SelectorIcon, CheckIcon,
            AlertError, AlertSuccess
     },

    setup(){

        const enabled = ref([])
        let selectedFinancialYear = inject('selectedFinancialYear')
        let loggedInUser =  inject("loggedInUser");

        const restrictions = ref([])
        const filter2 = ref("");
        const filteredRestrictions = computed(() => {
                return restrictions.value.filter(
                (val) =>{
                    const name = val.name.toString().toLowerCase();
                // const email = val.email.toString().toLowerCase();
                    
                    return name.includes(filter2.value.toLowerCase()) ;//|| email.includes(filter.value.toLowerCase());
                }
                );
            });


      const budgetLines = ref([
        { id: 0, name: '' }
      ])
      const query = ref('')
      const selectedBudgetLine = ref(budgetLines.value[0])
      const filteredBudgetLines = computed(() =>
        query.value === ''
            ? budgetLines.value
            : budgetLines.value.filter((budgetLine) => {
                return budgetLine.name.toLowerCase().includes(query.value.toLowerCase())
            })
      )
      let isLoading = ref(false);
      let errorMessage = ref("");
      let successMessage = ref("");
      let showModal = ref(false);

      let month = ref('0');
      let restricted_amount = ref('0');
      let fromPage = ref(0)
      let toPage   = ref(0)
      let totalPageResults   = ref(0);
      let prevPageUrl = ref('')
      let nextPageUrl = ref('')
      let pageUrl   = `/requisition/get-budget-line-restrictions/${selectedFinancialYear.value}`;
      let reloadUrl = ref('');


       async function loadBudgetlinesForRestriction(){


         try {

                const response = await Api().get(`/requisition/load-budget-lines-for-restriction/${selectedFinancialYear.value}`);

                if(response.data.status === "success"){
                        budgetLines.value         = response.data.budgetLines;
                        
                } 

            } catch (err) {

                if (err.response) {

                    errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                errorMessage.value =  err.request.data.message;

                } else {

                    errorMessage.value =  err.message;
                    
                }
            }

       }

        async function saveBudgetLineRestriction(){

            let restrictionObj = {

                'month' : month.value,
                'restricted_amount' : restricted_amount.value,
                'budget_line_id'    : selectedBudgetLine.value.id,
                'financial_year_id' : selectedFinancialYear.value,
                'added_by_user_id'   : loggedInUser.value.id,

            }

                try {
                    
                        const res = await Api().post(`/requisition/add-budget-line-restriction`,restrictionObj);

                        if (res.data.status === "success") {

                            successMessage.value = res.data.message;
                            showModal.value = false 
                            loadBudgetLineRestrictions(reloadUrl.value);
                            
                        }else{

                            errorMessage.value = res.data.message;

                        }


                    } catch (err) {

                        if (err.response) {

                            errorMessage.value =  err.response.data.message;

                        } else if (err.request) {

                            errorMessage.value = err.request.data.message ;

                        } else {

                            errorMessage.value = err.message;

                        }
                    }

                isLoading.value = false;

        }
        async function loadBudgetLineRestrictions(url = pageUrl){

            isLoading.value = true
            

            try {

                    const response = await Api().get(url);

                    if(response.data.status === "success"){

                            restrictions.value         = response.data.restrictions.data;
                            nextPageUrl.value          = response.data.restrictions.next_page_url
                            prevPageUrl.value          = response.data.restrictions.prev_page_url
                            fromPage.value             = response.data.restrictions.from
                            toPage.value               = response.data.restrictions.to
                            totalPageResults.value     = response.data.restrictions.total
                            successMessage.value       = response.data.message;
                            reloadUrl.value = url;
                    
                    } 

                } catch (err) {

                    if (err.response) {

                        errorMessage.value =  err.response.data.message;

                    } else if (err.request) {

                    errorMessage.value =  err.request.data.message;

                    } else {

                        errorMessage.value =  err.message;
                        
                    }
                }

                isLoading.value = false;

                    
        }

        function toMonthName(monthNumber){

            //n = Number()
            const date = new Date();
            date.setMonth(monthNumber - 1);

            return date.toLocaleString('en-US', {
                month: 'long',
            });

           // console.log(monthNumber)


        }

        async function toggleRestriction(restriction_id, val,index){

            //isLoading.value = true
            let switchValue = '';

            if(val){

                switchValue = 0;

            }else{

                switchValue = 1;

            }

            successMessage.value = '';
            errorMessage.value = '';

             isLoading.value = true;

          try {
            
              const res = await Api().patch(`/requisition/toggle-budget-line-restriction/${restriction_id}/${switchValue}`);

              if (res.data.status === "success") {

                successMessage.value = res.data.message;
                await  loadBudgetLineRestrictions(reloadUrl.value);

              }else{

                enabled.value[index] = val;

                errorMessage.value = res.data.message;

              }


            } catch (err) {

                if (err.response) {

                  errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                    errorMessage.value = err.request.data.message ;

                } else {

                  errorMessage.value = err.message;

                }
            }





            isLoading.value = false;

            
        }

       loadBudgetLineRestrictions()
       loadBudgetlinesForRestriction();

        return {

            restrictions, enabled,showModal,
            budgetLines,query, selectedBudgetLine, filteredBudgetLines,
            isLoading, errorMessage, successMessage, 
            saveBudgetLineRestriction, month , restricted_amount, 
            toMonthName, toggleRestriction,filter2, filteredRestrictions,
            fromPage, toPage, totalPageResults, prevPageUrl,nextPageUrl, loadBudgetLineRestrictions, selectedFinancialYear


        }
    }

}
</script>

<style>

</style>