<template>
  <div>
      <Loading v-model:active="isLoading" />
      <div class=" mt-3 mb-3 flex justify-center items-center">
              <AlertSuccess
                  v-if="successMessage"
                  :message="successMessage"
                  @hide="successMessage = null"
              />
              <AlertError
                  v-if="errorMessage"
                  :message="[errorMessage]"
                  @hide="errorMessage = null"
              />
       </div>
     <form class="space-y-8 divide-y divide-gray-200">
    <div class="space-y-8 divide-y divide-gray-200">
      <div class="pt-4">
        <div class="text-center">
          <h3 class="text-lg leading-6 font-bold text-red-900">Specify Assignee</h3>
          <div class="inline-flex flex-col sm:flex sm:flex-row sm:justify-center sm:space-x-4 space-y-4 sm:space-y-0 my-3">
              <div class="flex sm:justify-center items-center">
                <input id="budget_holder" v-model="assignee_type" value="budget_holder" @change="loadAssignees()" name="assignee" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 cursor-pointer" />
                <label for="budget_holder" class="ml-3 block text-sm font-medium text-gray-900"> Budget Holder</label>
              </div>
              <div class="flex sm:justify-center  items-center">
                <!-- <input disabled id="department"  v-model="assignee_type" value="department" @change="loadAssignees()" name="assignee" type="radio" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300" />
                <label for="department" class="ml-3 block text-sm font-medium text-gray-900"> Department </label> -->
              </div>
           </div>
        </div>
        <div class="mt-6 text-center">
          <div >
                <label for="country" class="block text-center text-sm font-medium text-red-900"> Select Assignee</label>
                <Combobox  as="div" class="pt-2 flex justify-center items-center text-center" v-model="selectedPerson">
                    <!-- <ComboboxLabel class="block text-sm font-medium text-gray-700">Assigned to</ComboboxLabel> -->
                    <div class="relative mt-1 w-2/5">
                    <ComboboxInput placeholder="Click the arrows or start typing name here" class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm" @change="query = $event.target.value" :display-value="(person) => person.name" />
                    <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </ComboboxButton>

                    <ComboboxOptions v-if="filteredPeople.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <ComboboxOption v-for="person in filteredPeople" :key="person.id" :value="person" as="template" v-slot="{ active, selected }">
                        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                            <span :class="['block truncate', selected && 'font-semibold']">
                            {{ person.name }}
                            </span>

                            <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                        </li>
                        </ComboboxOption>
                    </ComboboxOptions>
                    </div>
                </Combobox>
          </div>
        </div>
      </div>

      <div class="pt-8">
        <div class="text-center">
          <h3 class="text-lg leading-6 font-medium text-red-900">Budget Lines</h3>
          <p class="mt-1 text-xs text-gray-500">Tick all that apply</p>
        </div>
        <div class="mt-6">
          <!-- <div class="lg:grid lg:grid-cols-10 gap-4 my-2"> -->
          <!-- <div class="lg:flex lg:flex-row lg:space-x-4 my-2"> -->
            <!-- <legend class="text-base font-medium text-gray-900">By Email</legend> -->
            <div class="flex flex-row flex-wrap pb-4 mx-5">

                <div v-for="(budgetline,index) in budgetlineslist" :key="index" class="relative flex items-start py-2 px-4">
                  <div class="flex items-center h-5">
                    <input :id="budgetline.name" v-model="checkedBudgetLines" :name="budgetline.name" :value="budgetline.id"  type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="requisition_system" class="font-medium text-gray-700">{{ budgetline.name }}</label>
                    <p class=" text-xs block font-normal">{{ '[' + Number(budgetline.planned_amount).toLocaleString() + ']' }}</p>
                  </div>
                </div>
            </div>
        </div>
      </div>
    </div>

    <div class="pt-5">
      <div class="flex justify-end">
        <button type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Cancel</button>
        <button @click.prevent="saveBudgetLineAssignment()" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-900 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Save</button>
      </div>
    </div>
  </form>
  </div>
</template>

<script>
import { ref,computed, inject, watch} from 'vue'
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions,
} from '@headlessui/vue'
import {  CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import Api from "@/components/api/Api";
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";

export default {

  components: {
          
          Combobox,     ComboboxButton,
          ComboboxInput,    ComboboxOption,     ComboboxOptions,
          SelectorIcon, CheckIcon,
          AlertError, AlertSuccess
  },

  setup(){


      const people = ref([
        { id: 0, user_id: 0, name: '' }
      ])
      const query = ref('')
      const selectedPerson = ref(people.value[0])
      const filteredPeople = computed(() =>
      query.value === ''
          ? people.value
          : people.value.filter((person) => {
              return person.name.toLowerCase().includes(query.value.toLowerCase())
          })
      )

      let assignee_type = ref('');
      let checkedBudgetLines = ref([]);
      let isLoading = ref(false);
      let errorMessage = ref("");
      let successMessage = ref("");


      async function loadAssignees(){

        isLoading.value = true;

            try {

              const response = await Api().get(`/requisition/get-budget-line-assignees/${assignee_type.value}`);

              if(response.data.status === "success"){

                      people.value  = response.data.assignees;
                      
              
              } 

            } catch (err) {

                if (err.response) {


                    // errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                // errorMessage.value =  err.request.data.message;

                } else {

                    // errorMessage.value =  err.message;
                    
                }
           
            }

        isLoading.value = false;

      }


    let budgetlineslist = ref([]);
    let selectedFinancialYear = inject('selectedFinancialYear')
    let loggedInUser =  inject("loggedInUser");
    let tempBudgetlineslist = ref([]);


    async function loadBudgetlineslist(){


         try {

                const response = await Api().get(`/requisition/load-budget-lines-for-assignment/${selectedFinancialYear.value}`);

                if(response.data.status === "success"){

                        budgetlineslist.value         = response.data.budgetLines;
                        tempBudgetlineslist.value     = response.data.budgetLines;
                        
                } 

            } catch (err) {

                if (err.response) {

                    errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                errorMessage.value =  err.request.data.message;

                } else {

                    errorMessage.value =  err.message;
                    
                }
            }




    }

    async function saveBudgetLineAssignment(){

      let assignmentObj = {

          'assignee_type' : assignee_type.value,
          'erp_systems_role_id' : selectedPerson.value.id,
          'assignee_user_id' : selectedPerson.value.user_id,
          'assignments' : checkedBudgetLines.value,
          'financial_year_id' : selectedFinancialYear.value,
          'added_by_user_id' : loggedInUser.value.id,

      }

        try {
            
                const res = await Api().post(`/requisition/save-budget-line-assignment`,assignmentObj);

                if (res.data.status === "success") {

                  successMessage.value = res.data.message;
                 
                 
                  // loadBudgetLines(reloadUrl.value);
                   

                }else{

                     errorMessage.value = res.data.message;

                }
                window.scrollTo({ top: 0, behavior: "smooth" });


            } catch (err) {

                if (err.response) {

                    errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                    errorMessage.value = err.request.data.message ;

                } else {

                    errorMessage.value = err.message;

                }
            }

         isLoading.value = false;

      // console.log(assignmentObj)

    }


    async function loadAssigneeBudgetLines(){


         try {

                const response = await Api().get(`/requisition/load-assignee-budget-lines/${selectedFinancialYear.value}/${selectedPerson.value.id}/${selectedPerson.value.user_id}`);

                if(response.data.status === "success"){

                      let returnedAssignments= response.data.budgetLineAssignments;
                      budgetlineslist.value  = tempBudgetlineslist.value;

                      budgetlineslist.value = budgetlineslist.value.filter(ar => !returnedAssignments.find(rm => (rm.budget_line_id === ar.id) ))

                        
                } 

            } catch (err) {

                if (err.response) {

                    errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                errorMessage.value =  err.request.data.message;

                } else {

                    errorMessage.value =  err.message;
                    
                }
            }




    }

    watch(selectedPerson, async () => {

      checkedBudgetLines.value = [];
      loadAssigneeBudgetLines();

    })
    


    loadBudgetlineslist();
    
    return{

         budgetlineslist,checkedBudgetLines,
         query,selectedPerson,filteredPeople,
         loadAssignees,assignee_type, isLoading, errorMessage, successMessage,
         saveBudgetLineAssignment,loadAssigneeBudgetLines

    }
  }

}
</script>

<style>

</style>