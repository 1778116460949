<template>
 <div>
    <div class="grid grid-rows-1 sm:flex sm:justify-between sm:items-center pb-4">

        <div class="relative flex">
            <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
            </div>
            <input type="text" id="table-search" class="block flex-1 p-2 pl-10 sm:w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items">
        </div>
    </div>
    <div class="py-2">
      <h5> List of accountabilities already submitted </h5>
    </div>
     <div class="flex flex-col">
        <div class="-my-2  sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Requisition ID</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Requisitioner</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Approval Level</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Requisition Amount</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Accountable Amount</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">To Be Accounted For</th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="(accountability) in accountabilities" :key="accountability.id">
                                <td class="px-6 py-4 text-sm font-medium text-gray-900">
                                 {{ accountability.requisition_id }} 
                                </td>
                                <td class="px-6 py-4 text-sm font-medium text-gray-900">
                                 {{ accountability.requisitioner }} 
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                {{ accountability.description }}
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                {{ accountability.approval_level }}
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                {{ accountability.requisition_amount }}
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                {{ accountability.accountable_amount }}
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                {{ accountability.to_be_accounted_for }}
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="4">
                                    <nav class="bg-white px-4 py-3 flex items-center justify-between  sm:px-6" aria-label="Pagination">
                                        <div class="hidden sm:block">
                                        <p class="text-sm text-gray-700">
                                            Showing
                                            {{ ' ' }}
                                            <span class="font-medium">1</span>
                                            {{ ' ' }}
                                            to
                                            {{ ' ' }}
                                            <span class="font-medium">10</span>
                                            {{ ' ' }}
                                            of
                                            {{ ' ' }}
                                            <span class="font-medium">20</span>
                                            {{ ' ' }}
                                            results
                                        </p>
                                        </div>
                                        <div class="flex-1 flex justify-between sm:justify-end">
                                        <a href="#" class="relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Previous </a>
                                        <a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Next </a>
                                        </div>
                                    </nav>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>


<script>
export default {

   name: "AccountabilityReportComponent",
    setup(){

        const accountabilities = [
            {   requisition_id: 'JMS/DCS/2022-07-08 17:44:17/25T4R', 
                requisitioner: 'Frank Bulungu', 
                description: 'Per diem and Lunch allowance for TRs to support MAUL-JMS Facilities transition', 
                approval_level: 'Frank Bulungu - [ Requisitioner ]',
                requisition_amount: '4,873,600.00',
                accountable_amount: '2,400,000.00',
                to_be_accounted_for: 'Per Diem',
            },
            {   requisition_id: 'JMS/DFAA/2022-07-09 09:51:32/8J8V9', 
                requisitioner: 'Lorna Mugabi Mulindwa', 
                description: 'Advance Ugx. 2,000,000/= only to Grace Namiiro being payment worth of units for WENRECO that will be supplied to JMS.', 
                approval_level: 'Lorna Mugabi Mulindwa - [ Requisitioner ]',
                requisition_amount: '2,000,000.00	',
                accountable_amount: '2,000,000.00',
                to_be_accounted_for: 'Expenses, Contingency Fund',
            }

        ]

        return {
            accountabilities
            
        }
    }


}
</script>

<style>

</style>