import { ref } from "vue";

// State
const equipment = ref(null);
const manufacturer_id = ref(null);
const manufacturer_name = ref(null);
const inquiry_selected_index = ref(null);
const manufacturer_url = ref(null);

//Setters
export const setEquipment = async (equipmentobj) => {

    equipment.value = equipmentobj

};

export const setManufacturerID = async (manufacturerobj) => {

    manufacturer_id.value = manufacturerobj

};

export const setSelectedIndex = async (index) => {

    inquiry_selected_index.value = index

};

export const setManufacturerName = async (name) => {

    manufacturer_name.value = name

};

export const setManufacturerUrl = async (url) => {

    manufacturer_url.value = url

};

// Getters
export const getEquipment = () => {

    return equipment;

};

export const getManufacturerID = () => {

    return manufacturer_id.value;

};

export const getSelectedIndex = () => {

    return inquiry_selected_index.value;

};

export const getManufacturerName = () => {

    return manufacturer_name.value;

};

export const getManufacturerUrl = () => {

    return manufacturer_url.value;

};