<template>
  <div class="mt-8">
     <Loading v-model:active="isLoading" />
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <button @click.prevent="$router.back()" class="capitalize flex text-blue-500 hover:underline">
        <ArrowLeftIcon class="mr-2 h-6 w-6" />
        back
      </button>
      <div class="flex justify-center items-center">
            <AlertSuccess
              v-if="successMessage"
              :message="successMessage"
              @hide="successMessage = null"
            />
            <AlertError
              v-if="errorMessage"
              :message="[errorMessage]"
              @hide="errorMessage = null"
            />
      </div>
      <div class="flex justify-center items-center">
        <h2 class="text-xl leading-6 font-medium text-gray-900">Create Tender</h2>
      </div>
    </div>
    <div class="mt-12 max-w-2xl mx-auto">
      <form  method="post">
        <div class="space-y-8 divide-y divide-gray-200">
          <div>

            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div class="sm:col-span-6">
                <label for="tender_name" class="block text-sm font-medium text-gray-700">
                  Tender Name
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input type="text" name="tender_name" v-model="tender_name" id="tender_name" autocomplete="tender_name" class="flex-1 px-3  focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                </div>
              </div>

              <div class="sm:col-span-6">
                <label for="company_name" class="block text-sm font-medium text-gray-700">
                  Company/Organisation Name
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input type="text" name="company_name" v-model="company_name" id="company_name" autocomplete="company_name" class="flex-1 px-3  focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                </div>
              </div>

              <div class="sm:col-span-6">
                <label for="box_address" class="block text-sm font-medium text-gray-700">
                  Company box address
                </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <input type="text" name="box_address" v-model="box_address" id="box_address" autocomplete="box_address" class="flex-1 px-3  focus:ring-indigo-500 border py-3 focus:border-indigo-500 block w-full min-w-0  rounded-md sm:text-sm border-gray-300" />
                </div>
              </div>
              <div class="flex justify-center sm:col-span-6">
                <button @click.prevent="createTender()" type="submit" class="block bg-red-900 hover:bg-red-700 p-2 rounded-lg w-1/2 mx-auto text-white mt-4 px-40 text-center">Save</button>

              </div>

            </div>
          </div>


        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, inject } from "vue";
import {ArrowLeftIcon} from '@heroicons/vue/solid';
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import Api from "@/components/api/Api";
import { getTender } from "@/stores/Tender/Tender.js" 

export default {
    name: "EditEquipment",
      props: {
        
      },
    components:{ArrowLeftIcon,AlertError,AlertSuccess},
    setup(){

        let isLoading = ref(false);
        let tender_name = ref('');
        let company_name = ref('');
        let box_address = ref('');
        let loggedInUser =  inject("loggedInUser");
       
        
        let userid = loggedInUser.value.id;
        let tender_id  = ref('');
        let errorMessage = ref("");
        let successMessage = ref("");

        let selectedTender = getTender();
        
        if(selectedTender.value != null){

            tender_id.value       = selectedTender.value.id;
            tender_name.value     = selectedTender.value.name;
            company_name.value    = selectedTender.value.company_name;
            box_address.value     = selectedTender.value.box_address;

        }


        function createTender() {

            isLoading.value = true;

            let tender = {
                'tender_name': tender_name.value,
                'company_name': company_name.value,
                'box_address': box_address.value,
                'created_by_id': userid,
                'tender_id'    : tender_id.value
            };

            Api()
                .post("/tenders/create-tender", tender)
                .then((response) => {
                    let message = response.data.message;

                    let status = response.data.status;

                    if (status === "error") {

                        errorMessage.value = message;
                       
                     
                        
                    } else if (status === "success") {
                        successMessage.value  = message;
                       
                    }
                })
                .catch((error) => {

                  errorMessage.value = "An error has occured! Please try again" + error.message;
                   

                })
                .finally(() => {

                    isLoading.value = false;

                });
        }
       

         return {
           tender_name,
           company_name,
           box_address,
           errorMessage,
           successMessage,
           createTender,
           isLoading
        };

    }

}
</script>

<style>

</style>