<template>
  <div>
     <Loading v-model:active="isLoading" />
    <div class=" mt-3 mb-3 flex justify-center items-center">
        <AlertSuccess
            v-if="successMessage"
            :message="successMessage"
            @hide="successMessage = null"
        />
        <AlertError
            v-if="errorMessage"
            :message="[errorMessage]"
            @hide="errorMessage = null"
        />
    </div>
    <div class="grid grid-cols-1 gap-3 sm:grid sm:grid-cols-3 sm:gap-4 lg:grid lg:grid-cols-4 lg:gap-4  md:grid md:grid-cols-3 md-gap-3 drop-shadow-sm border border-gray-300 rounded-r-md rounded-l-md p-5">

        
        <div  class="sm:grid sm:grid-cols-1 sm:gap-4 sm:items-start">
          <Listbox as="div" v-model="selectedSort">
              <ListboxLabel class="block text-sm font-medium text-red-900"> Sort By </ListboxLabel>
              <div class="mt-1 relative">
              <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  <span class="block truncate">{{ selectedSort.name }}</span>
                  <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
              </ListboxButton>

              <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                  <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  <ListboxOption as="template" v-for="sortOption in sort_by" :key="sortOption.id" :value="sortOption" v-slot="{ active, selected }">
                      <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                      <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                          {{ sortOption.name }}
                      </span>

                      <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                      </span>
                      </li>
                  </ListboxOption>
                  </ListboxOptions>
              </transition> 
              </div>
          </Listbox>
        </div>
        <div  class="sm:grid sm:grid-cols-1 sm:gap-4 sm:items-start">
          <Listbox as="div" v-model="selectedViewRating">
              <ListboxLabel class="block text-sm font-medium text-red-900"> View Rating By </ListboxLabel>
              <div class="mt-1 relative">
              <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  <span class="block truncate">{{ selectedViewRating.name }}</span>
                  <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
              </ListboxButton>

              <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                  <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  <ListboxOption as="template" v-for="viewby in view_rating_by" :key="viewby.id" :value="viewby" v-slot="{ active, selected }">
                      <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                      <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                          {{ viewby.name }}
                      </span>

                      <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                      </span>
                      </li>
                  </ListboxOption>
                  </ListboxOptions>
              </transition> 
              </div>
          </Listbox>
        </div>
         <div  class="sm:grid sm:grid-cols-1 sm:gap-4 sm:items-start">
            <Listbox as="div" v-model="selectedMonth">
                <ListboxLabel class="block text-sm font-medium text-red-900"> Select Month </ListboxLabel>
                <div class="mt-1 relative">
                <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <span class="block truncate">{{ selectedMonth.name }}</span>
                    <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    <ListboxOption as="template" v-for="month in months" :key="month.id" :value="month" v-slot="{ active, selected }">
                        <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                            {{ month.name }}
                        </span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                        </li>
                    </ListboxOption>
                    </ListboxOptions>
                </transition> 
                </div>
            </Listbox>
         </div>
         <div class="sm:grid sm:grid-cols-1 sm:gap-4 sm:items-start">
            <Listbox as="div" v-model="selectedPeriod">
                <ListboxLabel class="block text-sm font-bold text-red-900"> Select Year </ListboxLabel>
                <div class="mt-1 relative">
                <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <span class="block truncate">{{ selectedPeriod.name }}</span>
                    <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-32 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    <ListboxOption as="template" v-for="period in periods" :key="period.id" :value="period" v-slot="{ active, selected }">
                        <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                            {{ period.name }}
                        </span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                        </li>
                    </ListboxOption>
                    </ListboxOptions>
                </transition>
                </div>
            </Listbox>
         </div>
          <div v-if="selectedViewRating.name == 'Customer'" class="sm:grid sm:col-span-2 sm:gap-4 sm:items-start">
            <!-- <label for="country" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> {{ select_who_text }} </label> -->
            <Combobox as="div" class="sm:mt-0 sm:col-span-1" v-model="selectedCustomer">
                <ComboboxLabel class="block text-sm font-bold text-red-900">Facility Name</ComboboxLabel>
                <div class="relative mt-1">
                <ComboboxInput placeholder="Type Facility Name or Click arrows" class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm" 
                      @change="queryCustomer = $event.target.value" :display-value="(customer) => customer.customer_name" />
                <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </ComboboxButton>

                <ComboboxOptions v-if="filteredCustomers.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    <ComboboxOption v-for="customer in filteredCustomers" :key="customer.id" :value="customer" as="template" v-slot="{ active, selected }">
                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                        <span :class="['block truncate', selected && 'font-semibold']">
                        {{ customer.customer_name }}
                        </span>

                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                    </li>
                    </ComboboxOption>
                </ComboboxOptions>
                </div>
            </Combobox>
          </div>
          <div v-if="selectedViewRating.name == 'Field Officer'" class="sm:grid sm:col-span-2 sm:gap-4 sm:items-start">
            <!-- <label for="country" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> {{ select_who_text }} </label> -->
            <Combobox as="div" class="sm:mt-0 sm:col-span-1" v-model="selectedFieldOfficer">
                <ComboboxLabel class="block text-sm font-bold text-red-900">Field Officer</ComboboxLabel>
                <div class="relative mt-1">
                <ComboboxInput placeholder="Type Facility Name or Click arrows" class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm" 
                      @change="queryFieldOfficer = $event.target.value" :display-value="(fieldOfficer) => fieldOfficer.name" />
                <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </ComboboxButton>

                <ComboboxOptions v-if="filteredFieldOfficers.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    <ComboboxOption v-for="fieldOfficer in filteredFieldOfficers" :key="fieldOfficer.id" :value="fieldOfficer" as="template" v-slot="{ active, selected }">
                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                        <span :class="['block truncate', selected && 'font-semibold']">
                        {{ fieldOfficer.name }}
                        </span>

                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                    </li>
                    </ComboboxOption>
                </ComboboxOptions>
                </div>
            </Combobox>
          </div>
        
        <div class="flex items-end">
            <button @click="getRecords()" type="button" class=" w-full lg:h-9 h-10 px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-900 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Get Records
            </button>

        </div>

          
    </div>
    <div class="flex flex-col mt-8">
            <div class="-my-2  sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
                        <table class="w-full divide-y divide-gray-200">
                            <thead class="bg-teal-700">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white capitalize tracking-normal">Job Date</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white capitalize tracking-normal">Facility</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white capitalize tracking-normal">Department</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white capitalize tracking-normal">Equipment</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white capitalize tracking-normal">Service Type</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white capitalize tracking-normal">Service Done By</th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="(report,index) in jobCardReport" :key="index">
                                    <td class="px-6 py-4 text-xs text-gray-900">
                                       {{ report.date }}
                                    </td>
                                    <td class="px-6 py-4 text-xs text-gray-900">
                                       {{ report.customer }}
                                    </td>
                                    <td class="px-6 py-4 text-xs text-gray-900">
                                       {{ report.department }}
                                    </td>
                                    <td class="px-6 py-4 text-xs text-gray-900">
                                       {{ report.equipment }}
                                    </td>
                                    <td class="px-6 py-4 text-xs text-gray-900">
                                       {{ report.activity }}
                                    </td>
                                    <td class="px-6 py-4 text-xs text-gray-900">
                                       {{ report.field_officer_name }}
                                    </td> 
                                </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                  <td colspan="6">
                                      <nav class="bg-white px-4 py-3 flex items-center justify-between  sm:px-6" aria-label="Pagination">
                                          <div class="hidden sm:block">
                                          <p class="text-sm text-gray-700">
                                              Showing
                                              {{ ' ' }}
                                              <span class="font-medium">{{ fromPage }}</span>
                                              {{ ' ' }}
                                              to
                                              {{ ' ' }}
                                              <span class="font-medium">{{ toPage }}</span>
                                              {{ ' ' }}
                                              of
                                              {{ ' ' }}
                                              <span class="font-medium">{{ totalPageResults }}</span>
                                              {{ ' ' }}
                                              results
                                          </p>
                                          </div>
                                          <div class="flex-1 flex justify-between sm:justify-end">
                                          <a :class="prevPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="getRecords(prevPageUrl)"  class=" inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Previous </a>
                                          <a :class="nextPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="getRecords(nextPageUrl)" class="ml-3 cursor-pointer  inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Next </a>
                                          </div>
                                      </nav>
                                  </td>
                              </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
    </div>
   
  </div>
</template>

<script>
import { ref, computed, inject } from 'vue'
import { CheckIcon, SelectorIcon} from '@heroicons/vue/solid'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  ComboboxLabel,
  Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions 
} from '@headlessui/vue'
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import MeimsApi from "@/components/api/MeimsApi";

export default {
    name: "ArchivesComponent",
    components: {
        CheckIcon,
        SelectorIcon,
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxOption,
        ComboboxOptions,
        ComboboxLabel,
        Listbox,
        ListboxButton,
        ListboxLabel,
        ListboxOption,
        ListboxOptions,
        AlertError, AlertSuccess
    },

    setup(){

        let activePage = inject('activePage');
        activePage.value = 'Job Cards'
       
        
        const periods = [
        { id: 1, name: '2027' },
        { id: 2, name: '2026' },
        { id: 3, name: '2025' },
        { id: 4, name: '2024' },
        { id: 5, name: '2023' },
        { id: 6, name: '2022' },
        ]
        const selectedPeriod = ref(periods[5]) 
       // let activeRole = inject('activeRole');

       
        const months = [
        { id: '01', name: 'January' },
        { id: '02', name: 'February' },
        { id: '03', name: 'March' },
        { id: '04', name: 'April' },
        { id: '05', name: 'May' },
        { id: '06', name: 'June' },
        { id: '07', name: 'July' },
        { id: '08', name: 'August' },
        { id: '09', name: 'September' },
        { id: '10', name: 'October' },
        { id: '11', name: 'November' },
        { id: '12', name: 'December' },
        ]
        const selectedMonth = ref(months[0]) 

        const sort_by = [
        { id: '1', name: 'unrated' },
        { id: '2', name: 'rated' },
        ]
        const selectedSort = ref(sort_by[0]) 

        const view_rating_by = [
        { id: '1', name: 'Customer' },
        { id: '2', name: 'Field Officer' },
        ]
        const selectedViewRating = ref(view_rating_by[0])
       
       
        let isLoading = ref(false);
        let errorMessage = ref("");
        let successMessage = ref("");

        const customers = ref([
        { id: 0, customer_name: 'All' }
        ])
        const queryCustomer = ref('')
        const selectedCustomer = ref(customers.value[0])
        const filteredCustomers = computed(() =>
        queryCustomer.value === ''
            ? customers.value
            : customers.value.filter((customer) => {
                return customer.customer_name.toLowerCase().includes(queryCustomer.value.toLowerCase())
            })
        )

        const fieldOfficers = ref([
        { id: 0, name: 'All' }
        ])
        const queryFieldOfficer = ref('')
        const selectedFieldOfficer= ref(fieldOfficers.value[0])
        const filteredFieldOfficers = computed(() =>
        queryFieldOfficer.value === ''
            ? fieldOfficers.value
            : fieldOfficers.value.filter((fieldOfficer) => {
                return fieldOfficer.name.toLowerCase().includes(queryFieldOfficer.value.toLowerCase())
            })
        )

        const jobCardReport = ref([])

        let fromPage = ref(0)
        let toPage   = ref(0)
        let totalPageResults   = ref(0);
        let prevPageUrl = ref('#')
        let nextPageUrl = ref('#')
        // let loggedInUser =  inject("loggedInUser");

            
        async function loadFieldOfficers(){

            try {

            const response = await MeimsApi().get('get-field-officers-erp');

            if(response.data.status === "success"){

                    fieldOfficers.value = fieldOfficers.value.concat(response.data.fieldOfficers);
                  
            
            } 

            } catch (err) {

                if (err.response) {

                    errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                errorMessage.value =  err.request.data.message;

                } else {

                    errorMessage.value =  err.message;
                    
                }
            }


        }
        async function loadCustomers(){

            try {

            const response = await MeimsApi().get('get-customer-list-erp');

            if(response.data.status === "success"){

                    // customers.value  = response.data.customers;
                    customers.value  = customers.value.concat(response.data.customers);
                    // customers.value.unshift( { id: 0, customer_name: 'All' })
                  //  successMessage.value = response.data.message;
            
            } 

            } catch (err) {

                if (err.response) {

                    errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                errorMessage.value =  err.request.data.message;

                } else {

                    errorMessage.value =  err.message;
                    
                }
            }


        }

        loadCustomers();
        loadFieldOfficers();

        async function getRecords(url = ''){


            
            errorMessage.value = '';
            successMessage.value = '';

           if(selectedViewRating.value.name != 'Customer'){

              
              selectedCustomer.value.id = 0;



           }else if(selectedViewRating.value.name != 'Field Officer'){ 

               selectedFieldOfficer.value.id   = 0;


           }

            isLoading.value = true;

         try {

          let response = null;

            if(url){

             response = await MeimsApi().get(`/get-job-card-report-erp/${selectedSort.value.id}/${selectedViewRating.value.id}/${selectedMonth.value.id}/${selectedPeriod.value.name}${url}&fieldOfficer_id=${selectedFieldOfficer.value.id}&customer_id=${selectedCustomer.value.id}`)

            }else{

             response = await MeimsApi().get(`/get-job-card-report-erp/${selectedSort.value.id}/${selectedViewRating.value.id}/${selectedMonth.value.id}/${selectedPeriod.value.name}?fieldOfficer_id=${selectedFieldOfficer.value.id}&customer_id=${selectedCustomer.value.id}`)

            }

            if(response.data.status === "success"){

                   jobCardReport.value      = response.data.report.data;
                   nextPageUrl.value        = response.data.report.next_page_url
                   prevPageUrl.value        = response.data.report.prev_page_url
                   fromPage.value           = response.data.report.from
                   toPage.value             = response.data.report.to
                   totalPageResults.value   = response.data.report.total
                  
                  //  successMessage.value = response.data.message;
            
             } 

         } catch (err) {

                if (err.response) {

                    errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                errorMessage.value =  err.request.data.message;

                } else {

                    errorMessage.value =  err.message;
                    
                }
            }


            
            isLoading.value = false;

        }

        return {

            sort_by, selectedSort,
            view_rating_by, selectedViewRating,
            periods,selectedPeriod,
            months,selectedMonth,
            fieldOfficers, queryFieldOfficer, selectedFieldOfficer, filteredFieldOfficers,
            customers, queryCustomer, selectedCustomer, filteredCustomers,
            jobCardReport,
            errorMessage, successMessage, isLoading,
            getRecords, fromPage, toPage, totalPageResults, prevPageUrl,nextPageUrl,
            

        }
    }

}
</script>

<style>

</style>