import axios from "axios";
import { logoutUser } from "@/stores/User.js"

let BaseApi = axios.create({
    baseURL: "https://erp.jms.co.ug/api",
    // baseURL: "http://localhost:8000"
});

// Add a request intercepto
BaseApi.interceptors.request.use(
    function (config) {
        // NProgress.start();
        return config;
    },
    function (error) {
        // NProgress.done();
        return Promise.reject(error);
    }
);

// Add a response interceptor
BaseApi.interceptors.response.use(
   async function (response) {
        // NProgress.done();
        return response;
    },
    async function (error) {

        if(error.response !== null){

            if(error.response.status == 401){

                const res = await logoutUser();

                if (res.status === "success") {
                   
                   window.location.href = '/?nocache=' + (new Date()).getTime();

                } 

            }
        }
        // NProgress.done();
        return Promise.reject(error);
    }
);

let Api = function () {
    
    let token = localStorage.getItem("token");

    if (token) {
        BaseApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    return BaseApi;
};

export default Api;
