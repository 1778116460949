<template>
<div>
     <Loading v-model:active="isLoading" />
    <div class="flex m-5 p-5 justify-end items-end">
        <a @click.prevent="logout()" class="block rounded-md border text-red-900 border-1 border-red-900 hover:border-indigo-400  cursor-pointer p-2"> logout</a>
    </div>
    <div class=" mt-8 flex justify-center items-center">
        <AlertSuccess
            v-if="successMessage"
            :message="successMessage"
            @hide="successMessage = null"
        />
        <AlertError
            v-if="errorMessage"
            :message="[errorMessage]"
            @hide="errorMessage = null"
        />
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:place-items-stretch place-items-center md:h-1/2 h-screen p-4">
        <div class="lg:col-span-4 md:col-span-2 mt-6 lg:mb-6 col-span-1">
            <h1 class="mb-4 text-4xl text-center font-extrabold tracking-tight leading-none text-red-900 md:text-5xl lg:text-6xl dark:text-white">Welcome to the JMS ERP</h1>
            <p class="mb-6 text-sm  text-center font-normal text-teal-700 lg:text-sm sm:px-16 xl:px-48 dark:text-gray-400">This menu lists the applications that you have access to. Click on any of your choice to get started</p>
        </div>
        
        <div>
            <router-link to="/meims/uptime-report" :class="meimsSystem? 'cursor-pointer' : 'pointer-events-none opacity-50'" class="block md:h-48 p-6 max-w-sm bg-white rounded-lg border  shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-red-900 dark:text-white">M.E.I.M.S</h5>
                <p class="font-normal text-xs text-teal-700 dark:text-gray-400"> A reporting module for the Medical Equipment Inventory Management System (MEIMS).</p>
            </router-link>
        </div>
        <div>
         <router-link to="/tenders/home"  :class="tendersSystem? 'cursor-pointer' : 'pointer-events-none opacity-50'" class="block md:h-48 p-6 max-w-sm bg-white rounded-lg border  shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-red-900 dark:text-white">Tenders & Invoicing</h5>
                <p class="font-normal text-xs text-teal-700 dark:text-gray-400">Manage tenders, request specifications from manufacturers, compile best offers into a tender document</p>
        </router-link>
        </div>
        <div>
            <router-link to="/requisition" :class="requisitionsSystem? 'cursor-pointer' : 'pointer-events-none opacity-50'" class="block md:h-48 p-6 max-w-sm bg-white rounded-lg border  shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-red-900 dark:text-white">Requisitions</h5>
                <p class="font-normal text-xs text-teal-700 dark:text-gray-400">Enter budget lines, make request for requisitions, enter accountability,...etc</p>
            </router-link>
        </div>
        <div>
            <router-link to="/fundraising/home"  :class="fundraisingSystem? 'cursor-pointer' : 'pointer-events-none opacity-50'" class="block md:h-48 p-6 max-w-sm bg-white rounded-lg border  shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-red-900 dark:text-white">Fundraising</h5>
                    <p class="font-normal text-xs text-teal-700 dark:text-gray-400">Start Fundraising for different projects or intiatives. You can set fundraising targets and monitor collections</p>
            </router-link>
        </div>
        <div v-if="userFromStorage.erp_role_id == 1 || userFromStorage.erp_role_id == 2">
            <router-link to="/manage/users" class="block md:h-48 p-6 max-w-sm bg-white rounded-lg border  shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 cursor-pointer">
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-red-900 dark:text-white">Users</h5>
                <p class="font-normal text-xs text-teal-700 dark:text-gray-400">Add/edit/delete users and also manage their roles within the erp</p>
            </router-link>       
        </div>
         <!-- <div v-if="userFromStorage.erp_role_id == 1 || userFromStorage.erp_role_id == 2">
            <router-link to="/manage/users" class="block md:h-48 p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 cursor-pointer">
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-red-900 dark:text-white">Users</h5>
                <p class="font-normal text-red-900 dark:text-gray-400">Add/edit/delete users and also manage their roles within the erp</p>
            </router-link>       
        </div> -->
        
    </div>
            <GeneralModalComponent :show="showModal" @dismiss="showModal = false">
                <template v-slot:header>
                            Update Password
                            <AlertError
                            v-if="errorMessage"
                            :message="[errorMessage]"
                            @hide="errorMessage = null"
                        />
                            
                    </template>
                    <template v-slot:body>
                    <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2 p-1">
                        <div class="sm:col-span-2 w-full">
                            <label for="old_password" class="block text-xs font-medium text-red-900">Old Password</label>
                            <div class="mt-1">
                            <input id="old_password" v-model="old_password" name="old_password" type="password" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                            </div>
                        </div>
                        <div class="sm:col-span-1 w-full">
                            <label for="new_password" class="block text-xs font-medium text-red-900">New Password</label>
                            <div class="mt-1">
                            <input id="new_password" v-model="new_password" name="new_password" type="password" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                            </div>
                        </div>       
                        <div class="sm:col-span-1 w-full">
                            <label for="confirm_password" class="block text-xs font-medium text-red-900">Confirm Password</label>
                            <div class="mt-1">
                            <input id="confirm_password" v-model="confirm_password" name="confirm_password" type="password" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                            </div>
                        </div>
                        <div class="sm:col-span-2 w-full">
                            <p v-if="passwordStrength < 2" class="text-sm font-medium text-red-400 pb-2">Password is still weak</p>
                            <p v-if="passwordStrength  == 2" class="text-sm font-medium text-amber-400 pb-2">Password is fair</p>
                            <p v-if="passwordStrength  == 3" class="text-sm font-medium text-yellow-400 pb-2">Password is OK</p>
                            <p v-if="passwordStrength > 3" class="text-sm font-medium text-green-400 pb-2">Excellent! Password is strong</p>
                            <div class="flex space-x-1 ">
                                <div class="h-1  w-1/4 rounded" v-bind:class="{
                                            'bg-red-400' : passwordStrength <= 1,
                                            'bg-amber-400' : passwordStrength == 2,
                                            'bg-yellow-400' : passwordStrength == 3,
                                            'bg-green-400' : passwordStrength == 4
                                        }"></div>
                                <div class="h-1  w-1/4 rounded" v-bind:class="{
                                            'bg-gray-400' : passwordStrength <= 1,
                                            'bg-amber-400' : passwordStrength == 2,
                                            'bg-yellow-400' : passwordStrength == 3,
                                            'bg-green-400' : passwordStrength == 4
                                        }"></div>
                                <div class="h-1  w-1/4 rounded" v-bind:class="{
                                            'bg-gray-400' : passwordStrength <= 2,
                                            'bg-yellow-400' : passwordStrength == 3,
                                            'bg-green-400' : passwordStrength == 4
                                        }"></div>
                                <div class="h-1  w-1/4 rounded" v-bind:class="{
                                            'bg-gray-400' : passwordStrength <= 3,
                                            'bg-green-400' : passwordStrength == 4
                                        }"></div>
                            </div>
                        </div>
                    </div>
                    </template>
                    <template v-slot:footer>
                    <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <button :class="passwordStrength < 2? 'bg-gray-400' : 'bg-red-900 hover:bg-red-700 ' " :disabled ="passwordStrength < 2 ? true : false" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-1 sm:text-sm" @click="updatePassword()">save</button>
                        <!-- <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-2 sm:text-sm" @click="showModal = false" >Cancel</button> -->
                    </div>
                    </template>
            </GeneralModalComponent>
</div>
</template>

<script>
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import {  ref, computed  } from "vue";

import {getUserSytems,getUser, logoutUser, updateUserPasswordUpdate} from "@/stores/User.js"
import GeneralModalComponent from "../components/generalPurposeComponents/GeneralPurposeModalComponent.vue";
import Api from "@/components/api/Api";
 
export default {
name: "ErpMenuView",
  components: { AlertSuccess, AlertError, GeneralModalComponent},
  setup() {
    let isLoading = ref(false);
    // const router = useRouter();
    let errorMessage = ref("");
    let successMessage = ref("");

    let userFromStorage = getUser();
    let systems         = getUserSytems();
  
    let meimsSystem = ref(false);

   if( !(typeof systems.value.meims == 'undefined')){

      meimsSystem.value = true;

   }

    let tendersSystem = ref(false);
   if( !(typeof systems.value.tenders == 'undefined')){

      tendersSystem.value = true;

   }
    
    let requisitionsSystem = ref(false);
    if( !(typeof systems.value.requisitions == 'undefined')){

      requisitionsSystem.value = true;

   } 

    // add new systems here
    //include card in template
    let fundraisingSystem = ref(false);
    if( !(typeof systems.value.fundraising == 'undefined')){

       fundraisingSystem.value = true;

    } 

    async function logout() {

       isLoading.value = true;

       const res = await logoutUser();

        if(res.status === "success"){

          window.location.href = '/?nocache=' + (new Date()).getTime();
        //  isLoading.value = false;
        
        }else{

          if(res.message){

            errorMessage.value = res.message;

          }else{
          
            errorMessage.value = "An error has occurred! Please try again";

          }

        }
    
    }

    let showModal = ref(false);
    let old_password = ref('')
    let new_password = ref('')
    let confirm_password = ref('')

    if(userFromStorage.value.update_password){

        showModal.value = true;
    }

    async function updatePassword(){

        errorMessage.value = '';
        successMessage.value = '';

            if((!old_password.value || old_password.value.toString().trim() == '' )){

                errorMessage.value = "Old Password cannot be empty";
                return;

            }

            if((!new_password.value || new_password.value.toString().trim() == '' )){

                errorMessage.value = "New Password cannot be empty";
                return;

            }
            if((new_password.value && new_password.value.toString().trim().length < 8 )){

                errorMessage.value = "New Password must be more than 8 characters long";
                return;

            }

            if((!confirm_password.value || confirm_password.value.toString().trim() == '' )){

                errorMessage.value = "Confirm Password cannot be empty";
                return;

            }

            if((confirm_password.value.toString().trim() !==  new_password.value.toString().trim() )){

                errorMessage.value = "New Password and Confirm Password must match";
                return;

            }

            
            isLoading.value = true;

            let  user = {
                    'old_password': old_password.value.trim(),
                    'new_password': new_password.value.trim(),
                    'confirm_password': confirm_password.value.trim(),
                    'user_to_edit' : userFromStorage.value.id
                };

            try {
                const res = await Api().post("/users/update-password", user)

                if (res.data.status === "success") {

                    successMessage.value  = res.data.message;

                    old_password.value = '';
                    new_password.value = '';
                    confirm_password.value = '';
                    showModal.value = false;

                    updateUserPasswordUpdate();

                }else{

                  errorMessage.value = res.data.message;

                }

            } catch (err) {

                if (err.response) {

                        errorMessage.value = "An error has occurred! " + err.response.message;

                } else if (err.request) {

                    errorMessage.value =  err.request.data.message;

                } else {

                    errorMessage.value =  err.message;

                }
            }

            isLoading.value = false;


    }

    let zxcvbn = require('zxcvbn');

    const passwordStrength = computed(() => {

        let result = zxcvbn( new_password.value )
      return result.score;
    });

  
    return {
              logout,
              errorMessage,
              successMessage,
              isLoading,
              userFromStorage,meimsSystem,tendersSystem, requisitionsSystem,
              showModal,
              old_password, new_password, confirm_password, updatePassword, passwordStrength,
              fundraisingSystem
           };
  }

}
</script>

<style>

</style>