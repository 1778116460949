<template>
  <div>
    <div class="mb-5">
        <div>
          <button
            class="capitalize flex text-blue-500 hover:underline"
            v-if="currentComponent"
            @click="swapComponent(null)"
          >
            <ArrowLeftIcon class="mr-2 h-6 w-6" />
            back
          </button>
        </div>
      </div>
    <component :is="currentComponent"></component>
    <div v-if="!currentComponent">
       <Loading v-model:active="isLoading" />
      <div class=" mt-3 mb-3 flex justify-center items-center">
              <AlertSuccess
                  v-if="successMessage"
                  :message="successMessage"
                  @hide="successMessage = null"
              />
              <AlertError
                  v-if="errorMessage"
                  :message="[errorMessage]"
                  @hide="errorMessage = null"
              />
      </div>
        <!-- <div class="grid grid-rows-3 gap-1 sm:flex sm:justify-between sm:items-center pb-4"> -->
        <div class="flex flex-row flex-wrap xl:space-y-0 justify-between items-center pb-4">
              <div>
                  <div class="flex flex-col sm:flex-row shadow-md sm:rounded-md">
                    <button type="button" @click="selectedFinancialYear == 0? showNoticeModal = true : swapComponent('AccountantAddBugdetLineCategoryComponent')" class="relative inline-flex items-center px-4 py-2 border hover:text-red-500 border-red-900  text-sm font-medium text-red-900 hover:bg-gray-100 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">Categories</button>
                    <button type="button" @click="selectedFinancialYear == 0? showNoticeModal = true : showModal = true" class="-ml-px relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium hover:text-red-500 text-red-900 hover:bg-gray-100 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">Add Budget Line</button>
                    <button type="button" @click="selectedFinancialYear == 0? showNoticeModal = true : swapComponent('AccountantBugdetLineAssignmentListComponent')" class="-ml-px relative inline-flex items-center px-4 py-2 hover:text-red-500 border border-red-900  text-sm font-medium text-red-900 hover:bg-gray-100 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">Assign Budget Line</button>
                    <button type="button" @click="selectedFinancialYear == 0? showNoticeModal = true : swapComponent('AccountantBudgetLineRestrictionsComponent')" class="-ml-px relative inline-flex items-center px-4 py-2 hover:text-red-500 border border-red-900 text-sm font-medium text-red-900 hover:bg-gray-100 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">Restrictions</button>
                  </div>
              </div>
              <div class="mt-2 lg:mt-0">
                  <select id="financialYear"  @change="loadBudgetLines(`/requisition/get-budget-lines/${selectedFinancialYear}`)" v-model="selectedFinancialYear" name="financialYear" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-300 rounded-md">
                    <option value="0">select financial Year</option>
                    <option v-for="financialYear in financialYears" :key="financialYear.id" :value="financialYear.id"> {{ financialYear.name }} </option>
                  </select>
                </div>
              <!-- <label for="table-search" class="sr-only">Search</label> -->
              <div class="relative flex pr-3 mt-2 lg:mt-2 xl:mt-0">
                  <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                      <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                  </div>
                  <input type="text" v-model="filter2" id="table-search" class="block  w-full flex-1 p-2 pl-10 sm:w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for budget lines">
              </div>
        </div>
        <!-- <div class="my-5">
          <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="small_size">Upload Excel sheet with Budget Lines Here</label>
          <div class="sm:flex sm:flex-row sm:flex-wrap lg:space-x-5">
            <div class="w-fit mr-3">
                <select id="excelbudgetlinecategories" v-model="selectedBudgetLineCategoryForExcel" name="excelbudgetlinecategories" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block h-9 w-full text-sm border-gray-300 rounded-md">
                  <option value="0">select category</option>
                  <option v-for="category in categories" :key="category.id" :value="category.id"> {{ category.name }} </option>
                </select>
            </div>
            <div class="my-2  lg:my-0 sm:flex sm:flex-row sm:flex-wrap sm:justify-center sm:items-center">
              <input @change="handleFile($event)" class="sm:flex-none block mb-1 text-xs text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none" id="small_size" type="file">
              <button  :disabled="!budgetLineFile" type="button" @click="selectedFinancialYear == 0? showNoticeModal = true : uploadBudgetLinesByExcel()" class=" inline-flex items-center h-7 p-1.5 sm:mx-2 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-900 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Upload
              </button>
            </div>
          </div>
        </div> -->
        <div class="flex flex-col">
            <div class="-my-2  sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
                        <table class="w-full table-auto divide-y divide-gray-200">
                            <thead class="bg-teal-700">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Ref</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Budget Line</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Planned Amount</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Supplementary Amt</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Total Used</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Balance Left</th>
                                    <!-- <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Over Spent</th> -->
                                    <!-- <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Returned Amount</th> -->
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">% used</th>
                                    <!-- <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Created Date</th> -->
                                    <th scope="col" class="relative px-6 py-3">
                                    <span class="sr-only">manage</span>
                                    </th>
                                    <!-- <th scope="col" class="relative px-6 py-3">
                                    <span class="sr-only">Transactions</span>
                                    </th>
                                    <th scope="col" class="relative px-6 py-3">
                                    <span class="sr-only">Over Spends</span>
                                    </th>
                                    <th scope="col" class="relative px-6 py-3">
                                    <span class="sr-only">Edit</span>
                                    </th>
                                    <th scope="col" class="relative px-6 py-3">
                                    <span class="sr-only">Delete</span>
                                    </th> -->
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="(budgetLine) in filteredBudgetLinesTable" :key="budgetLine.ref">
                                    <td class="px-6 py-4 text-xs font-medium text-gray-900">
                                    {{ budgetLine.ref }}
                                    </td>
                                    <td class="px-6 py-4 text-xs capitalize truncate font-normal text-gray-900">
                                    {{ budgetLine.name }} 
                                    </td>
                                    <td class="px-6 py-4 text-xs text-gray-500">
                                    {{ budgetLine.planned_amount ? Number(budgetLine.planned_amount).toLocaleString() : '0.00' }} 
                                    </td>
                                    <td class="px-6 py-4 text-xs text-gray-500">
                                    {{ budgetLine.supplementary_amount }}
                                    </td>
                                    <td class="px-6 py-4 text-xs text-gray-500">
                                    {{ budgetLine.total_used ?   Number(budgetLine.total_used).toLocaleString() : 0.00 }}
                                    </td>
                                    <td class="px-6 py-4 text-xs text-gray-500">

                                     {{ budgetLine.total_used ?  ((Number(budgetLine.planned_amount) + Number(budgetLine.supplementary_amount)) - Number(budgetLine.total_used)).toLocaleString()  : (Number(budgetLine.planned_amount) + Number(budgetLine.supplementary_amount)).toLocaleString() }}
                                    
                                    </td>
                                    <!-- <td class="px-6 py-4 text-xs text-gray-500">
                                    {{ budgetLine.over_spent }}
                                    </td> -->
                                    <!-- <td class="px-6 py-4 text-xs text-gray-500">
                                    {{ budgetLine.supplementary_budget }}
                                    </td> -->
                                    <!-- <td class="px-6 py-4 text-xs text-gray-500">
                                    {{ budgetLine.returned_amount }}
                                    </td> -->
                                    <td class="px-6 py-4 text-xs text-gray-500">
                                    {{ budgetLine.total_used ? ((budgetLine.total_used / (Number(budgetLine.planned_amount)+ Number(budgetLine.supplementary_amount)) ) * 100).toFixed(2)+'%' : '0.00%'  }}
                                    </td>
                                    <!-- <td class="px-6 py-4 text-sm text-gray-500">
                                    {{ budgetLine.financial_year }}
                                    </td> -->
                                    <!-- <td class="px-6 py-4 text-xs text-gray-500">
                                    {{ budgetLine.date_created }}
                                    </td> -->
                                    <td class="px-6 py-4 text-right text-xs font-medium">
                                      <Menu as="div" class="relative inline-block text-left">
                                          <div>
                                            <MenuButton
                                              class="
                                                inline-flex
                                                justify-center
                                                w-full
                                                rounded-md
                                                border-gray-300
                                                px-4
                                                bg-white
                                                text-xs
                                                font-medium
                                                text-blue-500
                                                hover:underline
                                                focus:outline-none
                                              "
                                            >
                                              Manage
                                              <ChevronDownIcon
                                                class="-mr-1 ml-2 h-5 w-5"
                                                aria-hidden="true"
                                              />
                                            </MenuButton>
                                          </div>

                                          <transition
                                            enter-active-class="transition ease-out duration-100"
                                            enter-from-class="transform opacity-0 scale-95"
                                            enter-to-class="transform opacity-100 scale-100"
                                            leave-active-class="transition ease-in duration-75"
                                            leave-from-class="transform opacity-100 scale-100"
                                            leave-to-class="transform opacity-0 scale-95"
                                          >
                                            <MenuItems class="origin-top-right absolute right-0 mt-2 w-36 rounded-md
                                                shadow-lg
                                                bg-white
                                                ring-1 ring-black ring-opacity-5
                                                focus:outline-none
                                                z-20
                                              "
                                            >
                                              <div class="py-1">
                                                <!-- <MenuItem v-slot="{ active }">
                                                  <a href="#"
                                                    :class="[
                                                      active
                                                        ? 'bg-gray-100 text-gray-900'
                                                        : 'text-gray-700',
                                                      'block px-4 py-2 text-xs',
                                                    ]"
                                                    @click.prevent="showFundManagementModal = true"
                                                    >Fund Management</a
                                                  >
                                                </MenuItem> -->
                                                <!-- <MenuItem v-slot="{ active }">
                                                  <a
                                                    href="#"
                                                    :class="[
                                                      active
                                                        ? 'bg-gray-100 text-gray-900'
                                                        : 'text-gray-700',
                                                      'block px-4 py-2 text-xs',
                                                    ]"
                                                    @click="swapComponent('AccountantViewBudgetLineTransactionsComponent')"
                                                    >Transactions</a
                                                  >
                                                </MenuItem> -->

                                                <!-- <MenuItem v-slot="{ active }">
                                                  <a
                                                    href="#"
                                                  
                                                    :class="[
                                                      active
                                                        ? 'bg-gray-100 text-gray-900'
                                                        : 'text-gray-700',
                                                      'block px-4 py-2 text-xs',
                                                    ]"
                                                    >Over Spends</a
                                                  >
                                                </MenuItem> -->

                                                <MenuItem v-slot="{ active }">
                                                  <a
                                                      href="#"
                                                     @click="editBudgetLine(budgetLine)"
                                                      :class="[
                                                      active
                                                        ? 'bg-gray-100 text-gray-900'
                                                        : 'text-gray-700',
                                                      'block px-4 py-2 text-xs',
                                                    ]"
                                                  >Edit</a>
                                                </MenuItem>
                                                <MenuItem v-slot="{ active }">
                                                  <a
                                                    @click.stop="selectedBudgetLineId = budgetLine.id; showPrompt = true;"
                                                    class="cursor-pointer"
                                                    :class="[
                                                      active ? 'bg-gray-100' : '',
                                                      'block px-4 py-2 text-xs text-red-500',
                                                    ]">Delete</a>
                                                </MenuItem>
                                              </div>
                                            </MenuItems>
                                          </transition>
                                        </Menu>
                                    <!-- <a href="#" class="text-indigo-600 hover:text-indigo-900">Manage</a> -->
                                    </td>
                                    <!-- <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    <a href="#" class="text-red-600 hover:text-red-900">Delete</a>
                                    </td> -->
                                </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                  <td colspan="8">
                                      <nav class="bg-white px-4 py-3 flex items-center justify-between  sm:px-6" aria-label="Pagination">
                                          <div class="hidden sm:block">
                                          <p class="text-xs text-gray-700">
                                              Showing
                                              {{ ' ' }}
                                              <span class="font-medium">{{ fromPage }}</span>
                                              {{ ' ' }}
                                              to
                                              {{ ' ' }}
                                              <span class="font-medium"> {{ toPage }} </span>
                                              {{ ' ' }}
                                              of
                                              {{ ' ' }}
                                              <span class="font-medium">{{ totalPageResults }}</span>
                                              {{ ' ' }}
                                              results
                                          </p>
                                          </div>
                                          <div class="flex-1 flex justify-between sm:justify-end">
                                            <a :class="prevPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="loadBudgetLines( `/requisition/get-budget-lines/${selectedFinancialYear}`+prevPageUrl)"  class="relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Previous </a>
                                            <a :class="nextPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="loadBudgetLines(`/requisition/get-budget-lines/${selectedFinancialYear}`+nextPageUrl)" class="ml-3 cursor-pointer relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Next </a>
                                          </div>
                                      </nav>
                                  </td>
                              </tr>
                          </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
     <DeleteAlert :show="showPrompt"  item="budget line"  @cancel="showPrompt = false" @delete="deleteBudgetLine(selectedBudgetLineId); showPrompt = false" />
    </div>
    <ManagerAccountsModalComponent :show="showModal" @dismiss="showModal = false">
       <template v-slot:header>
                   Add Budget Line
                    <AlertError
                      v-if="errorMessage"
                      :message="[errorMessage]"
                      @hide="errorMessage = null"
                     />
        </template>
        <template v-slot:body>
         <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-8 border border-1 border-gray-200 p-10">
            <div class="sm:col-span-5">
                <label for="budgetLineName" class="block text-xs font-medium text-gray-900">Budget Line Name</label>
                <div class="mt-1">
                  <input id="budgetLineName" v-model="budget_line_name" name="budgetLineName" type="text" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                </div>
            </div>
             <div class="sm:col-span-3">
              <label for="reference_number" class="block text-xs font-medium text-gray-900"> Reference Number</label>
              <div class="mt-1">
                <input type="text" v-model="budget_line_reference_number" name="reference_number" id="reference_number" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
              </div>
            </div>
       
            <div class="sm:col-span-5">
               <label for="country" class="block text-xs font-medium text-gray-900 pt-0.5 pb-0.5"> Select Budget Line Category</label>
                <Combobox as="div" class="" v-model="selectedCategory">
                    <div class="relative">
                    <ComboboxInput placeholder="Click the arrows or start typing name here" class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm" @change="query = $event.target.value" :display-value="(category) => category.name" />
                    <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </ComboboxButton>

                    <ComboboxOptions v-if="filteredCategories.length > 0" class="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <ComboboxOption v-for="category in filteredCategories" :key="category.id" :value="category" as="template" v-slot="{ active, selected }">
                        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                            <span :class="['block truncate', selected && 'font-semibold']">
                            {{ category.name }}
                            </span>

                            <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                        </li>
                        </ComboboxOption>
                    </ComboboxOptions>
                    </div>
                </Combobox>
            </div>

             <div class="sm:col-span-3">
                <label for="financialYear" class="block text-xs font-medium text-gray-900"> Financial Year </label>
                <div class="mt-1">
                  <select id="financialYear" v-model="selectedFinancialYear2" name="financialYear" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-xs border-gray-300 rounded-md">
                    <option value="0">select financial year</option>
                    <option v-for="financialYear in financialYears2" :key="financialYear.id" :value="financialYear.id"> {{ financialYear.name }} </option>
                  </select>
                </div>
             </div> 
              <div class="sm:col-span-4">
              <label for="budgetline_amount" class="block text-xs font-medium text-gray-900"> Budget Line Amount </label>
              <div class="mt-1">
                <input id="budgetline_amount" v-model="budget_line_amount" name="budgetline_amount" type="number" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
              </div>
            </div>
            <div class="sm:col-span-4">
              <label for="supplementary" class="block text-xs font-medium text-gray-900"> Supplementary Amount</label>
              <div class="mt-1">
                <input type="number" v-model="supplementary_amount" name="supplementary" id="supplementary" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
              </div>
            </div>

             
        </div>
        </template>
        <template v-slot:footer>
          <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-900 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-1 sm:text-sm" @click="addBudgetLine()">save</button>
            <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-2 sm:text-sm" @click="showModal = false" >Cancel</button>
          </div>
        </template>
    </ManagerAccountsModalComponent>
    <ManagerAccountsModalComponent :show="showFundManagementModal" @dismiss="showFundManagementModal = false">
       <template v-slot:header>
            Fund Management  ( MARKETING - BUSINESS PROMOTION )
        </template>
         <template v-slot:body>
             <div class="mt-6 border border-1 border-gray-200 p-10">
                  
                 <div>
                    <label for="budgetLineCategory" class="block text-sm font-medium text-gray-700"> Select Action</label>
                    <div class="mt-1">
                      <select id="budgetLineCategory" name="budgetLineCategory" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                        <option>select</option>
                        <option>Updating Budget line planned amount</option>
                        <option>Adding Fund to budget lines planned amount</option>
                      </select>
                    </div>
                  </div>
                  <div >
                    <label for="amount" class="block text-sm font-medium text-gray-700"> Amount</label>
                    <div class="mt-1">
                      <input type="text" name="amount" id="amount" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                    </div>
                  </div>
              </div>

         </template>
          <template v-slot:footer>
          <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-1 sm:text-sm" @click="showFundManagementModal = false">save</button>
            <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-2 sm:text-sm" @click="showFundManagementModal = false" >Cancel</button>
          </div>
        </template>
    </ManagerAccountsModalComponent>
     <GeneralModalComponent :show="showNoticeModal" @dismiss="showNoticeModal = false">
      <template v-slot:header>
                  Alert
      </template>
          <template v-slot:body>
            <h1 class="text-lg"> Please select a Financial Year to get started </h1>
          <!-- <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2 p-1">
             
          </div> -->
          </template>
          <template v-slot:footer>
          <div class="text-center my-5">
              <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-2 sm:text-sm" @click="showNoticeModal = false" >Close</button>
          </div>
          </template>
     </GeneralModalComponent>
  </div>
</template>

<script>
import {  ChevronDownIcon } from "@heroicons/vue/solid";
import { Menu, MenuButton, MenuItem, MenuItems, } from "@headlessui/vue";
import { ArrowLeftIcon } from "@heroicons/vue/outline";
import AccountantAddBugdetLineCategoryComponent from "./ManagerAccountsAddBugdetLineCategoryComponent.vue";
import AccountantBugdetLineAssignmentListComponent from "./ManagerAccountsBugdetLineAssignmentListComponent.vue";
import AccountantBudgetLineRestrictionsComponent from "./ManagerAccountsBudgetLineRestrictionsComponent.vue";
import AccountantViewBudgetLineTransactionsComponent from "./ManagerAccountsViewBudgetLineTransactionsComponent.vue";
import { ref, provide, computed, inject } from 'vue'
import ManagerAccountsModalComponent from "./ManagerAccountsModalComponent.vue";
import Api from "@/components/api/Api";
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, } from '@headlessui/vue'
import {  CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import DeleteAlert from "@/components/alertComponents/DeleteAlert.vue";
import GeneralModalComponent from "@/components/generalPurposeComponents/GeneralPurposeModalComponent.vue";


export default {

  components:{
      ChevronDownIcon,
      Menu, MenuButton, MenuItem, MenuItems,
      ArrowLeftIcon,
      AccountantAddBugdetLineCategoryComponent,
      AccountantBugdetLineAssignmentListComponent,
      ManagerAccountsModalComponent,
      AccountantBudgetLineRestrictionsComponent,
      AccountantViewBudgetLineTransactionsComponent,
      Combobox, ComboboxButton, ComboboxInput,  ComboboxOption, ComboboxOptions,
      SelectorIcon, CheckIcon,
      AlertError, AlertSuccess,DeleteAlert,
      GeneralModalComponent
      
  },

  setup(){

     let activePage = inject('activePage');
     activePage.value = 'Budget Lines'
     let loggedInUser =  inject("loggedInUser");
     let budget_line_name = ref('')
     let budget_line_amount = ref('0')
     let supplementary_amount = ref('0.00')
     let budget_line_reference_number = ref('')
     let editBudgetLineId = ref('0');
     let isLoading = ref(false);
     let errorMessage = ref("");
     let successMessage = ref("");

     const showPrompt = ref(false);
     let selectedBudgetLineId = ref('0');


    
    const budgetLines = ref([])

     let currentComponent = ref(null);
      
      function swapComponent(component) {
          currentComponent.value = component;

          if(!component){

            loadCategoryList()

          }
      }

      let showModal = ref(false);
      let showFundManagementModal = ref(false);
      let showNoticeModal = ref(false)
      const financialYears = ref([
        { id: 0, name: 'select financial Year' }
      ])

      const financialYears2 = ref([
        { id: 0, name: 'select financial Year' }
      ])

      let selectedFinancialYear = ref(0)

      provide('selectedFinancialYear',selectedFinancialYear)
      let selectedFinancialYear2 = ref(0)
      async function loadFinancialYears(){

            try {

            const response = await Api().get('/requisition/get-financial-years');

            if(response.data.status === "success"){

                    financialYears.value  = response.data.financialYears;
                    financialYears2.value  = response.data.financialYears;
                    
                  //  successMessage.value = response.data.message;
            
            } 

            } catch (err) {

                if (err.response) {


                    // errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                // errorMessage.value =  err.request.data.message;

                } else {

                    // errorMessage.value =  err.message;
                    
                }
            }


      }

      const categories = ref([
      { id: 0,  name: '' }
      ])
      const query = ref('')
      const selectedCategory = ref(categories.value[0])
      const filteredCategories = computed(() =>
        query.value === ''
            ? categories.value
            : categories.value.filter((category) => {
                return category.name.toLowerCase().includes(query.value.toLowerCase())
            })
      )

       const filter2 = ref("");
       const filteredBudgetLinesTable = computed(() => {
            return budgetLines.value.filter(
            (val) =>{
                const name = val.name.toString().toLowerCase();
               // const email = val.email.toString().toLowerCase();
                
                return name.includes(filter2.value.toLowerCase()) ;//|| email.includes(filter.value.toLowerCase());
            }
            );
        });

      let fromPage = ref(0)
      let toPage   = ref(0)
      let totalPageResults   = ref(0);
      let prevPageUrl = ref('')
      let nextPageUrl = ref('')
      let pageUrl   = `/requisition/get-budget-lines/${selectedFinancialYear.value}`;
      let reloadUrl = ref('');


      async function loadCategoryList(){

        try {

                const res = await Api().get(`/requisition/get-budget-categories-list`);

                if (res.data.status === "success") {

                    categories.value = res.data.categories;

                }

            } catch (err) {

                if (err.response) {

                   //

                } else if (err.request) {

                   //

                } else {
                    
                     //

                }
            }


      }


      async function addBudgetLine(){

         isLoading.value = true;

            let budgetLineObj = {

                'budget_line_name' : budget_line_name.value,
                'budget_line_amount' : budget_line_amount.value ,
                'supplementary_amount' : supplementary_amount.value ,
                'budget_line_category_id' : selectedCategory.value.id ,
                'budget_line_reference_number' : budget_line_reference_number.value,
                'financial_year_id': selectedFinancialYear2.value,
                'added_by_user_id': loggedInUser.value.id,
                'budget_line_id':  editBudgetLineId.value,
             }

            try {
            
                const res = await Api().post(`/requisition/add-budget-line`,budgetLineObj);

                if (res.data.status === "success") {

                  successMessage.value = res.data.message;
                  budget_line_name.value = '';
                  budget_line_amount.value = '0';
                  selectedCategory.value =  { id: 0,  name: '' };
                  budget_line_reference_number.value = ''
                  selectedFinancialYear2.value = 0
                  editBudgetLineId.value = 0;
                  supplementary_amount.value = '0'
                  showModal.value = false;
                  loadBudgetLines(reloadUrl.value);
                   

                }else{

                     errorMessage.value = res.data.message;

                }


            } catch (err) {

                if (err.response) {

                    errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                    errorMessage.value = err.request.data.message ;

                } else {

                    errorMessage.value = err.message;

                }
            }



          //console.log(budgetLineObj)

         isLoading.value = false;

      }

      async function loadBudgetLines(url = pageUrl){

       isLoading.value = true
       

         try {

                const response = await Api().get(url);

                if(response.data.status === "success"){

                        budgetLines.value         = response.data.budgetLines.data;
                        nextPageUrl.value        = response.data.budgetLines.next_page_url
                        prevPageUrl.value        = response.data.budgetLines.prev_page_url
                        fromPage.value           = response.data.budgetLines.from
                        toPage.value             = response.data.budgetLines.to
                        totalPageResults.value   = response.data.budgetLines.total
                        successMessage.value     = response.data.message;
                        reloadUrl.value = url;
                
                } 

            } catch (err) {

                if (err.response) {

                    errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                errorMessage.value =  err.request.data.message;

                } else {

                    errorMessage.value =  err.message;
                    
                }
            }

          isLoading.value = false;

            
      }

      async function editBudgetLine(budgetLineObj){


           budget_line_name.value  = budgetLineObj.name;
           budget_line_amount.value  = budgetLineObj.planned_amount
           supplementary_amount.value  = budgetLineObj.supplementary_amount
           selectedCategory.value = {'id': budgetLineObj.budget_line_category_id, 'name': budgetLineObj.category_name}
           budget_line_reference_number.value =  budgetLineObj.ref
           selectedFinancialYear2.value = budgetLineObj.financial_year_id
           editBudgetLineId.value = budgetLineObj.id
           
           showModal.value = true;

      }

      async function deleteBudgetLine(budget_line_id){

          successMessage.value = '';
            errorMessage.value = '';

             isLoading.value = true;

          try {
            
              const res = await Api().delete(`/requisition/delete-budget-line/${budget_line_id}`);

              if (res.data.status === "success") {

                successMessage.value = res.data.message;
                selectedBudgetLineId.value = 0;
                await  loadBudgetLines(reloadUrl.value);

              }else{

                errorMessage.value = res.data.message;
              }


            } catch (err) {

                if (err.response) {

                  errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                    errorMessage.value = err.request.data.message ;

                } else {

                  errorMessage.value = err.message;

                }
            }
          isLoading.value = false;

      }

      let budgetLineFile = ref(null);
      let selectedBudgetLineCategoryForExcel = ref(0)

      async function uploadBudgetLinesByExcel(){


            errorMessage.value = "";
            successMessage.value = "";

            if(selectedBudgetLineCategoryForExcel.value == '0'){

              errorMessage.value = 'Please select a budget line category';
              return;
              
            }
            
            isLoading.value =  true;

           const formData = new FormData();

           formData.append('added_by_user_id',loggedInUser.value.id);
           formData.append('budget_line_category_id',selectedBudgetLineCategoryForExcel.value);
           formData.append('financial_year_id',selectedFinancialYear.value);
           formData.append('file', budgetLineFile.value);
            
            const options = {
                headers: {
                "Content-Type": "multipart/form-data",
                },
            };

            try {

              const res = await Api().post("/requisition/uploadBudgetLinesByExcel", formData,options)

              if (res.data.status === "success") {

                  successMessage.value  = res.data.message;
                  budgetLineFile.value = null;
                  selectedBudgetLineCategoryForExcel.value = 0
                  await loadBudgetLines(reloadUrl.value);
                  
              }else{

                errorMessage.value = res.data.message;
              }

            } catch (err) {

                if (err.response) {

                  if (err.response.status === 422) {

                      //

                    }else{

                        errorMessage.value = "An error has occurred! " + err.response.message;

                    }

                } else if (err.request) {

                  errorMessage.value =  err.request.data.message;

                } else {

                  errorMessage.value =  err.message;

                }
            }

           isLoading.value = false;


      }

      function handleFile(e){

            var files = e.target.files || e.dataTransfer.files;
            if(files[0]){

              budgetLineFile.value = files[0];

            }else{

              budgetLineFile.value = null;


            }

      }



      //loadBudgetLines();
      loadCategoryList();
      loadFinancialYears();

    return {

        budgetLines, swapComponent, currentComponent,showModal,showFundManagementModal,
        selectedFinancialYear,financialYears,selectedFinancialYear2,financialYears2,
        query,selectedCategory,filteredCategories, loadCategoryList,
        addBudgetLine, isLoading, errorMessage, successMessage, 
        budget_line_name, budget_line_amount, supplementary_amount, budget_line_reference_number,
        loadBudgetLines, fromPage, toPage, totalPageResults, prevPageUrl,nextPageUrl,pageUrl,
        editBudgetLine, showPrompt, selectedBudgetLineId, deleteBudgetLine,
        filter2, filteredBudgetLinesTable,showNoticeModal,
        uploadBudgetLinesByExcel, handleFile, budgetLineFile, selectedBudgetLineCategoryForExcel,categories

    }
  }

}
</script>

<style>

</style>