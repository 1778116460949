<template>
  <div>
    <div class="mb-5">
      <div>
        <button
          class="capitalize flex text-blue-500 hover:underline"
          v-if="currentComponent"
          @click="swapComponent(null)"
        >
          <ArrowLeftIcon class="mr-2 h-6 w-6" />
          back
        </button>
      </div>
    </div>

    <component :is="currentComponent"></component>
    <div v-if="!currentComponent">
        <Loading v-model:active="isLoading" />
        <div class=" mt-2 mb-2 flex justify-center items-center">
            <AlertSuccess
                v-if="successMessage"
                :message="successMessage"
                @hide="successMessage = null"
            />
            <AlertError
                v-if="errorMessage"
                :message="[errorMessage]"
                @hide="errorMessage = null"
            />
        </div>
        <div class="sm:flex sm:justify-center sm:items-end  mb-5">
          <div class="sm:mx-4 my-3">
              <label for="status_select" class="block text-sm font-bold text-red-900">Status</label>
              <select v-model="status_select" @change="setPeriodSelect()" id="status_select" name="status_select" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded-md">
                <option value="0">Pending</option>
                <option value="1">Approved</option>
                <option value="2">Sent Back</option>
                <option value="3">Rejected</option>
              </select>
          </div>
          <div v-if="status_select != '0'" class="sm:mx-4  my-3">
              <label for="period_select" class="block text-sm font-bold text-red-900">Period</label>
              <select v-model="period_select" id="period_select" name="period_select" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded-md">
                <!-- <option selected value>All</option> -->
                <option value="today">Today</option>
                <option value="yesterday">Yesterday</option>
                <option value="this_week">This Week</option>
                <option value="this_month">This Month</option>
              </select>
          </div>
          <div class="sm:mx-4  my-3">
              <button @click="reloadRequisitions()" type="button" class="inline-flex items-center px-7 py-2 mb-0.5 border border-transparent text-xs font-medium rounded shadow-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  load
              </button>

          </div>
        </div>
        <div class="grid grid-rows-2 sm:flex sm:justify-between sm:items-center pb-4">
            <div class="pr-3">
                <button type="button" @click="swapComponent('RequisitionerAddRequisitionComponent')" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-md text-white bg-red-900 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Create New Requisition
                </button>

            </div>
            <!-- <label for="table-search" class="sr-only">Search</label> -->
            <div class="relative flex">
                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                </div>
                <input type="text" v-model="filter" id="table-search" class="block flex-1 p-2 pl-10 sm:w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items">
            </div>
        </div>
        <div class="flex flex-col">
            <div class="-my-2  sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
                        <table class="w-full divide-y divide-gray-200">
                            <thead class="bg-teal-700">
                                <tr>
                                    <!-- <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">#</th> -->
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"> Id Number</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Reason</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Description</th>
                                    <!-- <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">FY</th> -->
                                    <th scope="col" class="relative px-6 py-3">
                                    <span class="sr-only">statuses</span>
                                    </th>
                                    <th v-if="status == 2" scope="col" class="relative px-6 py-3">
                                    <span class="sr-only">view</span>
                                    </th>
                                    <th scope="col" class="relative px-6 py-3">
                                    <span class="sr-only">delete</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="(requisition) in filteredRequisitionsTable" :key="requisition.id">
                                    <!-- <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {{ index+1 }}
                                    </td> -->
                                    <td class="px-6 py-4 break-words text-sm font-medium text-gray-900">
                                      <a :id="requisition.requisition_name" :name="requisition.requisition_name" class="text-blue-600 visited:text-purple-600 
                                            hover:underline cursor-pointer" @click="status == 2? swapComponent('RequisitionerAddRequisitionComponent',requisition.id) : showRequisition(requisition.id)">
                                              {{ requisition.requisition_name }}
                                     </a>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {{ requisition.purpose_of_requisition }}
                                    </td>
                                    <td class="px-6 py-4 break-words text-sm text-gray-500">
                                    {{ requisition.summary }}
                                    </td>
                                    <!-- <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {{ requisition.financial_year }}
                                    </td> -->
                                    <td class="px-6 py-4 whitespace-nowrap text-right text-xs font-normal">
                                    <a href="#" @click="showTransitionStatuses(requisition.id)" class="bg-indigo-600 text-white text-xs  py-1 px-2 rounded-md cursor-pointer hover:bg-indigo-900 shadow-md">statuses</a>
                                    </td>
                                    <td v-if="status == 2" class="px-6 py-4 whitespace-nowrap text-right text-xs font-normal">
                                    <a href="#" @click="showRequisition(requisition.id)" class="bg-green-600 text-white text-xs  py-1 px-2 rounded-md cursor-pointer hover:bg-green-900 shadow-md">view comments</a>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-right text-xs font-normal">
                                    <a @click="selectedRequisition = requisition.id; showPrompt = true;"  class="text-white bg-red-600 py-1 px-2 rounded-md text-xs cursor-pointer hover:bg-red-900 shadow-md">delete</a>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                  <td colspan="10">
                                      <nav class="bg-white px-4 py-3 flex items-center justify-between  sm:px-6" aria-label="Pagination">
                                          <div class="hidden sm:block">
                                          <p class="text-sm text-gray-700">
                                              Showing
                                              {{ ' ' }}
                                              <span class="font-medium">{{ fromPage }}</span>
                                              {{ ' ' }}
                                              to
                                              {{ ' ' }}
                                              <span class="font-medium">{{ toPage }}</span>
                                              {{ ' ' }}
                                              of
                                              {{ ' ' }}
                                              <span class="font-medium">{{ totalPageResults }}</span>
                                              {{ ' ' }}
                                              results
                                          </p>
                                          </div>
                                          <div class="flex-1 flex justify-between sm:justify-end">
                                          <a :class="prevPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="getRequisitions( pageUrl+prevPageUrl)"  class="relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Previous </a>
                                          <a :class="nextPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="getRequisitions( pageUrl+nextPageUrl)" class="ml-3 cursor-pointer relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Next </a>
                                          </div>
                                      </nav>
                                  </td>
                              </tr>
                          </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <requisitioner-view-requisition-modal-component :requisition="requisitionProp" :requisitionAttachments="requisitionAttachmentsProp" :requisitionTransitions="requisitionTransitionsProp" :show="showRequisitionModal" @dismiss="showRequisitionModal = false">
           <template v-slot:footer>
              <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm" @click="showRequisitionModal = false">Close</button>
                    <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm" @click="downloadRequisitionPdf()">Download Requisition Report</button>
              </div>
           </template>
        </requisitioner-view-requisition-modal-component>
        <RequisitionerMultiPurposeModalComponent :show="showStatusesModal" @dismiss="showStatusesModal = false">
            <template v-slot:header>
                        Requisition Action Statuses
            </template>
            <template v-slot:body>
             <div class="flex flex-col">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead class="bg-teal-700">
                                    <tr>
                                        <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">#</th>
                                        <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Role</th>
                                        <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">User</th>
                                        <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Seen</th>
                                        <th scope="col" class="px-1 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Duration</th>
                                        <th scope="col" class="pl-1 pr-3 py-3 text-center text-xs font-medium text-white uppercase tracking-wider">Status</th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                    <tr v-for="(transition,index) in requisitionTransitions" :key="index" >
                                        <td class="pl-3 pr-2 py-4 text-sm text-gray-500">
                                           {{ index + 1 }}
                                        </td>
                                        <td class="px-4 py-4 text-sm text-gray-500">
                                         {{ transition.role_name }} 
                                        </td>
                                        <td class="px-4 py-4 text-sm text-gray-500">
                                          {{ transition.name }}
                                        </td>
                                        <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                                          {{ transition.seen_on? 'Yes, on ' + transition.seen_on : 'No' }}
                                        </td>
                                        <td class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                                          {{ transition.duration }} days
                                        </td>
                                        <td class="pl-1 pr-3 py-4 text-sm text-gray-500">
                                          {{ transition.status }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
              </div>
            </template>
        </RequisitionerMultiPurposeModalComponent>
    </div>
  <DeleteAlert :show="showPrompt"  item="requisition"  @cancel="showPrompt = false" @delete="deleteRequisition(selectedRequisition); showPrompt = false" />
  </div>
</template>

<script>
import RequisitionerAddRequisitionComponent from "./RequisitionerAddRequisitionComponent.vue";
import RequisitionerViewRequisitionModalComponent from "./RequisitionerViewRequisitionModalComponent.vue";
import RequisitionerMultiPurposeModalComponent from "./RequisitionerMultiPurposeModalComponent.vue";
import { ref, inject, watch, computed, provide } from 'vue'
import { ArrowLeftIcon } from "@heroicons/vue/outline";
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import Api from "@/components/api/Api";
import DeleteAlert from "@/components/alertComponents/DeleteAlert.vue";
import { useRoute} from "vue-router";
import html2pdf from "html2pdf.js";

export default {
    name: "RequisitionerRequisitionsComponent",
    components: {
        RequisitionerAddRequisitionComponent,
         ArrowLeftIcon,
         RequisitionerViewRequisitionModalComponent,
         RequisitionerMultiPurposeModalComponent,
          AlertError, AlertSuccess, DeleteAlert
    },

    setup() {

      //keep track of active page
      let activePage = inject('activePage');
      let status_title = inject('status_title');
      let loggedInUser =  inject("loggedInUser");
      let status = ref('0');
      let isEditing = ref(0)
      provide('isEditing',isEditing)
      
      const route = useRoute();
      let dateRange = ref(route.params.date);

      let status_select = ref('0')
      let period_select = ref('')

      if(dateRange.value){

        period_select.value = dateRange.value;

      }

      setRequisitionStatusFromUrl();

      activePage.value = 'Requisitions'
     
      let pageUrl = ref(`/requisition/get-requisitioner-requisitions/${loggedInUser.value.id}/${status.value}/${dateRange.value}`);
      const requisitions = ref([
        { id: '', 
        requisition_name: '', 
        purpose_of_requisition: '', 
        summary: '' },
       
      ])

        let currentComponent = ref(null);
        
        function swapComponent(component, isEdit = 0) {

            isEditing.value = isEdit;
            currentComponent.value = component;

        }

        const showRequisitionModal = ref(false);
        const showStatusesModal    = ref(false);
        let isLoading = ref(false);
        let errorMessage = ref("");
        let successMessage = ref("");
        let fromPage = ref(0)
        let toPage   = ref(0)
        let totalPageResults   = ref(0);
        let prevPageUrl = ref('#')
        let nextPageUrl = ref('#')

        let requisitionProp = ref({});
        let requisitionAttachmentsProp = ref([]);
        let requisitionTransitionsProp = ref([]);
        let requisitionTransitions   = ref([]);
        let selectedRequisition  = ref('0');
        const showPrompt = ref(false);
        

      const filter = ref("");
      const filteredRequisitionsTable = computed(() => {
        return requisitions.value.filter(
          (val) =>{
            const name = val.requisition_name.toString().toLowerCase();
            const summary = val.summary.toString().toLowerCase();
            const reason = val.purpose_of_requisition.toString().toLowerCase();
            
            return name.includes(filter.value.toLowerCase()) || summary.includes(filter.value.toLowerCase()) 
                    || reason.includes(filter.value.toLowerCase());
          }
        );
      });


      async function getRequisitions(url = '') {
        if(url == ''){
          url = pageUrl.value
        }
        isLoading.value = true;

        try{

          

          const response = await  Api().get(url);

          if (response.data.status === "success") {

               
                successMessage.value = response.data.message
                requisitions.value = response.data.requisitions.data;
                nextPageUrl.value = response.data.requisitions.next_page_url
                prevPageUrl.value = response.data.requisitions.prev_page_url
                fromPage.value = response.data.requisitions.from
                toPage.value   = response.data.requisitions.to
                totalPageResults.value   = response.data.requisitions.total
          }

        }catch (err) {

            if (err.response) {

              errorMessage.value = err.response.data.message;

                //return { 'message': err.response.data.message, 'status': 'error' };

            } else if (err.request) {

                errorMessage.value = err.request.data.message

                //return { 'message': err.request.data.message, 'status': 'error' };

            } else {

                errorMessage.value = err.message

              // return { 'message': err.message, 'status': 'error' };

            }
        }

        isLoading.value = false;

      }

      watch(currentComponent, async (newComponent) => {
        if (!newComponent) {
    
         getRequisitions();
        }
      })

      function setRequisitionStatusFromUrl(){

        if(route.params.status == 'pending'){

          status_title.value = 'Pending'

          status.value = 0;

          status_select.value = 0;

        }else if(route.params.status == 'approved'){

          status_title.value = 'Approved'

          status.value = 1;

          status_select.value = 1;

        }else if(route.params.status == 'sent back'){

          status_title.value = 'Sent Back'

          status.value = 2;

          status_select.value = 2;
        
        }else if(route.params.status == 'rejected'){

          status_title.value = 'Rejected'

          status.value = 3;

          status_select.value = 3;

        }

      }
     
      async function showRequisition(requisition_id){

        isLoading.value = true;

        try{

          const response = await  Api().get(`/requisition/get-requisition/${requisition_id}`);

          if (response.data.status === "success") {

                requisitionProp.value = response.data.requisition;
                requisitionAttachmentsProp.value = response.data.attachments;
                requisitionTransitionsProp.value = response.data.transitions;
                
               
          }

        }catch (err) {

            if (err.response) {

              errorMessage.value = err.response.data.message;

                //return { 'message': err.response.data.message, 'status': 'error' };

            } else if (err.request) {

                errorMessage.value = err.request.data.message

                //return { 'message': err.request.data.message, 'status': 'error' };

            } else {

                errorMessage.value = err.message

              // return { 'message': err.message, 'status': 'error' };

            }
        }

        isLoading.value = false;
        showRequisitionModal.value = true;

      }

      async function showTransitionStatuses(requisition_id){


         isLoading.value = true;

        try{

          const response = await  Api().get(`/requisition/get-transition-statuses/${requisition_id}`);

          if (response.data.status === "success") {

               
                requisitionTransitions.value = response.data.transitions;
               
          }

        }catch (err) {

            if (err.response) {

              errorMessage.value = err.response.data.message;

                //return { 'message': err.response.data.message, 'status': 'error' };

            } else if (err.request) {

                errorMessage.value = err.request.data.message

                //return { 'message': err.request.data.message, 'status': 'error' };

            } else {

                errorMessage.value = err.message

              // return { 'message': err.message, 'status': 'error' };

            }
        }

        isLoading.value = false;
        showStatusesModal.value = true;

      }

      async function deleteRequisition(requisition_id){

        successMessage.value = '';
        errorMessage.value = '';

        isLoading.value = true;

          try {
            
              const res = await Api().delete(`/requisition/delete-requisition/${requisition_id}`);

              if (res.data.status === "success") {

                successMessage.value = res.data.message;
                await  getRequisitions();

              }else{

                errorMessage.value = res.data.message;
              }


            } catch (err) {

                if (err.response) {

                  errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                    errorMessage.value = err.request.data.message ;

                } else {

                  errorMessage.value = err.message;

                }
            }
          isLoading.value = false;


      }

      function downloadRequisitionPdf(){

        let element = document.getElementById('requisition-to-convert');
        let downloadFileName = Date.now();

        if(requisitionProp.value){

          downloadFileName = requisitionProp.value.requisition_name

        }

        let opt = {
          margin:       0,
          filename:     downloadFileName,
          image:        { type: 'jpeg', quality: 1 },
          // html2canvas:  { scale: 1 },
          jsPDF:        { unit: 'mm', orientation: 'portrait' },
          };

        html2pdf().from(element).set(opt).save();

       // html2pdf(document.getElementById('requisition-to-convert'));

      }

      async function reloadRequisitions(){

        status.value    = status_select.value;
        dateRange.value = period_select.value;

        pageUrl.value = `/requisition/get-requisitioner-requisitions/${loggedInUser.value.id}/${status.value}/${dateRange.value}`;

        await  getRequisitions(pageUrl.value);

      }

      function setPeriodSelect(){

        if(!dateRange.value){

            period_select.value = 'today'
          
        }


      }

      


      getRequisitions();

      return {
       requisitions, swapComponent, currentComponent,
       showRequisitionModal, showStatusesModal, getRequisitions,
       errorMessage, successMessage, isLoading, 
      fromPage, toPage, totalPageResults, prevPageUrl,nextPageUrl,pageUrl,showRequisition,
      requisitionProp, requisitionAttachmentsProp,requisitionTransitionsProp,
      filteredRequisitionsTable,filter, showTransitionStatuses, requisitionTransitions,deleteRequisition,selectedRequisition,
      showPrompt, status_title, downloadRequisitionPdf,status,
      status_select, period_select,reloadRequisitions, setPeriodSelect
      }
    },

}
</script>

<style>

</style>