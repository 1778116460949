<template>
  <div class="mt-8">
    <Loading v-model:active="isLoading" />
    <div class="mt-5 max-w-2xl mx-auto">
      <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <button
          @click.prevent="
            $router.push({
              name: 'ViewManufacturerList',
              params: { tender_id, equipment_id, equipment_name, tender_name },
            })
          "
          class="capitalize flex text-blue-500 hover:underline"
        >
          <ArrowLeftIcon class="mr-2 h-6 w-6" />
          back
        </button>
        <div class="flex justify-center items-center space-y-4 flex-col">
              <AlertSuccess
                v-if="successMessage"
                :message="successMessage"
                @hide="successMessage = null"
              />
              <AlertError
                v-if="errorMessage"
                :message="[errorMessage]"
                @hide="errorMessage = null"
              />
        </div>
        <div class="flex justify-center items-center">
          <h2 class="text-xl leading-6 font-medium text-gray-900">
            Manufacturers Submission
          </h2>
        </div>
        <div class="flex justify-center items-center">
          <h3 class="text-xl pt-3 leading-6 font-extrabold text-blue-400">
            {{ manufacturer_name }}
          </h3>
        </div>
      </div>
    </div>
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between items-center">
        <h2 class="text-xl leading-6 font-medium text-gray-900"></h2>
        <div
          class="
            relative
            z-0
            flex-1
            px-2
            flex
            items-center
            justify-center
            sm:inset-0
            my-10
          "
        >
          <div class="w-full max-w-xl mx-auto">
            <label for="search" class="sr-only">Search</label>
            <div class="relative">
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  left-0
                  pl-3
                  flex
                  items-center
                "
              >
                <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                id="search"
                name="search"
                v-model="filter"
                class="
                  block
                  w-full
                  bg-gray-100
                  border border-transparent
                  rounded-md
                  py-3
                  pl-10
                  pr-3
                  text-sm
                  placeholder-gray-400
                  focus:outline-none
                  focus:bg-gray-50
                  focus:border-white
                  focus:ring-white
                  focus:text-gray-900
                  focus:placeholder-gray-500
                  sm:text-sm
                "
                placeholder="Search"
                type="search"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div
        class="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center"
      >
        <div
          class="
            space-y-4
            sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-1 sm:gap-5
          "
        >
        <div class="grid gap-6 items-end mb-4 md:grid-cols-4">
            <div class="relative">
                <input disabled="true" :value="price_in_usd" type="text" id="small_filled" class="block rounded-t-lg px-2.5 pb-1.5 pt-4 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label for="small_filled" class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] left-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-3">price in usd</label>
            </div>
            <div class="relative">
                <input disabled="true"  :value="incoterm" type="text" id="small_outlined" class="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label for="small_outlined" class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1">incoterm</label>
            </div>
             <div class="relative">
                <input disabled="true" :value="lead_time" type="text" id="small_outlined" class="block px-2.5 pb-1.5 pt-3 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label for="small_outlined" class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1">lead time in weeks</label>
            </div>
            <div class="relative z-0">
                <input disabled="true" :value="country" type="text" id="small_standard" class="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label for="small_standard" class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">country of origin</label>
            </div>
            
        </div>
          <!-- <button
            @click.prevent="downloadReport()"
            href=""
            class="
              flex
              items-center
              justify-center
              px-4
              py-3
              border border-transparent
              text-base
              font-medium
              rounded-md
              shadow-sm
              text-white
              bg-blue-600
              hover:bg-blue-500
              sm:px-8
            "
          >
            Download Equipment Report
          </button> -->
        </div>
        <div
          class="
            space-y-4
            sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-1 sm:gap-5
          "
        >
        <div class="flex items-center pl-7 pr-7 rounded border border-gray-200 dark:border-gray-700">
          <input id="best_offer" v-model="is_best_offer" true-value="1" false-value="0" type="checkbox" value="" @change="makeBestOffer()" class="w-6 h-6 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
          <label for="best_offer" class="py-4 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">select as best offer</label>
        </div>
          <!-- <button
            @click.prevent=""
            href=""
            class="
              flex
              items-center
              justify-center
              px-4
              py-3
              border border-transparent
              text-base
              font-medium
              rounded-md
              shadow-sm
              text-white
              bg-green-600
              hover:bg-green-500
              sm:px-8
            "
          >
            select as best offer
          </button> -->
        </div>
      </div>
    </div>
    <div class="mt-12 max-w-6xl mx-auto">
      <div
        id="table-div"
        class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg"
      >
        <table
          style="border: 1px black solid; border-collapse: collapse"
          class="min-w-full divide-y divide-gray-200"
        >
          <thead style="border: 1px solid black" class="bg-gray-50">
            <tr style="border: 1px solid black">
              <th
                style="border: 1px solid black"
                scope="col"
                class="
                  px-6
                  py-3
                  text-left text-xs
                  font-medium
                  text-gray-500
                  uppercase
                  tracking-wider
                "
              >
                s/n
              </th>
              <th
                style="border: 1px solid black"
                scope="col"
                class="
                  px-6
                  py-3
                  text-left text-xs
                  font-medium
                  text-gray-500
                  uppercase
                  tracking-wider
                "
              >
                Item Name
              </th>
              <th
                style="border: 1px solid black"
                scope="col"
                class="
                  px-6
                  py-3
                  text-left text-xs
                  font-medium
                  text-gray-500
                  uppercase
                  tracking-wider
                "
              >
                Technical Specifications
              </th>
              <th
                style="border: 1px solid black"
                scope="col"
                class="
                  px-6
                  py-3
                  text-left text-xs
                  font-medium
                  text-gray-500
                  uppercase
                  tracking-wider
                "
              >
                Compliance
              </th>
              <th
                style="border: 1px solid black"
                scope="col"
                class="
                  px-6
                  py-3
                  text-left text-xs
                  font-medium
                  text-gray-500
                  uppercase
                  tracking-wider
                "
              >
               Clinical significance of non complaince
              </th>

              <!-- <th style="border: 1px solid black ;"></th> -->
            </tr>
          </thead>
          <tbody style="border: 1px solid black">
            <tr
              style="border: 1px solid black"
              v-for="(submission, index) in getSubmissions"
              :key="submission.id"
              :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
            >
              <td
                style="border: 1px solid black"
                v-if="index == 0"
                rowspan="0"
                class="
                  px-6
                  py-4
                  whitespace-nowrap
                  text-sm
                  font-medium
                  text-gray-900
                "
              >
                {{ index + 1 }}
              </td>
              <td
                style="border: 1px solid black"
                v-if="index == 0"
                rowspan="0"
                class="px-6 py-4 text-sm text-gray-500"
              >
                {{ submission.equipment_name }}
              </td>
              <td
                style="border: 1px solid black"
                class="px-6 py-4 text-sm text-gray-500"
              >
                {{ submission.technical_spec }}
              </td>
              <td
                style="border: 1px solid black"
                class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
              >
                {{ submission.compliance == 1? "compliant" : "non compliant" }}
              </td>

              <td
                style="border: 1px solid black"
                class="px-6 py-4 text-sm text-gray-500"
              >
                {{ submission.notes }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowLeftIcon, SearchIcon } from "@heroicons/vue/solid";
import { ref, computed } from "vue";
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import Api from "@/components/api/Api";
import { getTender, getEquipment, getManufacturer } from "@/stores/Tender/Tender.js" 


export default {
  name: "ViewManufacturer Submission",
  props: {},
  components: { ArrowLeftIcon, SearchIcon, AlertSuccess, AlertError },
  setup() {
    let equipment_id = ref("");
    let tender_id = ref("");
    let equipment_name = ref("");
    let tender_name = ref("");
    let manufacturer_name = ref("");
    let manufacturer_id = ref("");
    let link_id = ref("");
    let is_best_offer = ref("");

    let selectedTender       = getTender();
    let selectedEquipment    = getEquipment();
    let selectedManufacturer = getManufacturer();


    equipment_id.value           = selectedEquipment.value.id;
    tender_id.value              = selectedTender.value.id;
    equipment_name.value         = selectedEquipment.value.name;
    tender_name.value            = selectedTender.value.name;

    manufacturer_name.value      = selectedManufacturer.value.name;
    manufacturer_id.value        = selectedManufacturer.value.manufacturer_id;
    link_id.value                = selectedManufacturer.value.id;
    is_best_offer.value          = selectedManufacturer.value.is_best_offer;
    

    let price_in_usd = ref('');
    let incoterm = ref('');
    let country = ref('');
    let lead_time = ref('');
    let link_to_brochure = ref('');
    let link_to_manufacturer_authorization = ref('');

    let isLoading  = ref(false);
    let errorMessage = ref("");
    let successMessage = ref("");

    const submissionsList = ref([]);

    const getSubmissions = computed(() => {
      return submissionsList.value.filter(
        (val) =>
          val.technical_spec.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
      );
    });

    const filter = ref("");

    let isDownloading = ref(false);

    function downloadReport() {
      isDownloading.value = true;

      setTimeout(() => {
        const header =
          "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
          "xmlns:w='urn:schemas-microsoft-com:office:word' " +
          "xmlns='http://www.w3.org/TR/REC-html40'>" +
          "<head><meta charset='utf-8'><title>Manufacturer Submission</title></head><body>";
        const footer = "</body></html>";
        const sourceHTML =
          header +
          document.querySelector("#table-div-download").innerHTML +
          footer;

        const source =
          "data:application/vnd.ms-word;charset=utf-8," +
          encodeURIComponent(sourceHTML);
        const fileDownload = document.createElement("a");
        document.body.appendChild(fileDownload);
        fileDownload.href = source;
        fileDownload.download = "report.doc";
        fileDownload.click();
        document.body.removeChild(fileDownload);
        isDownloading.value = false;
      }, 10);
    }

    function loadManufacturerSubmission(){

        isLoading.value = true;

             Api()
                .get(`/equipment/load-manufacturer-submission/${equipment_id.value}/${manufacturer_id.value}`)
                .then((response) => {
               
                  let status = response.data.status;
                  //let message = response.data.message;
                  if (status === "success") {
        
                    successMessage.value = response.data.message;
                      
                    if(response.data.manufacturer_submission){
                      
                      price_in_usd.value                       = response.data.manufacturer_submission.price_in_usd;
                      incoterm.value                           = response.data.manufacturer_submission.incoterm;
                      country.value                            = response.data.manufacturer_submission.country;
                      lead_time.value                          = response.data.manufacturer_submission.lead_time;
                      link_to_brochure.value                   = response.data.manufacturer_submission.link_to_brochure;
                      link_to_manufacturer_authorization.value = response.data.manufacturer_submission.link_to_manufacturer_authorization;    
                      
                    }

                    if(response.data.tech_submissions){

                          submissionsList.value = response.data.tech_submissions;

                      }

                  } 

                })
                .catch((error) => {
                  
                  errorMessage.value = "An error has occurred! " + error.message;
                  
                })
                .finally(() => {

                  isLoading.value = false;
                  
                });


    }

    function makeBestOffer(){

     isLoading.value = true;

      

      // save
      let data = {
          
          'link_id': link_id.value,
          'equipment_id' : equipment_id.value,
          'is_best_offer' : is_best_offer.value,
        };


        Api()
            .post("/equipment/make-best-offer", data)
            .then((response) => {

                let message = response.data.message;

                let status = response.data.status;

                if (status === "error") {

                    errorMessage.value = message; 
                    isLoading.value = false;

                } else if (status === "success") {

                    successMessage.value  = message;
                    isLoading.value = false;
                     
                }
            })
            .catch((error) => {

              errorMessage.value = "An error has occured! Please try again" + error.message;
              isLoading.value = false;

            }); 

        

    }

    loadManufacturerSubmission();

    return {
      submissionsList,
      getSubmissions,
      filter,
      downloadReport,
      isDownloading,
      equipment_id,
      tender_id,
      equipment_name,
      tender_name,
      manufacturer_name,
      successMessage,
      errorMessage,
      isLoading,
      makeBestOffer,
      is_best_offer,
      price_in_usd,
      lead_time,
      incoterm,
      country
    };
  },
};
</script>

<style>
</style>