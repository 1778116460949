<template>
 <div>
    <div class="mb-3 pt-2">
        <div>
            <button
            class="capitalize flex text-blue-500 hover:underline"
            v-if="currentComponent"
            @click="swapComponent(null)"
            >
            <ArrowLeftIcon class="mr-2 h-6 w-6" />
            back
            </button>
        </div>
    </div>
    <component :is="currentComponent"></component>
    <div  v-if="!currentComponent">
        <div class="grid grid-rows-2 sm:flex sm:justify-between sm:items-center pb-4">
            <!-- <div class="md:pr-3">
                <button disabled type="button" @click="swapComponent('AccountantBudgetLineAssignmentComponent')" class="inline-flex w-full justify-center items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-900 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Assign Budget line
                </button>

            </div> -->
            <!-- <label for="table-search" class="sr-only">Search</label> -->
            <!-- <div class="relative flex">
                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                </div>
                <input type="text" id="table-search" class="block flex-1 p-2 pl-10 sm:w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items">
            </div> -->
        </div>
        <!-- <div class="flex flex-col">
            <div class="-my-2  sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
                        <table class="w-full table-auto divide-y divide-gray-200">
                            <thead class="bg-teal-700">
                                <tr>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">#</th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Directorate</th>                                    
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Budget Line</th>                                    
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Amount</th>                                    
                                    <th scope="col" class="relative px-6 py-3">
                                    <span class="sr-only">delete</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="(budgetLineDepartmentAssignment,index) in budgetLineDepartmentAssignmentList" :key="index">
                                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {{ index+1 }}
                                    </td>
                                    <td class="px-6 py-4 break-words text-sm font-medium text-gray-900">
                                    {{ budgetLineDepartmentAssignment.directorate }}
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {{ budgetLineDepartmentAssignment.budgetline }}
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {{ budgetLineDepartmentAssignment.amount }}
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    <a href="#" class="text-white bg-red-600 py-1 px-2 rounded-md text-xs cursor-pointer hover:bg-red-900 shadow-md">delete</a>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="6">
                                        <nav class="bg-white px-4 py-3 flex items-center justify-between  sm:px-6" aria-label="Pagination">
                                            <div class="hidden sm:block">
                                            <p class="text-sm text-gray-700">
                                                Showing
                                                {{ ' ' }}
                                                <span class="font-medium">1</span>
                                                {{ ' ' }}
                                                to
                                                {{ ' ' }}
                                                <span class="font-medium">10</span>
                                                {{ ' ' }}
                                                of
                                                {{ ' ' }}
                                                <span class="font-medium">20</span>
                                                {{ ' ' }}
                                                results
                                            </p>
                                            </div>
                                            <div class="flex-1 flex justify-between sm:justify-end">
                                            <a href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> Previous </a>
                                            <a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"> Next </a>
                                            </div>
                                        </nav>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
 </div>
</template>

<script>
import { ref } from 'vue'
import AccountantBudgetLineAssignmentComponent from "./ManagerAccountsBudgetLineAssignmentComponent.vue";
import { ArrowLeftIcon } from "@heroicons/vue/outline";

export default {

     components:{
      ArrowLeftIcon,
      AccountantBudgetLineAssignmentComponent,
  },

  setup(){
     const budgetLineDepartmentAssignmentList = [
      { directorate: 'Customer Services	', budgetline: 'Expiry', amount: '400,000,000' },
      { directorate: 'Customer Services	', budgetline: 'Damages & Losses', amount: '80,000,000' },     
    ]

     let currentComponent = ref(null);
      
    function swapComponent(component) {
        currentComponent.value = component;
    }

    return {

      budgetLineDepartmentAssignmentList, currentComponent, swapComponent

    }
  }

}
</script>

<style>

</style>