<template>
  <div class="mt-8">
     <Loading v-model:active="isLoading" />
    <div class="mt-5 max-w-2xl mx-auto">
      <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <button
          @click.prevent="$router.push({ name: 'EquipmentList', params: { id: tender_id , tender_name} })"
          class="capitalize flex text-blue-500 hover:underline"
        >
          <ArrowLeftIcon class="mr-2 h-6 w-6" />
          back
        </button>
        <div class="flex justify-center items-center space-y-4 flex-col">
              <AlertSuccess
                v-if="successMessage"
                :message="successMessage"
                @hide="successMessage = null"
              />
              <AlertError
                v-if="errorMessage"
                :message="[errorMessage]"
                @hide="errorMessage = null"
              />
        </div>
        <div class="flex justify-center items-center">
          <h2 class="text-xl leading-6 font-normal text-gray-900 mt-2">
            Manufacturer List For <span class="text-red-900 font-medium"> {{ equipment_name }} </span>
          </h2>
        </div>
      </div>
    </div>
    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between items-center">
        <h2 class="text-xl leading-6 font-medium text-gray-900"></h2>
        <div
          class="
            relative
            z-0
            flex-1
            px-2
            flex
            items-center
            justify-center
            sm:inset-0
            my-10
          "
        >
          <div class="w-full max-w-xl mx-auto">
            <label for="search" class="sr-only">Search list</label>
            <div class="relative">
              <div
                class="
                  pointer-events-none
                  absolute
                  inset-y-0
                  left-0
                  pl-3
                  flex
                  items-center
                "
              >
                <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                id="search"
                name="search"
                v-model="filter"
                class="
                  block
                  w-full
                  bg-gray-100
                  border border-transparent
                  rounded-md
                  py-3
                  pl-10
                  pr-3
                  text-sm
                  placeholder-gray-400
                  focus:outline-none
                  focus:bg-gray-50
                  focus:border-white
                  focus:ring-white
                  focus:text-gray-900
                  focus:placeholder-gray-500
                  sm:text-sm
                "
                placeholder="Search list"
                type="search"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div
        class="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center"
      >
        <div
          class="
            space-y-4
            sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-1 sm:gap-5
          "
        >
          <button
            @click.prevent="showModal"
            href=""
            class="
              flex
              items-center
              justify-center
              px-4
              py-3
              border border-transparent
              text-base
              font-medium
              rounded-md
              shadow-sm
              text-white
              bg-blue-600
              hover:bg-blue-500
              sm:px-8
            "
          >
            Send Link to Manufacturer
          </button>
        </div>
      </div>
    </div> -->
    <ModalComponent v-show="isModalVisible" @close="closeModal">
      <!-- header -->
      <template v-slot:header>
        <h1 class="font-bold text-xl">Send Link for Procedure Trolley for Operating Room </h1>
      </template>

      <!-- body -->
      <template v-slot:body>
        <input
          type="text"
          name="manufacturer_name"
          id="manufacturer_name"
          placeholder="Manufacturer's Name"
          class="rounded-2xl w-full p-3 my-1 shadow-md outline-none"
        />

        <input
          type="email"
          name="manufacturer_email"
          id="manufacturer_email"
          placeholder="Manufacturer's E-mail"
          class="rounded-2xl w-full p-3 my-1 shadow-md outline-none"
        />

        <button
          type="button"
          class="rounded-2xl bg-purple-800 shadow-md p-3 my-1 w-full text-white"
        >
          send
        </button>
      </template>

      <!-- footer -->
      <!-- <template v-slot:footer>
        <p class="text-purple-900 text-xs font-bold">
          Already have an account? <a href="#">Sign up</a>
        </p>
      </template> -->
    </ModalComponent>

    <div class="flex justify-center items-center">
      <h2 class="text-xl leading-6 font-medium text-gray-900"></h2>
    </div>
    <div class="mt-12 max-w-6xl mx-auto">
      <div
        class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
      >
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-teal-700">
            <tr>
              <th
                scope="col"
                class="
                  px-6
                  py-3
                  text-left text-xs
                  font-medium
                  text-white
                  capitalize
                  tracking-wider
                "
              >
                Created Date
              </th>
              <th
                scope="col"
                class="
                  px-6
                  py-3
                  text-left text-xs
                  font-medium
                  text-white
                  capitalize
                  tracking-wider
                "
              >
                Manufacturer Name
              </th>
              <th
                scope="col"
                class="
                  px-6
                  py-3
                  text-left text-xs
                  font-medium
                  text-white
                  capitalize
                  tracking-wider
                "
              >
                Link Sent
              </th>
              <th
                scope="col"
                class="
                  px-6
                  py-3
                  text-left text-xs
                  font-medium
                  text-white
                  capitalize
                  tracking-wider
                "
              >
                Response Received
              </th>
               <th
                scope="col"
                class="
                  px-6
                  py-3
                  text-left text-xs
                  font-medium
                  text-white
                  capitalize
                  tracking-wider
                "
              >
                 Best Offer
              </th>
              <th
                scope="col"
                class="
                  px-6
                  py-3
                  text-left text-xs
                  font-medium
                  text-white
                  capitalize
                  tracking-wider
                "
              ></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(manufacturer, index) in getManufacturerList"
              :key="manufacturer.id"
              :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
            >
              <td
                class="
                  px-6
                  py-4
                  whitespace-nowrap
                  text-sm
                  font-medium
                  text-gray-900
                "
              >
                {{ manufacturer.date }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ manufacturer.name }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ manufacturer.sent_link == 1 ? 'yes' : 'no' }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ manufacturer.response_received == 1? 'yes' : 'no' }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-500">
                 {{ manufacturer.is_best_offer == 1? 'yes' : 'no' }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <button
                 v-if="manufacturer.response_received == 1"
                  @click="
                    selectedManufacturer = manufacturer; setManufacturer(manufacturer);
                    $router.push({ name: 'ViewManufacturerSubmission'});
                  "
                  class="text-white bg-green-600 py-1 px-2 rounded-md text-xs cursor-pointer hover:bg-green-900 shadow-md"
                >
                  view submission
                </button>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <button
                  @click="
                    selectedManufacturer = manufacturer; setManufacturer(manufacturer);
                    $router.push({ name: 'ViewInquiries', params:{ tender_id , equipment_id, equipment_name, tender_name, 
                                          'manufacturer_name' : selectedManufacturer.name,'manufacturer_id': selectedManufacturer.manufacturer_id } });
                  "
                  class="bg-indigo-600 text-white text-xs  py-1 px-2 rounded-md cursor-pointer hover:bg-indigo-900 shadow-md"
                >
                  view inquiries
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { SearchIcon, ArrowLeftIcon } from "@heroicons/vue/solid";
import { ref, computed } from "vue";
import ModalComponent from "@/components/TenderComponents/ModalComponent.vue";
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import Api from "@/components/api/Api";
import { getTender, getEquipment, setManufacturer } from "@/stores/Tender/Tender.js" 

export default {
  name: "ViewManfacturerList",
  components: { SearchIcon, ArrowLeftIcon, ModalComponent, AlertSuccess, AlertError},
  setup() {
    let isModalVisible = ref(false);
    let isLoading  = ref(false);
    const manufacturerList = ref([]);
    // const {success,error} = toast;
    const selectedManufacturer = ref();
    const filter = ref("");

    let equipment_id = ref('');
    let tender_id = ref('');
    let equipment_name = ref('');
    let tender_name = ref('');
    
    let selectedTender = getTender();
    let selectedEquipment = getEquipment();
   
    equipment_id.value      = selectedEquipment.value.id;
    tender_id.value         = selectedTender.value.id;
    equipment_name.value    = selectedEquipment.value.name;
    tender_name.value       = selectedTender.value.name;
  
    let errorMessage = ref("");
    let successMessage = ref("");

    const getManufacturerList = computed(() => {
      return manufacturerList.value.filter(
        (val) =>
          val.name.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
      );
    });

    function showModal() {
      isModalVisible.value = true;
    }
    function closeModal() {
      isModalVisible.value = false;
    }

    function loadManufacturerList(){


        isLoading.value = true;
        
        Api()
          .get(`/equipment/show-manufacturer-list/${equipment_id.value}`)
          .then((response) => {
            //Code for handling the response
            //add attribute editTeamName so that you can toggle showDetails row
            let status = response.data.status;
            //let message = response.data.message;
            if (status === "success") {
              manufacturerList.value = response.data.manufacturers;
            } else {
              
              manufacturerList.value = null;
              
            }
          })
          .catch((error) => {
              
              errorMessage.value = "An error has occurred! " + error.message;
          })
          .finally(() => {
            isLoading.value = false;
          });

    }

    loadManufacturerList();

    return {
      manufacturerList,
      selectedManufacturer,
      getManufacturerList,
      filter,
      isModalVisible,
      showModal,
      closeModal,
      equipment_id,
      equipment_name,
      tender_id,
      isLoading,
      errorMessage,
      successMessage,
      tender_name,
      setManufacturer
    };
  },
};
</script>

<style>
</style>