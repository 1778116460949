<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="fixed inset-0 flex z-40 md:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
          <div class="relative flex-1 flex flex-col max-w-xs w-full bg-white">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <div class="flex-shrink-0 flex items-center px-4">
                <img class="h-24 w-auto" src="/JMS_LOGO2.webp" alt="Workflow" />
              </div>
              <nav class="mt-5 px-2 space-y-1">
                 <router-link :to="{name: item.href }" v-for="item in navigation" :key="item.name" @click="activePage = item.name; sidebarOpen = false;" 
                    :class="[activePage == item.name ? 'bg-red-900 text-white' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']">
                  <component :is="item.icon" :class="[activePage == item.name ? 'text-white' : 'text-gray-600 group-hover:text-gray-500', 'mr-4 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                  {{ item.name }}
                 </router-link>
                <div class="border-t border-gray-200 ">
                    <a href="#" @click="showModal = true" :class="[ 'whitespace-nowrap text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-3 text-sm font-medium rounded-md']">
                        <component :is="CogIcon" :class="['text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                        Change Password
                    </a>
                    <!-- <a href="#" :class="[ ' text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-3 text-sm font-medium rounded-md']">
                        <component :is="LocationMarkerIcon" :class="['text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                        Update Location
                    </a> -->
                    <a href="#" @click.prevent="backToErpMenu()" :class="[ ' text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-3 text-sm font-medium rounded-md']">
                        <component :is="ArrowLeftIcon" :class="['text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                        ERP Menu
                    </a>
                    <a href="#" @click.prevent="logout()" :class="[ ' text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-3 text-sm font-medium rounded-md']">
                        <component :is="LogoutIcon" :class="['text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                        logout
                    </a>
                      <Menu as="div" class="relative block text-left py-2">
                            <div>
                              <MenuButton href="#" :class="[ 'w-full text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']">
                                  
                                  Switch Accounts
                                  <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                </MenuButton>
                            </div>

                            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                              <MenuItems class="  mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div class="py-1">
                                  <MenuItem v-for="role in roles" :key="role.id" >
                                    <a  @click.stop="switchRole(role.role_name);" href="#" class="cursor-pointer hover:bg-gray-100 hover:text-black" :class="[ activeRole == role.role_name ? 'bg-red-900 text-white' : 'text-gray-700', 'block px-4 py-2 text-sm']">{{ role.role_name }}</a>
                                  </MenuItem>
                                </div>
                              </MenuItems>
                            </transition>
                      </Menu>  
                  </div>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14">
          <!-- Force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 ">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex-1 flex flex-col min-h-0 border-r border-gray-200">
        <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto bg-white">
          <div class="flex items-center flex-shrink-0 px-4">
            <img class="h-24 w-auto" src="/JMS_LOGO2.webp" alt="Workflow" />
          </div>
          <nav class="mt-5 flex-1 px-2 space-y-1 bg-white">
            <router-link :to="{name: item.href }" v-for="item in navigation" :key="item.name" @click="activePage = item.name" 
               :class="[activePage == item.name ? 'bg-red-900 text-white' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']">
              <component :is="item.icon" :class="[activePage == item.name ? 'text-white' : 'text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
              {{ item.name }}
            </router-link>
             
            <div class="border-t border-gray-200 ">

               <a href="#" @click="showModal = true" :class="[ ' text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-3 text-sm font-medium rounded-md']">
                  <component :is="CogIcon" :class="['text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                  Change Password
               </a>
               <!-- <a href="#" :class="[ ' text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-3 text-sm font-medium rounded-md']">
                  <component :is="LocationMarkerIcon" :class="['text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                  Update Location
               </a> -->
               <a href="#" @click.stop="backToErpMenu()" :class="[ ' text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-3 text-sm font-medium rounded-md']">
                  <component :is="ArrowLeftIcon" :class="['text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                   ERP Menu
               </a>
               <a href="#" @click.stop="logout()" :class="[ ' text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-3 text-sm font-medium rounded-md']">
                  <component :is="LogoutIcon" :class="['text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                  logout
               </a>
                <Menu as="div" class="relative block text-left py-2">
                      <div>
                        <MenuButton href="#" :class="[ 'w-full text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']"> 
                            Switch Accounts
                            <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                          </MenuButton>
                      </div>

                      <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                        <MenuItems class="mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div class="py-1">

                            <MenuItem v-for="role in roles" :key="role.id" >
                             
                              <a  @click.stop="switchRole(role.role_name);" class="cursor-pointer hover:bg-gray-100 hover:text-black" :class="[ activeRole == role.role_name ? 'bg-red-900 text-white' : 'text-gray-700', 'block px-4 py-2 text-sm']">{{ role.role_name }}</a>
                            </MenuItem>
                  
                          </div>
                        </MenuItems>
                      </transition>
                </Menu>
                
            </div>
          </nav>
        </div>
        
      </div>
    </div>
    <div class="md:pl-64 flex flex-col flex-1">
      <div class="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
        <button type="button" class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <MenuIcon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <main class="flex-1">
        <div class="py-3">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 class="text-2xl text-center font-semibold text-red-900">{{ activeRole +' '+ status_title + ' ' + activePage }}</h1>
          </div>
          <div class="max-w-full px-5 sm:px-6 md:px-8">
             <Loading v-model:active="isLoading" />
            <div class=" mt-8 flex justify-center items-center">
                <AlertSuccess
                    v-if="successMessage"
                    :message="successMessage"
                    @hide="successMessage = null"
                />
                <AlertError
                    v-if="errorMessage"
                    :message="[errorMessage]"
                    @hide="errorMessage = null"
                />
            </div>
            <!-- Replace with your content -->
            <div class="py-4">
                <router-view />
            </div>
            <!-- /End replace -->
          </div>
        </div>
      </main>
    </div>
    <GeneralModalComponent :show="showModal" @dismiss="showModal = false">
        <template v-slot:header>
                    Update Password
                    <AlertError
                    v-if="errorMessage"
                    :message="[errorMessage]"
                    @hide="errorMessage = null"
                />
                    
            </template>
            <template v-slot:body>
            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2 p-1">
                <div class="sm:col-span-2 w-full">
                    <label for="old_password" class="block text-xs font-medium text-red-900">Old Password</label>
                    <div class="mt-1">
                    <input id="old_password" v-model="old_password" name="old_password" type="password" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                    </div>
                </div>
                <div class="sm:col-span-1 w-full">
                    <label for="new_password" class="block text-xs font-medium text-red-900">New Password</label>
                    <div class="mt-1">
                    <input id="new_password" v-model="new_password" name="new_password" type="password" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                    </div>
                </div>       
                <div class="sm:col-span-1 w-full">
                    <label for="confirm_password" class="block text-xs font-medium text-red-900">Confirm Password</label>
                    <div class="mt-1">
                    <input id="confirm_password" v-model="confirm_password" name="confirm_password" type="password" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                    </div>
                </div>
                 <div class="sm:col-span-2 w-full">
                  <p v-if="passwordStrength < 2" class="text-sm font-medium text-red-400 pb-2">Password is still weak</p>
                  <p v-if="passwordStrength  == 2" class="text-sm font-medium text-amber-400 pb-2">Password is fair</p>
                  <p v-if="passwordStrength  == 3" class="text-sm font-medium text-yellow-400 pb-2">Password is OK</p>
                  <p v-if="passwordStrength > 3" class="text-sm font-medium text-green-400 pb-2">Excellent! Password is strong</p>
                        <div class="flex space-x-1 ">
                            <div class="h-1  w-1/4 rounded" v-bind:class="{
                                        'bg-red-400' : passwordStrength <= 1,
                                        'bg-amber-400' : passwordStrength == 2,
                                        'bg-yellow-400' : passwordStrength == 3,
                                        'bg-green-400' : passwordStrength == 4
                                    }"></div>
                            <div class="h-1  w-1/4 rounded" v-bind:class="{
                                        'bg-gray-400' : passwordStrength <= 1,
                                        'bg-amber-400' : passwordStrength == 2,
                                        'bg-yellow-400' : passwordStrength == 3,
                                        'bg-green-400' : passwordStrength == 4
                                    }"></div>
                            <div class="h-1  w-1/4 rounded" v-bind:class="{
                                        'bg-gray-400' : passwordStrength <= 2,
                                        'bg-yellow-400' : passwordStrength == 3,
                                        'bg-green-400' : passwordStrength == 4
                                    }"></div>
                            <div class="h-1  w-1/4 rounded" v-bind:class="{
                                        'bg-gray-400' : passwordStrength <= 3,
                                        'bg-green-400' : passwordStrength == 4
                                    }"></div>
                        </div>
                </div>
            </div>
            </template>
            <template v-slot:footer>
            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button :class="passwordStrength < 2? 'bg-gray-400' : 'bg-red-900 hover:bg-red-700 ' " :disabled ="passwordStrength < 2? true : false" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-1 sm:text-sm" @click="updatePassword()">save</button>
                <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-2 sm:text-sm" @click="showModal = false" >Cancel</button>
            </div>
            </template>
        </GeneralModalComponent>
  </div>
</template>

<script>
import { ref, provide, computed } from 'vue'
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import {
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  MenuIcon,
  XIcon,
  ClockIcon,
  CheckIcon,
  ArchiveIcon,
  CreditCardIcon,
  ScaleIcon,
  
} from '@heroicons/vue/outline'
import { ChevronDownIcon , LogoutIcon, CogIcon, LocationMarkerIcon, ArrowLeftIcon} from '@heroicons/vue/solid'
import { useRouter } from "vue-router";
// import Api from "@/components/api/Api";
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import {logoutUser, getUser,getUserSytems} from "@/stores/User.js"
import {initRolesLists, getRequisitionRoles} from "@/stores/ErpSystemRolesLists.js"
import { getCurrent_active_user_role, initCurrent_active_user_role} from "@/stores/Requisition.js"
import GeneralModalComponent from "../components/generalPurposeComponents/GeneralPurposeModalComponent.vue";
import Api from "@/components/api/Api";

export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    MenuIcon,
    XIcon,
    Menu, MenuButton, MenuItem, MenuItems,
    ChevronDownIcon, LogoutIcon, CogIcon, LocationMarkerIcon, ArrowLeftIcon,
    ClockIcon, CheckIcon, ArchiveIcon, CreditCardIcon,
    ScaleIcon, ChartBarIcon,
    AlertError,AlertSuccess,GeneralModalComponent
  },
  setup() {

    const router = useRouter();

    const sidebarOpen = ref(false)
    const activePage = ref('Dashboard');
    const status_title = ref('')
    let isLoading = ref(false);
    let errorMessage = ref("");
    let successMessage = ref("");
    let loggedInUser = getUser();
    const userRequisitionRoles = getUserSytems().value['requisitions'];
    const navigation = ref([])
    const activeRole = getCurrent_active_user_role();
    let printpdf = ref(false);

   let showModal = ref(false);
   let old_password = ref('')
   let new_password = ref('')
   let confirm_password = ref('')

    
    provide('activePage',activePage);
    provide('status_title',status_title);
    provide("loggedInUser",loggedInUser);
    provide("userRequisitionRoles",userRequisitionRoles);
    provide('activeRole',activeRole);
    provide('printpdf',printpdf)

    if(!userRequisitionRoles){

     router.push({name:'ErpMenuView'});

   }else{
    
    if(activeRole.value){

      setNavigationMenu(activeRole.value,false);
      loadRequisitionDefaults(false);

    }else{

      loadRequisitionDefaults();
    }
    
   }
    const roles = ref([]);

    function switchRole(role){
      
     if(role !=  activeRole.value){
      activeRole.value = role;
      

      if(role == 'Supervisor'){

       navigation.value = [
        { name: 'Dashboard', href: 'SupervisorDashboardComponent', icon: HomeIcon, current: true },
        { name: 'Pending Requisitions', href: 'SupervisorPendingComponent', icon: ClockIcon, current: false },
        { name: 'Approved Requisitions', href: 'SupervisorApprovedComponent', icon: CheckIcon, current: false },
        { name: 'Rejected Requisitions', href: 'SupervisorRejectedComponent', icon: XIcon, current: false },
        { name: 'Archives', href: 'ArchivesComponent', icon: ArchiveIcon, current: false },
       ]

        initCurrent_active_user_role(activeRole.value)
        activeRole.value = getCurrent_active_user_role().value;

        activePage.value = 'Dashboard';
        router.push({name:'SupervisorDashboardComponent'});

      }else if(role == 'Requisitioner'){

         navigation.value = [
          { name: 'Dashboard', href: 'RequisitionerDashboard', icon: HomeIcon, current: true },
          { name: 'Requisitions', href: 'RequisitionerRequisitionsComponent', icon: FolderIcon, current: false },
          { name: 'Accountabilities', href: 'RequisitionerRequisitionsAccountability', icon: ScaleIcon, current: false },
          { name: 'Archives', href: 'ArchivesComponent', icon: ArchiveIcon, current: false },
         ]

        initCurrent_active_user_role(activeRole.value)
        activeRole.value = getCurrent_active_user_role().value;

        activePage.value = 'Dashboard';
        router.push({name:'RequisitionerDashboard'});


      }else if(role == 'Budget Holder'){

         navigation.value = [
          { name: 'Dashboard', href: 'BudgetholderDashboardComponents', icon: HomeIcon, current: true },
          { name: 'Pending Requisitions', href: 'BudgetholderPendingComponent', icon: ClockIcon, current: false },
          { name: 'Approved Requisitions', href: 'BudgetholderApprovedComponent', icon: CheckIcon, current: false },
          { name: 'Rejected Requisitions', href: 'BudgetholderRejectedComponent', icon: XIcon, current: false },
          // { name: 'Excess Goods', href: 'BudgetholderExcessGoodsComponent', icon: ArchiveIcon, current: false },
          { name: 'My Budget Lines', href: 'BudgetholderBudgetLinesComponent', icon: CreditCardIcon, current: false },
          { name: 'Accountabilities', href: 'BudgetholderAccountabilitiesComponent', icon: ScaleIcon, current: false },
          // { name: 'Reports', href: 'ReportsComponent', icon: ChartBarIcon, current: false },
          { name: 'Archives', href: 'ArchivesComponent', icon: ArchiveIcon, current: false },
         ]

        initCurrent_active_user_role(activeRole.value)
        activeRole.value = getCurrent_active_user_role().value;

        activePage.value = 'Dashboard';
        router.push({name:'BudgetholderDashboardComponents'});

      }else if(role == 'Accountant'){

         navigation.value = [
          { name: 'Dashboard', href: 'AccountantDashboardComponent', icon: HomeIcon, current: true },
          { name: 'Pending Requisitions', href: 'AccountantPendingComponent', icon: ClockIcon, current: false },
          { name: 'Approved Requisitions', href: 'AccountantApprovedComponent', icon: CheckIcon, current: false },
          { name: 'Rejected Requisitions', href: 'AccountantRejectedComponent', icon: XIcon, current: false },
          { name: 'Exchange Rates',       href: 'AccountantExchangeRatesComponent', icon: CreditCardIcon, current: false },
          // { name: 'Reports', href: 'ReportsComponent', icon: ChartBarIcon, current: false },
          { name: 'Archives', href: 'ArchivesComponent', icon: ArchiveIcon, current: false },
         ]

        initCurrent_active_user_role(activeRole.value)
        activeRole.value = getCurrent_active_user_role().value;

        activePage.value = 'Dashboard';
        router.push({name:'AccountantDashboardComponent'});

      }else if(role == 'Manager Accounts'){

         navigation.value = [
          { name: 'Dashboard', href: 'ManagerAccountsDashboardComponent', icon: HomeIcon, current: true },
          { name: 'Pending Requisitions', href: 'ManagerAccountsPendingComponent', icon: ClockIcon, current: false },
          { name: 'Approved Requisitions', href: 'ManagerAccountsApprovedComponent', icon: CheckIcon, current: false },
          { name: 'Rejected Requisitions', href: 'ManagerAccountsRejectedComponent', icon: XIcon, current: false },
          { name: 'Budget Lines', href: 'ManagerAccountsBudgetLinesComponent', icon: CreditCardIcon, current: false },
          // { name: 'Reports', href: 'ReportsComponent', icon: ChartBarIcon, current: false },
          { name: 'Archives', href: 'ArchivesComponent', icon: ArchiveIcon, current: false },
         ]

        initCurrent_active_user_role(activeRole.value)
        activeRole.value = getCurrent_active_user_role().value;

        activePage.value = 'Dashboard';
        router.push({name:'ManagerAccountsDashboardComponent'});

      }else if(role == 'Director Finance'){

         navigation.value = [
          { name: 'Dashboard', href: 'DirectorFinanceDashboardComponent', icon: HomeIcon, current: true },
          { name: 'Pending Requisitions',  href: 'DirectorFinancePendingComponent', icon: ClockIcon, current: false },
          { name: 'Approved Requisitions', href: 'DirectorFinanceApprovedComponent', icon: CheckIcon, current: false },
          { name: 'Rejected Requisitions', href: 'DirectorFinanceRejectedComponent', icon: XIcon, current: false },
          { name: 'Accountabilities', href: 'DirectorFinanceAccountabilitiesComponent', icon: ScaleIcon, current: false },
          // { name: 'Reports', href: 'ReportsComponent', icon: ChartBarIcon, current: false },
          { name: 'Archives', href: 'ArchivesComponent', icon: ArchiveIcon, current: false },
         ]

        initCurrent_active_user_role(activeRole.value)
        activeRole.value = getCurrent_active_user_role().value;

        activePage.value = 'Dashboard';
        router.push({name:'DirectorFinanceDashboardComponent'});

      }else if(role == 'Payments Office'){

         navigation.value = [
          { name: 'Dashboard', href: 'PaymentsOfficeDashboardComponent', icon: HomeIcon, current: true },
          { name: 'Pending Requisitions',  href: 'PaymentsOfficePendingComponent', icon: ClockIcon, current: false },
          { name: 'Approved Requisitions', href: 'PaymentsOfficeApprovedComponent', icon: CheckIcon, current: false },
          { name: 'Rejected Requisitions', href: 'PaymentsOfficeRejectedComponent', icon: XIcon, current: false },
          { name: 'Accountabilities', href: 'PaymentsOfficeAccountabilitiesComponent', icon: ScaleIcon, current: false },
          // { name: 'Reports', href: 'ReportsComponent', icon: ChartBarIcon, current: false },
          { name: 'Archives', href: 'ArchivesComponent', icon: ArchiveIcon, current: false },
         ]

        initCurrent_active_user_role(activeRole.value)
        activeRole.value = getCurrent_active_user_role().value;

        activePage.value = 'Dashboard';
        router.push({name:'PaymentsOfficeDashboardComponent'});

      }else if(role == 'Procurement'){

         navigation.value = [
          { name: 'Dashboard', href: 'ProcurementDashboardComponent', icon: HomeIcon, current: true },
          { name: 'Pending Requisitions', href: 'ProcurementPendingComponent', icon: ClockIcon, current: false },
          { name: 'Approved Requisitions', href: 'ProcurementApprovedComponent', icon: CheckIcon, current: false },
          { name: 'Rejected Requisitions', href: 'ProcurementRejectedComponent', icon: XIcon, current: false },
          // { name: 'Reports', href: 'ReportsComponent', icon: ChartBarIcon, current: false },
          { name: 'Archives', href: 'ArchivesComponent', icon: ArchiveIcon, current: false },
         ]

        initCurrent_active_user_role(activeRole.value)
        activeRole.value = getCurrent_active_user_role().value;

        activePage.value = 'Dashboard';
        router.push({name:'ProcurementDashboardComponent'});

      }


     }


    }

    function backToErpMenu(){

       router.push({name:'ErpMenuView'});
    }

    async function logout() { 

       isLoading.value = true;

       const res = await logoutUser();

        if(res.status === "success"){

          window.location.href = '/?nocache=' + (new Date()).getTime();
          //isLoading.value = false;
        
        }else{

          if(res.message){

            errorMessage.value = res.message;

          }else{
          
            errorMessage.value = "An error has occurred! Please try again";

          }

        }
    }

     async function loadRequisitionDefaults(reloadRoles = true){

     
      const res =  await initRolesLists();

       if(res.status === "success"){

          roles.value = getRequisitionRoles().value;

          roles.value = roles.value.filter((el) => {
            return userRequisitionRoles.some((f) => {
              return f.erp_system_role_id === el.id
            });
          });

          if(roles.value.length > 0){

            if(!activeRole.value && reloadRoles){

                 initCurrent_active_user_role(roles.value[0].role_name)
                 activeRole.value = getCurrent_active_user_role().value;
                 setNavigationMenu(activeRole.value,true)
            }else {
              //this has been added so that when user press back to erp menu and comes back, 
              //to requisition system, they will get the dashboard loaded
              setNavigationMenu(activeRole.value,true)
            }

          }else{

            // if user has no roles take them back to erp menu 
            // maybe later display error message to the users
             router.push({name:'ErpMenuView'});

          }
         
           
       }
     
     
     }

    function setNavigationMenu(role, redirect=true){

      if(role == 'Supervisor'){

       navigation.value = [
        { name: 'Dashboard', href: 'SupervisorDashboardComponent', icon: HomeIcon, current: true },
        { name: 'Pending Requisitions', href: 'SupervisorPendingComponent', icon: ClockIcon, current: false },
        { name: 'Approved Requisitions', href: 'SupervisorApprovedComponent', icon: CheckIcon, current: false },
        { name: 'Rejected Requisitions', href: 'SupervisorRejectedComponent', icon: XIcon, current: false },
        { name: 'Archives', href: 'ArchivesComponent', icon: ArchiveIcon, current: false },
       ]
       if(redirect){
        activePage.value = 'Dashboard';
        
        router.push({name:'SupervisorDashboardComponent'});
       }
       activeRole.value = 'Supervisor'
         
      }else if(role == 'Requisitioner'){

         navigation.value = [
          { name: 'Dashboard', href: 'RequisitionerDashboard', icon: HomeIcon, current: true },
          { name: 'Requisitions', href: 'RequisitionerRequisitionsComponent', icon: FolderIcon, current: false },
          { name: 'Accountabilities', href: 'RequisitionerRequisitionsAccountability', icon: ScaleIcon, current: false },
          { name: 'Archives', href: 'ArchivesComponent', icon: ArchiveIcon, current: false },
         ]

        if(redirect){
          activePage.value = 'Dashboard';
          router.push({name:'RequisitionerDashboard'});
        }
        activeRole.value = 'Requisitioner'


      }else if(role == 'Budget Holder'){

         navigation.value = [
          { name: 'Dashboard', href: 'BudgetholderDashboardComponents', icon: HomeIcon, current: true },
          { name: 'Pending Requisitions', href: 'BudgetholderPendingComponent', icon: ClockIcon, current: false },
          { name: 'Approved Requisitions', href: 'BudgetholderApprovedComponent', icon: CheckIcon, current: false },
          { name: 'Rejected Requisitions', href: 'BudgetholderRejectedComponent', icon: XIcon, current: false },
          // { name: 'Excess Goods', href: 'BudgetholderExcessGoodsComponent', icon: ArchiveIcon, current: false },
          { name: 'My Budget Lines', href: 'BudgetholderBudgetLinesComponent', icon: CreditCardIcon, current: false },
          { name: 'Accountabilities', href: 'BudgetholderAccountabilitiesComponent', icon: ScaleIcon, current: false },
          // { name: 'Reports', href: 'ReportsComponent', icon: ChartBarIcon, current: false },
          { name: 'Archives', href: 'ArchivesComponent', icon: ArchiveIcon, current: false },
         ]

       if(redirect){
          activePage.value = 'Dashboard';
          router.push({name:'BudgetholderDashboardComponents'});
        }
        activeRole.value = 'Budget Holder'

      }else if(role == 'Accountant'){

         navigation.value = [
          { name: 'Dashboard', href: 'AccountantDashboardComponent', icon: HomeIcon, current: true },
          { name: 'Pending Requisitions', href: 'AccountantPendingComponent', icon: ClockIcon, current: false },
          { name: 'Approved Requisitions', href: 'AccountantApprovedComponent', icon: CheckIcon, current: false },
          { name: 'Rejected Requisitions', href: 'AccountantRejectedComponent', icon: XIcon, current: false },
          { name: 'Exchange Rates',       href: 'AccountantExchangeRatesComponent', icon: CreditCardIcon, current: false },
          // { name: 'Reports', href: 'ReportsComponent', icon: ChartBarIcon, current: false },
          { name: 'Archives', href: 'ArchivesComponent', icon: ArchiveIcon, current: false },
         ]

         if(redirect){
            activePage.value = 'Dashboard';
            router.push({name:'AccountantDashboardComponent'});
         }
         activeRole.value = 'Accountant'

      }else if(role == 'Manager Accounts'){

         navigation.value = [
          { name: 'Dashboard', href: 'ManagerAccountsDashboardComponent', icon: HomeIcon, current: true },
          { name: 'Pending Requisitions', href: 'ManagerAccountsPendingComponent', icon: ClockIcon, current: false },
          { name: 'Approved Requisitions', href: 'ManagerAccountsApprovedComponent', icon: CheckIcon, current: false },
          { name: 'Rejected Requisitions', href: 'ManagerAccountsRejectedComponent', icon: XIcon, current: false },
          { name: 'Budget Lines', href: 'ManagerAccountsBudgetLinesComponent', icon: CreditCardIcon, current: false },
          // { name: 'Reports', href: 'ReportsComponent', icon: ChartBarIcon, current: false },
          { name: 'Archives', href: 'ArchivesComponent', icon: ArchiveIcon, current: false },
         ]

         if(redirect){
            activePage.value = 'Dashboard';
            router.push({name:'ManagerAccountsDashboardComponent'});
         }
         activeRole.value = 'Manager Accounts'

      }else if(role == 'Director Finance'){

         navigation.value = [
          { name: 'Dashboard', href: 'DirectorFinanceDashboardComponent', icon: HomeIcon, current: true },
          { name: 'Pending Requisitions',  href: 'DirectorFinancePendingComponent', icon: ClockIcon, current: false },
          { name: 'Approved Requisitions', href: 'DirectorFinanceApprovedComponent', icon: CheckIcon, current: false },
          { name: 'Rejected Requisitions', href: 'DirectorFinanceRejectedComponent', icon: XIcon, current: false },
          { name: 'Accountabilities', href: 'DirectorFinanceAccountabilitiesComponent', icon: ScaleIcon, current: false },          
          // { name: 'Reports', href: 'ReportsComponent', icon: ChartBarIcon, current: false },
          { name: 'Archives', href: 'ArchivesComponent', icon: ArchiveIcon, current: false },
         ]

         if(redirect){
            activePage.value = 'Dashboard';
            router.push({name:'DirectorFinanceDashboardComponent'});
         }
         activeRole.value = 'Director Finance'

      }else if(role == 'Payments Office'){

         navigation.value = [
          { name: 'Dashboard', href: 'PaymentsOfficeDashboardComponent', icon: HomeIcon, current: true },
          { name: 'Pending Requisitions',  href: 'PaymentsOfficePendingComponent', icon: ClockIcon, current: false },
          { name: 'Approved Requisitions', href: 'PaymentsOfficeApprovedComponent', icon: CheckIcon, current: false },
          { name: 'Rejected Requisitions', href: 'PaymentsOfficeRejectedComponent', icon: XIcon, current: false },
          { name: 'Accountabilities', href: 'PaymentsOfficeAccountabilitiesComponent', icon: ScaleIcon, current: false },
          // { name: 'Reports', href: 'ReportsComponent', icon: ChartBarIcon, current: false },
          { name: 'Archives', href: 'ArchivesComponent', icon: ArchiveIcon, current: false },
         ]

         if(redirect){
            activePage.value = 'Dashboard';
            router.push({name:'PaymentsOfficeDashboardComponent'});
         }
         activeRole.value = 'Payments Office'

      }else if(role == 'Procurement'){

         navigation.value = [
          { name: 'Dashboard', href: 'ProcurementDashboardComponent', icon: HomeIcon, current: true },
          { name: 'Pending Requisitions', href: 'ProcurementPendingComponent', icon: ClockIcon, current: false },
          { name: 'Approved Requisitions', href: 'ProcurementApprovedComponent', icon: CheckIcon, current: false },
          { name: 'Rejected Requisitions', href: 'ProcurementRejectedComponent', icon: XIcon, current: false },
          // { name: 'Reports', href: 'ReportsComponent', icon: ChartBarIcon, current: false },
          { name: 'Archives', href: 'ArchivesComponent', icon: ArchiveIcon, current: false },
         ]

         if(redirect){
            activePage.value = 'Dashboard';
            router.push({name:'ProcurementDashboardComponent'});
         }
         activeRole.value = 'Procurement'

      }
  
    }

    async function updatePassword(){

       errorMessage.value = '';
       successMessage.value = '';

        if((!old_password.value || old_password.value.toString().trim() == '' )){

              errorMessage.value = "Old Password cannot be empty";
              return;

        }

        if((!new_password.value || new_password.value.toString().trim() == '' )){

              errorMessage.value = "New Password cannot be empty";
              return;

        }
        if((new_password.value && new_password.value.toString().trim().length < 8 )){

              errorMessage.value = "New Password must be more than 8 characters long";
              return;

        }

        if((!confirm_password.value || confirm_password.value.toString().trim() == '' )){

              errorMessage.value = "Confirm Password cannot be empty";
              return;

        }

        if((confirm_password.value.toString().trim() !==  new_password.value.toString().trim() )){

              errorMessage.value = "New Password and Confirm Password must match";
              return;

        }

        
          isLoading.value = true;

          let  user = {
                  'old_password': old_password.value.trim(),
                  'new_password': new_password.value.trim(),
                  'confirm_password': confirm_password.value.trim(),
                  'user_to_edit' : loggedInUser.value.id
              };

          try {
            const res = await Api().post("/users/update-password", user)

            if (res.data.status === "success") {

                successMessage.value  = res.data.message;

                old_password.value = '';
                new_password.value = '';
                confirm_password.value = '';
                showModal.value = false;
              

            }else{

               errorMessage.value = res.data.message;
            }

          } catch (err) {

              if (err.response) {

                      errorMessage.value = "An error has occurred! " + err.response.message;

              } else if (err.request) {

                 errorMessage.value =  err.request.data.message;

              } else {

                 errorMessage.value =  err.message;

              }
          }

           isLoading.value = false;


    }

    let zxcvbn = require('zxcvbn');

     const passwordStrength = computed(() => {

         let result = zxcvbn( new_password.value )
       return result.score;
     });

    return {
      navigation,
      sidebarOpen,
      LogoutIcon,ArrowLeftIcon,
      CogIcon, LocationMarkerIcon,activePage,status_title,
      roles,switchRole,activeRole,
      logout,
      backToErpMenu,
      errorMessage,
      successMessage,
      isLoading,
      userRequisitionRoles,
      showModal,
      old_password, new_password, confirm_password, updatePassword, passwordStrength
    }
  },
}
</script>

<style>

</style>