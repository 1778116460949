<template>
  <div>
   <Loading v-model:active="isLoading" />
    <div class=" mt-3 mb-3 flex justify-center items-center">
        <AlertSuccess
            v-if="successMessage"
            :message="successMessage"
            @hide="successMessage = null"
        />
        <AlertError
            v-if="errorMessage"
            :message="[errorMessage]"
            @hide="errorMessage = null"
        />
    </div>
      <section aria-labelledby="payment-details-heading">
        <form action="#" method="POST">
          <div class="shadow-lg sm:rounded-md sm:overflow-hidden">
            <div class="bg-white py-6 px-4 sm:p-6">
              <div>
                <h2 id="payment-details-heading" class="text-lg leading-6 font-extrabold text-red-900">EXCHANGE RATE SETTINGS</h2>
                <p class="mt-1 text-xs text-gray-700">Update the exchange rates to be used throughout the requisition system</p>
              </div>

              <div class="mt-6 grid grid-cols-6 gap-6">
                <div class="col-span-6 sm:col-span-2">
                  <label for="first-name" class="block text-sm font-medium text-red-900">USD</label>
                  <input type="text" v-model="usd" name="first-name" id="first-name" autocomplete="cc-given-name" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
                <div class="col-span-6 sm:col-span-2">
                  <label for="first-name" class="block text-sm font-medium text-red-900">EUR </label>
                  <input type="text" v-model="eur" name="first-name" id="first-name" autocomplete="cc-given-name" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
                <div class="col-span-6 sm:col-span-2">
                  <label for="first-name" class="block text-sm font-medium text-red-900">GBP</label>
                  <input type="text" v-model="gbp" name="first-name" id="first-name" autocomplete="cc-given-name" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
                <div class="col-span-6 sm:col-span-2">
                  <label for="first-name" class="block text-sm font-medium text-red-900">KSHS</label>
                  <input type="text" v-model="kshs" name="first-name" id="first-name" autocomplete="cc-given-name" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
                <div class="col-span-6 sm:col-span-2">
                  <label for="first-name" class="block text-sm font-medium text-red-900">Indian Rupee</label>
                  <input type="text" v-model="inr" name="first-name" id="first-name" autocomplete="cc-given-name" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
                <div class="col-span-6 sm:col-span-2">
                  <label for="first-name" class="block text-sm font-medium text-red-900">SA Rand</label>
                  <input type="text" v-model="zar" name="first-name" id="first-name" autocomplete="cc-given-name" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
                <div class="col-span-6 sm:col-span-2">
                  <label for="first-name" class="block text-sm font-medium text-red-900">United Arab Emirates Dirham </label>
                  <input type="text" v-model="aed" name="first-name" id="first-name" autocomplete="cc-given-name" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>

                
              </div>
            </div>
            <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button type="submit" @click.prevent="saveExchangeRate()" class="bg-red-900 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-800">Save</button>
            </div>
          </div>
        </form>
      </section>
 </div>
</template>

<script>
import { ref, inject } from 'vue'
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";
import Api from "@/components/api/Api";

export default {

   components: {
          AlertError, AlertSuccess
    },


    setup(){

      let isLoading = ref(false);
      let errorMessage = ref("");
      let successMessage = ref("");

      let usd      = ref('0');
      let eur      = ref('0');
      let gbp      = ref('0');
      let kshs     = ref('0');
      let inr      = ref('0');
      let zar      = ref('0');
      let aed      = ref('0');

      let activePage = inject('activePage')
      activePage.value = 'Exchange Rates'

      let status_title = inject('status_title');
      status_title.value = '';

      let loggedInUser =  inject("loggedInUser");

      

      async function loadExchangeRates(){


        try {

           const response = await Api().get(`/app/get-exchange-rates`);

           if(response.data.status === "success"){
                
                let exchangeRates = response.data.exchangeRates;

                

                usd.value  = exchangeRates.usd[0].amount;
                eur.value  = exchangeRates.eur[0].amount
                gbp.value  = exchangeRates.gbp[0].amount;
                kshs.value  = exchangeRates.kshs[0].amount;
                inr.value  = exchangeRates.inr[0].amount;
                zar.value  = exchangeRates.zar[0].amount;
                aed.value  = exchangeRates.aed[0].amount;
               
                successMessage.value = response.data.message;
           
           } 

        } catch (err) {

            if (err.response) {

                errorMessage.value =  err.response.data.message;

            } else if (err.request) {

               errorMessage.value =  err.request.data.message;

            } else {

                errorMessage.value =  err.message;
                
            }
        }


      }

      async function saveExchangeRate(){


        isLoading.value = true;

        let exchangeRateObj = {

            'usd': usd.value,
            'eur': eur.value,
            'gbp': gbp.value,
            'kshs': kshs.value,
            'inr': inr.value,
            'zar': zar.value,
            'aed': aed.value,
            'added_by_user_id': loggedInUser.value.id,
        }

        try {
          
            const res = await Api().post(`/app/add-exchange-rate`,exchangeRateObj);

            if (res.data.status === "success") {

              successMessage.value = res.data.message;

            }


          } catch (err) {

              if (err.response) {

                 errorMessage.value =  err.response.data.message;

              } else if (err.request) {

                  errorMessage.value = err.request.data.message ;

              } else {

                 errorMessage.value = err.message;

              }
          }
        isLoading.value = false;

      }

      loadExchangeRates();

      return {
        usd,eur,gbp,kshs,inr,zar,aed,saveExchangeRate,
        errorMessage, successMessage, isLoading, 
      }

    }


}
</script>

<style>

</style>