<template>
  <div>
    <div class="sm:hidden">
      <label for="tabs" class="sr-only">Select a tab</label>
      <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
      <select id="tabs" name="tabs" v-model="selectTab" @change="currentTab = selectTab; swapComponent(null,selectTab)"  class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
        <option v-for="tab in tabs" :key="tab.name" :selected="tab.current" >{{ tab.name }}</option>
      </select>
    </div>
    <div class="hidden sm:block">
      <nav class="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
        <a v-for="(tab, tabIdx) in tabs" :key="tab.name" @click="currentTab = tab.name; selectTab = tab.name;  swapComponent(tab.component,null)" :href="tab.href" :class="[currentTab == tab.name ? 'text-red-900' : 'text-gray-500 hover:text-gray-700', tabIdx === 0 ? 'rounded-l-lg' : '', tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10']" :aria-current="tab.current ? 'page' : undefined">
          <span>{{ tab.name }}</span>
          <span aria-hidden="true" :class="[currentTab == tab.name ? 'bg-red-900' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
        </a>
      </nav>
    </div>
    <div v-if="currentComponent == 'AccountantBudgetLineAssignmentComponent'" class="mb-2 pt-3">
        <div>
            <button
            class="capitalize flex text-blue-500 hover:underline"
            v-if="currentComponent == 'AccountantBudgetLineAssignmentComponent'"
            @click="swapComponent(null)"
            >
            <ArrowLeftIcon class="mr-2 h-6 w-6" />
            back
            </button>
        </div>
    </div>
    <component class="pt-3" :is="currentComponent"></component>

    <div class="pt-5" v-if="!currentComponent">
     <Loading v-model:active="isLoading" />
     <div class=" mt-3 mb-3 flex justify-center items-center">
            <AlertSuccess
                v-if="successMessage"
                :message="successMessage"
                @hide="successMessage = null"
            />
            <AlertError
                v-if="errorMessage"
                :message="[errorMessage]"
                @hide="errorMessage = null"
            />
        </div>
       <div class="grid grid-rows-2 sm:flex sm:justify-between sm:items-center pb-4">
            <div class="md:pr-3">

                <button type="button" @click="swapComponent('AccountantBudgetLineAssignmentComponent')" class="inline-flex w-full justify-center items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-900 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Assign Budget line
                </button>

            </div>
            <!-- <label for="table-search" class="sr-only">Search</label> -->
            <div class="relative flex">
                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                </div>
                <input type="text"  v-model="filter" id="table-search" class="block flex-1 p-2 pl-10 sm:w-80 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items">
            </div>
        </div>
         <div class="w-full sm:flex sm:justify-between sm:mb-2"> 
            <div class="w-full">
            </div>
            <div class="flex items-center justify-center sm:justify-end w-full lg:w-1/2 sm:flex-wrap">
              <label class="px-5 text-sm w-fit text-red-900"> Rows per page </label>
              <select @change="resetTableRows()" v-model="skip" class="block py-1 mb-2 px-0 w-16 text-sm text-gray-800 bg-transparent border-0 border-b-2 border-gray-500 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                  <option  :value="5">5</option>
                  <option  :value="10">10</option>
                  <option  :value="20">20</option>
                  <option  :value="50">50</option>
                  <option  :value="100">100</option>
              </select>
            </div>
         </div>
        <div class="flex flex-col">
            <div class="-my-2  sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
                        <table class="w-full table-auto divide-y divide-gray-200">
                            <thead class="bg-teal-700">
                                <tr>
                                    <!-- <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">#</th> -->
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Directorate</th>                                    
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Budget Holder</th>                                    
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Budget Line</th>                                    
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Amount</th>                                    
                                    <!-- <th scope="col" class="relative px-6 py-3">
                                    <span class="sr-only">edit</span>
                                    </th> -->
                                    <th scope="col" class="relative px-6 py-3">
                                    <span class="sr-only">delete</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="(budgetLineAssignment,index) in filteredbudgetLineAssignmentListTable.slice(filter? 0 : startPagination,filter? filteredbudgetLineAssignmentListTable.length : stopPagination)" :key="index">
                                    <!-- <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {{ index+1 }}
                                    </td> -->
                                    <td class="px-6 py-4 break-words text-sm font-medium text-gray-900">
                                    {{ budgetLineAssignment.directorate }}
                                    </td>
                                    <td class="px-6 py-4 break-words text-sm font-medium text-gray-900">
                                    {{ budgetLineAssignment.budgetholder }}
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {{ budgetLineAssignment.budgetline }}
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {{ budgetLineAssignment.amount? Number(budgetLineAssignment.amount).toLocaleString() : '0.00' }}
                                    </td>
                                    <!-- <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    <a href="#" class="text-blue-600 hover:text-blue-900">edit</a>
                                    </td> -->
                                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    <a @click="selectedBudgetLineAssignment = budgetLineAssignment.id; showPrompt = true;" class="text-white bg-red-600 py-1 px-2 rounded-md text-xs cursor-pointer hover:bg-red-900 shadow-md">delete</a>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                  <td colspan="7">
                                      <nav class="bg-white px-4 py-3 flex items-center justify-between  sm:px-6" aria-label="Pagination">
                                          <div class="hidden sm:block">
                                           <p class="text-sm text-gray-700">
                                              Showing
                                              {{ ' ' }}
                                              <span class="font-medium">{{ filter ? filteredbudgetLineAssignmentListTable.length > 0 ? 1 : 0 : (Number(startPagination) + 1) }} </span>
                                              {{ ' ' }}
                                              to
                                              {{ ' ' }}
                                              <span class="font-medium"> {{filter ? filteredbudgetLineAssignmentListTable.length : stopPagination > filteredbudgetLineAssignmentListTable.length ? filteredbudgetLineAssignmentListTable.length :  stopPagination}} </span>
                                              {{ ' ' }}
                                              of
                                              {{ ' ' }}
                                              <span class="font-medium">{{ filteredbudgetLineAssignmentListTable.length }}</span>
                                              {{ ' ' }}
                                              results
                                        </p>
                                          </div>
                                          <div class="flex-1 flex justify-between sm:justify-end">
                                           <!-- <a :class="prevPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="loadBudgetHolderAssignments( pageUrl+prevPageUrl)"  class="relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Previous </a>
                                           <a :class="nextPageUrl ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="loadBudgetHolderAssignments( pageUrl+nextPageUrl)" class="ml-3 cursor-pointer relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Next </a> -->
                                            <a :class="(startPagination > 0) && (!filter) ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="previousPagination()"  class="relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Previous </a>
                                            <a :class="(stopPagination < filteredbudgetLineAssignmentListTable.length) && (!filter) ? 'cursor-pointer' : 'pointer-events-none' " @click.stop="nextPagination()" class="ml-3 cursor-pointer relative inline-flex items-center px-4 py-2 border border-red-900 text-sm font-medium rounded-md text-red-900 bg-white hover:bg-gray-100"> Next </a>  
                                          </div>
                                      </nav>
                                  </td>
                              </tr>
                          </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
     <DeleteAlert :show="showPrompt"  item="assignment"  @cancel="showPrompt = false" @delete="deleteAssignment(selectedBudgetLineAssignment); showPrompt = false" />
    </div>

  </div>
</template>

<script>

import { ref, inject , computed} from 'vue'
import AccountantBudgetLineDepartmentAssignmentListComponent from './ManagerAccountsBudgetLineDepartmentAssignmentListComponent.vue'
import AccountantBudgetLineAssignmentComponent from "./ManagerAccountsBudgetLineAssignmentComponent.vue";
import { ArrowLeftIcon } from "@heroicons/vue/outline";
import Api from "@/components/api/Api";
import DeleteAlert from "@/components/alertComponents/DeleteAlert.vue";
import AlertError from "@/components/alertComponents/AlertError.vue";
import AlertSuccess from "@/components/alertComponents/AlertSuccess.vue";

export default {

  components: {
         AccountantBudgetLineDepartmentAssignmentListComponent,
         AccountantBudgetLineAssignmentComponent,
         ArrowLeftIcon,
         DeleteAlert,   AlertError, AlertSuccess,
    },


  setup() {

    let currentTab  = ref("Budget Holder Assignments");
    const tabs = [
      { name: 'Budget Holder Assignments', href: '#', component: null, current: true },
      // { name: 'Department Assigments', href: '#', component: 'AccountantBudgetLineDepartmentAssignmentListComponent', current: false },
    ]

    let currentComponent = ref(null);
    let selectTab = ref('Budget Holder Assignments')

    async function swapComponent(component, selectTabOption = null){

      if(!selectTabOption){

        if(component == null){

         await loadBudgetHolderAssignments();

        }

           currentComponent.value = component;

      }else{

        if(selectTabOption == 'Budget Holder Assignments'){

          currentComponent.value = null;

        }else if(selectTabOption == 'Department Assigments') {

           currentComponent.value = 'AccountantBudgetLineDepartmentAssignmentListComponent';

        }

      }
       
      

    }

    const budgetLineAssignmentList = ref([])

    let isLoading = ref(false);
    let errorMessage = ref("");
    let successMessage = ref("");
    let fromPage = ref(0)
    let toPage   = ref(0)
    let totalPageResults   = ref(0);
    let prevPageUrl = ref('')
    let nextPageUrl = ref('')
    let reloadUrl = ref('');
    let selectedFinancialYear = inject('selectedFinancialYear')
    let pageUrl   = `/requisition/get-assigned-budget-lines/${selectedFinancialYear.value}`;
    const showPrompt = ref(false);
    let selectedBudgetLineAssignment = ref('0');

    const filter = ref("");
    const filteredbudgetLineAssignmentListTable = computed(() => {
            return budgetLineAssignmentList.value.filter(
            (val) =>{
                const name  = val.budgetholder.toString().toLowerCase();
                const name2 = val.budgetline.toString().toLowerCase();
                const name3 = val.directorate.toString().toLowerCase();
                
                return name.includes(filter.value.toLowerCase()) || name2.includes(filter.value.toLowerCase()) || name3.includes(filter.value.toLowerCase());
            }
            );
        });

     async function loadBudgetHolderAssignments(url = pageUrl){

       isLoading.value = true
       

         try {

                const response = await Api().get(url);

                if(response.data.status === "success"){

                        // console.log(response.data.budgetLineAssignmentList.data)

                        budgetLineAssignmentList.value        = response.data.budgetLineAssignmentList.data;
                        nextPageUrl.value        = response.data.budgetLineAssignmentList.next_page_url
                        prevPageUrl.value        = response.data.budgetLineAssignmentList.prev_page_url
                        fromPage.value           = response.data.budgetLineAssignmentList.from
                        toPage.value             = response.data.budgetLineAssignmentList.to
                        // totalPageResults.value   = response.data.budgetLineAssignmentList.total
                        successMessage.value     = response.data.message;
                        reloadUrl.value = url;
                
                } 

            } catch (err) {

                if (err.response) {

                    errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                errorMessage.value =  err.request.data.message;

                } else {

                    errorMessage.value =  err.message;
                    
                }
            }

          isLoading.value = false;

            
     }

      async function deleteAssignment(selectedId){

            successMessage.value = '';
            errorMessage.value = '';

             isLoading.value = true;

          try {
            
              const res = await Api().delete(`/requisition/delete-budget-line-assignment/${selectedId}`);

              if (res.data.status === "success") {

                successMessage.value = res.data.message;
                await  loadBudgetHolderAssignments(reloadUrl.value);

              }else{

                errorMessage.value = res.data.message;
              }


            } catch (err) {

                if (err.response) {

                  errorMessage.value =  err.response.data.message;

                } else if (err.request) {

                    errorMessage.value = err.request.data.message ;

                } else {

                  errorMessage.value = err.message;

                }
            }
          isLoading.value = false;


            
        }

    loadBudgetHolderAssignments();

     let startPagination = ref(0)
     let skip = ref(10);
     let stopPagination  = ref(skip.value)
      

      function nextPagination(){

        startPagination.value = Number(startPagination.value) + Number(skip.value);
        stopPagination.value = Number(stopPagination.value) + Number(skip.value);

      }

      function previousPagination(){

        startPagination.value = Number(startPagination.value) - Number(skip.value);
        stopPagination.value = Number(stopPagination.value) - Number(skip.value);

      }

      function resetTableRows(){

        startPagination.value = 0;
        stopPagination.value = skip.value;

      }


    return {
      tabs,currentTab, swapComponent, currentComponent, selectTab,
      budgetLineAssignmentList,loadBudgetHolderAssignments,
      fromPage, toPage, totalPageResults, prevPageUrl,nextPageUrl,pageUrl, showPrompt,
      selectedBudgetLineAssignment, deleteAssignment,filteredbudgetLineAssignmentListTable, filter,
      errorMessage, successMessage,isLoading,
      startPagination, stopPagination, nextPagination, previousPagination, skip,
      resetTableRows

    }
  },
}
</script>

<style>

</style>