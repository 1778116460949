import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import ErpMenuView from '../views/ErpMenuView.vue'
import MeimsView from '../views/MeimsView.vue'
import RequisitionView from '../views/RequisitionView.vue'
import TendersView from '../views/TendersView.vue'
import UserManagementView from '../views/UserManagementView.vue'
import RequisitionerDashboardComponent from '../components/requisitionComponents/requisitionerComponents/RequisitionerDashboardComponent.vue'
import RequisitionerRequisitionsComponent from '../components/requisitionComponents/requisitionerComponents/RequisitionerRequisitionsComponent.vue'
import RequisitionerAccountabilityComponent from '../components/requisitionComponents/requisitionerComponents/RequisitionerAccountabilityComponent.vue'
import ArchivesComponent from '../components/requisitionComponents/ArchivesComponent.vue'
import ReportsComponent from '../components/requisitionComponents/reportComponents/ReportsComponent.vue'
import SupervisorDashboardComponent from '../components/requisitionComponents/supervisorComponents/SupervisorDashboardComponent.vue'
import SupervisorPendingComponent from '../components/requisitionComponents/supervisorComponents/SupervisorPendingComponent.vue'
import SupervisorApprovedComponent from '../components/requisitionComponents/supervisorComponents/SupervisorApprovedComponent.vue'
import SupervisorRejectedComponent from '../components/requisitionComponents/supervisorComponents/SupervisorRejectedComponent.vue'

import BudgetholderAccountabilitiesComponent from '../components/requisitionComponents/budgetholderComponents/BudgetholderAccountabilitiesComponent.vue'
import BudgetholderApprovedComponent from '../components/requisitionComponents/budgetholderComponents/BudgetholderApprovedComponent.vue'
import BudgetholderBudgetLinesComponent from '../components/requisitionComponents/budgetholderComponents/BudgetholderBudgetLinesComponent.vue'
import BudgetholderDashboardComponents from '../components/requisitionComponents/budgetholderComponents/BudgetholderDashboardComponents.vue'
import BudgetholderExcessGoodsComponent from '../components/requisitionComponents/budgetholderComponents/BudgetholderExcessGoodsComponent.vue'
import BudgetholderPendingComponent from '../components/requisitionComponents/budgetholderComponents/BudgetholderPendingComponent.vue'
import BudgetholderRejectedComponent from '../components/requisitionComponents/budgetholderComponents/BudgetholderRejectedComponent.vue'

import UsersComponent from '../components/erpManagementComponents/UsersComponent.vue'
import GeneralSettingsComponent from '../components/erpManagementComponents/GeneralSettingsComponent.vue'
import RolesComponent from '../components/erpManagementComponents/RolesComponent.vue'
import FinancialYearComponent from '../components/erpManagementComponents/FinancialYearComponent.vue'

import AccountantApprovedComponent from '../components/requisitionComponents/accountantComponents/AccountantApprovedComponent.vue'
import AccountantDashboardComponent from '../components/requisitionComponents/accountantComponents/AccountantDashboardComponent.vue'
import AccountantPendingComponent from '../components/requisitionComponents/accountantComponents/AccountantPendingComponent.vue'
import AccountantRejectedComponent from '../components/requisitionComponents/accountantComponents/AccountantRejectedComponent.vue'
import AccountantExchangeRatesComponent from '../components/requisitionComponents/accountantComponents/AccountantExchangeRatesComponent.vue'

import ManagerAccountsApprovedComponent from '../components/requisitionComponents/managerAccountsComponents/ManagerAccountsApprovedComponent.vue'
import ManagerAccountsDashboardComponent from '../components/requisitionComponents/managerAccountsComponents/ManagerAccountsDashboardComponent.vue'
import ManagerAccountsPendingComponent from '../components/requisitionComponents/managerAccountsComponents/ManagerAccountsPendingComponent.vue'
import ManagerAccountsRejectedComponent from '../components/requisitionComponents/managerAccountsComponents/ManagerAccountsRejectedComponent.vue'
import ManagerAccountsBudgetLinesComponent from '../components/requisitionComponents/managerAccountsComponents/ManagerAccountsBudgetLinesComponent.vue'

import DirectorFinanceApprovedComponent from '../components/requisitionComponents/directorFinanceComponents/DirectorFinanceApprovedComponent.vue'
import DirectorFinanceDashboardComponent from '../components/requisitionComponents/directorFinanceComponents/DirectorFinanceDashboardComponent.vue'
import DirectorFinancePendingComponent from '../components/requisitionComponents/directorFinanceComponents/DirectorFinancePendingComponent.vue'
import DirectorFinanceRejectedComponent from '../components/requisitionComponents/directorFinanceComponents/DirectorFinanceRejectedComponent.vue'
import DirectorFinanceAccountabilitiesComponent from '../components/requisitionComponents/directorFinanceComponents/DirectorFinanceAccountabilitiesComponent.vue'

import PaymentsOfficeApprovedComponent from '../components/requisitionComponents/paymentsOfficeComponents/PaymentsOfficeApprovedComponent.vue'
import PaymentsOfficeDashboardComponent from '../components/requisitionComponents/paymentsOfficeComponents/PaymentsOfficeDashboardComponent.vue'
import PaymentsOfficePendingComponent from '../components/requisitionComponents/paymentsOfficeComponents/PaymentsOfficePendingComponent.vue'
import PaymentsOfficeRejectedComponent from '../components/requisitionComponents/paymentsOfficeComponents/PaymentsOfficeRejectedComponent.vue'
import PaymentsOfficeAccountabilitiesComponent from '../components/requisitionComponents/paymentsOfficeComponents/PaymentsOfficeAccountabilitiesComponent.vue'

import ProcurementApprovedComponent from '../components/requisitionComponents/procurementComponents/ProcurementApprovedComponent.vue'
import ProcurementDashboardComponent from '../components/requisitionComponents/procurementComponents/ProcurementDashboardComponent.vue'
import ProcurementPendingComponent from '../components/requisitionComponents/procurementComponents/ProcurementPendingComponent.vue'
import ProcurementRejectedComponent from '../components/requisitionComponents/procurementComponents/ProcurementRejectedComponent.vue'

import FundraisingView from '../views/FundraisingView.vue'
import DashboardHome from '../components/FundraisingComponents/DashboardHome.vue'
import DashboardDonations from '../components/FundraisingComponents/DashboardDonations.vue'
import DashboardProjects from '../components/FundraisingComponents/DashboardProjects.vue'
import DashboardAddProject from '../components/FundraisingComponents/DashboardAddProject.vue'
import DashboardAddDonation from '../components/FundraisingComponents/DashboardAddDonation.vue'
import DashboardTables from '../components/FundraisingComponents/DashboardTables.vue'
import DashboardAddTables from '../components/FundraisingComponents/DashboardAddTables.vue'
import DashboardEditTable from '../components/FundraisingComponents/DashboardEditTable.vue'
import DashboardAddTemplateData from '../components/FundraisingComponents/DashboardAddTemplateData.vue'
import DashboardResources from '../components/FundraisingComponents/DashboardResources.vue'
import DashboardAddResource from '../components/FundraisingComponents/DashboardAddResource.vue'
import DashboardCollections from '../components/FundraisingComponents/DashboardCollections.vue'
import DashboardAddCollection from '../components/FundraisingComponents/DashboardAddCollection.vue'

import TenderList from "../components/TenderComponents/TenderList.vue";
import EditTender from "../components/TenderComponents/EditTender.vue";
import EquipmentList from "../components/TenderComponents/EquipmentList.vue";
import EditEquipment from "../components/TenderComponents/EditEquipment.vue";
import ManufacturerSubmissionView from "../components/TenderComponents/ManufacturerSubmissionView.vue";
import ViewManfacturerList from "../components/TenderComponents/ViewManfacturerList.vue";
import ViewManufacturerSubmission from "../components/TenderComponents/ViewManufacturerSubmission.vue";
import ViewInquiries from "../components/TenderComponents/ViewInquiries.vue";

import ExternalViewForManufacturer from "../views/ExternalViewForManufacturer.vue";
import ListOfEquipmentSentToManufacturer from "../components/TenderComponents/ListOfEquipmentSentToManufacturer.vue";
import ManufacturerView from "../components/TenderComponents/ManufacturerView.vue";
import ManufacturerViewInquiries from "../components/TenderComponents/ManufacturerViewInquiries.vue";

import UptimeReport from "../components/meimsComponents/UptimeReportComponent.vue";
import JobcardReport from "../components/meimsComponents/JobcardReportComponent.vue";

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView,
    meta: { guestOnly: true },
  },
  {
    path: '/erpmenu',
    name: 'ErpMenuView',
    component: ErpMenuView,
    meta: { authOnly: true },
  },
  {
    path: '/meims',
    name: 'MeimsView',
    component: MeimsView,
    meta: { authOnly: true },
    children: [
      {
        path: 'uptime-report',
        name: 'UptimeReport',
        component: UptimeReport
      },
      {
        path: 'jobcard-report',
        name: 'JobcardReport',
        component: JobcardReport
      }
    ]
  },
  {
    path: '/requisition',
    name: 'RequisitionView',
    component: RequisitionView,
    meta: { authOnly: true },
    children: [
      {
        name: "RequisitionerDashboard",
        path: "dashboard",
        component: RequisitionerDashboardComponent,
        //alias: ''
      },
      {
        name: "RequisitionerRequisitionsComponent",
        path: "requisitions/:status?/:date?",
        component: RequisitionerRequisitionsComponent,
      },
      {
        name: "RequisitionerRequisitionsAccountability",
        path: "accountability",
        component: RequisitionerAccountabilityComponent,
      },

      {
        name: "ArchivesComponent",
        path: "archives",
        component: ArchivesComponent,
      },
      {
        name: "ReportsComponent",
        path: "reports",
        component: ReportsComponent,
      },

      {
        name: "SupervisorDashboardComponent",
        path: "supervisor/dashboard",
        component: SupervisorDashboardComponent, 
      },
      {
        name: "SupervisorPendingComponent",
        path: "supervisor/pending/:status?/:date?",
        component: SupervisorPendingComponent,
      },
      {
        name: "SupervisorApprovedComponent",
        path: "supervisor/approved/:status?/:date?",
        component: SupervisorApprovedComponent,
      },
      {
        name: "SupervisorRejectedComponent",
        path: "supervisor/rejected/:status?/:date?",
        component: SupervisorRejectedComponent,
      },

      {
        name: "BudgetholderAccountabilitiesComponent",
        path: "budgetholder/accountabilities",
        component: BudgetholderAccountabilitiesComponent,
      },
      {
        name: "BudgetholderApprovedComponent",
        path: "budgetholder/approved/:status?/:date?",
        component: BudgetholderApprovedComponent,
      },
      {
        name: "BudgetholderBudgetLinesComponent",
        path: "budgetholder/budgetlines",
        component: BudgetholderBudgetLinesComponent,
      },
      {
        name: "BudgetholderDashboardComponents",
        path: "budgetholder/dashboard",
        component: BudgetholderDashboardComponents,
      },
      {
        name: "BudgetholderExcessGoodsComponent",
        path: "budgetholder/excessgoods",
        component: BudgetholderExcessGoodsComponent,
      },
      {
        name: "BudgetholderPendingComponent",
        path: "budgetholder/pending/:status?/:date?",
        component: BudgetholderPendingComponent,
      },
      {
        name: "BudgetholderRejectedComponent",
        path: "budgetholder/rejected/:status?/:date?",
        component: BudgetholderRejectedComponent,
      },

      {
        name: "AccountantApprovedComponent",
        path: "accountant/approved/:status?/:date?",
        component: AccountantApprovedComponent,
      },
      {
        name: "AccountantDashboardComponent",
        path: "accountant/dashboard",
        component: AccountantDashboardComponent,
      },
      {
        name: "AccountantPendingComponent",
        path: "accountant/pending/:status?/:date?",
        component: AccountantPendingComponent,
      },
      {
        name: "AccountantRejectedComponent",
        path: "accountant/rejected/:status?/:date?",
        component: AccountantRejectedComponent,
      },
      {
        name: "AccountantExchangeRatesComponent",
        path: "accountant/exchangerates",
        component: AccountantExchangeRatesComponent,
      },

      {
        name: "ManagerAccountsApprovedComponent",
        path: "manageraccounts/approved/:status?/:date?",
        component: ManagerAccountsApprovedComponent,
      },
      {
        name: "ManagerAccountsPendingComponent",
        path: "manageraccounts/pending/:status?/:date?",
        component: ManagerAccountsPendingComponent,
      },
      {
        name: "ManagerAccountsRejectedComponent",
        path: "manageraccounts/rejected/:status?/:date?",
        component: ManagerAccountsRejectedComponent,
      },
      {
        name: "ManagerAccountsDashboardComponent",
        path: "manageraccounts/dashboard",
        component: ManagerAccountsDashboardComponent,
      },
      {
        name: "ManagerAccountsBudgetLinesComponent",
        path: "manageraccounts/budgetlines",
        component: ManagerAccountsBudgetLinesComponent,
      },

      {
        name: "DirectorFinanceApprovedComponent",
        path: "directorfinance/approved/:status?/:date?",
        component: DirectorFinanceApprovedComponent,
      },
      {
        name: "DirectorFinancePendingComponent",
        path: "directorfinance/pending/:status?/:date?",
        component: DirectorFinancePendingComponent,
      },
      {
        name: "DirectorFinanceRejectedComponent",
        path: "directorfinance/rejected/:status?/:date?",
        component: DirectorFinanceRejectedComponent,
      },
      {
        name: "DirectorFinanceDashboardComponent",
        path: "directorfinance/dashboard",
        component: DirectorFinanceDashboardComponent,
      },
      {
        name: "DirectorFinanceAccountabilitiesComponent",
        path: "directorfinance/accountabilities",
        component: DirectorFinanceAccountabilitiesComponent,
      },

      {
        name: "PaymentsOfficeApprovedComponent",
        path: "paymentsoffice/approved/:status?/:date?",
        component: PaymentsOfficeApprovedComponent,
      },
      {
        name: "PaymentsOfficePendingComponent",
        path: "paymentsoffice/pending/:status?/:date?",
        component: PaymentsOfficePendingComponent,
      },
      {
        name: "PaymentsOfficeRejectedComponent",
        path: "paymentsoffice/rejected/:status?/:date?",
        component: PaymentsOfficeRejectedComponent,
      },
      {
        name: "PaymentsOfficeDashboardComponent",
        path: "paymentsoffice/dashboard",
        component: PaymentsOfficeDashboardComponent,
      },
      {
        name: "PaymentsOfficeAccountabilitiesComponent",
        path: "paymentsoffice/accountabilities",
        component: PaymentsOfficeAccountabilitiesComponent,
      },

      {
        name: "ProcurementDashboardComponent",
        path: "procurement/dashboard",
        component: ProcurementDashboardComponent,
      },
      {
        name: "ProcurementPendingComponent",
        path: "procurement/pending/:status?/:date?",
        component: ProcurementPendingComponent,
      },
      {
        name: "ProcurementApprovedComponent",
        path: "procurement/approved/:status?/:date?",
        component: ProcurementApprovedComponent,
      },
      {
        name: "ProcurementRejectedComponent",
        path: "procurement/rejected/:status?/:date?",
        component: ProcurementRejectedComponent,
      },

      
    ]
  },
  {
    path: '/tenders',
    name: 'TendersView',
    component: TendersView,
    meta: { authOnly: true },
    children: [
      {
        path: 'home',
        name: 'TenderList',
        component: TenderList
      },
      {
        path: 'edit-tender',
        name: 'EditTender',
        component: EditTender
      },
      {
        path: 'equipment-list',
        name: 'EquipmentList',
        component: EquipmentList
      },
      {
        path: 'editequipment',
        name: 'EditEquipment',
        component: EditEquipment
      },
      {
        path: 'manufacturersubmissionview',
        name: 'ManufacturerSubmissionView',
        component: ManufacturerSubmissionView,
        children: [
          {
            path: '',
            name: 'ViewManufacturerList',
            component: ViewManfacturerList
          },
          {
            path: 'viewsubmission',
            name: 'ViewManufacturerSubmission',
            component: ViewManufacturerSubmission
          }
        ]

      },
      {

        path: 'view-inquiries',
        name: 'ViewInquiries',
        component: ViewInquiries,

      },
    ]
  },
  {
    path: '/manufacturer',
    name: 'ExternalViewForManufacturer',
    component: ExternalViewForManufacturer,
    meta: { guestOnly: true },
    children: [
      {
        path: 'list/:manufacturer_id',
        name: 'ListOfEquipmentSentToManufacturer',
        component: ListOfEquipmentSentToManufacturer
      },
      {
        path: 'view',
        name: 'ManufacturerView',
        component: ManufacturerView
      },
      {

        path: 'view-inquiries',
        name: 'ManufacturerViewInquiries',
        component: ManufacturerViewInquiries,

      }
    ]
  },
  {
    path: '/manage',
    name: 'UserManagementView',
    component: UserManagementView,
    meta: { authOnly: true },
    children: [
      {
        name: "UsersComponent",
        path: "users",
        component: UsersComponent,
        alias: ''
      },
      {
        name: "FinancialYearComponent",
        path: "financialyear",
        component: FinancialYearComponent,
      },
      {
        name: "GeneralSettingsComponent",
        path: "generalSettings",
        component: GeneralSettingsComponent,
      },
      {
        name: "RolesComponent",
        path: "roles",
        component: RolesComponent,
      },
    ]
  },
  {
    path: '/fundraising',
    name: 'FundraisingView',
    component: FundraisingView,
    meta: { authOnly: true },
    children: [
      {
        path: 'home',
        name: 'dashboard-home',
        component: DashboardHome
      },
      {
        path: 'donations',
        name: 'dashboard-donations',
        component: DashboardDonations
      },
      {
        path: 'add-donations',
        name: 'dashboard-add-donations',
        component: DashboardAddDonation
      },
      {
        path: 'projects',
        name: 'dashboard-projects',
        component: DashboardProjects
      },
      {
        path: 'add-projects',
        name: 'dashboard-add-projects',
        component: DashboardAddProject
      },
      {
        path: 'tables',
        name: 'dashboard-tables',
        component: DashboardTables
      },
      {
        path: 'add-tables',
        name: 'dashboard-add-tables',
        component: DashboardAddTables
      },
      {
        path: 'edit-tables',
        name: 'dashboard-edit-tables',
        component: DashboardEditTable
      },
      {
        path: 'data-tables',
        name: 'dashboard-data-tables',
        component: DashboardAddTemplateData
      },
      {
        path: 'resources',
        name: 'dashboard-resources',
        component: DashboardResources
      },
      {
        path: 'add-resources',
        name: 'dashboard-add-resources',
        component: DashboardAddResource
      },
      {
        path: 'collections',
        name: 'dashboard-collections',
        component: DashboardCollections
      },
      {
        path: 'add-collections',
        name: 'dashboard-add-collections',
        component: DashboardAddCollection
      }


    ]
  },
  { path: "/:catchAll(.*)", redirect: "/" },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function isLoggedIn() {
  return localStorage.getItem("token");
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isLoggedIn()) {
      next({
        path: "/",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guestOnly)) {
    // this route requires guestonly, check if logged in
    // if not, redirect to dashboard page.
    if (isLoggedIn()) {
      next({
        path: "/erpmenu",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router
