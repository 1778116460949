<template>
  <div >
    <router-view/>
  </div>
</template>

<script>
export default {
    name: "ManufacturerSubmissionView"

}
</script>

<style>

</style>