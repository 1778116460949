<template>
  <div class="inline-block align-bottom bg-white rounded-lg px-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-fit sm:w-full sm:p-6">
    <div>
        <div class="bg-white shadow overflow-hidden sm:rounded-lg mb-3">
            <div class="px-4 py-5 sm:px-3">
                <h3 class="text-lg leading-6 font-medium text-gray-900">Requisition Information</h3>
                <p class="mt-1 max-w-2xl text-sm text-gray-500">ID No: JMS/DTS/2022-06-29 09:19:33/4F985</p>
            </div>
            <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl class="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-3">
                <div class="sm:col-span-1">
                    <dt class="text-sm font-medium text-gray-500">Name</dt>
                    <dd class="mt-1 text-sm text-gray-900">Gilbert Rutebemberwa</dd>
                </div>
                <!-- <div class="sm:col-span-1">
                <dt class="text-sm font-medium text-gray-500">ID No</dt>
                <dd class="mt-1 text-sm text-gray-900">JMS/DTS/2022-06-29 09:19:33/4F985</dd>
                </div> -->
                <div class="sm:col-span-1">
                    <dt class="text-sm font-medium text-gray-500">Reason</dt>
                    <dd class="mt-1 text-sm text-gray-900">Employee Refund</dd>
                </div>
                <div class="sm:col-span-1">
                    <dt class="text-sm font-medium text-gray-500">Date</dt>
                    <dd class="mt-1 text-sm text-gray-900">2022-06-29 09:19:33</dd>
                </div>
                <div class="sm:col-span-1">
                    <dt class="text-sm font-medium text-gray-500">Urgent</dt>
                    <dd class="mt-1 text-sm text-gray-900">No</dd>
                </div>
                <div class="sm:col-span-1">
                    <dt class="text-sm font-medium text-gray-500">Requisition Type</dt>
                    <dd class="mt-1 text-sm text-gray-900">Non Procurable</dd>
                </div>
                <div class="sm:col-span-3">
                    <dt class="text-sm font-medium text-gray-500">Budget Line</dt>
                    <dd class="mt-1 text-sm text-gray-900">Budget Line: 5010127 [ IFS & other Software Licences | ( 545,830,000 /=) ]</dd>
                </div>
                <div class="sm:col-span-3">
                    <!-- <dt class="text-sm font-medium text-gray-500">Budget Line</dt>
                    <dd class="mt-1 text-sm text-gray-900">Budget Line: 5010127 [ IFS & other Software Licences | ( 545,830,000 /=) ]</dd> -->
                    <div class="flex flex-col">
                      <div class="-my-2 sm:-mx-6 lg:-mx-8">
                            <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                                <div class="shadow overflow-x-auto ">
                                    <table class="min-w-full divide-y divide-gray-200 border border-slate-300 ">
                                        <thead class="bg-gray-50">
                                            <tr>
                                                <th colspan="5" scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">[ Requisition Amount Composition ]</th>
                                            </tr>
                                        </thead>
                                        <tbody class="bg-white divide-y divide-gray-200">
                                            <tr >
                                                <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300">
                                                    Currency: USD
                                                </td>
                                                <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300">
                                                    Exchange Rate: 3,780.00
                                                </td>
                                                <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300">
                                                    Currency Expenses: 50.00
                                                </td>
                                                <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300">
                                                    Currency Contingency Fund: 0.00
                                                </td>
                                                <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300">
                                                    Currency Per Diem: 0.00
                                                </td>                            
                                            </tr>
                                            <tr >
                                                <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300">
                                                    Expenses: 189,000.00
                                                </td>
                                                <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300">
                                                    Contingency Fund: 0.00
                                                </td>
                                                <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300">
                                                    Per Diem: 0.00
                                                </td>
                                                <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300">
                                                    Requisition Total: 189,000.00
                                                </td>
                                                <td class="px-3 py-4 text-xs text-gray-500 border border-slate-300">
                                                    Accountable Amount: 0.00
                                                </td>                            
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sm:col-span-3">
                    <dt class="text-sm font-medium text-gray-500">Description</dt>
                    <dd class="mt-1 text-sm text-gray-900">Paid for a 500 user licence to accommodate all staff for the Stock-Taking Briefing. Please refund to Gilbert Rutebemberwa</dd>
                </div>
                <div class="sm:col-span-2">
                <dt class="text-sm font-medium text-gray-500">Attachments</dt>
                <dd class="mt-1 text-sm text-gray-900">
                    <ul role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                        <div class="w-0 flex-1 flex items-center">
                        <PaperClipIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                        <span class="ml-2 flex-1 w-0 truncate"> zoom expansion license 2022.pdf </span>
                        </div>
                        <div class="ml-4 flex-shrink-0">
                        <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500"> Download </a>
                        </div>
                    </li>
                    <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                        <div class="w-0 flex-1 flex items-center">
                        <PaperClipIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                        <span class="ml-2 flex-1 w-0 truncate"> invoice.pdf </span>
                        </div>
                        <div class="ml-4 flex-shrink-0">
                        <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500"> Download </a>
                        </div>
                    </li>
                    </ul>
                </dd>
                </div>
            </dl>
            </div>
        </div>
        <div class="my-5">
            <p class="text-sm text-gray-500">Transition Status</p>
        </div>
        <div class="flex flex-col mx-3">
        <div class="-my-2 sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-x-auto  border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-green-50">
                            <tr>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Directorate</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Comment</th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status Date</th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr >
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    Gilbert Rutebemberwa
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    Supervisor 
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    Technical Services
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    approved
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    Approved
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    Tue, 26-Jul-22 10:54
                                </td>
                            </tr>
                            <tr >
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    Gilbert Rutebemberwa
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    Budget Holder 
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    Technical Services
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    approved
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    Approved
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    Tue, 26-Jul-22 10:54
                                </td>
                            </tr>
                            <tr >
                                <td class="px-6 py-4  text-sm text-gray-500">
                                    George Ssembatya
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    Accountant 
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    All
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    approved
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    Tue, 26-Jul-22 10:54
                                </td>
                            </tr>
                            <tr >
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    Brian Ainebyona
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    Manager Account 
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    Technical Services
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    approved
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    ok
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    Mon, 01-Aug-22 11:12
                                </td>
                            </tr>
                            <tr >
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    Mary Katusiime
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    DF 
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    All
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    approved
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    Tue, 26-Jul-22 10:54
                                </td>
                            </tr>
                            <tr >
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    Payments Office
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    Payments Office
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    All
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    approved
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    CT CHQ - 003200
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500">
                                    Thu, 11-Aug-22 08:58
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        </div>
    </div>
    
   </div>
</template>

<script>
import { PaperClipIcon } from '@heroicons/vue/solid'

export default {
     components: {
        PaperClipIcon,
    },

}
</script>

<style>

</style>